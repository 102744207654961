import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StatusPedido } from 'src/app/enum';
import { Pedido, Usuario } from 'src/app/models';
import { LoginService, PedidoService } from 'src/app/services';
import { MenuService } from 'src/app/services/menu.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { PedidosDetalheModalComponent } from '../pedidos-detalhe-modal/pedidos-detalhe-modal.component';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.scss']
})
export class PedidosComponent implements OnInit {

  pedidos: Pedido[];
  cliente: Usuario;
  mensagemListaVazia: boolean = false;
  pedidosBkp: Pedido[];
  areVendedor: boolean;
  usuarios: Usuario[] = [];

  constructor(
    private _service: PedidoService,
    private _loginService: LoginService,
    private _router: Router,
    private _menuService: MenuService,
    private _modalService: NgbModal,
    private _usuarioService: UsuarioService,
  ) { }

  ngOnInit() {
    this.areVendedor = this._loginService.areVendedor();

    if (this.areVendedor) {
      this.listar();
    }
  }

  listar() {
    this._service.ListarByVendedor(this._loginService.usuario.id)
      .subscribe(pedidos => {
        this.pedidos = pedidos
        this.pedidosBkp = pedidos;
        if (pedidos.length < 1) {
          this.mensagemListaVazia = true;
        }
      });
  }

  selecionar(pedido: Pedido) {
    // 
    
    const modal = this._modalService.open(PedidosDetalheModalComponent, { size: 'lg' });
    modal.componentInstance.pedido = pedido;

    modal.result.then(pedidoEditado => {
      for (const pedido of this.pedidos) {
        if (pedido.id == pedidoEditado.id) {
          // 
          pedido.status = pedidoEditado.status;
          pedido.estaFinalizado = pedidoEditado.estaFinalizado;
          pedido.dataHoraFinalizacao = pedidoEditado.dataHoraFinalizacao;
        }
      }
    }, () => { });
  }

  pesquisar(pesquisa: string) {
    this.pedidos = this.pedidosBkp;

    if (pesquisa != '') {
      this.pedidos = this.pedidos.filter(x => x.vendedor.nome.toLowerCase().trim().indexOf(pesquisa.toLowerCase().trim()) > -1);
    }

    if (this.pedidos.length < 1) {
      this.mensagemListaVazia = true;
    } else {
      this.mensagemListaVazia = false;
    }
  }

  retornaEnum(status: StatusPedido): string {
    switch (status) {
      case StatusPedido.Novo:
        return "Novo";
      case StatusPedido.Entregue:
        return "Entregue";
      case StatusPedido.EmTransporte:
        return "Em Transporte";
      case StatusPedido.EmSeparacao:
        return "Em Separação";
      case StatusPedido.AguardandoTransporte:
        return "Aguardando Transporte";
      case StatusPedido.AguardandoRetirada:
        return "Aguardando Retirada";
        break;
    }
  }
}
