<div class="card mt-3">
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <button type="button" class="btn btn-sm btn-outline-danger" (click)="voltarFiltro()"><i class="far fa-arrow-left"></i>&nbsp;Voltar</button>
        <button type="button" class="btn btn-sm btn-outline-primary float-right" (click)="verDetalhes()"><i class="far fa-search-dollar"></i>&nbsp;Detalhes</button>
      </div>
      <div class="col-12 col-md-5 col-lg-3 offset-lg-2">
        <div class="text-center text-md-right mt-4">
          <p class="mb-0">Período</p>
          <p>
            <strong>{{resultadoFiltroDto.filtroGastosDto.dataInicio | date:'dd/MM/yyyy'}}</strong>
            á
            <strong>{{resultadoFiltroDto.filtroGastosDto.dataFim | date:'dd/MM/yyyy'}}</strong>
          </p>

          <p class="mb-0">Organização</p>
          <p>
            <strong>{{resultadoFiltroDto.filtroGastosDto.organizacaoNome}}</strong>
          </p>

          <p class="mb-0">UF/Cidade</p>
          <p>
            <strong>{{resultadoFiltroDto.filtroGastosDto.uf}}/{{resultadoFiltroDto.filtroGastosDto.cidade? resultadoFiltroDto.filtroGastosDto.cidade: 'Todas'}}</strong>
          </p>

          <p class="mb-0">Centro de Custos</p>
          <p>
            <strong>{{resultadoFiltroDto.filtroGastosDto.centroCustoNome? resultadoFiltroDto.filtroGastosDto.centroCustoNome : 'Todos'}}</strong>
          </p>

          <p class="mb-0">Beneficiário</p>
          <p>
            <strong>{{resultadoFiltroDto.filtroGastosDto.beneficiarioNome? resultadoFiltroDto.filtroGastosDto.beneficiarioNome: 'Todos'}}</strong>
          </p>
        </div>
      </div>

      <div class="col-12 col-md-7 col-lg-6">
        <div class="text-center">
          <div class="mt-3 d-flex align-items-center" style="display: block;">
            <canvas baseChart [data]="doughnutChartData" [labels]="doughnutChartLabels" chartType="doughnut"
              [colors]="doughnutChartColors" [options]="doughnutChartOptions">
            </canvas>
            <span class="span-chart h3"><strong>{{resultadoFiltroDto.percentualSaldo}}%</strong></span>
          </div>
          <small class="mt-2 mb-0 text-muted">Saldo</small>
          <p>
            <strong class="span-chart h2" [class.text-danger]="resultadoFiltroDto.valorSaldo < 0">{{resultadoFiltroDto.valorSaldo | currency:'BRL'}}</strong>
          </p>

          <div class="row mt-5">
            <div class="col-6">
              <small class="mb-0 text-muted">Créditos</small>
              <p>
                <strong class="span-chart h5">{{resultadoFiltroDto.valorCreditos | currency:'BRL'}}</strong>
              </p>
            </div>
            <div class="col-6">
              <small class="mb-0 text-muted">Débitos</small>
              <p>
                <strong class="text-danger h5">{{resultadoFiltroDto.valorDebitos | currency:'BRL'}}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
