<app-modal title="Chat">
  <div class="row" style="height: 65vh; overflow-x: hidden;" [hidden]="tipoMidiaSelecionada != tipoMidia.Texto"
    [class.fade-animation]="fadeChat">
    <div class="col-md-6 col-lg-4" style="height: 65vh;">

      <div class="row">
        <div class="col-1">
          <a class="pointer" (click)="addAmigos()">
            <i class="fal fa-plus-circle fa-2x" ></i>
          </a>
        </div>

        <div class="col-11">
          <app-searchbox (search)="pesquisar($event)" placeholder="Pesquise o nome do contato" *ngIf="usuarios">
          </app-searchbox>
        </div>
      </div>

      <div style="height: 58vh; overflow-x: hidden; overflow-y: auto;">
        <p *ngIf="usuariosFiltrados?.length == 0" style="text-align: center;" class="mt-5">Sua lista de amigos está vazia</p>

        <div class="card border-0 rounded-0" [class.bg-light]="usuario.estaSelecionado" style="cursor: pointer;"
          *ngFor="let usuario of usuariosFiltrados; trackBy: trackByChatFn" (click)="selecionar(usuario, false)">
          <div class="row no-gutters">
            <div class="col-2 text-center mt-3">
              <img *ngIf="usuario.imagem" [src]="usuario.imagem" style="width: 54px; height: 54px;"
                class="rounded-circle usuario-borda" [class.selecionado]="usuario.estaSelecionado" alt="usuario.nome">
              <i *ngIf="!usuario.imagem" class="fas fa-user-circle text-muted rounded-circle usuario-borda"
                [class.selecionado]="usuario.estaSelecionado" style="font-size: 48px;"></i>
            </div>
            <div class="col-10 border-bottom">
              <div class="card-body mb-n2">
                <h6 class="card-title text-truncate">
                  <span [class.text-primary]="usuario.estaSelecionado">{{usuario.nome}}</span>
                  <br />
                  <small class="text-muted d-inline-block" *ngIf="!usuario.estaOnline"><i
                      class="fas fa-circle text-danger"></i> offline</small>
                  <small class="text-muted d-inline-block" *ngIf="usuario.estaOnline"><i
                      class="fas fa-circle text-success"></i> online</small>
                  <small class="text-muted d-inline-block" *ngIf="usuario.possuiNovasMensagens"> | <i
                      class="fas fa-circle text-primary"></i> novas mensagens</small>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-8" style="height: 65vh;">
      <div *ngIf="usuarioSelecionado" class="d-flex flex-column h-100">
        <div #chat class="flex-fill bg-light p-3" style="overflow-x: hidden; overflow-y: auto;">
          <div *ngFor="let chat of chats; trackBy: trackByChatFn">
            <div *ngIf="chat.usuarioDeletouId != usuarioLogadoId" class="mb-3 w-75 shadow-sm rounded p-3 bg-white"
              [class.bg-chat]="chat.enviadoPorMim" [class.ml-auto]="chat.enviadoPorMim">
              <button type="button" class="close" aria-label="Close" (click)="abrirModalExcluir(chat)">
                <span [ngbTooltip]="'Excluir mensagem'" aria-hidden="true">
                  <i class="far fa-trash-alt" style="font-size: 15px; padding: 10px;"></i>
                </span>
              </button>
              <span style="white-space: pre-wrap; word-wrap: break-word;"
                *ngIf="chat.tipo == tipoMidia.Texto">{{chat.conteudo}}</span>
              <div class="w-100 d-flex justify-content-center">
                <img [src]="chat.conteudo" class="img-fluid" *ngIf="chat.tipo == tipoMidia.Imagem"
                  style="max-height: 300px;" (click)="visualizarImagem(chat.conteudo)" />
                <video controls *ngIf="chat.tipo == tipoMidia.Video" style="max-height: 300px;">
                  <source [src]="sanitize(chat.conteudo)" type="video/mp4">
                  Seu browser não suporta reproduzir arquivos de vídeo.
                </video>
                <audio controls *ngIf="chat.tipo == tipoMidia.Audio">
                  <source [src]="sanitize(chat.conteudo)" type="audio/wav">
                  Seu browser não suporta reproduzir arquivos áudio.
                </audio>
                <a [href]="chat.conteudo" target="_blank" *ngIf="chat.tipo == tipoMidia.Arquivo"
                  class="text-decoration-none text-secondary">
                  <div class="border rounded-pill p-4 text-center bg-light mx-auto" style="width: 250px;">
                    <i class="far fa-file"></i> Arquivo
                  </div>
                </a>
              </div>
              <br />
              <small class="text-muted">
                <i class="far fa-check"
                  [ngbTooltip]="'Enviado em ' + _datePipe.transform(chat.dataHoraEnvio, 'dd/MM/yyyy HH:mm')"></i>
                <i class="far fa-check"
                  [ngbTooltip]="'Lido em ' + _datePipe.transform(chat.dataHoraLeitura, 'dd/MM/yyyy HH:mm')"
                  *ngIf="chat.dataHoraLeitura"></i>
                {{(chat.enviadoPorMim ? chat.dataHoraEnvio : chat.dataHoraLeitura) | date:'dd/MM/yyyy HH:mm'}}
              </small>
            </div>
          </div>

          <div *ngIf="chats?.length == 0"
            class="col-12 h-100 text-muted d-flex justify-content-center align-items-center flex-column">
            <div class="text-center">
              <i class="fad fa-comment-alt-dots fa-5x"></i>
              <p class="mt-5">Inicie agora mesmo uma conversa...</p>
            </div>
          </div>

        </div>

        <div class="pt-3 d-flex">
          <div class="w-100">
            <textarea #texto placeholder="Digite seu chat aqui..." type="text" autosize [maxRows]="5"
              class="form-control form-control-sm" (keydown.control.enter)="enviar()"
              (keydown.meta.enter)="enviar()"></textarea>
            <small class="text-muted">{{texto.value.length}} caracter(es) digitados.</small>
          </div>

          <button type="button" class="btn btn-primary rounded-circle ml-3"
            style="min-width: 48px; min-height: 48px; width: 48px; height: 48px;" ngbTooltip="Enviar mensagem"
            (click)="enviar()" *ngIf="texto.value.length > 0"><i class="fas fa-paper-plane"
              style="font-size: 24px; margin-top: 2px; margin-left: -4px;"></i></button>

          <button type="button" class="btn btn-primary rounded-circle ml-3"
            style="min-width: 48px; min-height: 48px; width: 48px; height: 48px;" ngbTooltip="Gravar áudio"
            (click)="iniciarGravacaoAudio()" *ngIf="texto.value.length == 0"><i class="fas fa-microphone"
              style="font-size: 24px; margin-top: 2px;"></i></button>

          <button type="button" class="btn btn-primary rounded-circle ml-3"
            style="min-width: 48px; min-height: 48px; width: 48px; height: 48px;" ngbTooltip="Mídia"
            (click)="fileInput.click()" *ngIf="texto.value.length == 0"><i class="fas fa-camera-alt"
              style="font-size: 24px; margin-top: 2px; margin-left: -1px;"></i></button>

          <button type="button" class="btn btn-primary rounded-circle ml-3"
            style="min-width: 48px; min-height: 48px; width: 48px; height: 48px;" ngbTooltip="Arquivo"
            (click)="fileInputArquivo.click()" *ngIf="texto.value.length == 0"><i class="fas fa-paperclip"
              style="font-size: 24px; margin-top: 2px; margin-left: -1px;"></i></button>

          <input #fileInput hidden type="file" accept="image/*,video/*" (change)="onFileChanged($event)">
          <input #fileInputArquivo hidden type="file" (change)="onfileArquivoChanged($event)">
        </div>
      </div>

      <div *ngIf="!usuarioSelecionado"
        class="bg-light text-muted d-flex justify-content-center align-items-center flex-column" style="height: 65vh;">
        <div class="text-center">
          <i class="fad fa-comments-alt fa-5x"></i>
          <p class="mt-5">Selecione algum usuário ao lado para começar...</p>
        </div>
      </div>
    </div>
  </div>

  <!-- Visualizar Foto -->
  <div class="row" style="height: 65vh" [hidden]="tipoMidiaSelecionada != tipoMidia.Imagem"
    [class.fade-animation]="fadeFoto">
    <img [src]="imagem" class="img-fluid mx-auto" (click)="fecharImagem()" style="max-height: 65vh;" />
  </div>

  <!-- Gravar Áudio -->
  <div class="row" [hidden]="tipoMidiaSelecionada != tipoMidia.Audio" [class.fade-animation]="fadeAudio"
    style="height: 65vh;">
    <div class="col-12 d-flex flex-column align-items-center justify-content-center mt-3">
      <lottie-player src="assets/animations/audio_record.json" background="transparent" speed="1"
        style="width: 180px; height: 180px;" loop autoplay>
      </lottie-player>
      <div class="mt-n3">
        <button type="button" class="btn rounded-circle btn-secondary mr-5"
          style="width: 48px; height: 48px; padding-top: 8px;" ngbTooltip="Abortar gravação de áudio"
          (click)="fecharGravacaoAudio()"><i class="fal fa-ban fa-lg"></i></button>

        <button type="button" class="btn rounded-circle btn-primary ml-5"
          style="width: 48px; height: 48px; padding-top: 8px;" ngbTooltip="Finalizar gravação de áudio"
          (click)="pararGravacaoAudio()"><i class="fal fa-check fa-lg"></i></button>
      </div>
    </div>
  </div>
</app-modal>