<div class="container">
  <form [formGroup]="filtroForm" novalidate autocomplete="off">
    <div class="row mt-3">
      <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
        <div class="row">
          <div class="col-4">
            <app-input>
              <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true" formControlName="uf"
                (change)="filtrar(filtroEnum.Estado)">
                <ng-option value="">Todos os Estados</ng-option>
                <ng-option *ngFor="let uf of ufs" [value]="uf.uf">{{uf.nome}}</ng-option>
              </ng-select>
            </app-input>
          </div>
          <div class="col-4">
            <app-input>
              <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true" formControlName="cidade"
                (change)="filtrar(filtroEnum.Cidade)">
                <ng-option value="">Todas as Cidades</ng-option>
                <ng-option *ngFor="let cidade of cidades" [value]="cidade.nome">{{cidade.nome}}</ng-option>
              </ng-select>
            </app-input>
          </div>
          <div class="col-4">
            <app-input>
              <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true" formControlName="bairro"
                (change)="filtrar(filtroEnum.Bairro)">
                <ng-option value="">Todos os Bairros</ng-option>
                <ng-option *ngFor="let bairro of bairros" [value]="bairro.nome">{{bairro.nome}}</ng-option>
              </ng-select>
            </app-input>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 mb-3">
      <app-denuncia [denuncias]="denuncias"></app-denuncia>
    </div>
    <div class="col-sm-1">
      <div class="btn-fixed-top">
        <button type="button" class="btn btn-secondary btn-radius" placement="bottom" ngbTooltip="Usar localização atual"
          (click)="usarLocalizacao()">
          <i class="fal fa-map-marker-alt"></i>
        </button>
      </div>

      <div class="btn-fixed2">
        <button type="button" class="btn btn-secondary btn-radius" placement="top" ngbTooltip="Adicionar"
          (click)="abrirAdicionar()">
          <i class="fal fa-plus"></i>
        </button>
      </div>

      <div class="btn-fixed">
        <button type="button" class="btn btn-secondary btn-radius" placement="top" ngbTooltip="Início"
          (click)="scrollTop()">
          <i class="fal fa-arrow-to-top"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="text-center mt-5" *ngIf="mensagemListaVazia">
    <div class="mb-3">
      <i class="fal fa-sad-tear text-muted fa-5x"></i>
    </div>
    <span class="text-muted">Nenhuma denúncia encontrada.</span>
  </div>

</div>
