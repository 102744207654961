import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Usuario } from 'src/app/models';
import { Contato } from 'src/app/models/contato.model';
import { LoginService } from 'src/app/services';
import { ContatoService } from 'src/app/services/contato.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { FiltroUsuario } from '../../filter/Filtro-usuario';
import { NotificationService } from '../../messages/notification.service';
import { SharedMasks } from '../../shared.mask';
import { ModalRemoverComponent } from '../modal-remover/modal-remover.component';

@Component({
  selector: 'app-modal-adicionar-usuarios',
  templateUrl: './modal-adicionar-usuarios.component.html',
  styleUrls: ['./modal-adicionar-usuarios.component.scss']
})
export class ModalAdicionarUsuariosComponent implements OnInit {

  form: UntypedFormGroup;
  filtro: UntypedFormGroup;
  filter: FiltroUsuario;
  telefoneMovelMask: any;
  usuariosEncontrados: Usuario[] = [];
  mostrarMensagem: boolean = false;
  areSubmitting: boolean;
  notAreSubmitting: boolean;
  nadaEncontrado: boolean;
  estaCarregando: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private _usuarioService: UsuarioService,
    private _loginService: LoginService,
    private _contatoService: ContatoService,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.gerarForm();
    this.notAreSubmitting = true;
  }

  gerarForm() {
    this.form = this.formBuilder.group({
      id: [null],
      usuario1Id: [null],
      usuario2Id: [null],
      usuario2Confirmado: [false],
    });

    this.filtro = this.formBuilder.group({
      email: [null],
      telefone: [null]
    });

    // Atribuir Mascaras
    this.telefoneMovelMask = SharedMasks.telefoneMovelMask;
  }

  filtrarUsuarios(filtro) {
    this.estaCarregando = true;

    this.filter = filtro;
    this.filter.usuarioLogado1Id = this._loginService.usuario.id;

    this._usuarioService.filtrar(this.filter).subscribe(usuarios => {
      this.usuariosEncontrados = usuarios;

      

      if (usuarios.length == 0)
        this.nadaEncontrado = true;

    }, () => this.estaCarregando = false);

    if (this.usuariosEncontrados.length > 0)
      this.mostrarMensagem = true;

    this.estaCarregando = false;
  }

  selecionouUsuario(usuario) {
    this.adicionarUsuario(usuario);
  }

  adicionarUsuario(usuario) {
    var contato = new Contato()

    contato.usuario1Id = this._loginService.usuario.id;
    contato.usuario2Id = usuario.id;

    this._contatoService.salvar(contato)
      .subscribe(resp => {
        usuario.pedidoAmizadeEnviado = true;
        this.activeModal.close(usuario);
      }, error => usuario.pedidoAmizadeEnviado = false);
  }

  confirmarUsuario(usuario) {
    this._contatoService.encontrarPorUsuarioSalvar(usuario.id)
      .subscribe(resp => {
        this.notificationService.notifty(`Solicitação aceita com sucesso!`);
        usuario.pedidoConfirmado = true;
        usuario.pedidoAmizadeRecebido = false;
      }, error => usuario.pedidoAmizadeRecebido = false);
  }

  removerUsuario(usuario) {
    

    const modalRef = this.modalService.open(ModalRemoverComponent, { centered: true });
    modalRef.componentInstance.mensagem = `Deseja realmente desfazer a amizade com ${usuario.nome}?`;
    modalRef.componentInstance.titulo = `Remover`;

    modalRef.result.then(confirmaRemocao => {
      if (confirmaRemocao) {
        usuario.pedidoConfirmado = false;
        this._contatoService.encontrarPorUsuarioRemover(usuario.id).subscribe(_ => {
          this.notificationService.notifty(`Sua amizade foi desfeita!`);
        });
        usuario.pedidoConfirmado = false;
      }
    }, dismiss => { });
    

  }

  cancelarAmizadeUsuario(usuario) {
    const modalRef = this.modalService.open(ModalRemoverComponent, { centered: true });
    modalRef.componentInstance.mensagem = `Deseja realmente cancelar a solicitação de amizade com ${usuario.nome}?`;
    modalRef.componentInstance.titulo = `Cancelar`;

    modalRef.result.then(confirmaRemocao => {
      if (confirmaRemocao) {
        usuario.pedidoAmizadeEnviado = false;
        this._contatoService.encontrarPorUsuarioRemover(usuario.id).subscribe(_ => {
          this.notificationService.notifty(`Seu pedido de amizade foi desfeito!`);
        });
      }
    }, dismiss => { });
  }

  verificarValorCampoTelefone(filtro) {
    if (filtro.telefone != null)
      this.filtro.controls.telefone.setValue(null)
  }

  verificarValorCampoEmail(filtro) {
    if (filtro.email != null)
      this.filtro.controls.email.setValue(null)
  }
}
