<nav aria-label="breadcrumb" *ngIf="!hiddingToPerfil">
  <ol class="breadcrumb b-radius breadcrumb-appckground mt-3">
    <a class="breadcrumb-item mt-1" aria-current="page" [routerLink]="['/usuarios']"><i class="fas fa-user"></i>&nbsp;Usuários</a>
    <li class="breadcrumb-item active breadcrumb-link-actual mt-1" aria-current="page">Adicionar</li>
    <li class="ml-auto">
      <button type="button" class="btn btn-sm btn-outline-primary btn-on" [routerLink]="['/usuarios']">
        <i class="fal fa-list"></i>
        <span class="d-none d-lg-inline-flex">&nbsp;Listar</span>
      </button>
    </li>
  </ol>
</nav>
<nav aria-label="breadcrumb" *ngIf="hiddingToPerfil">
  <ol class="breadcrumb b-radius breadcrumb-appckground mt-3">
    <li class="breadcrumb-item active breadcrumb-link-actual mt-1" aria-current="page"><i class="fal fa-user-cog"></i>&nbsp;Perfil</li>
  </ol>
</nav>
<form [formGroup]="usuarioForm" novalidate autocomplete="off">
  <input type="hidden" formControlName="id" />
  <div class="row">
    <div class="col-lg-9 order-2 order-lg-1">
      <div class="row">
        <div class="col-12">
          <app-input label="Nome" errorMessage="Campo obrigatório." [isRequired]="true">
            <input #firstElement type="text" formControlName="nome" class="form-control form-control-sm" placeholder="Nome do usuário"/>
          </app-input>
        </div>

        <div class="col">
          <app-input label="E-mail" errorMessage="Campo obrigatório." [isRequired]="true">
            <input type="email" formControlName="email" class="form-control form-control-sm" placeholder="email@email.com.br" />
          </app-input>
        </div>

        <div class="col-6" *ngIf="!hiddingPassword">
          <app-input label="Senha" errorMessage="Campo obrigatório." [isRequired]="true">
            <input type="password" formControlName="senha" class="form-control form-control-sm" placeholder="Senha" />
          </app-input>
        </div>
      </div>
    </div>
    <div class="col-lg-3 order-1 order-lg-2">
      <div class="row">
        <div class="col">
          <app-input label="Imagem do usuário" labelAlign="text-center" (change)="onFileChanged($event)">
            <app-img formControlName="imagem" icone="fas fa-user"></app-img>
          </app-input>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <button type="submit" class="btn btn-sm btn-primary btn-on-full mr-2" (click)="salvar(usuarioForm.value)" [disabled]="areSubmitting || usuarioForm.invalid">
    <i class="fal fa-check"></i>&nbsp;Salvar</button>
  <button type="button" class="btn btn-sm btn-outline-danger btn-on float-right" (click)="remover()" *ngIf="usuarioForm.controls.id.value != null && !hiddingToPerfil">
    <i class="fal fa-trash"></i>
    <span class="d-none d-lg-inline-flex">&nbsp;Remover</span></button>
</form>
