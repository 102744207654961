import { Component, OnInit } from '@angular/core';
import { Postagem, Politico } from 'src/app/models';
import { PostagemService } from 'src/app/services/postagem.service';
import { PoliticoService } from 'src/app/services';
import { UfService } from 'src/app/shared/uf.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-postagem-lista',
  templateUrl: './postagem-lista.component.html',
  styleUrls: ['./postagem-lista.component.scss']
})
export class PostagemListaComponent implements OnInit {

  postagens: Postagem[];
  politicos: Politico[];
  ufs: any[];
  mensagemListaVazia: boolean = false;
  statusProp: string;
  filtroForm: UntypedFormGroup;
  id: string;

  constructor(
    private postagemService: PostagemService,
    private politicoService: PoliticoService,
    private ufService: UfService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.listar();

    this.filtroForm = this.formBuilder.group({
      politicoId: [null],
      uf: [null],
      pesquisa: [null],
    });

    // Listar Adicionais
    this.ufs = this.ufService.listar();
    this.politicoService.listar()
      .subscribe(politicos => this.politicos = politicos);

    // Verificar se esta editando o politico
    this.id = this.route.snapshot.fragment;
    if (this.id != null) {
      this.filtroForm.controls.politicoId.setValue(this.id);
      // location.replaceState('/postagens');
    };
  }

  listar() {
    this.postagemService.aprovando()
      .subscribe(postagens => {
        this.postagens = postagens.filter(x => x.midias.length > 0);
        if (postagens.length < 1) {
          this.mensagemListaVazia = true;
        }
      });
  }

  pesquisar(pesquisa: string) {
    this.postagemService.listar(pesquisa)
      .subscribe(postagens => this.postagens = postagens);
  }
}
