export const environment = {
  production: false,
  api: 'https://olharnacional.ddns.net/api',
  mapsApi: 'https://onmaps.olharnacional.ddns.net/api',
  geocodeApi: 'https://geocode.maps.co/',
  signalR: 'https://olharnacional.ddns.net/api',
  applicationServerPublicKey: `AAAA2nVEBpE:APA91bHjdcQu8fDS-7HSe_mElNc9CnWoVJu0iCObxSLokrclLaegXOkUTfgTgS2utXt6rvU1gsNYzPZ3sCc2wcw7Y0nzf567SmEKULvGaSboYXhtZulqkplM3T21hY-163z4iCPmVJ1d`,
  onStockApi: 'https://onstock.olharnacional.ddns.net/api'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
