import { FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@alenosmanovic91x/angularx-social-login';
import { DatePipe, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbActiveModal, NgbDateParserFormatter, NgbDatepickerI18n, NgbModalConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { GoogleChartsModule } from 'angular-google-charts';
import { TextMaskModule } from 'angular2-text-mask';
import { ChartsModule } from 'ng2-charts';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { AutosizeModule } from 'ngx-autosize';
import { ROUTES } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppInterceptor } from './app.interceptor';
import { NotificationsService } from './core/generated';
import { BeneficiarioListaComponent } from './pages/beneficiario/beneficiario-lista/beneficiario-lista.component';
import { BeneficiarioComponent } from './pages/beneficiario/beneficiario.component';
import { CentroCustoListaComponent } from './pages/centro-custo/centro-custo-lista/centro-custo-lista.component';
import { CentroCustoComponent } from './pages/centro-custo/centro-custo.component';
import { ChatListaComponent } from './pages/chat/chat-lista/chat-lista.component';
import { ContainerPropostasDenunciasComponent } from './pages/container-propostas-denuncias/container-propostas-denuncias.component';
import { DenunciaCadastroComponent } from './pages/denuncia/denuncia-cadastro/denuncia-cadastro.component';
import { DenunciaDetalheComponent } from './pages/denuncia/denuncia-detalhe/denuncia-detalhe.component';
import { DenunciaItemComponent } from './pages/denuncia/denuncia-item/denuncia-item.component';
import { DenunciaListaComponent } from './pages/denuncia/denuncia-lista/denuncia-lista.component';
import { DenunciaMenuComponent } from './pages/denuncia/denuncia-menu/denuncia-menu.component';
import { DenunciaReportadaComponent } from './pages/denuncia/denuncia-reportada/denuncia-reportada.component';
import { DenunciaVideoComponent } from './pages/denuncia/denuncia-video/denuncia-video.component';
import { DenunciaComponent } from './pages/denuncia/denuncia.component';
import { FeedDetalheComponent } from './pages/feed/feed-detalhe/feed-detalhe.component';
import { ComentarioListaReportadosComponent } from './pages/feed/feed-item/comentario-lista-reportados/comentario-lista-reportados.component';
import { ComentarioComponent } from './pages/feed/feed-item/comentario/comentario.component';
import { FeedItemComponent } from './pages/feed/feed-item/feed-item.component';
import { FeedMenuComponent } from './pages/feed/feed-menu/feed-menu.component';
import { FeedComponent } from './pages/feed/feed.component';
import { GastoPublicoFiltroComponent } from './pages/gasto-publico/gasto-publico-filtro/gasto-publico-filtro.component';
import { GastoPublicoDetalheComponent } from './pages/gasto-publico/gasto-publico-grafico/gasto-publico-detalhe/gasto-publico-detalhe.component';
import { GastoPublicoGraficoComponent } from './pages/gasto-publico/gasto-publico-grafico/gasto-publico-grafico.component';
import { GastoPublicoComponent } from './pages/gasto-publico/gasto-publico.component';
import { LancamentoListaComponent } from './pages/lancamento/lancamento-lista/lancamento-lista.component';
import { LancamentoComponent } from './pages/lancamento/lancamento.component';
import { LoginComponent } from './pages/login/login.component';
import { MenuComponent } from './pages/menu/menu.component';
import { OrganizacaoListaComponent } from './pages/organizacao/organizacao-lista/organizacao-lista.component';
import { OrganizacaoComponent } from './pages/organizacao/organizacao.component';
import { PedidosDetalheModalComponent } from './pages/pedidos-detalhe-modal/pedidos-detalhe-modal.component';
import { PedidosComponent } from './pages/pedidos/pedidos.component';
import { PoliticaPrivacidadeComponent } from './pages/politica-privacidade/politica-privacidade.component';
import { PoliticoListaComponent } from './pages/politico/politico-lista/politico-lista.component';
import { PoliticoComponent } from './pages/politico/politico.component';
import { PostagemCadastroComponent } from './pages/postagem/postagem-cadastro/postagem-cadastro.component';
import { PostagemDetalheComponent } from './pages/postagem/postagem-detalhe/postagem-detalhe.component';
import { PostagemItemComponent } from './pages/postagem/postagem-item/postagem-item.component';
import { PostagemListaComponent } from './pages/postagem/postagem-lista/postagem-lista.component';
import { PostagemMenuComponent } from './pages/postagem/postagem-menu/postagem-menu.component';
import { PostagemReportadaComponent } from './pages/postagem/postagem-reportada/postagem-reportada.component';
import { PostagemVideoComponent } from './pages/postagem/postagem-video/postagem-video.component';
import { PostagemComponent } from './pages/postagem/postagem.component';
import { ProdutosListaComponent } from './pages/produtos/produtos-lista/produtos-lista.component';
import { ProdutosComponent } from './pages/produtos/produtos.component';
import { PropostaListaComponent } from './pages/proposta/proposta-lista/proposta-lista.component';
import { PropostaComponent } from './pages/proposta/proposta.component';
import { RoomLiveListComponent } from './pages/room-live/room-live-list/room-live-list.component';
import { RoomLiveComponent } from './pages/room-live/room-live/room-live.component';
import { SuporteComponent } from './pages/suporte/suporte.component';
import { UsuarioAlterarSenhaComponent } from './pages/usuario/usuario-alterar-senha/usuario-alterar-senha.component';
import { UsuarioListaBloqueadosComponent } from './pages/usuario/usuario-lista-bloqueados/usuario-lista-bloqueados.component';
import { UsuarioListaComponent } from './pages/usuario/usuario-lista/usuario-lista.component';
import { UsuarioComponent } from './pages/usuario/usuario.component';
import { UsuariosDenunciadosComponent } from './pages/usuario/usuarios-denunciados/usuarios-denunciados.component';
import { VendedoresListaComponent } from './pages/vendedores/vendedores-lista/vendedores-lista.component';
import { VendedoresComponent } from './pages/vendedores/vendedores.component';
import { AuthGuardService, AvaliacaoService, BandeiraCartaoService, BeneficiarioService, CarrinhoCompraService, CartaoCreditoService, CentroCustoService, ComentarioService, EnderecoService, GosteiService, LancamentoService, LoginService, OnMapsService, OrganizacaoService, PedidoService, PoliticoService, ProdutoService, PropostaService, VendedorService } from './services';
import { ChatService } from './services/chat.service';
import { ContatoService } from './services/contato.service';
import { DenunciaService } from './services/denuncia.service';
import { MenuService } from './services/menu.service';
import { PostagemService } from './services/postagem.service';
import { RoomLiveService } from './services/room-live.service';
import { UsuarioService } from './services/usuario.service';
import { AudComponent } from './shared/aud/aud.component';
import { AudioRecordService } from './shared/aud/audio-record.service';
import { ImgComponent } from './shared/img/img.component';
import { InputComponent } from './shared/input/input.component';
import { NotificationService } from './shared/messages/notification.service';
import { SnackbarComponent } from './shared/messages/snackbar/snackbar.component';
import { ModalAcaoComponent } from './shared/modal/modal-acao/modal-acao.component';
import { ModalAdicionarUsuariosComponent } from './shared/modal/modal-adicionar-usuarios/modal-adicionar-usuarios.component';
import { ModalRemoverMensagemComponent } from './shared/modal/modal-remover-mensagem/modal-remover-mensagem/modal-remover-mensagem.component';
import { ModalRemoverComponent } from './shared/modal/modal-remover/modal-remover.component';
import { ModalComponent } from './shared/modal/modal.component';
import { SearchboxComponent } from './shared/searchbox/searchbox.component';
import { NgbDatePtParserFormatter } from './shared/shared.custom-date-adapter';
import { CustomDatepickerI18n, I18n } from './shared/shared.custom-date-i18n';
import { StatusService } from './shared/status.service';
import { UfService } from './shared/uf.service';
import { VidComponent } from './shared/vid/vid.component';

registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    PoliticoComponent,
    InputComponent,
    ImgComponent,
    PoliticoListaComponent,
    ModalComponent,
    ModalRemoverComponent,
    ModalAcaoComponent,
    SnackbarComponent,
    SearchboxComponent,
    PropostaComponent,
    PropostaListaComponent,
    UsuarioComponent,
    UsuarioListaComponent,
    LoginComponent,
    UsuarioAlterarSenhaComponent,
    FeedComponent,
    FeedMenuComponent,
    FeedItemComponent,
    FeedDetalheComponent,
    ComentarioComponent,
    UsuarioListaBloqueadosComponent,
    ComentarioListaReportadosComponent,
    ContainerPropostasDenunciasComponent,
    DenunciaComponent,
    DenunciaMenuComponent,
    DenunciaItemComponent,
    DenunciaDetalheComponent,
    DenunciaCadastroComponent,
    DenunciaListaComponent,
    DenunciaReportadaComponent,
    BeneficiarioComponent,
    BeneficiarioListaComponent,
    OrganizacaoComponent,
    OrganizacaoListaComponent,
    LancamentoComponent,
    LancamentoListaComponent,
    GastoPublicoComponent,
    CentroCustoComponent,
    CentroCustoListaComponent,
    GastoPublicoGraficoComponent,
    GastoPublicoDetalheComponent,
    GastoPublicoFiltroComponent,
    DenunciaVideoComponent,
    PostagemComponent,
    PostagemItemComponent,
    PostagemCadastroComponent,
    PostagemDetalheComponent,
    PostagemListaComponent,
    PostagemMenuComponent,
    PostagemReportadaComponent,
    PostagemVideoComponent,
    ChatListaComponent,
    AudComponent,
    VidComponent,
    ProdutosComponent,
    ProdutosListaComponent,
    VendedoresComponent,
    VendedoresListaComponent,
    ModalRemoverMensagemComponent,
    PedidosComponent,
    PedidosDetalheModalComponent,
    ModalAdicionarUsuariosComponent,
    PoliticaPrivacidadeComponent,
    SuporteComponent,
    UsuariosDenunciadosComponent,
    RoomLiveListComponent,
    RoomLiveComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(ROUTES),
    NgbModule,
    NgSelectModule,
    TextMaskModule,
    CurrencyMaskModule,
    GoogleChartsModule.forRoot(),
    ChartsModule,
    AutosizeModule,
    LoadingBarHttpClientModule,
    SocialLoginModule
  ],
  providers: [
    PoliticoService,
    NotificationService,
    PropostaService,
    UfService,
    StatusService,
    UsuarioService,
    LoginService,
    ComentarioService,
    AvaliacaoService,
    DenunciaService,
    GosteiService,
    OnMapsService,
    NgbActiveModal,
    AuthGuardService,
    BeneficiarioService,
    LancamentoService,
    OrganizacaoService,
    CentroCustoService,
    PostagemService,
    AudioRecordService,
    MenuService,
    DatePipe,
    ChatService,
    NotificationsService,
    VendedorService,
    ProdutoService,
    CartaoCreditoService,
    BandeiraCartaoService,
    EnderecoService,
    CarrinhoCompraService,
    PedidoService,
    ContatoService,
    RoomLiveService,
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt' },
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    [{ provide: NgbDateParserFormatter, useClass: NgbDatePtParserFormatter }],
    [{ provide: NgbModalConfig, useValue: { backdrop: false } }],
    // { provide: AuthServiceConfig, useFactory: provideConfig },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('177562439808032')
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
  //Lottie
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
