import { Component, OnInit } from '@angular/core';
import { Politico, Estado, Cidade } from 'src/app/models';
import { PoliticoService, OnMapsService, LoginService } from 'src/app/services';
import { NotificationService } from 'src/app/shared/messages/notification.service';
import { Location } from '@angular/common';
import { TipoMidia } from 'src/app/enum/tipo-midia';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-politico',
  templateUrl: './politico.component.html',
  styleUrls: ['./politico.component.scss']
})
export class PoliticoComponent implements OnInit {

  politico: Politico;
  politicoForm: UntypedFormGroup;
  areSubmitting: boolean;
  id: string;
  emailFormArray: UntypedFormArray;
  areAdmin: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private politicoService: PoliticoService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    this.areAdmin = this.loginService.areAdmin();

    this.politicoForm = this.formBuilder.group({
      id: [null],
      nome: [null, Validators.required],
      cargo: [null, Validators.required],
      siglaPartido: [null],
      imagem: [null, Validators.required],
      biografia: [null, Validators.required],
      email: [null],
      receberEmail: [true],
      emails: this.formBuilder.array([]),
    });

    // Verificar se esta editando o politico
    this.id = this.route.snapshot.params['id'];
    if (this.id != null) {
      this.politicoService.encontrar(this.id).subscribe(politico => {
        this.politicoForm.patchValue(politico);

        if (politico.acessores) {
          politico.acessores.forEach(email => {
            this.addEmails(email);
          });
        }

        // this.location.replaceState('/politicos');
      })
    }
  }

  salvar(politico: Politico) {
    this.areSubmitting = true;

    politico.acessores = [];
    this.politicoForm.controls.emails.value.forEach(acessor => {
      if (acessor.endereco != null) {
        politico.acessores.push(acessor.endereco);
      }
    });

    this.politicoService.salvar(politico)
      .subscribe(resp => {
        this.notificationService.notifty(`O político ${politico.nome} foi salvo com sucesso.`);
        this.resetForm();
        this.areSubmitting = false;
      }, error => this.areSubmitting = false);
  }

  remover() {
    this.politicoService.remover(this.id).subscribe(_ => {
      this.notificationService.notifty(`O político ${this.politicoForm.value.nome} foi removida.`);
      this.resetForm();
    });
  }

  verPropostas() {
    this.router.navigate(['/menu-propostas'], { fragment: this.id });
  }

  resetForm() {
    this.router.navigate(['/politicos']);
  }

  renderEmail(email: string): UntypedFormGroup {
    return this.formBuilder.group({
      endereco: [email || ''],
    });
  }

  addEmails(email: string) {
    this.emailFormArray = <UntypedFormArray>this.politicoForm.get('emails');
    this.emailFormArray.push(this.renderEmail(email));
  }

  getEmailsFormArray(): UntypedFormArray {
    return <UntypedFormArray>this.politicoForm.get('emails');
  }

  removeEmail(index: number) {
    this.emailFormArray = <UntypedFormArray>this.politicoForm.get('emails');
    this.emailFormArray.removeAt(index);
  }

  onFileChanged(event) {
    const file = event.target.files[0];

    if (file) {
      const tipo = file.type.includes('image') ? TipoMidia.Imagem : TipoMidia.Video;
      const mb = tipo == TipoMidia.Imagem ? 6 : 64;
      const maxSize = mb * 10000000;

      if (file.size > maxSize) {
        this.notificationService.notifty(`O tamanho ${tipo == TipoMidia.Imagem ? 'da imagem' : 'do vídeo'} não pode exceder ${mb}mb`);
      } else {
        this.politicoService
          .salvarMidia(file, tipo)
          .subscribe((url: string) => {
            if (url) {
              url = url.split('"').join('');
              this.politicoForm.controls.imagem.setValue(url);
            }
          });
      }
    }

  }
}
