import { Lancamento } from "./lancamento.model";

export class Beneficiario {
  id: string;
  imagem: string;
  nome: string;
  uf: string;
  cidade: string;
  endereco: string;
  telefone: string;
  ramoAtividade: string;
  organizacaoId: string;
  lancamentos: Lancamento[];

  constructor(id?: string, nome?: string) {
    this.id = id;
    this.nome = nome;
  }
}
