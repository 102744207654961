import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { Usuario, Login } from '../models';
import { environment } from 'src/environments/environment';
import { ResetarSenhaDto } from '../models/resetar-senha-dto.model';
import { Router, NavigationEnd } from '@angular/router';
import { SocialAuthService, SocialUser } from '@alenosmanovic91x/angularx-social-login';

@Directive()
@Injectable()
export class LoginService {

    usuario: Usuario;
    lastUrl: string;

    @Output() alterou = new EventEmitter<Usuario>();
    @Output() handle = new EventEmitter();
    @Output() logou = new EventEmitter();
    @Output() deslogou = new EventEmitter();

    constructor(
        private http: HttpClient,
        private router: Router,
        private authService: SocialAuthService
    ) {
        this.router.events.pipe(
            filter(e => e instanceof NavigationEnd)
        )
            .subscribe((e: NavigationEnd) => this.lastUrl = e.url);
    }

    isLoggedIn(): boolean {
        this.usuario = JSON.parse(localStorage.getItem('usuario'));
        return this.usuario != null;
    }

    getToken(): string {
        if (this.isLoggedIn()) {
            return this.usuario.token;
        }

        return null;
    }

    canAccessRoute(roles: string[]): boolean {
        if (!this.isLoggedIn())
            return false;

        if (!roles)
            return true;

        return roles.some(x => x === this.usuario.perfil);
    }

    handleLogin() {
        this.handle.emit();
    }

    login(login: Login, coordenadas: string): Observable<Usuario> {
        return this.http.post<Usuario>(`${environment.api}/usuarios/login/?coordenadas=${coordenadas}`,
            {
                email: login.email,
                senha: login.senha
            }).pipe(tap(usuario => {

                if (usuario.reportado && usuario.verificado) {
                }
                else {
                    localStorage.setItem('usuario', JSON.stringify(usuario));
                    this.alterou.emit(usuario);
                    this.logou.emit();
                }
            }));

    }

    encontrarLoginPorEmail(email: string): Observable<Usuario> {
        return this.http.get<Usuario>(`${environment.api}/usuarios/gerar-codigo/${email}`);
    }

    cadastrarNovaSenha(dto: ResetarSenhaDto) {
        return this.http.post<ResetarSenhaDto>(`${environment.api}/usuarios/resetar-senha`, dto)
    }

    alterarUsuario(usuario: Usuario) {
        this.usuario.nome = usuario.nome;
        this.usuario.imagem = usuario.imagem;
        localStorage.setItem('usuario', JSON.stringify(this.usuario));
        this.alterou.emit(this.usuario);
    }

    loginFacebook(user: SocialUser, coordenadas: string): Observable<Usuario> {
        return this.http.post<Usuario>(`${environment.api}/usuarios/login-social/?coordenadas=${coordenadas}`,
            {
                provider: 'facebook',
                id: user.id,
                perfil: 'user',
                email: user.email,
                nome: user.name,
                imagem: user.photoUrl
            }).pipe(tap(usuario => {
                localStorage.setItem('usuario', JSON.stringify(usuario));
                this.logou.emit();
            }));
    }

    logout() {
        this.router.navigateByUrl('/');
        localStorage.removeItem('usuario');

        try {
            this.authService.signOut();
        } catch (error) {
        }

        this.deslogou.emit();
    }

    areAdmin(): boolean {
        if (!this.isLoggedIn()) {
            return false;
        }
        return this.usuario.perfil == 'admin';
    }

    areVendedor(): boolean {
        if (!this.isLoggedIn()) {
            return false;
        }
        return this.usuario.perfil == 'vendedor';
    }

    getUsuario(): Usuario {
        if (this.isLoggedIn())
            return this.usuario;
        else
            return null;
    }
}