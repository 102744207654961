import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Comentario, Denuncia, Gostei, GosteiDto, Midia } from 'src/app/models';
import { LoginService, ComentarioService, DenunciaService, GosteiService } from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/shared/messages/notification.service';
import { ComentarioComponent } from '../../feed/feed-item/comentario/comentario.component';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ModalRemoverComponent } from 'src/app/shared/modal/modal-remover/modal-remover.component';
import { TipoMidia } from 'src/app/enum';
import { DenunciaVideoComponent } from '../denuncia-video/denuncia-video.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-denuncia-item',
  templateUrl: './denuncia-item.component.html',
  styleUrls: ['./denuncia-item.component.scss']
})
export class DenunciaItemComponent implements OnInit {

  id: string;
  comentarioForm: UntypedFormGroup;
  areSubmitting: boolean = false;
  areAdmin: boolean = false;
  comentarios: Comentario[];
  areLoggedIn: boolean = false;
  showMessage = false;
  usuarioId: string;
  @Input() denuncia: Denuncia;
  @Input() mostrarDetalhes = false;
  @Input() gostei?: boolean = null;
  @Output() login = new EventEmitter();
  tipo = TipoMidia;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private comentarioService: ComentarioService,
    private gosteiService: GosteiService,
    private denunciaService: DenunciaService,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private usuarioService: UsuarioService,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.comentarioForm = this.formBuilder.group({
      texto: [null]
    });

    this.areLoggedIn = this.loginService.isLoggedIn();

    if (this.areLoggedIn && this.loginService.usuario.perfil === "admin") {
      this.areAdmin = true;
    }

    // Verificar se tem id na rota
    this.id = this.route.snapshot.params['id'];

    if (this.id) {
      this.denunciaService.encontrar(this.id).subscribe(denuncia => {
        denuncia.midias.forEach(midia => {
          if (midia.tipoMidia == TipoMidia.Video)
            midia.url = `${midia.url}/thumb`;
        });
        this.denuncia = denuncia;
      });
    }

    if (this.areLoggedIn) {
      this.usuarioId = this.loginService.usuario.id;

      var dto = new GosteiDto;

      if (this.id != null) {
        dto.denunciaId = this.id;
      }
      else {
        dto.denunciaId = this.denuncia.id;
      }
      dto.usuarioId = this.loginService.usuario.id;

      this.gosteiService.rateDenuncia(dto).subscribe(gostei => {
        if (gostei != null) {
          this.gostei = gostei.like;
        }
      });
    }

    this.getComentarios();

    if (this.denuncia != null) {
      this.denuncia.midias = this.denuncia.midias.filter(x => x.aprovada || x.aprovada == null);
    }
  }

  getComentarios() {
    if (this.id) {
      this.comentarioService.listarPorDenuncia(this.id).subscribe(comentarios => {
        this.comentarios = comentarios;
        this.showMessage = comentarios.length < 1;
      });
    };
  }

  comentar() {
    if (this.areLoggedIn) {
      var comentario = new Comentario;
      comentario.texto = this.comentarioForm.value.texto;
      comentario.usuarioId = this.loginService.usuario.id;
      comentario.denunciaId = this.denuncia.id;
      comentario.dataHora = new Date();

      this.comentarioService.salvar(comentario)
        .subscribe(resp => {
          this.areSubmitting = false;
          this.getComentarios();
          this.comentarioForm.reset();
          this.notificationService.notifty(`Comentado com sucesso!`);
        }, error => this.areSubmitting = false);
    }
    else {
      this.loginService.handleLogin();
    }
  }

  abrirVideo(midia: Midia, titulo: string) {
    if (midia.tipoMidia == TipoMidia.Video) {
      const modalRef = this.modalService.open(DenunciaVideoComponent, { centered: true, size: 'lg' });
      modalRef.componentInstance.title = titulo;
      modalRef.componentInstance.url = this.sanitizer.bypassSecurityTrustResourceUrl(midia.url.replace("/thumb", "/preview"));
    }
  }

  verMais(comentario: Comentario) {
    if (this.areLoggedIn) {
      const modalRef = this.modalService.open(ComentarioComponent, { centered: true });
      modalRef.result.then(confirma => {
        this.getComentarios();
      }, dismiss => { });

      modalRef.componentInstance.comentario = comentario;
    }
    else {
      this.loginService.handleLogin();
    }
  }

  like(gostei: boolean) {
    if (this.areLoggedIn) {
      this.gostei = gostei;
      var avaliacao = new Gostei;
      avaliacao.like = this.gostei;
      avaliacao.denunciaId = this.denuncia.id;
      avaliacao.usuarioId = this.loginService.usuario.id;

      this.gosteiService.salvar(avaliacao)
        .subscribe(resp => {
          this.areSubmitting = false;
          this.denunciaService.encontrar(avaliacao.denunciaId).subscribe(denuncia => {
            denuncia.midias.forEach(midia => {
              if (midia.tipoMidia == TipoMidia.Video)
                midia.url = `${midia.url}/thumb`;
            });
            this.denuncia = denuncia;
          });
        }, error => this.areSubmitting = false);
    }
    else {
      this.loginService.handleLogin();
    }
  }

  denunciar(denuncia: Denuncia) {
    if (this.areLoggedIn) {
      denuncia.reportado = true;
      this.denunciaService.salvar(denuncia).subscribe(() => {
        this.notificationService.notifty(`Denúnciado com sucesso!`)
        this.denunciaService.tiraBolinhaDenuncia();
      }
        , error => this.notificationService.notifty(`Erro ao tentar denúnciar`));
    }
    else {
      this.loginService.handleLogin();
    }
  }

  remover(denuncia: Denuncia) {
    if (this.areLoggedIn) {
      const modalRef = this.modalService.open(ModalRemoverComponent, { centered: true });
      modalRef.componentInstance.mensagem = `Deseja realmente remover a denuncia ${this.denuncia.titulo}?`;
      modalRef.componentInstance.titulo = `Remover`;

      modalRef.result.then(confirmaRemocao => {
        if (confirmaRemocao) {
          this.denunciaService.remover(denuncia.id).subscribe(_ => {
            this.notificationService.notifty(`Denúnciada removida!`);
            this.denunciaService.atualizaDenuncias();
            this.router.navigate(['/feed/denuncias']);
          });
        }
      }, dismiss => { });
    }
    else {
      this.loginService.handleLogin();
    }
  }

  bloquear(denuncia: Denuncia) {

    if (this.id) {
      this.router.navigate(['feed/denuncias']);
    }

    this.areSubmitting = true;
    if (this.areLoggedIn) {

      this.usuarioService.bloquear(this.loginService.usuario.id, denuncia.usuarioId).subscribe(() => {
        this.denunciaService.atualizaDenuncias();
        this.notificationService.notifty(`Bloqueado com sucesso!`)
        this.areSubmitting = false;

      }, error => {
        this.areSubmitting = false;
        this.notificationService.notifty(`Erro ao tentar bloquear`)
      });
    }
    else {
      this.loginService.handleLogin();
    }
  }
}
