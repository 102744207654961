import { Injectable } from '@angular/core';
import { StatusProposta } from '../pages/proposta/status/status-proposta';

@Injectable()
export class StatusService {
    listar() {
        return [
            { key: 'Proposta', value: StatusProposta.proposta},
            { key: 'Aprovado Câmara',value: StatusProposta.aprovadoCamara},
            { key: 'Aprovado Senado',value: StatusProposta.aprovadoSenado},
            { key: 'Aprovado',value: StatusProposta.aprovado},
            { key: 'Rejeitada Câmara',value: StatusProposta.rejeitadaCamara},
            { key: 'RejeitadaSenado',value: StatusProposta.rejeitadaSenado},
            { key: 'Rejeitada',value: StatusProposta.rejeitada}
        ];
    }

    obterTexto(status: StatusProposta): string {
        switch (status) {
            case StatusProposta.proposta:
                return 'Proposta';
            case StatusProposta.aprovadoCamara:
                return 'Aprovado Câmara';
            case StatusProposta.aprovadoSenado:
                return 'Aprovado Senado';
            case StatusProposta.aprovado:
                return 'Aprovado';
            case StatusProposta.rejeitadaCamara:
                return 'Rejeitada Câmara';
            case StatusProposta.rejeitadaSenado:
                return 'Rejeitada Senado';
            case StatusProposta.rejeitada:
                return 'Rejeitada';
        }
    }
}