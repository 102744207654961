export class FiltroGastosDto {
    uf: string;
    cidade: string;
    dataInicio: Date;
    dataFim: Date;
    organizacaoId: string;
    beneficiarioId: string;
    centroCustoId: string;
    organizacaoNome: string;
    beneficiarioNome: string;
    centroCustoNome: string;
}