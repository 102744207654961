import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Proposta, Politico, Estado, Cidade } from 'src/app/models';
import { PropostaService, PoliticoService, OnMapsService, LoginService } from 'src/app/services';
import { NotificationService } from 'src/app/shared/messages/notification.service';
import { UfService } from 'src/app/shared/uf.service';
import { SharedConversions, DateIO } from 'src/app/shared/shared.conversions';
import { StatusService } from 'src/app/shared/status.service';
import { Location } from '@angular/common';
import { FiltroEnum, TipoMidia } from 'src/app/enum';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-proposta',
  templateUrl: './proposta.component.html',
  styleUrls: ['./proposta.component.scss']
})
export class PropostaComponent implements OnInit {

  proposta: Proposta;
  propostaForm: UntypedFormGroup;
  areSubmitting: boolean;
  statusProps: any[];
  politicos: Politico[];
  id: string;
  ufs: Estado[];
  cidades: Cidade[];
  showCidade = false;
  filtroEnum = FiltroEnum;

  @ViewChild('firstElement') firstElementRef: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private propostaService: PropostaService,
    private politicoService: PoliticoService,
    private ufService: UfService,
    private statusService: StatusService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private mapsService: OnMapsService,
    private loginService: LoginService,
  ) { }

  ngOnInit() {
    this.propostaForm = this.formBuilder.group({
      id: [null],
      titulo: [null, Validators.required],
      status: [1, Validators.required],
      data: [SharedConversions.convertDate(DateIO.js, DateIO.ngb, new Date()), Validators.required],
      descricao: [null, Validators.required],
      imagem: [null, Validators.required],
      politicoId: ['', Validators.required],
      uf: ['Brasil', Validators.required],
      cidade: ['']
    });

    // Verificar se esta editando o proposta
    this.id = this.route.snapshot.params['id'];
    if (this.id != null) {
      this.propostaService.encontrar(this.id).subscribe(proposta => {

        const data = SharedConversions.convertDate(DateIO.cs, DateIO.ngb, proposta.data);

        this.propostaForm.controls.id.setValue(this.id);
        this.propostaForm.controls.titulo.setValue(proposta.titulo);
        this.propostaForm.controls.status.setValue(proposta.status);
        this.propostaForm.controls.data.setValue(data);
        this.propostaForm.controls.descricao.setValue(proposta.descricao);
        this.propostaForm.controls.imagem.setValue(proposta.imagem);
        this.propostaForm.controls.politicoId.setValue(proposta.politicoId);
        this.propostaForm.controls.uf.setValue(proposta.uf);
        this.propostaForm.controls.cidade.setValue(proposta.cidade);
        this.showCidade = proposta.cidade != '' && proposta.uf != "Brasil";

        this.location.replaceState('/propostas');
      })
    }

    // Listar Adicionais
    this.mapsService.estados().subscribe(estados => {
      var estadoNovo = new Estado();
      estadoNovo.nome = 'Brasil';
      estadoNovo.uf = 'Brasil';
      estados.push(estadoNovo);
      this.ufs = estados;
    });

    this.statusProps = this.statusService.listar();
    if (this.loginService.usuario.politicos != null && !this.loginService.areAdmin()) {
      this.politicoService.listar()
        .subscribe(politicos => this.politicos = this.loginService.usuario.politicos);
    } else {
      this.politicoService.listar()
        .subscribe(politicos => this.politicos = politicos);
    }
  }

  remover() {
    this.propostaService.remover(this.id).subscribe(_ => {
      this.notificationService.notifty(`A proposta ${this.propostaForm.value.titulo} foi removida.`);
      this.resetForm();
    });
  }

  salvar() {
    var proposta = this.propostaForm.value;
    proposta.data = SharedConversions.convertDate(DateIO.ngb, DateIO.cs, proposta.data);

    this.areSubmitting = true;

    this.propostaService.salvar(proposta)
      .subscribe(resp => {
        this.notificationService.notifty(`O proposta ${proposta.titulo} foi salvo com sucesso.`);
        this.resetForm();
        this.areSubmitting = false;
      }, error => this.areSubmitting = false);
  }

  resetForm() {
    this.router.navigate(['/propostas']);
  }

  filtrar(tipo: FiltroEnum) {
    if (tipo == FiltroEnum.Estado && this.propostaForm.controls.uf.value != '') {
      this.propostaForm.controls.cidade.setValue('');
      this.mapsService.cidades(this.propostaForm.controls.uf.value).subscribe(cidades => this.cidades = cidades);
    }

    if (this.propostaForm.controls.uf.value == '') {
      this.propostaForm.controls.cidade.setValue('');
    }

    if (this.propostaForm.controls.uf.value != 'Brasil' && this.propostaForm.controls.uf.value != '') {
      this.showCidade = true;
    }
    else {
      this.showCidade = false;
    }
  }

  onFileChanged(event) {
    const file = event.target.files[0];

    if (file) {
      const tipo = file.type.includes('image') ? TipoMidia.Imagem : TipoMidia.Video;
      const mb = tipo == TipoMidia.Imagem ? 6 : 64;
      const maxSize = mb * 10000000;

      if (file.size > maxSize) {
        this.notificationService.notifty(`O tamanho ${tipo == TipoMidia.Imagem ? 'da imagem' : 'do vídeo'} não pode exceder ${mb}mb`);
      } else {
        this.propostaService
          .salvarMidia(file, tipo)
          .subscribe((url: string) => {
            if (url) {
              url = url.split('"').join('');
              this.propostaForm.controls.imagem.setValue(url);
            }
          });
      }
    }

  }
}
