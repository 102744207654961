<button type="button" #hackButton class="d-none">
  NÃO REMOVER ESTE BOTÃO!!!
  Este botão deve existir para fazer com que a caixa de seleção de imagens não seja aberta ao clicar em qualquer parte
  do componente.
</button>
<input #fileInput id="ileInput" type="file" accept="audio/*" (change)="addFile()" style="display: none;"/>
<div class="text-center avatar-container" [class.full-width]="fullWidth" style="position: relative;">
  <div class="avatar-wrapper" style="width: 100%; height: 100%;" [class.full-width]="fullWidth"
    [class.avatar-wrapper-error]="!isValid() && isRequired()">
    <div class="avatar-flex" style="width: 100%; height: 100%;" [class.full-width]="fullWidth" *ngIf="!isValid() && !gravandoAudio">
      <i class="{{icone}} text-center avatar-icon-size"></i>
    </div>

    <audio controls class="avatar mt-n2 mb-5 pb-5" [class.full-width]="fullWidth" *ngIf="isValid()"
      style="width: 100%; height: 100%;" [hidden]="gravandoAudio">
      <source [src]="sanitize(url)" />
      Your browser does not support HTML5 audio.
    </audio>

    <div class="row" [hidden]="!gravandoAudio">
      <div class="col-12 d-flex flex-column align-items-center justify-content-center mt-3">
        <lottie-player src="assets/animations/audio_record.json" background="transparent" speed="1"
          style="width: 180px; height: 180px;" loop autoplay>
        </lottie-player>
        <div class="mt-n3">
          <button type="button" class="btn rounded-circle btn-secondary mr-5" style="width: 48px; height: 48px; padding-top: 8px;"
            ngbTooltip="Abortar gravação de áudio" (click)="abortarGravacaoAudio()"><i
              class="fal fa-ban fa-lg"></i></button>

          <button type="button" class="btn rounded-circle btn-primary ml-5" style="width: 48px; height: 48px; padding-top: 8px;"
            ngbTooltip="Finalizar gravação de áudio" (click)="pararGravacaoAudio()"><i
              class="fal fa-check fa-lg"></i></button>
        </div>
      </div>
    </div>
  </div>
  <button type="button" (click)="changeFile()" class="btn color-icon avatar-btn btn-primary" tabindex="-1" *ngIf="!gravandoAudio">
    <i class="fal fa-volume text-light"></i>
  </button>
  <div class="dropdown" ngbDropdown style="position: absolute; right: 0;">
    <a [routerLink]="[]" class="btn-invisible" #dropdown ngbDropdownToggle></a>
    <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-right mb-3">
      <li class="dropdown-item px-0"> <a [routerLink]="[]" class="d-inline-block w-100" (click)="iniciarGravacaoAudio()">
        <span class="px-3"><i class="fal fa-microphone"></i>&nbsp;&nbsp;&nbsp;Gravar</span></a></li>
      <li class="dropdown-item px-0"> <a [routerLink]="[]" class="d-inline-block w-100" (click)="openFile()">
        <span class="px-3"><i class="fal fa-sync"></i> Escolher</span></a></li>
      <li class="dropdown-item px-0"> <a [routerLink]="[]" class="d-inline-block w-100" (click)="removeFile()" *ngIf="isValid()">
        <span class="px-3"><i class="fal fa-trash-alt"></i> Remover</span></a></li>
    </ul>
  </div>
</div>

