<nav aria-label="breadcrumb ">
  <ol class="breadcrumb b-radius breadcrumb-appckground mt-3">
    <li class="breadcrumb-item active breadcrumb-link-actual mt-1" aria-current="page"><i
        class="fas fa-user"></i>&nbsp;Usuários</li>
    <li class="ml-auto">
      <button type="button" class="btn btn-sm btn-outline-primary btn-on" [routerLink]="['adicionar']">
        <i class="fal fa-plus"></i>
        <span class="d-none d-lg-inline-flex">&nbsp;Adicionar</span>
      </button>
    </li>
  </ol>
</nav>

<div class="d-flex justify-content-center w-100" *ngIf="estaCarregando">
  Carregando...
</div>

<app-searchbox (search)="pesquisar($event)" *ngIf="!mensagemListaVazia && !estaCarregando"></app-searchbox>
<div class="table-responsive" *ngIf="!mensagemListaVazia && !estaCarregando">
  <table class="table table-sm table-hover table-striped">
    <thead>
      <tr>
        <th></th>
        <th class="text-left">Nome</th>
        <th class="text-left">E-Mail</th>
        <th class="text-left">Primeiro Acesso</th>
        <th class="text-left">Último Acesso</th>
      </tr>
    </thead>
    <tbody class="text-left">
      <tr class="text-nowrap tr-click" *ngFor="let usuario of usuarios">
        <td [routerLink]="['/usuarios', usuario.id]" class="td-adjustment"><img src="{{usuario.imagem}}"
            class="img-adjustment" *ngIf="usuario.imagem" /><i class="fal fa-user-circle" *ngIf="!usuario?.imagem"
            style="margin-left: 1.5px;"></i></td>
        <td [routerLink]="['/usuarios', usuario.id]">{{usuario.nome}}</td>
        <td [routerLink]="['/usuarios', usuario.id]">{{usuario.email}}</td>
        <td [routerLink]="['/usuarios', usuario.id]">{{usuario.dataTermosUso | date: 'dd/MM/yyyy HH:mm'}}</td>
        <td>
          <span [routerLink]="['/usuarios', usuario.id]">
            {{usuario.dataUltimoAcesso| date: 'dd/MM/yyyy HH:mm'}}
          </span>
          <a title="Localização aproximada" class="ml-3" *ngIf="usuario.localUltimoAcesso"
            href="{{usuario.localUltimoAcesso}}" target="_blank"><i class="fas fa-map-marked-alt"></i></a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="mensagemListaVazia">
  <h6 class="text-muted">Não possui usuários.</h6>
</div>