import { TipoMidia } from "../enum";
import { Usuario } from ".";

export class Chat{
    id: string;
    tipo: TipoMidia;
    conteudo: string;
    remetente: Usuario;
    remetenteId: string;
    dataHoraEnvio: Date;
    destinatario?: Usuario;
    destinatarioId?: string;
    dataHoraLeitura?: Date;
    foiLido: boolean;
    ehPublico: boolean;
    enviadoPorMim: boolean;
    usuarioDeletouId: string;
}