import { Component, OnInit } from '@angular/core';
import { Lancamento } from 'src/app/models/lancamento.model';
import { LancamentoService } from 'src/app/services';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-lancamento-lista',
  templateUrl: './lancamento-lista.component.html',
  styleUrls: ['./lancamento-lista.component.scss']
})
export class LancamentoListaComponent implements OnInit {

  lancamentos: Lancamento[] = [];
  lancamentosBkp: Lancamento[] = [];
  mensagemListaVazia: boolean = false;
  filtroForm: UntypedFormGroup;

  constructor(
    private lancamentoService: LancamentoService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit() {
    this.listar();

    this.filtroForm = this.formBuilder.group({
      pesquisa: [null],
    });
  }

  listar() {
    this.lancamentoService.listar()
      .subscribe(lancamentos => {
        this.lancamentos = lancamentos
        this.lancamentosBkp = lancamentos

        this.mensagemListaVazia = lancamentos.length == 0;
      });
  }

  pesquisar(pesquisa: string) {
    this.lancamentoService.listar(pesquisa)
      .subscribe(lancamentos => this.lancamentos = lancamentos);
  }

  filtrar(any) {
    var filtro = this.filtroForm.value;
    this.lancamentos = this.lancamentosBkp;

    this.lancamentos = this.lancamentos.filter(x => x.beneficiario.nome.toLocaleLowerCase().indexOf
    (filtro.pesquisa.toLocaleLowerCase()) > -1 || x.organizacao.nome.toLocaleLowerCase().indexOf
    (filtro.pesquisa.toLocaleLowerCase()) > -1 || x.valor.valueOf() == filtro.pesquisa);

    this.mensagemListaVazia = this.lancamentos.length == 0;
  }
}
