import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Lancamento } from '../models/lancamento.model';
import { FiltroGastosDto } from '../dtos';
import { ResultadoFiltroGastosVm } from '../viewModels/resultado-filtro-gastos-vm';

@Injectable()
export class LancamentoService {
    constructor(private http: HttpClient) { }

    listar(pesquisa?: string): Observable<Lancamento[]> {
        let params: HttpParams;
        if (pesquisa) {
            params = new HttpParams().set('query', pesquisa);
        }
        return this.http.get<Lancamento[]>(`${environment.api}/lancamentos`, { params: params });
    }

    encontrar(id: string): Observable<Lancamento> {
        return this.http.get<Lancamento>(`${environment.api}/lancamentos/${id}`);
    }

    filtrarGastosDetahe(filtroGastosDto: FiltroGastosDto): Observable<Lancamento[]> {
        return this.http.post<Lancamento[]>(`${environment.api}/lancamentos/filtrando-gastos-detalhe`, filtroGastosDto);
    }

    filtrarGastos(filtroGastosDto: FiltroGastosDto): Observable<ResultadoFiltroGastosVm> {
        return this.http.post<ResultadoFiltroGastosVm>(`${environment.api}/lancamentos/filtrando-gastos`, filtroGastosDto);
    }

    salvar(lancamento: Lancamento): Observable<Lancamento> {
        return this.http.post<Lancamento>(`${environment.api}/lancamentos`, lancamento);
    }

    remover(id: string): Observable<Lancamento> {
        return this.http.delete<Lancamento>(`${environment.api}/lancamentos/${id}`);
    }
}

