import { Component, OnInit } from '@angular/core';
import { Usuario, BloqueioUsuario } from 'src/app/models';
import { UsuarioService } from 'src/app/services/usuario.service';
import { LoginService } from 'src/app/services';
import { NotificationService } from 'src/app/shared/messages/notification.service';

@Component({
  selector: 'app-usuario-lista-bloqueados',
  templateUrl: './usuario-lista-bloqueados.component.html',
  styleUrls: ['./usuario-lista-bloqueados.component.scss']
})
export class UsuarioListaBloqueadosComponent implements OnInit {

  bloqueados: BloqueioUsuario[];
  mensagemListaVazia: boolean;
  areSubmiting: boolean;

  constructor(
    private usuarioService: UsuarioService,
    private loginService: LoginService,
    private notificationService: NotificationService, ) { }

  ngOnInit() {
    this.listar();
  }

  desbloquear(id: string) {
    this.areSubmiting = true;
    this.usuarioService.desbloquear(id).subscribe(() => {
      this.notificationService.notifty(`Usuário desbloqueado com sucesso!`);
      this.listar();

      this.areSubmiting = false;
    });
  }

  listar() {
    this.usuarioService.encontrarBloqueados(this.loginService.usuario.id).subscribe(bloqueados => {
      this.bloqueados = bloqueados;
      this.mensagemListaVazia = bloqueados.length < 1;
    });
  }
  
}
