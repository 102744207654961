import { HttpClient, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable, Output, Directive } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { LiveIceCandidateDto } from "../dtos/live/live-ice-candidate-dto";
import * as signalR from "@microsoft/signalr";
import { LivePeerConnectionDescriptionDto } from "../dtos/live/live-peer-connection-descritpion-dto";
import { RoomLive } from "../models/room-live.model";
import { Usuario } from "../models";
import { LoginService } from "./login.service";
import { RoomLiveItem } from "../models/room-live-item.model";
import { PeerConnectionDescriptionDto } from "../dtos/peer-connection/peer-connection-description-dto";

@Directive()
@Injectable()
export class RoomLiveService {

    hubConnection?: signalR.HubConnection;

    @Output() userStartedWatching: EventEmitter<RoomLiveItem> = new EventEmitter<RoomLiveItem>()
    @Output() watchOfferReceived: EventEmitter<PeerConnectionDescriptionDto> = new EventEmitter<PeerConnectionDescriptionDto>()
    @Output() watchAnswerReceived: EventEmitter<any> = new EventEmitter<any>()
    @Output() userStoppedWatching: EventEmitter<string> = new EventEmitter<string>()
    @Output() candidateAdded: EventEmitter<LiveIceCandidateDto> = new EventEmitter<LiveIceCandidateDto>()
    @Output() liveStarted: EventEmitter<string> = new EventEmitter<string>()
    @Output() liveEnded: EventEmitter<string> = new EventEmitter<string>()

    constructor(
        private _http: HttpClient,
        private _loginService: LoginService) { }

    iniciarLive(live: RoomLive): Observable<string> {
        return this._http.post<string>(`${environment.api}/RoomsLive`, live);
    }

    watchLive(item: RoomLiveItem): Observable<RoomLiveItem> {
        return this._http.post<RoomLiveItem>(`${environment.api}/RoomsLive/watch/`, item, {
            headers: { 'Content-Type': 'application/json' }
        });
    }

    sendWatchOffer(description: PeerConnectionDescriptionDto, itemId: string): Observable<any> {
        return this._http.post<any>(`${environment.api}/RoomsLive/sendWatchOffer/${itemId}`, description);
    }

    sendWatchAnswer(description: LivePeerConnectionDescriptionDto, itemId: string): Observable<any> {
        return this._http.post<any>(`${environment.api}/RoomsLive/sendWatchAnswer/${itemId}`, description);
    }

    addCandidate(candidate: LiveIceCandidateDto): Observable<LiveIceCandidateDto> {
        return this._http.post<LiveIceCandidateDto>(`${environment.api}/RoomsLive/addCandidate/`, candidate);
    }

    listarSalasAoVivo(): Observable<Usuario[]> {
        return this._http.get<Usuario[]>(`${environment.api}/RoomsLive/listar/salas/aovivo/`);
    }

    encerrouLive(id: string, notify: boolean = true) {
        if (notify)
            return this._http.get(`${environment.api}/RoomsLive/encerrar/${id}`);
        else {
            let params: HttpParams = new HttpParams().append('notify', `${notify}`);
            return this._http.get(`${environment.api}/RoomsLive/encerrar/${id}`, { params });
        }
    }

    stopWatching(liveId: string, itemId: string) {
        return this._http.get(`${environment.api}/RoomsLive/stopWatching/${liveId}/item/${itemId}`);
    }

    find(id: string): Observable<RoomLive> {
        return this._http.get<RoomLive>(`${environment.api}/RoomsLive/find/live/${id}`);
    }

    async validateConnection(callback: Function): Promise<void> {
        if (!this.hubConnection) {
            this.buildConnection();
        }

        if (this.hubConnection != null && this.hubConnection.state == signalR.HubConnectionState.Disconnected) {
            await this.hubConnection.start();
        }

        setTimeout(() => {
            this.hubConnection.on('UserStartedWatching', (item: RoomLiveItem) => this.userStartedWatching.emit(item));
            this.hubConnection.on('WatchOfferReceived', (offer: PeerConnectionDescriptionDto) => this.watchOfferReceived.emit(offer));
            this.hubConnection.on('WatchAnswerReceived', (answer: LivePeerConnectionDescriptionDto, itemId: string) => this.watchAnswerReceived.emit({ answer, itemId }));
            this.hubConnection.on('UserStoppedWatching', (liveId: string) => this.userStoppedWatching.emit(liveId));
            this.hubConnection.on('CandidateAdded', (candidate: LiveIceCandidateDto) => this.candidateAdded.emit(candidate));
            this.hubConnection.on('LiveStarted', (liveId: string) => this.liveStarted.emit(liveId));
            this.hubConnection.on('LiveEnded', (liveId: string) => this.liveEnded.emit(liveId));
        });

        callback();
    }

    private buildConnection(): void {
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${environment.api}/liveHub`,
                {
                    accessTokenFactory: () => this._loginService.usuario?.token
                })
            .withAutomaticReconnect()
            .build();
    }

}
