<nav aria-label="breadcrumb ">
  <ol class="breadcrumb b-radius breadcrumb-appckground mt-3">
    <a class="breadcrumb-item mt-1" aria-current="page" [routerLink]="['/lancamentos']"><i
        class="far fa-user-chart"></i>&nbsp;Lançamentos</a>
    <li class="breadcrumb-item active breadcrumb-link-actual mt-1" aria-current="page">Adicionar</li>
    <li class="ml-auto">
      <button type="button" class="btn btn-sm btn-outline-primary btn-on" [routerLink]="['/lancamentos']">
        <i class="fal fa-list"></i>
        <span class="d-none d-lg-inline-flex">&nbsp;Listar</span>
      </button>
    </li>
  </ol>
</nav>
<form [formGroup]="lancamentoForm" novalidate autocomplete="off">
  <input type="hidden" formControlName="id" />
  <div class="row">
    <div class="col-md-6">
      <app-input label="Beneficiário" errorMessage="Este campo é obrigatório." [isRequired]="true">
        <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true" formControlName="beneficiarioId">
          <ng-option value="">Selecione o Beneficiário</ng-option>
          <ng-option *ngFor="let beneficiario of beneficiarios" [value]="beneficiario.id">{{beneficiario.nome}}
          </ng-option>
        </ng-select>
      </app-input>
    </div>

    <div class="col-md-6">
      <app-input label="Organização" errorMessage="Este campo é obrigatório." [isRequired]="true">
        <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true" formControlName="organizacaoId" (change)="listarCentroCustos()">
          <ng-option value="">Selecione a Organização</ng-option>
          <ng-option *ngFor="let organizacao of organizacoes" [value]="organizacao.id">{{organizacao.nome}}
          </ng-option>
        </ng-select>
      </app-input>
    </div>

    <div class="col-md-3">
      <app-input label="Valor" errorMessage="Campo obrigatório." [isRequired]="true">
        <input type="text" formControlName="valor" class="form-control form-control-sm" currencyMask [options]="optionsCurrencyMask" placeholder="0,00" />
      </app-input>
    </div>

    <div class="col-md-3">
      <app-input label="Credita Valor" errorMessage="Campo obrigatório." [isRequired]="true">
        <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true" formControlName="creditaValor">
          <ng-option value="1">Sim</ng-option>
          <ng-option value="0">Não</ng-option>
        </ng-select>
      </app-input>
    </div>

    <div class="col-6 col-lg-3">
      <app-input label="Centro de Custos" errorMessage="Este campo é obrigatório." [isRequired]="true">
        <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true" formControlName="centroCustoId">
          <ng-option value="">Selecione o Centro de Custos</ng-option>
          <ng-option *ngFor="let centroCusto of centroCustos" [value]="centroCusto.id">{{centroCusto.nome}}
          </ng-option>
        </ng-select>
      </app-input>
    </div>

    <div class="col-6 col-lg-3">
      <app-input label="Data">
        <div class="input-group">
          <input class="form-control form-control-sm" type="text" ngbDatepicker #data="ngbDatepicker"
            formControlName="data" maxlength="10" />
          <div class="input-group-append">
            <button class="btn btn-sm btn-outline-secondary" (click)="data.toggle()" type="button">
              <i class="fal fa-calendar"></i>
            </button>
          </div>
        </div>
      </app-input>
    </div>

    <div class="col-12">
      <app-input label="Histórico" errorMessage="Campo obrigatório." [isRequired]="true">
        <textarea type="text" formControlName="historico" class="form-control form-control-sm" placeholder="Histórico"
          rows="5"></textarea>
      </app-input>
    </div>
  </div>

  <button type="submit" class="btn btn-sm btn-primary btn-on-full mr-2" (click)="salvar()"
    [disabled]="areSubmitting || this.lancamentoForm.invalid">
    <i class="fal fa-check"></i>&nbsp;Salvar
  </button>

  <button type="button" class="btn btn-sm btn-outline-danger btn-on float-right" (click)="remover()"
    *ngIf="lancamentoForm.controls.id.value != null">
    <i class="fal fa-trash"></i>
    <span class="d-none d-lg-inline-flex">&nbsp;Remover</span>
  </button>
</form>
