import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/models';
import { UsuarioService } from 'src/app/services/usuario.service';
import { NotificationService } from 'src/app/shared/messages/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalRemoverComponent } from 'src/app/shared/modal/modal-remover/modal-remover.component';
import { Location } from '@angular/common';
import { LoginService } from 'src/app/services';
import { find } from 'rxjs/operators';
import { ChatService } from 'src/app/services/chat.service';
import { Chat } from 'src/app/models/chat.model';
import { TipoMidia } from 'src/app/enum/tipo-midia';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements OnInit {

  usuario: Usuario;
  chat: Chat;
  usuarioForm: UntypedFormGroup;
  areSubmitting: boolean;
  id: string;
  hiddingPassword: boolean = false;
  hiddingToPerfil: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private usuarioService: UsuarioService,
    private _chatService: ChatService,
    private loginService: LoginService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private location: Location
  ) { }

  ngOnInit() {
    this.usuarioForm = this.formBuilder.group({
      id: [null],
      email: [null, Validators.required],
      telefone: [null],
      documento: [null],
      facebookId: [null],
      googleId: [null],
      senha: [null],
      token: [null],
      temSenha: [false],
      perfil: [null],
      nome: [null, Validators.required],
      imagem: [null],
      reportado: [null],
      verificado: [null]
    });

    // Verificar se esta editando o usuario
    this.id = this.route.snapshot.params['id'];

    if (this.id != null) {

      if (this.id == this.loginService.usuario.id) {
        this.hiddingToPerfil = true;
      }
      else {
        this.hiddingToPerfil = false;
      }

      this.usuarioService.encontrar(this.id).subscribe(usuario => {
        this.usuarioForm.patchValue(usuario);
        this.usuario = usuario;
        this.usuarioForm.controls.temSenha.setValue(true);
        this.usuarioForm.controls.perfil.setValue(usuario.perfil);
        this.usuarioForm.controls.reportado.setValue(usuario.reportado);
        this.usuarioForm.controls.verificado.setValue(usuario.verificado);
        this.hiddingPassword = true;
        this.location.replaceState('/usuarios');
      })
    }
  }

  salvar(usuario: Usuario) {
    this.areSubmitting = true;

    if (this.usuarioForm.controls.temSenha.value != true && this.areSubmitting != true) {
      this.notificationService.notifty(`Por favor preencha o campo de senha.`);
      this.areSubmitting = false;
    }
    else {
      if (usuario.perfil == null) {
        usuario.perfil = 'user';
      }

      this.usuarioService.salvar(usuario)
        .subscribe(resp => {

          this.notificationService.notifty(`O usuário ${usuario.nome} foi salvo com sucesso.`);
          if (usuario.id == this.loginService.usuario.id) {
            this.loginService.alterarUsuario(usuario);
          }
          if (this.loginService.areAdmin()) {
            this.router.navigate(['/usuarios']);
          }
          else {
            this.router.navigate(['/feed']);
          }
          this.areSubmitting = false;
        }, error => this.areSubmitting = false);
    }
  }

  remover() {
    const modalRef = this.modalService.open(ModalRemoverComponent, { centered: true });
    modalRef.componentInstance.mensagem = `Deseja realmente remover o usuário ${this.usuario.nome}?`;
    modalRef.componentInstance.titulo = `Remover`;

    modalRef.result.then(confirmaRemocao => {
      if (confirmaRemocao) {
        this.usuarioService.remover(this.usuario.id).subscribe(_ => {
          this.notificationService.notifty(`O usuário ${this.usuario.nome} foi removido.`);
          this.resetForm();
        });
      }
    }, dismiss => { });
  }

  resetForm() {
    this.router.navigate(['/usuarios']);
  }

  onFileChanged(event) {
    const file = event.target.files[0];

    if (file) {
      const tipo = file.type.includes('image') ? TipoMidia.Imagem : TipoMidia.Video;
      const mb = tipo == TipoMidia.Imagem ? 6 : 64;
      const maxSize = mb * 10000000;

      if (file.size > maxSize) {
        this.notificationService.notifty(`O tamanho ${tipo == TipoMidia.Imagem ? 'da imagem' : 'do vídeo'} não pode exceder ${mb}mb`);
      } else {
        this.usuarioService
          .salvarMidia(file, tipo)
          .subscribe((url: string) => {
            if (url) {
              url = url.split('"').join('');
              this.usuarioForm.controls.imagem.setValue(url);
            }
          });
      }
    }

  }
}
