import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Vendedor } from '../models';
import { TipoMidia } from '../enum/tipo-midia';

@Injectable()
export class VendedorService {
    constructor(private http: HttpClient) { }

    vendedor: Vendedor;

    listar(pesquisa?: string): Observable<Vendedor[]> {
        let params: HttpParams;
        if (pesquisa) {
            params = new HttpParams().set('query', pesquisa);
        }
        return this.http.get<Vendedor[]>(`${environment.api}/vendedores`, { params: params });
    }

    encontrar(id: string): Observable<Vendedor> {
        return this.http.get<Vendedor>(`${environment.api}/vendedores/${id}`);
    }

    salvar(vendedor: Vendedor): Observable<Vendedor> {
        return this.http.post<Vendedor>(`${environment.api}/vendedores`, vendedor);
    }

    remover(id: string): Observable<Vendedor> {
        return this.http.delete<Vendedor>(`${environment.api}/vendedores/${id}`);
    }

    salvarMidia(blob: any, tipo: TipoMidia): Observable<string> {
        const formData = new FormData();
        formData.append('file', blob);

        return this.http.post<string>(`${environment.api}/vendedores/salvarMidia/${tipo}`, formData, { responseType: 'text' as 'json' });
    }
}