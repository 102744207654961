import { Component, OnInit } from '@angular/core';
import { Postagem, Usuario, Estado, Cidade, Bairro } from 'src/app/models';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { FiltroEnum, TipoMidia } from 'src/app/enum';
import { PostagemService } from 'src/app/services/postagem.service';
import { LoginService, OnMapsService } from 'src/app/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Endereco } from 'src/app/models/endereco.model';
import { PostagemCadastroComponent } from '../postagem-cadastro/postagem-cadastro.component';
import * as $ from 'jquery';
import { FiltroDenunciaDto } from 'src/app/dtos/filtro-denuncia-dto';
import { AddressResult } from 'src/app/models/address-result.model';

@Component({
  selector: 'app-postagem-menu',
  templateUrl: './postagem-menu.component.html',
  styleUrls: ['./postagem-menu.component.scss']
})
export class PostagemMenuComponent implements OnInit {

  mensagemListaVazia: boolean = false;
  postagensBkp: Postagem[] = [];
  postagens: Postagem[] = [];
  usuario: Usuario;
  filtroForm: UntypedFormGroup;
  currentRate: number;
  showImagens: boolean;
  ufs: Estado[];
  cidades: Cidade[];
  bairros: Bairro[];
  filtroEnum = FiltroEnum;
  estaCarregando: boolean = false;
  estaFiltrando: boolean = false;
  filtroDenunciaDto: FiltroDenunciaDto;
  esconderCarregarMais: boolean = false;

  constructor(
    private postagemService: PostagemService,
    private loginService: LoginService,
    private formBuilder: UntypedFormBuilder,
    private mapsService: OnMapsService,
    private modalService: NgbModal,
    private usuarioService: UsuarioService,
  ) { }

  ngOnInit() {
    this.isLoggedIn();
    this.filtroDenunciaDto = new FiltroDenunciaDto;

    // Preenchendo usuário
    this.loginService.alterou.subscribe(usuario => {
      this.usuario = usuario;
      this.listar();
    })
    this.usuario = this.loginService.usuario;

    this.postagemService.atualiza.subscribe(() => this.listar());

    this.filtroForm = this.formBuilder.group({
      uf: [''],
      cidade: [''],
      bairro: ['']
    });

    this.listar();

    this.loginService.logou.subscribe(resp => this.listar());
    this.loginService.deslogou.subscribe(resp => this.listar());

    // Listar Adicionais
    this.mapsService.estados().subscribe(estados => this.ufs = estados);

    for (const post of this.postagens) {
      post.mostrarRemover = false;

    }
  }

  scrollTop() {
    setTimeout(function () {
      $('.content-page').animate({ scrollTop: 0 }, 200);
    }, 1)
  }

  filtrar(filtroEnum: FiltroEnum) {
    this.estaFiltrando = true;
    this.filtroDenunciaDto.filtrandoPostagens = true;

    if (filtroEnum == FiltroEnum.Estado) {

      if (this.filtroForm.controls.uf.value == '') {
        this.postagens = this.postagensBkp;
        this.esconderCarregarMais = false;
        this.filtroDenunciaDto.estado = null;
        this.filtroDenunciaDto.cidade = null;
        this.filtroDenunciaDto.bairro = null;
        this.estaFiltrando = false;
      } else {
        this.filtroDenunciaDto.estado = new Estado;
        this.filtroDenunciaDto.estado.uf = this.filtroForm.controls.uf.value;

        // verifica na lista atual antes de verificar no bkp
        this.postagens = this.postagens.filter(x => x.uf == this.filtroForm.controls.uf.value);

        if (this.postagens == null || this.postagens.length == 0) {

          // verifica no bkp antes de ir pro banco
          this.postagens = this.postagensBkp.filter(x => x.uf == this.filtroForm.controls.uf.value);
          this.esconderCarregarMais = false;

          if (this.postagens == null || this.postagens.length == 0) {
            this.postagemService.filtrar(this.filtroDenunciaDto, this.postagensBkp.length).subscribe(postagensFiltrada => {
              this.postagens = postagensFiltrada;
              if (this.estaFiltrando && this.postagens.length < 10)
                this.esconderCarregarMais = true;
            });
          }
        }

        this.mapsService.cidades(this.filtroForm.controls.uf.value).subscribe(cidades => this.cidades = cidades);
      }
      this.cidades = [];
      this.bairros = [];
      this.filtroForm.controls.cidade.setValue('');
      this.filtroForm.controls.bairro.setValue('');
    }

    if (filtroEnum == FiltroEnum.Cidade) {
      if (this.filtroForm.controls.cidade.value == '') {
        this.postagens = this.postagensBkp;
        this.postagens = this.postagens.filter(x => x.uf == this.filtroForm.controls.uf.value);
        this.esconderCarregarMais = false;
        this.filtroDenunciaDto.cidade = null;
        this.filtroDenunciaDto.bairro = null;
      } else {
        this.filtroDenunciaDto.cidade = new Cidade;
        this.filtroDenunciaDto.cidade.nome = this.filtroForm.controls.cidade.value;

        // verifica na lista atual antes de verificar no bkp
        this.postagens = this.postagens.filter(x => x.cidade == this.filtroForm.controls.cidade.value);

        if (this.postagens == null || this.postagens.length == 0) {

          // verifica no bkp antes de ir pro banco
          this.postagens = this.postagensBkp.filter(x => x.cidade == this.filtroForm.controls.cidade.value);
          this.esconderCarregarMais = false;

          if (this.postagens == null || this.postagens.length == 0) {
            this.postagemService.filtrar(this.filtroDenunciaDto, this.postagensBkp.length).subscribe(postagensFiltrada => {
              this.postagens = postagensFiltrada;
              if (this.estaFiltrando && this.postagens.length < 10)
                this.esconderCarregarMais = true;
            });
          }
        }
        this.mapsService.bairros(this.filtroForm.controls.uf.value, this.filtroForm.controls.cidade.value).subscribe(bairros => this.bairros = bairros)
      }
      this.bairros = [];
      this.filtroForm.controls.bairro.setValue('');
    }

    if (filtroEnum == FiltroEnum.Bairro) {
      if (this.filtroForm.controls.bairro.value == '') {
        this.postagens = this.postagensBkp;
        this.postagens = this.postagens.filter(x => x.cidade == this.filtroForm.controls.cidade.value);
        this.esconderCarregarMais = false;
        this.filtroDenunciaDto.bairro = null;
      } else {
        this.filtroDenunciaDto.bairro = new Bairro;
        this.filtroDenunciaDto.bairro.nome = this.filtroForm.controls.bairro.value;

        // verifica na lista atual antes de verificar no bkp
        this.postagens = this.postagens.filter(x => x.bairro == this.filtroForm.controls.bairro.value);
        this.esconderCarregarMais = false;




        if (this.postagens == null || this.postagens.length == 0) {

          // verifica no bkp antes de ir pro banco
          this.postagens = this.postagensBkp.filter(x => x.bairro == this.filtroForm.controls.bairro.value);

          if (this.postagens == null || this.postagens.length == 0) {
            this.postagemService.filtrar(this.filtroDenunciaDto, this.postagensBkp.length).subscribe(postagensFiltrada => {
              this.postagens = postagensFiltrada
              if (this.estaFiltrando && this.postagens.length < 10)
                this.esconderCarregarMais = true;
            });
          }
        }

      }
    }

    if (this.postagens.length < 1) {
      this.mensagemListaVazia = true;
    }
    else {
      this.mensagemListaVazia = false;
    }


  }

  listar() {
    this.estaCarregando = true;

    if (!this.estaFiltrando) {
      this.postagemService.listarPartial(this.postagensBkp.length).subscribe(postagens => {

        postagens.forEach(postagem => {
          postagem.midias.forEach(midia => {
            if (midia.tipoMidia == TipoMidia.Video)
              midia.url = `${midia.url}/thumb`;
          });
        });

        if (this.loginService.isLoggedIn()) {
          this.usuarioService.encontrarBloqueados(this.loginService.usuario.id).subscribe(bloqueados => {
            if (bloqueados.length > 0) {
              bloqueados.forEach(bloqueado => {
                postagens = postagens.filter(x => x.usuarioId != bloqueado.usuarioBloqueadoId);
              });
              this.pushPostagens(postagens);
            }
            else {
              this.pushPostagens(postagens);
            }
          });
        }
        else {
          this.pushPostagens(postagens);
        }

        if (postagens.length < 1) {
          this.mensagemListaVazia = true;
        }
        else {
          this.mensagemListaVazia = false;
        }

        this.estaCarregando = false;
      }, () => this.estaCarregando = false);
    }
    else {
      this.postagemService.filtrar(this.filtroDenunciaDto, this.postagensBkp.length).subscribe(postagensFiltrada => {

        for (const postagemFiltrada of postagensFiltrada) {
          this.postagens.push(postagemFiltrada);
        }

        this.estaCarregando = false;

        if (postagensFiltrada.length < 10)
          this.esconderCarregarMais = true;
      });
    }
  }

  private pushPostagens(postagens: Postagem[]) {
    for (const postagem of postagens) {
      this.postagens.push(postagem);
    }

    this.postagensBkp = this.postagens;
  }

  isLoggedIn() {
    return this.loginService.isLoggedIn();
  }

  usarLocalizacao() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;

        this.mapsService.getLocalizacao(latitude, longitude).subscribe((result: AddressResult) => {
          if (result) {
            const state = this.ufs.find(x => x.nome == result.address.state);

            this.filtroForm.controls.uf.setValue(state.uf);
            this.filtroForm.controls.cidade.setValue(result.address.city);
            this.filtroForm.controls.bairro.setValue(result.address.suburb);

            this.mapsService.cidades(state.uf).subscribe(cidades => this.cidades = cidades);
            this.mapsService.bairros(state.uf, this.filtroForm.controls.cidade.value).subscribe(bairros => this.bairros = bairros);

            this.postagens = this.postagens.filter(x => x.uf == result.address.state && x.cidade == result.address.city && x.bairro == result.address.suburb);


            this.mensagemListaVazia = this.postagens.length == 0;
          }
        });
      }, _ => { }, { enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 });
    }
  }

  abrirAdicionar() {
    if (this.loginService.isLoggedIn()) {
      const modalRef = this.modalService.open(PostagemCadastroComponent, { size: 'lg', centered: true });
      modalRef.result.then(confirma => {
        this.listar();
      }, dismiss => { });
    }
    else {
      this.loginService.handleLogin();
    }
  }
}
