import { Politico, Vendedor, Endereco } from ".";
import { Organizacao } from "./organizacao.model";

export class Usuario {
    id: string;
    email: string;
    telefone: string;
    documento: string;
    facebookId: string;
    googleId: string;
    senha: string;
    token: string;
    temSenha: boolean;
    perfil: string;
    nome: string;
    imagem: string;
    politicos: Politico[];
    organizacoes: Organizacao[];
    dataUltimoAcesso: Date;
    dataTermosUso: Date;
    localUltimoAcesso: string;
    estaOnline: boolean;
    possuiNovasMensagens: boolean;
    enderecos: Endereco[] = [];
    pedidoAmizadeEnviado: boolean;
    pedidoAmizadeRecebido: boolean;
    pedidoConfirmado: boolean;
    verificado: boolean;
    reportado: boolean;

    //Propriedades de visualização
    estaSelecionado: boolean;

    constructor(){
        this.estaSelecionado = false;
        this.politicos = [];
    }
}