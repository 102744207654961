import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Pedido } from '../models';

@Injectable()
export class PedidoService {
    constructor(private http: HttpClient) { }

    listar(pesquisa?: string): Observable<Pedido[]> {
        let params: HttpParams;
        if (pesquisa) {
            params = new HttpParams().set('query', pesquisa);
        }
        return this.http.get<Pedido[]>(`${environment.api}/Pedidos`, { params: params });
    }

    ListarByVendedor(vendedorId: string): Observable<Pedido[]> {
        return this.http.get<Pedido[]>(`${environment.api}/Pedidos/listar/${vendedorId}`,);
    }

    encontrar(id: string): Observable<Pedido> {
        return this.http.get<Pedido>(`${environment.api}/Pedidos/${id}`);
    }

    salvar(Pedido: Pedido): Observable<Pedido> {
        return this.http.post<Pedido>(`${environment.api}/Pedidos`, Pedido);
    }

    remover(id: string): Observable<Pedido> {
        return this.http.delete<Pedido>(`${environment.api}/Pedidos/${id}`);
    }
}