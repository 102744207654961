<app-modal title="Adicionar Usuários">
  <div class="row" style="height: 65vh; overflow-x: hidden; overflow-y: auto;">
    <div class="col-12">
      <form [formGroup]="filtro" novalidate autocomplete="off">
        <label class="text-center;">Digite o e-mail ou telefone para encontrar um
          usuário</label>

        <app-input label="E-mail" errorMessage="Campo obrigatório.">
          <input type="email" (blur)="verificarValorCampoTelefone(this.filtro.value)" formControlName="email"
            class="form-control form-control-sm" placeholder="email@email.com.br" />
        </app-input>

        <app-input label="Telefone" errorMessage="Campo obrigatório.">
          <input type="tel" (blur)="verificarValorCampoEmail(this.filtro.value)" formControlName="telefone"
            [textMask]="{mask: telefoneMovelMask, guide: false}" class="form-control form-control-sm"
            placeholder="Telefone" />
        </app-input>
<!-- 
        <div style="text-align: right;">
          <button type="submit" class="btn btn-primary"
            [disabled]="!this.filtro.controls.email.value && !this.filtro.controls.telefone.value || this.filtro.controls.email.value && this.filtro.controls.telefone.value"
            (click)="filtrarUsuarios(this.filtro.value)">Filtrar</button>
        </div> -->

        <div style="text-align: right;">
          <button type="submit" class="btn btn-primary" (click)="filtrarUsuarios(this.filtro.value)"
            [disabled]="estaCarregando || !this.filtro.controls.email.value && !this.filtro.controls.telefone.value || this.filtro.controls.email.value && this.filtro.controls.telefone.value"><i
              *ngIf="!estaCarregando"></i><i class="far fa-spinner fa-spin mr-2"
              *ngIf="estaCarregando"></i>Filtrar</button>
        </div>
      </form>

      <label *ngIf="usuariosEncontrados.length == 0 && this.mostrarMensagem">Nenhum usuário encontrado</label>

      <h4 class="mt-1" *ngIf="usuariosEncontrados.length > 0">Usuários</h4>

      <p *ngIf="usuariosEncontrados?.length == 0 && this.nadaEncontrado" style="text-align: center;" class="mt-5">Nenhum
        resultado encontrado</p>

      <div class="row mt-3 pointer" (click)="selecionouUsuario(usuario)" style="cursor: pointer;" *ngFor="let usuario of usuariosEncontrados;">
        <div class="col-2 text-center">
          <img *ngIf="usuario.imagem" [src]="usuario.imagem" style="width: 54px; height: 54px;"
            class="rounded-circle usuario-borda" [class.selecionado]="usuario.estaSelecionado" alt="usuario.nome">
          <i *ngIf="!usuario.imagem" class="fas fa-user-circle text-muted rounded-circle usuario-borda"
            [class.selecionado]="usuario.estaSelecionado" style="font-size: 48px;"></i>
        </div>
        <div class="col-10 mt-2">
          <h4 class="card-title">
            <span>{{usuario.nome}}</span>
            <small class="float-right" >Conversar</small>
            <!-- <button
              *ngIf="!this.usuario.pedidoAmizadeEnviado && !this.usuario.pedidoAmizadeRecebido  && !this.usuario.pedidoConfirmado"
              style="text-align: right;" type="button" class="btn btn-sm btn-outline-primary float-right"
              (click)="adicionarUsuario(usuario)">
              Adicionar
              &nbsp;
              <i class="fal fa-plus"></i>
            </button>
            <button *ngIf="this.usuario.pedidoAmizadeEnviado && !this.usuario.pedidoAmizadeRecebido"
              style="text-align: right;" type="button" class="btn btn-sm btn-outline-primary float-right"
              (click)="cancelarAmizadeUsuario(usuario)">
              Pendente
              &nbsp;
              <i class="fal fa-clock"></i>
            </button>
            <button *ngIf="this.usuario.pedidoAmizadeRecebido" style="text-align: right;" type="button"
              class="btn btn-sm btn-outline-primary float-right" (click)="confirmarUsuario(usuario)">
              Confirmar
              &nbsp;
              <i class="fal fa-check"></i>
            </button>

            <button *ngIf="this.usuario.pedidoConfirmado" style="text-align: right;" type="button"
              class="btn btn-sm btn-outline-primary float-right" (click)="removerUsuario(usuario)">
              Amigo
              &nbsp;
              <i class="fal fa-star"></i>
            </button> -->
          </h4>
        </div>
      </div>
    </div>
  </div>
</app-modal>