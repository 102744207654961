import { Injectable, Injector } from '@angular/core';
import { Observable, } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from './shared/messages/notification.service';
import { LoginService } from './services';
import { UsuarioService } from './services/usuario.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

    constructor(private injector: Injector, private loginService: LoginService, private usuarioService: UsuarioService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const loginService = this.injector.get(LoginService);
        const ns = this.injector.get(NotificationService);
        // const loading = this.injector.get(LoadingService);

        if (loginService.isLoggedIn()) {
            req = req.clone({ headers: req.headers.set('Authorization', `Bearer ${loginService.getToken()}`) });
        }

        return next.handle(req)
            .pipe(
                tap((event: HttpEvent<any>) => {
                }, (err: HttpErrorResponse) => {

                    let message = '';
                    if (err.error != null) {
                        if (typeof (err.error) === 'string') {
                            message = err.error
                        }
                        else {
                            message = err.error.Message;
                        }
                    }
                    else {
                        message = err.message;
                    }

                    if (err instanceof HttpErrorResponse) {
                        if (!navigator.onLine) {
                            ns.notifty('Não foi possível se comunicar com o servidor.');
                        } else {

                            switch (err.status) {
                                case 400:
                                    ns.notifty(message || 'Má requisição.');
                                    break;
                                case 401:
                                    ns.notifty('Não autenticado.');
                                    this.usuarioService.alterarStatusOnline(this.loginService.getUsuario().id, false);

                                    this.loginService.logout();
                                    break;
                                case 403:
                                    ns.notifty(message || 'Não autorizado.');
                                    break;
                                case 404:
                                    ns.notifty(message || 'Não encontrado.');
                                    break;
                                case 500:
                                    ns.notifty(message || 'Erro interno do servidor.');
                                    break;
                                default:
                                    ns.notifty('Não foi possível se conectar com o servidor.');
                            }
                        }
                    } else {
                        ns.notifty('Erro interno do Angular.');
                    }

                    // loading.show(false);
                }, () => {

                    // loading.show(false);
                })
            );
    }
}