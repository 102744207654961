<app-modal title="Lista dos lançamento">
  <table class="table table-sm table-hover table-striped" *ngIf="lancamentos.length > 0">
    <thead>
      <tr>
        <th class="text-left">Data</th>
        <th class="text-left">Histórico</th>
        <th class="text-left">Valor</th>
      </tr>
    </thead>
    <tbody class="text-left">
      <tr class="text-nowrap text-light bg-success" *ngFor="let lancamento of lancamentos"
        [class]="lancamento.creditaValor ? 'border-success' : 'border-danger'">
        <td>{{lancamento.data | date:'dd/MM/yyyy'}}</td>
        <td>{{lancamento.historico}}</td>
        <td>{{lancamento.valor | currency:'BRL'}}</td>
      </tr>
    </tbody>
  </table>

  <div *ngIf="lancamentos.length == 0">
    <h6 class="text-muted">Não possui Lançamentos.</h6>
  </div>
</app-modal>
