import { Politico } from ".";

export class Proposta {
    id: string;
    avaliacao: number;
    data: Date;
    descricao: string;
    imagem: string;
    politicoId: string;
    status: number;
    statusText: string;
    titulo: string;
    uf: string;
    cidade: string;
    politico: Politico;
}