import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { LoginService } from "./login.service";
import { environment } from "src/environments/environment";

@Injectable()
export class MenuService {
    hubChatConnection: signalR.HubConnection;

    constructor(
        private _loginService: LoginService
    ) { }

    initConnectionGuard() {
        if (!this.hubChatConnection) {
            this.buildChatConnection();
            this.startChatConnection();
        }

        setInterval(() => {
            if (this.hubChatConnection.state == signalR.HubConnectionState.Disconnected) {
                this.startChatConnection();
            }
        }, 10000);
    }

    buildChatConnection() {
        this.hubChatConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${environment.signalR}/chatHub`,
        {
            accessTokenFactory: () => this._loginService.usuario.token
        })
        .build();
    }

    startChatConnection() {
        if(this._loginService.isLoggedIn()){

            this.hubChatConnection
            .start()
            .then(() => {
                
            })
            .catch(err => console.log('Error while starting connection: ' + err))
        }
    }

    closeChatConnection() {
        this.hubChatConnection
            .stop()
            .then(() => {
                
            })
            .catch(err => console.log('Error while stopping connection: ') + err)
    }
}