import { CarrinhoCompra } from "./carrinho-compra.model";
import { Vendedor } from "./vendedor.model";
import { Usuario } from "./usuario.model";

export class Endereco{
    id: string;
    estado: string;
    cidade: string;
    bairro: string;
    logradouro: string;
    descricao: string;
    complemento: string;
    referencia: string;
    usuarioId: string;
    usuario: Usuario;
}