import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services';

@Component({
  selector: 'app-container-propostas-denuncias',
  templateUrl: './container-propostas-denuncias.component.html',
  styleUrls: ['./container-propostas-denuncias.component.scss']
})
export class ContainerPropostasDenunciasComponent implements OnInit {

  isLoggedIn: boolean = false;

  constructor(
    private _loginService: LoginService,
  ) { }

  ngOnInit() {
    this._loginService.logou.subscribe(_ => {
      this.isLoggedIn = this._loginService.isLoggedIn();
    });

    this._loginService.deslogou.subscribe(_ => {
      this.isLoggedIn = this._loginService.isLoggedIn();
    });

    this.isLoggedIn = this._loginService.isLoggedIn();
  }
}
