import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gasto-publico',
  templateUrl: './gasto-publico.component.html',
  styleUrls: ['./gasto-publico.component.scss']
})
export class GastoPublicoComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {

  }
}
