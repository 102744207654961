import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

function padNumber(value: number) {
    if (isNumber(value)) {
        return `0${value}`.slice(-2);
    } else {
        return '';
    }
}

function isNumber(value: any): boolean {
    return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
}

@Injectable()
export class NgbDatePtParserFormatter extends NgbDateParserFormatter {
    day: number;
    month: number;
    year: number;

    parse(value: string): NgbDateStruct {
        if (value.length <= 10) {
            value = value.split('/').join('');

            if (value.length == 2) {
                this.day = parseInt(value.substr(0, 2));
                return { year: null, month: null, day: this.day };
            } else if (value.length == 4) {
                this.month = parseInt(value.substr(2, 2));
                return { year: null, month: this.month, day: null };
            } else if (value.length == 8) {
                this.year = parseInt(value.substr(4, 4));
                return { year: this.year, month: this.month, day: this.day };
            }
        }
        return null;
    }

    format(date: NgbDateStruct): string {
        return `${this.pad(date.day.toString(), 2)}/${this.pad(date.month.toString(), 2)}/${date.year}`;
    }

    pad(str: string, size: number) {
        while (str.length < (size || 2)) {
            str = '0' + str;
        }
        return str;
    }
}