import { Component, OnInit, ViewChild } from '@angular/core';
import { Vendedor, Produto, Usuario } from 'src/app/models';
import { UntypedFormGroup, FormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { PoliticoService, LoginService, VendedorService } from 'src/app/services';
import { NotificationService } from 'src/app/shared/messages/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedMasks } from 'src/app/shared/shared.mask';
import { UsuarioService } from 'src/app/services/usuario.service';
import { TipoMidia } from 'src/app/enum/tipo-midia';

@Component({
  selector: 'app-vendedores',
  templateUrl: './vendedores.component.html',
  styleUrls: ['./vendedores.component.scss']
})
export class VendedoresComponent implements OnInit {

  vendedor: Usuario;
  form: UntypedFormGroup;
  areSubmitting: boolean;
  id: string;
  telefoneMovelMask: any;
  hiddingPassword: boolean = false;
  hiddingToPerfil: boolean = false;

  areAdmin: boolean;
  produtos: Produto[]

  constructor(
    private formBuilder: UntypedFormBuilder,
    private _service: VendedorService,
    private _usuarioService: UsuarioService,
    private _notificationService: NotificationService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _loginService: LoginService
  ) { }

  ngOnInit() {
    this.areAdmin = this._loginService.areAdmin();

    this.form = this.formBuilder.group({
      id: [null],
      email: [null, Validators.required],
      telefone: [null],
      documento: [null],
      facebookId: [null],
      googleId: [null],
      senha: [null],
      token: [null],
      temSenha: [false],
      perfil: [null],
      nome: [null, Validators.required],
      imagem: [null],
    });

    // Verificar se esta editando o vendedor
    this.id = this._route.snapshot.params['id'];

    if (this.id != null) {

      this._usuarioService.encontrar(this.id).subscribe(vendedor => {
        this.form.patchValue(vendedor);
        this.hiddingPassword = true;
      })

      // Atribuir Mascaras
      this.telefoneMovelMask = SharedMasks.telefoneMovelMask;
    }
  }

  salvar(vendedor: Usuario) {
    this.areSubmitting = true;
    vendedor.perfil = 'vendedor';

    this._usuarioService.salvar(vendedor)
      .subscribe(resp => {
        this._notificationService.notifty(`O vendedor ${vendedor.nome} foi salvo com sucesso.`);
        this.resetForm();
        this.areSubmitting = false;
      }, error => this.areSubmitting = false);
  }

  remover() {
    this._usuarioService.remover(this.id).subscribe(_ => {
      this._notificationService.notifty(`O vendedor ${this.form.value.nome} foi removida.`);
      this.resetForm();
    });
  }

  resetForm() {
    this._router.navigate(['/vendedores']);
  }

  onFileChanged(event) {
    const file = event.target.files[0];

    if (file) {
      const tipo = file.type.includes('image') ? TipoMidia.Imagem : TipoMidia.Video;
      const mb = tipo == TipoMidia.Imagem ? 6 : 64;
      const maxSize = mb * 10000000;

      if (file.size > maxSize) {
        this._notificationService.notifty(`O tamanho ${tipo == TipoMidia.Imagem ? 'da imagem' : 'do vídeo'} não pode exceder ${mb}mb`);
      } else {
        this._usuarioService
          .salvarMidia(file, tipo)
          .subscribe((url: string) => {
            if (url) {
              url = url.split('"').join('');
              this.form.controls.imagem.setValue(url);
            }
          });
      }
    }

  }

}
