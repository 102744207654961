export class SharedMasks {
  static readonly cepMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  static readonly dddMask = [/\d/, /\d/];
  static readonly telefoneFixoMask = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  static readonly telefoneMovelMask = function (value) {
      const dig8 = ['(',/\d/,/\d/,')',/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
      const dig9 = ['(',/\d/,/\d/,')',/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
      if (value && value.length < 10) {
          return dig8;
      }

      return dig9;
  };
  static readonly cpfCnpjMask = function (value) {
      const cpf = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
      const cnpj = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

      if (value && value.length < 15) {
          return cpf;
      }

      return cnpj;
  };

  static readonly dateMask2 = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  static readonly dateMask = '00/00/0000';
  static readonly dateTimeMask = '00/00/0000 00:00';
}
