import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/models';
import { UsuarioService } from 'src/app/services/usuario.service';
import { LoginService } from 'src/app/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-usuario-lista',
  templateUrl: './usuario-lista.component.html',
  styleUrls: ['./usuario-lista.component.scss']
})
export class UsuarioListaComponent implements OnInit {

  usuarios: Usuario[];
  usuariosBkp: Usuario[];
  mensagemListaVazia: boolean;
  estaCarregando: boolean = true;

  constructor(
    private usuarioService: UsuarioService,
    private usuarioLogado: LoginService,
    private router: Router,
  ) { }

  ngOnInit() {

    if (this.usuarioLogado.usuario.perfil == 'user') {
      this.router.navigate(['feed/propostas']);
    }
    else {
      this.listar();
    }
  }

  listar() {
    this.estaCarregando = true;
    
    this.usuarioService.listar()
      .subscribe(usuarios => {
        this.usuarios = usuarios.filter(x => x.id != this.usuarioLogado.usuario.id);

        this.usuariosBkp = usuarios.filter(x => x.id != this.usuarioLogado.usuario.id);

        if (usuarios.length < 1) {
          this.mensagemListaVazia = true;
        }

        this.estaCarregando = false;
      }, _=> this.estaCarregando = false);
  }

  pesquisar(pesquisa: string) {
    this.usuarios = this.usuariosBkp;

    if (pesquisa != '') {
      this.usuarios = this.usuarios.filter(x => x.nome?.toLowerCase()?.trim()?.indexOf(pesquisa.toLowerCase().trim()) > -1 || x.email?.toLowerCase()?.trim()?.indexOf(pesquisa.toLowerCase().trim()) > -1);
    }

    if (this.usuarios.length < 1) {
      this.mensagemListaVazia = true;
    } else {
      this.mensagemListaVazia = false;
    }
  }
}

