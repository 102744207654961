import { Component, OnInit } from '@angular/core';
import { Bairro, Midia, Postagem, Estado, Cidade, Endereco } from 'src/app/models';
import { SharedConversions, DateIO } from 'src/app/shared/shared.conversions';
import { TipoMidia } from 'src/app/enum';
import { UfService } from 'src/app/shared/uf.service';
import { PostagemService } from 'src/app/services/postagem.service';
import { NotificationService } from 'src/app/shared/messages/notification.service';
import { LoginService, OnMapsService } from 'src/app/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AddressResult } from 'src/app/models/address-result.model';

@Component({
  selector: 'app-postagem-cadastro',
  templateUrl: './postagem-cadastro.component.html',
  styleUrls: ['./postagem-cadastro.component.scss']
})
export class PostagemCadastroComponent implements OnInit {

  postagemForm: UntypedFormGroup;
  midiaFormArray: UntypedFormArray;
  estaValidando: boolean;
  postagem = new Postagem;
  id: string;
  ufs: Estado[];
  cidades: Cidade[];
  bairros: Bairro[];
  midias: Midia[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private ufService: UfService,
    private postagemService: PostagemService,
    private notificationService: NotificationService,
    private loginService: LoginService,
    private activeModal: NgbActiveModal,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private mapsService: OnMapsService,
  ) { }

  ngOnInit() {
    this.postagemForm = this.formBuilder.group({
      id: [null],
      titulo: [null],
      data: [SharedConversions.convertDate(DateIO.js, DateIO.ngb, new Date()), Validators.required],
      uf: ['', Validators.required],
      cidade: ['', Validators.required],
      bairro: ['', Validators.required],
      descricao: [null, Validators.required],
      validada: [false],
      usuarioId: [this.loginService.usuario.id],
      midias: this.formBuilder.array([]),
    });

    // Listar Adicionais
    this.mapsService.estados().subscribe(estados => this.ufs = estados);

    // Verificar se esta editando a postagem
    this.id = this.route.snapshot.params.id;

    if (this.id != null) {
      this.postagemService.encontrar(this.id).subscribe(postagem => {
        this.postagem = postagem
        this.midias = this.postagem.midias;

        this.estaValidando = true;
        this.location.replaceState('/postagems');
      });
    };
  }

  renderMidia(url): UntypedFormGroup {
    if (url) {
      return this.formBuilder.group({
        url: [url],
      });
    }
    else {
      return this.formBuilder.group({
        url: [''],
      });
    }
  }

  addMidias() {
    this.midiaFormArray = <UntypedFormArray>this.postagemForm.get('midias');
    var url = null;
    this.midiaFormArray.push(this.renderMidia(url));
  }

  getMidiasFormArray(): UntypedFormArray {
    return <UntypedFormArray>this.postagemForm.get('midias');
  }

  removeMidia(index: number) {
    this.midiaFormArray = <UntypedFormArray>this.postagemForm.get('midias');
    this.midiaFormArray.removeAt(index);
  }

  enviar(postagem: Postagem) {
    if (this.id) {
      postagem = this.postagem;
    }
    else {
      postagem.midias = this.postagemForm.controls.midias.value;
      // postagem.data = new Date();

      postagem.data = <Date>SharedConversions.convertDate(DateIO.ngb, DateIO.cs, postagem.data);
    }

    postagem.midias.forEach(midia => {
      if (midia.url.match(".mp4"))
        midia.tipoMidia = TipoMidia.Video;
      else
        midia.tipoMidia = TipoMidia.Imagem
    });

    this.postagemService.salvar(postagem)
      .subscribe(resp => {
        if (this.estaValidando) {
          this.notificationService.notifty(`A postagem foi validada com sucesso.`);
          this.router.navigateByUrl("/img-validacao");
        } else {
          this.notificationService.notifty(`Postagem foi feita com sucesso.`);
          this.activeModal.close();
        }
        this.postagemService.tiraBolinhaImagens();
      }, error => {
        this.notificationService.notifty(`Erro ao enviar postagem.`);
      });
  }

  aceitar(midia: Midia) {
    let midiaCerta = this.midias.find(x => x.id == midia.id);
    midiaCerta.aprovada = true;
  }

  recusar(midia: Midia) {
    let midiaCerta = this.midias.find(x => x.id == midia.id);
    midiaCerta.aprovada = false;
  }

  aceitarTodos() {
    this.midias.forEach(midia => {
      midia.aprovada = true;
    });
  }

  recusarTodos() {
    this.midias.forEach(midia => {
      midia.aprovada = false;
    });
  }

  usarLocalizacao() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;

        this.mapsService.getLocalizacao(latitude, longitude).subscribe((result: AddressResult) => {
          if (result) {
            const state = this.ufs.find(x => x.nome == result.address.state);

            this.postagemForm.controls.uf.setValue(state.uf);
            this.postagemForm.controls.cidade.setValue(result.address.city);
            this.postagemForm.controls.bairro.setValue(result.address.suburb);

            this.mapsService.cidades(state.uf).subscribe(cidades => this.cidades = cidades);
            this.mapsService.bairros(state.uf, this.postagemForm.controls.cidade.value).subscribe(bairros => this.bairros = bairros);
          }
        });
      }, _ => { }, { enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 });
    }
  }

  filtrar(tipo: string) {
    if (tipo == 'estado') {
      this.postagemForm.controls.cidade.setValue('');
      this.postagemForm.controls.bairro.setValue('');
      this.bairros = [];
      this.mapsService.cidades(this.postagemForm.controls.uf.value).subscribe(cidades => this.cidades = cidades);
    }

    if (tipo == 'cidade') {
      this.postagemForm.controls.bairro.setValue('');
      this.mapsService.bairros(this.postagemForm.controls.uf.value, this.postagemForm.controls.cidade.value).subscribe(bairros => {
        this.bairros = bairros;
        if (this.bairros.length == 0) {
          var bairro = new Bairro();
          bairro.nome = 'Único';
          this.bairros.push(bairro);
        }
      }
      )
    }
  }

  onFileChanged(event, index) {
    const file = event.target.files[0];

    if (file) {
      const tipo = file.type.includes('image') ? TipoMidia.Imagem : TipoMidia.Video;
      const mb = tipo == TipoMidia.Imagem ? 6 : 64;
      const maxSize = mb * 10000000;

      if (file.size > maxSize) {
        this.notificationService.notifty(`O tamanho ${tipo == TipoMidia.Imagem ? 'da imagem' : 'do vídeo'} não pode exceder ${mb}mb`);
      } else {
        this.postagemService
          .salvarMidia(file, tipo)
          .subscribe((url: string) => {
            if (url) {
              url = url.split('"').join('');

              this.midiaFormArray = <UntypedFormArray>this.postagemForm.get('midias');

              this.midiaFormArray.removeAt(index);

              this.midiaFormArray = <UntypedFormArray>this.postagemForm.get('midias');

              this.midiaFormArray.push(this.renderMidia(url));
            }
          });
      }
    }
  }
}
