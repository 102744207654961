import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Estado, Cidade, Organizacao, Beneficiario, CentroCusto, Endereco } from 'src/app/models';
import { OnMapsService, OrganizacaoService, BeneficiarioService, CentroCustoService, LancamentoService } from 'src/app/services';
import { SharedConversions, DateIO } from 'src/app/shared/shared.conversions';
import { FiltroGastosDto } from 'src/app/dtos';
import { ResultadoFiltroGastosVm } from 'src/app/viewModels';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AddressResult } from 'src/app/models/address-result.model';

@Component({
  selector: 'app-gasto-publico-filtro',
  templateUrl: './gasto-publico-filtro.component.html',
  styleUrls: ['./gasto-publico-filtro.component.scss']
})
export class GastoPublicoFiltroComponent implements OnInit {

  gastosForm: UntypedFormGroup;
  id: string;
  ufs: Estado[];
  cidades: Cidade[];
  organizacoes: Organizacao[] = [];
  beneficiarios: Beneficiario[] = [];
  centroCustos: CentroCusto[] = [];
  resultadoFiltroDto: ResultadoFiltroGastosVm;
  estaLimpando: boolean;

  @ViewChild("firstElement") firstElementRef: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private mapsService: OnMapsService,
    private organizacaoService: OrganizacaoService,
    private beneficiarioService: BeneficiarioService,
    private centroCustoService: CentroCustoService,
    private lancamentoService: LancamentoService,
    private router: Router,
    private location: Location,
  ) { }

  ngOnInit() {
    this.gastosForm = this.formBuilder.group({
      uf: ['', Validators.required],
      cidade: [''],
      beneficiarioId: ['', Validators.required],
      centroCustoId: ['', Validators.required],
      organizacaoId: ['', Validators.required],
      dataInicio: [SharedConversions.convertDate(DateIO.js, DateIO.ngb, new Date(new Date().getFullYear(), new Date().getMonth() - 1))],
      dataFim: [SharedConversions.convertDate(DateIO.js, DateIO.ngb, new Date)],
    });

    this.firstElementRef.autoFocus = true;

    let params = <any>this.route.queryParams;

    if (params.value.resultado && !this.estaLimpando) {
      this.resultadoFiltroDto = JSON.parse(params.value.resultado);
      this.gastosForm.controls.dataInicio.setValue(SharedConversions.convertDate(DateIO.js, DateIO.ngb, new Date(this.resultadoFiltroDto.filtroGastosDto.dataInicio)));
      this.gastosForm.controls.dataFim.setValue(SharedConversions.convertDate(DateIO.js, DateIO.ngb, new Date(this.resultadoFiltroDto.filtroGastosDto.dataFim)));
    }

    // Listar Adicionais
    this.mapsService.estados().subscribe(estados => {
      var estadoNovo = new Estado();
      estadoNovo.nome = 'Brasil';
      estadoNovo.uf = 'Brasil';
      estados.push(estadoNovo);
      this.ufs = estados;

      if (this.resultadoFiltroDto) {
        this.gastosForm.controls.uf.setValue(this.resultadoFiltroDto.filtroGastosDto.uf);

        this.listarCidades();

        if (!this.resultadoFiltroDto.filtroGastosDto.cidade)
          this.listarOrganizacoes();
      }
    });
  }

  listarBeneficiarios() {
    this.beneficiarios = [];
    this.beneficiarioService.listarPorOrganizacao(this.gastosForm.controls.organizacaoId.value).subscribe(beneficiarios => {
      var beneficiarioNovo = new Beneficiario('0', 'Todos');
      beneficiarios.unshift(beneficiarioNovo);
      this.beneficiarios = beneficiarios;

      var centroCustoNovo = new CentroCusto('0', 'Todos');
      this.centroCustos.unshift(centroCustoNovo);

      this.gastosForm.controls.beneficiarioId.setValue('0');
      this.gastosForm.controls.centroCustoId.setValue('0');

      if (this.resultadoFiltroDto && this.resultadoFiltroDto.filtroGastosDto.beneficiarioId) {
        this.gastosForm.controls.beneficiarioId.setValue(this.resultadoFiltroDto.filtroGastosDto.beneficiarioId);
        this.resultadoFiltroDto.filtroGastosDto.beneficiarioId = null;
        this.listarCentroCustos();
      }

      this.location.replaceState('/feed/gastos-publicos');
    });
  }

  listarCidades() {
    this.beneficiarios = [];
    this.centroCustos = [];
    this.gastosForm.controls.beneficiarioId.setValue('');
    this.gastosForm.controls.centroCustoId.setValue('');

    if (this.gastosForm.controls.uf.value != '') {
      this.mapsService.cidades(this.gastosForm.controls.uf.value).subscribe(cidades => {
        this.cidades = cidades;

        if (this.resultadoFiltroDto && this.resultadoFiltroDto.filtroGastosDto.cidade && this.resultadoFiltroDto.filtroGastosDto.uf == this.gastosForm.controls.uf.value) {
          this.gastosForm.controls.cidade.setValue(this.resultadoFiltroDto.filtroGastosDto.cidade);
          this.resultadoFiltroDto.filtroGastosDto.cidade = null;
        }
        else
          this.gastosForm.controls.cidade.setValue('');

      });
      this.listarOrganizacoes();
    }

    this.gastosForm.controls.cidade.setValue('');
    this.gastosForm.controls.organizacaoId.setValue('');
    this.cidades = [];
    this.organizacoes = [];
  }

  listarOrganizacoes() {
    let req: Observable<Organizacao[]>;

    if (this.gastosForm.controls.cidade.value != '')
      req = this.organizacaoService.listarPorUfECidade(this.gastosForm.controls.uf.value, this.gastosForm.controls.cidade.value);
    else
      req = this.organizacaoService.listarPorUf(this.gastosForm.controls.uf.value);

    req.subscribe((organizacoes: Organizacao[]) => {
      this.organizacoes = organizacoes;
      if (this.resultadoFiltroDto && this.resultadoFiltroDto.filtroGastosDto.organizacaoId
        && this.organizacoes.find(x => x.id == this.resultadoFiltroDto.filtroGastosDto.organizacaoId)) {
        this.gastosForm.controls.organizacaoId.setValue(this.organizacoes.find(x => x.id == this.resultadoFiltroDto.filtroGastosDto.organizacaoId).id);
        this.resultadoFiltroDto.filtroGastosDto.organizacaoId = null;
        this.listarBeneficiarios();
      }

    });
  }

  listarCentroCustos() {
    this.centroCustos = [];
    if (this.gastosForm.controls.beneficiarioId.value != null && this.gastosForm.controls.beneficiarioId.value != 0) {
      this.centroCustoService.listarPorOrganizacao(this.gastosForm.controls.organizacaoId.value).subscribe((centroCustos: CentroCusto[]) => {
        this.centroCustos = centroCustos;
        var centroCustoNovo = new CentroCusto('0', 'Todos');
        this.centroCustos.unshift(centroCustoNovo);

        if (this.resultadoFiltroDto && this.resultadoFiltroDto.filtroGastosDto.centroCustoId) {
          this.gastosForm.controls.centroCustoId.setValue(this.resultadoFiltroDto.filtroGastosDto.centroCustoId);
          this.resultadoFiltroDto.filtroGastosDto.centroCustoId = null;
        }
        else
          this.gastosForm.controls.centroCustoId.setValue('0');
      });
    }
    else {
      var centroCustoNovo = new CentroCusto('0', 'Todos');
      this.centroCustos.unshift(centroCustoNovo);
      this.gastosForm.controls.centroCustoId.setValue('0');
    }
  }

  limparFiltro() {
    this.resultadoFiltroDto = null;
    this.estaLimpando = true;
    this.cidades = [];
    this.organizacoes = [];
    this.beneficiarios = [];
    this.centroCustos = [];
    this.ngOnInit();
  }

  usarLocalizacao() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;

        this.mapsService.getLocalizacao(latitude, longitude).subscribe((result: AddressResult) => {
          if (result) {
            const state = this.ufs.find(x => x.nome == result.address.state);

            this.mapsService.cidades(state.uf).subscribe(cidades => this.cidades = cidades);

            this.gastosForm.controls.uf.setValue(state.uf);
            this.gastosForm.controls.cidade.setValue(result.address.city);

            this.listarOrganizacoes();
          }
        });
      }, _ => { }, { enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 });
    }
  }

  filtrarDto() {
    let filtroGastosDto = new FiltroGastosDto();

    filtroGastosDto.beneficiarioId = this.gastosForm.controls.beneficiarioId.value == '0' ? null : this.gastosForm.controls.beneficiarioId.value;
    filtroGastosDto.centroCustoId = this.gastosForm.controls.centroCustoId.value == '0' ? null : this.gastosForm.controls.centroCustoId.value;
    filtroGastosDto.organizacaoId = this.gastosForm.controls.organizacaoId.value;

    filtroGastosDto.dataInicio = <Date>SharedConversions.convertDate(DateIO.ngb, DateIO.cs, this.gastosForm.controls.dataInicio.value);
    filtroGastosDto.dataFim = <Date>SharedConversions.convertDate(DateIO.ngb, DateIO.cs, this.gastosForm.controls.dataFim.value);
    filtroGastosDto.uf = this.gastosForm.controls.uf.value;
    filtroGastosDto.cidade = this.gastosForm.controls.cidade.value;

    filtroGastosDto.organizacaoNome = this.organizacoes.find(x => x.id == filtroGastosDto.organizacaoId).nome;
    filtroGastosDto.beneficiarioNome = this.beneficiarios.find(x => x.id == filtroGastosDto.beneficiarioId) ? this.beneficiarios.find(x => x.id == filtroGastosDto.beneficiarioId).nome : null;
    filtroGastosDto.centroCustoNome = this.centroCustos.find(x => x.id == filtroGastosDto.centroCustoId) ? this.centroCustos.find(x => x.id == filtroGastosDto.centroCustoId).nome : null;
    this.lancamentoService.filtrarGastos(filtroGastosDto).subscribe((resultado: ResultadoFiltroGastosVm) => {

      this.router.navigate(['/feed', 'gastos-publicos', 'grafico'], {
        queryParams: { resultado: JSON.stringify(resultado) }
      });
    });
  }
}