import { Component, OnInit, Input } from '@angular/core';
import { Postagem } from 'src/app/models';

@Component({
  selector: 'app-postagem',
  templateUrl: './postagem.component.html',
  styleUrls: ['./postagem.component.scss']
})
export class PostagemComponent implements OnInit {

  @Input() postagens: Postagem[];
  @Input() gostei?: boolean;

  constructor() { }

  ngOnInit() {
    for (const post of this.postagens) {
      post.mostrarRemover = false;
    }
  }

}
