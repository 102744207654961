import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Avaliacao, AvaliacaoDto } from '../models';

@Injectable()
export class AvaliacaoService {
    constructor(private http: HttpClient) { }

    avaliacao: Avaliacao;

    listar(pesquisa?: string): Observable<Avaliacao[]> {
        let params: HttpParams;
        if (pesquisa) {
            params = new HttpParams().set('query', pesquisa);
        }
        return this.http.get<Avaliacao[]>(`${environment.api}/avaliacoes`, { params: params });
    }

    encontrar(id: string): Observable<Avaliacao> {
        return this.http.get<Avaliacao>(`${environment.api}/avaliacoes/${id}`);
    }

    rate(dto: AvaliacaoDto): Observable<Avaliacao> {
        return this.http.post<Avaliacao>(`${environment.api}/avaliacoes/avaliacao`, dto);
    }

    salvar(avaliacao: Avaliacao): Observable<Avaliacao> {
        return this.http.post<Avaliacao>(`${environment.api}/avaliacoes`, avaliacao);
    }

    remover(id: string): Observable<Avaliacao> {
        return this.http.delete<Avaliacao>(`${environment.api}/avaliacoes/${id}`);
    }
}