import { Injectable, Output, EventEmitter, Directive } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { TipoLogin } from "../enum/tipo-login";
import { Observable } from "rxjs";
import { Chat } from "../models/chat.model";
import { environment } from "src/environments/environment";
import { TipoMidia } from "../enum";


@Directive()
@Injectable()
export class ChatService {

    @Output() alterado = new EventEmitter();
    
    constructor(
        private _http: HttpClient
    ) { }

    encontrarChatByUsuario(id: string): Observable<Chat> {
        return this._http.get<Chat>(`${environment.api}/chats/encontrar/${id}`);
    }

    listar(contagemLista: number, voceId?: string, outroId?: string): Observable<Chat[]> {
        return this._http.get<Chat[]>(`${environment.api}/chats?skip=${contagemLista}&voceId=${voceId}&outroId=${outroId}`);
    }

    salvar(chat: Chat): Observable<Date> {
        return this._http.post<Date>(`${environment.api}/chats`, chat);
    }

    salvarMidia(blob: any, tipo: TipoMidia): Observable<string> {

        const formData = new FormData();
        formData.append('file', blob, 'audio.mp3');

        return this._http.post<string>(`${environment.api}/chats/salvarMidia/${tipo}`, formData, { responseType: 'text' as 'json' });
    }

    // remover(id: number): Observable<any> {
    //     return this.http.delete<any>(`${environment.api}/audios/${id}`);
    // }

    obterExistencia(): Observable<boolean> {
        return this._http.get<boolean>(`${environment.api}/chats/possuiChats`);
    }

    remover(id: string): Observable<Chat> {
        return this._http.delete<Chat>(`${environment.api}/chats/remover/${id}`);
    }

    emitirAlterado() {
        this.alterado.emit();
    }
}