import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Endereco } from '../models';

@Injectable()
export class EnderecoService {
    constructor(private http: HttpClient) { }

    endereco: Endereco;

    listar(pesquisa?: string): Observable<Endereco[]> {
        let params: HttpParams;
        if (pesquisa) {
            params = new HttpParams().set('query', pesquisa);
        }
        return this.http.get<Endereco[]>(`${environment.api}/enderecos`, { params: params });
    }

    encontrar(id: string): Observable<Endereco> {
        return this.http.get<Endereco>(`${environment.api}/enderecos/${id}`);
    }

    salvar(endereco: Endereco): Observable<Endereco> {
        return this.http.post<Endereco>(`${environment.api}/enderecos`, endereco);
    }

    remover(id: string): Observable<Endereco> {
        return this.http.delete<Endereco>(`${environment.api}/enderecos/${id}`);
    }
}