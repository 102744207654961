import { Usuario } from "./usuario.model";
import { BandeiraCartao } from "./bandeira-cartao.model";

export class CartaoCredito {
    id: string;
    nomeTitularCartao: string;
    numeroCartao: string;
    anoExpiracap: string;
    mesExpiracao: string;
    cvv: string;

    bandeiraCartaoId: string;
    usuarioId: string;
    bandeiraCartao: BandeiraCartao;
    usuario: Usuario;
}