import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import * as $ from 'jquery';
import { Menu, Usuario, Comentario, Denuncia, Pedido } from 'src/app/models';
import { LoginService, ComentarioService, DenunciaService } from 'src/app/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from '../login/login.component';
import { ChatListaComponent } from '../chat/chat-lista/chat-lista.component';
import { ChatService } from 'src/app/services/chat.service';
import { MenuService } from 'src/app/services/menu.service';
import { NotificationService } from 'src/app/shared/messages/notification.service';
import { Chat } from 'src/app/models/chat.model';
import { NotificationMiddlewareService } from 'src/app/core/notification-middleware.service';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  menus: Menu[] = [];
  gastos: Menu[] = [];
  menusVendedores: Menu[] = [];
  usuario: Usuario;
  currentPage: string;
  reportados: Comentario[] = [];
  denunciados: Denuncia[] = [];
  denunciasReportadas: Denuncia[] = [];
  bolinhaReportados: boolean;
  bolinhaDenunciados: boolean;
  bolinhaDenunciasReportadas: boolean;
  colapsado: boolean = false;
  possuiChats: boolean = false;
  esconder: boolean = false;

  @ViewChild('som') som: ElementRef;
  @ViewChild('conteudo') conteudo: ElementRef;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private loginService: LoginService,
    private comentarioService: ComentarioService,
    private denunciaService: DenunciaService,
    private _menuService: MenuService,
    private _chatService: ChatService,
    private _ns: NotificationService,
    private _router: Router,
    private notificationMiddleware: NotificationMiddlewareService,
    private usuarioService: UsuarioService
  ) { }

  ngOnInit() {
    this.isLoggedIn();

    //Resetando o menu
    this.loginService.logou.subscribe(() => this.montarMenu());

    // Preenchendo usuário
    this.loginService.alterou.subscribe(usuario => {
      this.usuario = usuario;
    });
    this.usuario = this.loginService.usuario;

    if (this.usuario) {
      this.usuarioService.alterarStatusOnline(this.usuario.id, true);
    }

    this.comentarioService.tiraBola.subscribe(resp => {
      this.verificaReportados();
    });

    this.denunciaService.tiraBola.subscribe(resp => {
      this.verificaDenuncias();
    });

    this.denunciaService.tiraBolaDenuncia.subscribe(resp => {
      this.verificaDenunciasReportadas();
    });

    // Rota inicial
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentPage = event.url;
      }
    });

    //Inicia o SignalR
    this._menuService.initConnectionGuard();

    //Chats
    this.obterChats();

    this._chatService.alterado.subscribe(() => {
      this.obterChats();
    });

    this._menuService.hubChatConnection.on('chat_mensagem_recebida', (message: Chat) => {
      this._ns.notifty("Nova mensagem recebida. ");
      this.obterChats(() => {
        this.som.nativeElement.play();
      });
    })
    //Fim Chats

    // Menus
    this.montarMenu();

    if (this.isLoggedIn()) {
      this.verificaReportados();
      this.verificaDenuncias();
      this.verificaDenunciasReportadas();
    }

    this.loginService.handle.subscribe(() => {
      this.login();
    });
  }

  verificaReportados() {
    this.comentarioService.reportados().subscribe(reportados => {
      this.reportados = reportados;
      this.bolinhaReportados = this.reportados.length > 0;
    });
  }

  verificaDenuncias() {
    this.denunciaService.aprovando().subscribe(denunciados => {
      this.denunciados = denunciados;
      this.bolinhaDenunciados = this.denunciados.length > 0;
    });
  }

  verificaDenunciasReportadas() {
    this.denunciaService.reportados().subscribe(denunciasReportadas => {
      this.denunciasReportadas = denunciasReportadas;
      this.bolinhaDenunciasReportadas = this.denunciasReportadas.length > 0;
    });
  }

  montarMenu() {
    this.menus = [];
    this.gastos = [];

    if (this.areAdmin()) {
      this.menus.push(new Menu('/feed', 'Feed', 'fas fa-newspaper'));
      this.menus.push(new Menu('/politicos', 'Políticos', 'fas fa-user-tie'));
      this.menus.push(new Menu('/menu-propostas', 'Propostas', 'far fa-ballot-check'));
      this.menus.push(new Menu('/vendedores', 'Vendedores', 'fas fa-user-shield'));
      this.menus.push(new Menu('/usuarios', 'Usuários', 'fas fa-user'));
      this.gastos.push(new Menu('/beneficiarios', 'Beneficiários', 'fas fa-address-card'));
      this.gastos.push(new Menu('/organizacoes', 'Organizações', 'fas fa-building'));
      this.gastos.push(new Menu('/centro-custos', 'Centro de Custos', 'fas fa-vote-nay'));
      this.gastos.push(new Menu('/lancamentos', 'Lançamentos', 'fas fa-user-chart'));
    } else if (this.areAcessor()) {
      this.menus.push(new Menu('/feed', 'Feed', 'fas fa-newspaper'));
      this.menus.push(new Menu('/politicos', 'Políticos', 'fas fa-user-tie'));
      this.menus.push(new Menu('/menu-propostas', 'Propostas', 'far fa-ballot-check'));
    } else if (this.areOrgAdmin()) {
      this.menus.push(new Menu('/feed', 'Feed', 'fas fa-newspaper'));
      this.menus.push(new Menu('/beneficiarios', 'Beneficiários', 'fas fa-address-card'));
      this.menus.push(new Menu('/organizacoes', 'Organizações', 'fas fa-building'));
      this.menus.push(new Menu('/centro-custos', 'Centro de Custos', 'fas fa-vote-nay'));
    }
    else if (this.areVendedor()) {
      this.menus.push(new Menu('/feed', 'Feed', 'fas fa-newspaper'));
      this.menus.push(new Menu('/produtos', 'Produtos', 'fas fa-box-open'));
      this.menus.push(new Menu('/pedidos', 'Pedidos', 'fas fa-clipboard-list'));
    }
  }

  toggle() {
    this.colapsado = !this.colapsado;
  }

  selectPage(url: string) {
    $('#anchor').scrollTop(0);
    this.currentPage = url;
  }

  isPageActive(url: string): boolean {
    return this.currentPage == null ? false : (this.currentPage.indexOf(url) > -1);
  }

  isLoggedIn() {
    return this.loginService.isLoggedIn();
  }

  areAdmin() {
    return this.loginService.areAdmin();
  }

  areAcessor() {
    return this.loginService.usuario ? this.loginService.usuario.politicos.length > 0 : false;
  }

  areOrgAdmin() {
    return this.loginService.usuario ? this.loginService.usuario.organizacoes.length > 0 : false;
  }

  areVendedor() {
    return this.loginService.usuario ? this.loginService.usuario.perfil == 'vendedor' : false;
  }

  login() {
    const modal = this.modalService.open(LoginComponent, { size: 'sm', centered: true, backdrop: 'static', backdropClass: 'backdrop-ok', keyboard: false });
    modal.result.then(usuario => {
      this.usuario = usuario;
      this.obterChats();
      this.subscribeUser();
    }, () => { });
  }

  logout() {
    this.usuarioService.alterarStatusOnline(this.loginService.getUsuario().id, false);

    this.loginService.logout();
  }

  private obterChats(callback: Function = null) {
    if (this.loginService.isLoggedIn()) {
      this._chatService
        .obterExistencia()
        .subscribe((possuiChats: boolean) => {
          this.possuiChats = possuiChats;

          if (callback)
            callback();
        });
    }
  }

  abrirChat() {
    const modalRef = this.modalService.open(ChatListaComponent, { size: 'lg', centered: true, backdrop: 'static', backdropClass: 'backdrop-ok', keyboard: false })
  }

  // Inscricao do usuário para gerar FireBase
  subscribeUser() {
    this.notificationMiddleware.subscribeUser();
  }
  // // método para 'desinscrever' usuario
  // unsubscribeUser(){
  //   this.notificationMiddleware.unsubscribeUser();
  // }
}
