import { LiveIceCandidateDto } from "../dtos/live/live-ice-candidate-dto";
import { RoomLive } from "./room-live.model";
import { Usuario } from "./usuario.model";

export class RoomLiveItem {
    id: string;
    sessionId: string;
    liveId: string;
    live: RoomLive;
    userId: string;
    user: Usuario;
    connected: boolean;

    connection: RTCPeerConnection;
    pendingCandidates: LiveIceCandidateDto[] = [];
}