import { Component, OnInit } from '@angular/core';
import { Postagem, Usuario } from 'src/app/models';
import { PostagemService } from 'src/app/services/postagem.service';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/services';

@Component({
  selector: 'app-postagem-detalhe',
  templateUrl: './postagem-detalhe.component.html',
  styleUrls: ['./postagem-detalhe.component.scss']
})
export class PostagemDetalheComponent implements OnInit {

  id: string;
  postagem: Postagem;
  usuarioId: string;

  constructor(
    private postagemService: PostagemService,
    private route: ActivatedRoute,
    private _loginService: LoginService
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.usuarioId = this._loginService.usuario.id;

    if (this.id != null) {
      this.postagemService.encontrar(this.id).subscribe(postagem => {
        this.postagem = postagem;

        if (this.postagem != null) {
          this.postagem.midias = this.postagem.midias.filter(x => x.aprovada);
        }
      });
    }
  }

}
