<nav aria-label="breadcrumb ">
  <ol class="breadcrumb b-radius breadcrumb-appckground mt-3">
    <li class="breadcrumb-item active breadcrumb-link-actual mt-1" aria-current="page"><i class="fal fa-user-lock"></i>&nbsp;Usuários
      Bloqueados</li>
  </ol>
</nav>
<div class="table-responsive" *ngIf="!mensagemListaVazia">
  <table class="table table-sm table-hover table-striped">
    <thead>
      <tr>
        <th></th>
        <th class="text-left">Nome</th>
        <th class="text-left">Ação</th>
      </tr>
    </thead>
    <tbody class="text-left">
      <tr class="text-nowrap tr-click" *ngFor="let bloqueado of bloqueados" [routerLink]="[]">
        <td class="td-adjustment"><img src="{{bloqueado.usuarioBloqueado.imagem}}" class="img-adjustment" *ngIf="bloqueado.usuarioBloqueado.imagem" /><i
            class="fal fa-user-circle" *ngIf="!bloqueado?.usuarioBloqueado.imagem" style="margin-left: 1.5px;"></i></td>
        <td>{{bloqueado.usuarioBloqueado.nome}}</td>
        <td>
          <button type="button" class="btn btn-sm btn-outline-primary btn-on float-center" (click)="desbloquear(bloqueado.id)" [disabled]="areSubmiting">
            <i class="fal fa-lock-open-alt"></i>
            <span class="d-none d-lg-inline-flex">&nbsp;Desbloquear</span></button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="mensagemListaVazia">
  <h6 class="text-muted">Não possui usuários bloqueados.</h6>
</div>
