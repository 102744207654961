import { Component, OnInit } from '@angular/core';
import { Politico } from 'src/app/models';
import { PoliticoService, LoginService } from 'src/app/services';

@Component({
  selector: 'app-politico-lista',
  templateUrl: './politico-lista.component.html',
  styleUrls: ['./politico-lista.component.scss']
})
export class PoliticoListaComponent implements OnInit {

  politicos: Politico[];
  mensagemListaVazia: boolean = false;
  politicosBkp: Politico[];
  areAdmin: boolean;

  constructor(
    private politicoService: PoliticoService,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    this.areAdmin = this.loginService.areAdmin();

    if (this.areAdmin) {
      this.listar();
    } else {
      this.politicos = this.loginService.usuario.politicos;
      this.politicosBkp = this.politicos;
    }
  }

  listar() {
    this.politicoService.listar()
      .subscribe(politicos => {
        this.politicos = politicos
        this.politicosBkp = politicos;
        if (politicos.length < 1) {
          this.mensagemListaVazia = true;
        }
      });
  }

  pesquisar(pesquisa: string) {
    this.politicos = this.politicosBkp;

    if (pesquisa != '') {
      this.politicos = this.politicos.filter(x => x.nome.toLowerCase().trim().indexOf(pesquisa.toLowerCase().trim()) > -1 || x.cargo.toLowerCase().trim().indexOf(pesquisa.toLowerCase().trim()) > -1);
      
    }


    if (this.politicos.length < 1) {
      this.mensagemListaVazia = true;
    } else {
      this.mensagemListaVazia = false;
    }
  }
}

