<nav aria-label="breadcrumb ">
  <ol class="breadcrumb b-radius breadcrumb-appckground mt-3">
    <a class="breadcrumb-item mt-1" aria-current="page" [routerLink]="['/produtos']"><i
        class="fas fa-box-open"></i>&nbsp;Produto</a>
    <li class="breadcrumb-item active breadcrumb-link-actual mt-1" aria-current="page">Adicionar</li>
    <li class="ml-auto">
      <button type="button" class="btn btn-sm btn-outline-primary btn-on" [routerLink]="['/produtos']">
        <i class="fal fa-list"></i>
        <span class="d-none d-lg-inline-flex">&nbsp;Listar</span>
      </button>
    </li>
  </ol>
</nav>

<form [formGroup]="form" novalidate autocomplete="off">
  <input type="hidden" formControlName="id" />
  <div class="row">
    <div class="col-lg-9 order-2 order-lg-1">
      <div class="row">
        <div class="col-lg-9">
          <app-input label="Titulo" errorMessage="Campo obrigatório." [isRequired]="true">
            <input #firstElement type="text" formControlName="titulo" class="form-control form-control-sm"
              placeholder="Nome do produto" />
          </app-input>
        </div>

        <div class="col-lg-3">
          <app-input label="Valor" errorMessage="Campo obrigatório." [isRequired]="true">
            <input #firstElement currencyMask type="text" formControlName="valor"
              class="form-control form-control-sm" placeholder="$ 0,00" />
          </app-input>
        </div>

        <div class="col-lg-3">
          <app-input label="Valor Promoção">
            <input #firstElement currencyMask type="text"
              formControlName="valorPromocao" class="form-control form-control-sm"
              placeholder="$ 0,00" />
          </app-input>
        </div>

        <div class="col-lg-3">
          <app-input label="Quantidade" errorMessage="Campo obrigatório." [isRequired]="true">
            <input #firstElement type="number" formControlName="quantidade" class="form-control form-control-sm"
              placeholder="Quantidade do produto" />
          </app-input>
        </div>

      </div>
    </div>

    <div class="col-lg-3 order-1 order-lg-2">
      <div class="row">
        <div class="col">
          <app-input label="Ícone do produto" labelAlign="text-center" errorMessage="Campo obrigatório."
            [isRequired]="true">
            <app-img formControlName="imagem" icone="fas fa-box-open"></app-img>
          </app-input>
        </div>
      </div>
    </div>

    <div class="col-12 order-1 order-lg-2">
      <app-input label="Descrição">
        <textarea type="text" formControlName="descricao" class="form-control form-control-sm"
          placeholder="Descrição do produto" rows="3"></textarea>
      </app-input>
    </div>
  </div>

  <hr />

  <button type="submit" class="btn btn-sm btn-primary btn-on-full mr-2" (click)="salvar(form.value)"
    [disabled]="areSubmitting || this.form.invalid">
    <i class="fal fa-check"></i>&nbsp;Salvar
  </button>

  <button type="button" class="btn btn-sm btn-outline-danger btn-on float-right" (click)="remover()"
    *ngIf="form.controls.id.value != null && areVendedor">
    <i class="fal fa-trash"></i>
    <span class="d-none d-lg-inline-flex">&nbsp;Remover</span>
  </button>
</form>