import { Component, OnInit } from '@angular/core';
import { ResultadoFiltroGastosVm } from 'src/app/viewModels';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GastoPublicoDetalheComponent } from './gasto-publico-detalhe/gasto-publico-detalhe.component';
import { LancamentoService } from 'src/app/services';
import { Lancamento } from 'src/app/models';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-gasto-publico-grafico',
  templateUrl: './gasto-publico-grafico.component.html',
  styleUrls: ['./gasto-publico-grafico.component.scss']
})
export class GastoPublicoGraficoComponent implements OnInit {

  resultadoFiltroDto: ResultadoFiltroGastosVm;

  doughnutChartLabels: any = ['Créditos', 'Débitos'];
  doughnutChartData: any = [];
  doughnutChartColors: any = [{ backgroundColor: ['#005404', 'red'] }];
  doughnutChartOptions: any = {
    legend: { display: false },
    tooltips: { enabled: false },
    hover: { mode: null },
    cutoutPercentage: 60
  };

  constructor(
    private modalService: NgbModal,
    private lancamentoService: LancamentoService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    let params = <any>this.route.queryParams;

    this.resultadoFiltroDto = JSON.parse(params.value.resultado);
    this.doughnutChartData = [this.resultadoFiltroDto.valorCreditos, this.resultadoFiltroDto.valorDebitos];
  }

  verDetalhes() {
    this.lancamentoService.filtrarGastosDetahe(this.resultadoFiltroDto.filtroGastosDto).subscribe((lancamentos: Lancamento[]) => {
      const modalRef = this.modalService.open(GastoPublicoDetalheComponent, { centered: true });
      modalRef.result.then(confirma => {
      }, dismiss => { });

      modalRef.componentInstance.lancamentos = lancamentos;
    });
  }

  voltarFiltro() {
    this.router.navigate(['/feed', 'gastos-publicos'], {
      queryParams: { resultado: JSON.stringify(this.resultadoFiltroDto) }
    });
  }
}
