import { Component, OnInit } from '@angular/core';
import { Denuncia } from 'src/app/models';
import { DenunciaService } from 'src/app/services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-denuncia-detalhe',
  templateUrl: './denuncia-detalhe.component.html',
  styleUrls: ['./denuncia-detalhe.component.scss']
})
export class DenunciaDetalheComponent implements OnInit {

  id: string;
  denuncia: Denuncia;

  constructor(
    private denunciaService: DenunciaService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    if (this.id != null) {
      this.denunciaService.encontrar(this.id).subscribe(denuncia => {
        this.denuncia = denuncia;

        if (this.denuncia != null) {
          this.denuncia.midias = this.denuncia.midias.filter(x => x.aprovada);
        }
      });
    }
  }

}
