import { Routes } from '@angular/router';
import { PoliticoListaComponent } from './pages/politico/politico-lista/politico-lista.component';
import { PoliticoComponent } from './pages/politico/politico.component';
import { PropostaComponent } from './pages/proposta/proposta.component';
import { PropostaListaComponent } from './pages/proposta/proposta-lista/proposta-lista.component';
import { UsuarioComponent } from './pages/usuario/usuario.component';
import { UsuarioListaComponent } from './pages/usuario/usuario-lista/usuario-lista.component';
import { UsuarioAlterarSenhaComponent } from './pages/usuario/usuario-alterar-senha/usuario-alterar-senha.component';
import { FeedDetalheComponent } from './pages/feed/feed-detalhe/feed-detalhe.component';
import { UsuarioListaBloqueadosComponent } from './pages/usuario/usuario-lista-bloqueados/usuario-lista-bloqueados.component';
import { ComentarioListaReportadosComponent } from './pages/feed/feed-item/comentario-lista-reportados/comentario-lista-reportados.component';
import { ContainerPropostasDenunciasComponent } from './pages/container-propostas-denuncias/container-propostas-denuncias.component';
import { DenunciaListaComponent } from './pages/denuncia/denuncia-lista/denuncia-lista.component';
import { DenunciaCadastroComponent } from './pages/denuncia/denuncia-cadastro/denuncia-cadastro.component';
import { DenunciaDetalheComponent } from './pages/denuncia/denuncia-detalhe/denuncia-detalhe.component';
import { DenunciaMenuComponent } from './pages/denuncia/denuncia-menu/denuncia-menu.component';
import { FeedMenuComponent } from './pages/feed/feed-menu/feed-menu.component';
import { DenunciaReportadaComponent } from './pages/denuncia/denuncia-reportada/denuncia-reportada.component';
import { AuthGuardService } from './services';
import { BeneficiarioComponent } from './pages/beneficiario/beneficiario.component';
import { BeneficiarioListaComponent } from './pages/beneficiario/beneficiario-lista/beneficiario-lista.component';
import { OrganizacaoComponent } from './pages/organizacao/organizacao.component';
import { OrganizacaoListaComponent } from './pages/organizacao/organizacao-lista/organizacao-lista.component';
import { LancamentoComponent } from './pages/lancamento/lancamento.component';
import { LancamentoListaComponent } from './pages/lancamento/lancamento-lista/lancamento-lista.component';
import { GastoPublicoComponent } from './pages/gasto-publico/gasto-publico.component';
import { CentroCustoComponent } from './pages/centro-custo/centro-custo.component';
import { CentroCustoListaComponent } from './pages/centro-custo/centro-custo-lista/centro-custo-lista.component';
import { GastoPublicoFiltroComponent } from './pages/gasto-publico/gasto-publico-filtro/gasto-publico-filtro.component';
import { GastoPublicoGraficoComponent } from './pages/gasto-publico/gasto-publico-grafico/gasto-publico-grafico.component';
import { PostagemMenuComponent } from './pages/postagem/postagem-menu/postagem-menu.component';
import { PostagemDetalheComponent } from './pages/postagem/postagem-detalhe/postagem-detalhe.component';
import { PostagemCadastroComponent } from './pages/postagem/postagem-cadastro/postagem-cadastro.component';
import { PostagemReportadaComponent } from './pages/postagem/postagem-reportada/postagem-reportada.component';
import { NotificationsService } from './core/generated';
import { VendedoresComponent } from './pages/vendedores/vendedores.component';
import { VendedoresListaComponent } from './pages/vendedores/vendedores-lista/vendedores-lista.component';
import { ProdutosComponent } from './pages/produtos/produtos.component';
import { ProdutosListaComponent } from './pages/produtos/produtos-lista/produtos-lista.component';
import { PedidosComponent } from './pages/pedidos/pedidos.component';
import { PoliticaPrivacidadeComponent } from './pages/politica-privacidade/politica-privacidade.component';
import { SuporteComponent } from './pages/suporte/suporte.component';
import { UsuariosDenunciadosComponent } from './pages/usuario/usuarios-denunciados/usuarios-denunciados.component';
import { RoomLiveComponent } from './pages/room-live/room-live/room-live.component';

export const ROUTES: Routes = [
  {
    path: 'feed', component: ContainerPropostasDenunciasComponent, children: [
      { path: 'propostas', component: FeedMenuComponent },
      // { path: 'denuncias', component: DenunciaMenuComponent },
      { path: 'postagens', component: PostagemMenuComponent },
      // {
      //   // path: 'gastos-publicos', component: GastoPublicoComponent, children: [
      //   //   { path: '', component: GastoPublicoFiltroComponent },
      //   //   { path: 'grafico', component: GastoPublicoGraficoComponent },
      //   //   { path: '**', redirectTo: '', pathMatch: "full" }
      //   // ]
      // },
      { path: '**', redirectTo: 'propostas', pathMatch: "full" }
    ]
  },

  { path: 'politica-privacidade', component: PoliticaPrivacidadeComponent },
  { path: 'suporte', component: SuporteComponent },


  // {
  //   path: 'centro-custos/adicionar', component: CentroCustoComponent,
  //   canActivate: [AuthGuardService],
  //   data: { roles: ['admin', 'adminorganizacao', 'adminorganizacaoeacessor'] }
  // },
  // {
  //   path: 'centro-custos/:id', component: CentroCustoComponent,
  //   canActivate: [AuthGuardService],
  //   data: { roles: ['admin', 'adminorganizacao', 'adminorganizacaoeacessor'] }
  // },
  // {
  //   path: 'centro-custos', component: CentroCustoListaComponent,
  //   canActivate: [AuthGuardService],
  //   data: { roles: ['admin', 'adminorganizacao', 'adminorganizacaoeacessor'] }
  // },
  // {
  //   path: 'lancamentos/adicionar', component: LancamentoComponent,
  //   canActivate: [AuthGuardService],
  //   data: { roles: ['admin'] }
  // },
  // {
  //   path: 'lancamentos/:id', component: LancamentoComponent,
  //   canActivate: [AuthGuardService],
  //   data: { roles: ['admin'] }
  // },
  // {
  //   path: 'lancamentos', component: LancamentoListaComponent,
  //   canActivate: [AuthGuardService],
  //   data: { roles: ['admin'] }
  // },
  // {
  //   path: 'organizacoes/adicionar', component: OrganizacaoComponent,
  //   canActivate: [AuthGuardService],
  //   data: { roles: ['admin', 'adminorganizacao', 'adminorganizacaoeacessor'] }
  // },
  // {
  //   path: 'organizacoes/:id', component: OrganizacaoComponent,
  //   canActivate: [AuthGuardService],
  //   data: { roles: ['admin', 'adminorganizacao', 'adminorganizacaoeacessor'] }
  // },
  // {
  //   path: 'organizacoes', component: OrganizacaoListaComponent,
  //   canActivate: [AuthGuardService],
  //   data: { roles: ['admin', 'adminorganizacao', 'adminorganizacaoeacessor'] }
  // },
  // {
  //   path: 'beneficiarios/adicionar', component: BeneficiarioComponent,
  //   canActivate: [AuthGuardService],
  //   data: { roles: ['admin', 'adminorganizacao', 'adminorganizacaoeacessor'] }
  // },
  // {
  //   path: 'beneficiarios/:id', component: BeneficiarioComponent,
  //   canActivate: [AuthGuardService],
  //   data: { roles: ['admin', 'adminorganizacao', 'adminorganizacaoeacessor'] }
  // },
  // {
  //   path: 'beneficiarios', component: BeneficiarioListaComponent,
  //   canActivate: [AuthGuardService],
  //   data: { roles: ['admin', 'adminorganizacao', 'adminorganizacaoeacessor'] }
  // },
  {
    path: 'politica-privacidade', component: PoliticaPrivacidadeComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin', 'user', 'acessor', 'vendedor', 'adminorganizacaoeacessor'] }
  },
  {
    path: 'suporte', component: SuporteComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin', 'user', 'acessor', 'vendedor', 'adminorganizacaoeacessor'] }
  },
  {
    path: 'politicos/adicionar', component: PoliticoComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin', 'acessor', 'adminorganizacaoeacessor'] }
  },
  {
    path: 'politicos/:id', component: PoliticoComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin', 'acessor', 'adminorganizacaoeacessor'] }
  },
  {
    path: 'politicos', component: PoliticoListaComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin', 'acessor', 'adminorganizacaoeacessor'] }
  },
  {
    path: 'vendedores/adicionar', component: VendedoresComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin', 'acessor', 'adminorganizacaoeacessor'] }
  },
  {
    path: 'vendedores/:id', component: VendedoresComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin', 'acessor', 'adminorganizacaoeacessor'] }
  },
  {
    path: 'vendedores', component: VendedoresListaComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin', 'acessor', 'adminorganizacaoeacessor'] }
  },
  {
    path: 'produtos/adicionar', component: ProdutosComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['vendedor'] }
  },
  {
    path: 'produtos/:id', component: ProdutosComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['vendedor'] }
  },
  {
    path: 'produtos', component: ProdutosListaComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['vendedor'] }
  },
  {
    path: 'pedidos', component: PedidosComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['vendedor'] }
  },
  {
    path: 'menu-propostas/adicionar', component: PropostaComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin', 'acessor', 'adminorganizacaoeacessor'] }
  },
  {
    path: 'menu-propostas/:id', component: PropostaComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin', 'acessor', 'adminorganizacaoeacessor'] }
  },
  {
    path: 'menu-propostas', component: PropostaListaComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin', 'acessor', 'adminorganizacaoeacessor'] }
  },
  {
    path: 'postagens/adicionar', component: PostagemCadastroComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin'] }
  },
  {
    path: 'postagens/:id', component: PostagemCadastroComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin'] }
  },
  {
    path: 'postagem/reportada', component: PostagemReportadaComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin'] }
  },
  {
    path: 'usuario/reportada', component: UsuariosDenunciadosComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin'] }
  },
  // {
  //   path: 'denuncias/adicionar', component: DenunciaCadastroComponent,
  //   canActivate: [AuthGuardService],
  //   data: { roles: ['admin'] }
  // },
  // {
  //   path: 'denuncias/:id', component: DenunciaCadastroComponent,
  //   canActivate: [AuthGuardService],
  //   data: { roles: ['admin'] }
  // },
  // {
  //   path: 'denuncia/reportada', component: DenunciaReportadaComponent,
  //   canActivate: [AuthGuardService],
  //   data: { roles: ['admin'] }
  // },
  {
    path: 'img-validacao', component: DenunciaListaComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin'] }
  },
  {
    path: 'usuarios/bloqueados/:id', component: UsuarioListaBloqueadosComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin', 'user', 'acessor', 'vendedor'] }
  },
  {
    path: 'usuarios/adicionar', component: UsuarioComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin'] }
  },
  {
    path: 'usuarios/:id', component: UsuarioComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin', 'user'] }
  },
  {
    path: 'usuarios', component: UsuarioListaComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin'] }
  },
  {
    path: 'reportados', component: ComentarioListaReportadosComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin', 'user', 'acessor', 'vendedor'] }
  },
  {
    path: 'alterar-senha', component: UsuarioAlterarSenhaComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin', 'user', 'acessor', 'vendedor'] }
  },
  {
    path: 'room-live/:liveId', component: RoomLiveComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin', 'user', 'acessor', 'vendedor'] }
  },
  {
    path: 'room-live', component: RoomLiveComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin', 'user', 'acessor', 'vendedor'] }
  },
  {
    path: 'perfil/:id', component: UsuarioComponent,
    canActivate: [AuthGuardService],
    data: { roles: ['admin', 'user', 'acessor', 'vendedor'] }
  },

  { path: 'detalhes/:id', component: FeedDetalheComponent },
  // { path: 'detalhes-denuncia/:id', component: DenunciaDetalheComponent },
  { path: 'detalhes-postagem/:id', component: PostagemDetalheComponent },

  //paths relacionados ao push message
  { path: 'notifications', component: NotificationsService },

  { path: '**', redirectTo: 'feed' },
]
