import { Beneficiario } from "./beneficiario.model";
import { Organizacao } from "./organizacao.model";
import { CentroCusto } from "./centro-custo.model";

export  class  Lancamento {
 data: Date;
 historico: Date;
 valor: number;
 creditaValor: boolean;
 centroCustos: string;
 beneficiarioId: string;
 organizacaoId: string;
 centroCustoId: string;
 centroCusto: CentroCusto;
 beneficiario: Beneficiario;
 organizacao: Organizacao;
}
