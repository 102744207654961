<div class="card mt-3">
  <div *ngIf="!mostrarDetalhes">
    <div class="card-header py-2">
      <div class="row">
        <div class="col-4 col-md-3 col-lg-2">
          <div *ngIf="postagem?.usuario?.imagem" class="rounded-circle img-fluid img-usuario p-0 m-0"
            [style.background-image]="'url(' + postagem?.usuario?.imagem + ')'"
            style="background-repeat: no-repeat; background-size: cover; background-position: center; transition: all 250ms ease-in-out; margin-left: -10px !important;">
          </div>

          <i *ngIf="!postagem?.usuario?.imagem" class="fal fa-user-circle fa-4x mt-1"></i>
        </div>
        <div class="col-8 col-md-9 col-lg-10 mt-3">
          <span class="card-title h5">{{postagem?.usuario?.nome}}</span>

          <button *ngIf="postagem?.verificado" type="button" class="btn btn-sm bg-transparent float-right p-0 m-0"
            placement="bottom" ngbTooltip="Verificada" [routerLink]="[]">
            <i class="fas fa-check-circle text-primary fa-lg"></i>
          </button>
        </div>
      </div>
    </div>
    <ngb-carousel *ngIf="postagem?.midias" [showNavigationArrows]="postagem.midias?.length > 1"
      [showNavigationIndicators]="postagem.midias?.length > 1" interval="false">
      <ng-template ngbSlide *ngFor="let midia of postagem?.midias;">
        <div [style.background-image]="'url(' + midia.url + ')'" (click)="abrirVideo(midia, postagem.titulo)"
          style="background-repeat: no-repeat; background-size: cover; background-position: center; width: 100%; height: 300px; transition: all 250ms ease-in-out; position: relative;"
          [ngStyle]="{'cusrsor': midia.tipoMidia != tipo.Video ? 'default' : 'pointer'}">
          <i *ngIf="midia.tipoMidia == tipo.Video" class="fas fa-video fa-2x text-white"
            style="text-shadow: 1px 1px 2px gray; position: absolute; bottom: 25px; right: 35px;"></i>
        </div>
      </ng-template>
    </ngb-carousel>

    <div class="card-body py-2">
      <p class="mb-2">
        <small class="text-muted">{{postagem?.uf}} • {{postagem?.cidade}} • {{postagem?.bairro}} • {{postagem?.data |
          date:'dd/MM/yyyy'}}</small>
        <span class="badge badge-secondary float-right">{{postagem?.statusText}}</span>
      </p>
      <a [routerLink]="['/detalhes-postagem', postagem.id]">
        <span class="card-title h5">{{postagem?.titulo}}</span>
        <div>
          <span>{{postagem?.descricao  | slice:0:120 }}{{ postagem.descricao.length > 120 ? '...' : ''}}</span>
        </div>
      </a>
    </div>
  </div>

  <div *ngIf="mostrarDetalhes">
    <div class="card-header py-2">
      <div class="row">
        <div class="col-4 col-md-3 col-lg-2">
          <div *ngIf="postagem?.usuario?.imagem" class="rounded-circle img-fluid img-usuario p-0 m-0"
            [style.background-image]="'url(' + postagem?.usuario?.imagem + ')'"
            style="background-repeat: no-repeat; background-size: cover; background-position: center; transition: all 250ms ease-in-out; margin-left: -10px !important;">
          </div>

          <i *ngIf="!postagem?.usuario?.imagem" class="fal fa-user-circle fa-4x mt-1"></i>
        </div>
        <div class="col-8 col-md-9 col-lg-10 mt-3">
          <span class="card-title h5">{{postagem?.usuario?.nome}}</span>

          <button *ngIf="postagem?.verificado" type="button" class="btn btn-sm bg-transparent float-right p-0 m-0"
            placement="bottom" ngbTooltip="Verificada" [routerLink]="[]">
            <i class="fas fa-check-circle text-primary fa-lg"></i>
          </button>
        </div>
      </div>
    </div>

    <ngb-carousel *ngIf="postagem?.midias.length > 1" [showNavigationArrows]="postagem?.midias?.length > 1"
      [showNavigationIndicators]="postagem?.midias?.length > 1" interval="false">
      <ng-template ngbSlide *ngFor="let midia of postagem?.midias;">
        <div [id]="midia.id + '-video-canvas-container'" class="btn"
          style="width: 100%; height: 300px; display: flex; justify-content: center; position: relative;"
          *ngIf="midia.tipoMidia == tipo.Video" (click)="abrirVideo(midia, postagem.titulo)">
          <canvas [id]="midia.id + '-video-canvas'" style="width: auto; height: 100%;"></canvas>
          <i *ngIf="midia.tipoMidia == tipo.Video" class="fas fa-video fa-2x text-white"
            style="text-shadow: 1px 1px 2px gray; position: absolute; bottom: 25px; right: 35px;"></i>
        </div>

        <div *ngIf="midia.tipoMidia != tipo.Video">
          <div [style.background-image]="'url(' + midia.url + ')'"
            style="background-repeat: no-repeat; background-size: cover; background-position: center; width: 100%; height: 300px; transition: all 250ms ease-in-out; position: relative;">
          </div>
        </div>
      </ng-template>
    </ngb-carousel>

    <div *ngIf="postagem?.midias.length <= 1">
      <div *ngFor="let midia of postagem?.midias;">
        <div [class.btn]="midia.tipoMidia == tipo.Video" (click)="abrirVideo(midia, postagem.titulo)"
          [style.background-image]="'url(' + midia.url + ')'"
          style="background-repeat: no-repeat; background-size: cover; background-position: center; width: 100%; height: 300px; transition: all 250ms ease-in-out; position: relative;">
          <i *ngIf="midia.tipoMidia == tipo.Video" class="fas fa-video fa-2x text-white"
            style="text-shadow: 1px 1px 2px gray; position: absolute; bottom: 25px; right: 35px;"></i>
        </div>
      </div>

      <div class="card-body py-2">
        <p class="mb-2">
          <small class="text-muted">{{postagem?.uf}} • {{postagem?.cidade}} • {{postagem?.bairro}} • {{postagem?.data |
            date:'dd/MM/yyyy'}}</small>
          <span class="badge badge-secondary float-right">{{postagem?.statusText}}</span>
        </p>
        <span class="card-title h5">{{postagem?.titulo}}</span>
      </div>
    </div>
    <div class="card-body py-2" *ngIf="mostrarDetalhes">
      <span class="card-text">{{postagem?.descricao}}</span>
    </div>

    <div *ngIf="!areLoggedIn" class="card-footer text-muted py-1">
      <button type="submit" class="btn btn-sm bg-transparent btn-like" (click)="like(true)">
        <i class="far fa-thumbs-up fa-lg"></i></button>

      <button type="submit" class="btn btn-sm bg-transparent btn-deslike" (click)="like(false)">
        <i class="far fa-thumbs-down fa-lg"></i></button>

      <button *ngIf="postagem?.verificado == false && !postagem.reportado" type="button"
        class="btn btn-sm bg-transparent float-right" placement="top" ngbTooltip="Denunciar"
        (click)="denunciar(postagem)">
        <i class="fas fa-bullhorn text-danger fa-lg"></i>
      </button>
    </div>

    <div *ngIf="areLoggedIn" class="card-footer text-muted py-1">
      <button type="submit" class="btn btn-sm bg-transparent btn-like"
        [class.btn-like-active]="gostei && gostei != null" (click)="like(true)">
        <i [class]="gostei && gostei != null ? 'fas fa-thumbs-up fa-lg' : 'far fa-thumbs-up fa-lg'"></i></button>

      <button type="submit" class="btn btn-sm bg-transparent btn-deslike"
        [class.btn-deslike-active]="!gostei && gostei != null" (click)="like(false)">
        <i [class]="!gostei && gostei != null ? 'fas fa-thumbs-down fa-lg' : 'far fa-thumbs-down fa-lg'"></i></button>

      <button *ngIf="areAdmin || postagem?.usuarioId == usuarioId" type="button"
        class="btn btn-sm bg-transparent float-right" placement="top" ngbTooltip="Remover" (click)="remover(postagem)">
        <i class="fas fa-trash text-danger fa-lg"></i>
      </button>

      <button *ngIf="postagem?.usuarioId != usuarioId && !areSubmitting" type="button"
        class="btn btn-sm bg-transparent float-right" placement="top" ngbTooltip="Bloquear"
        (click)="bloquear(postagem)">
        <i class="fas fa-ban text-danger fa-lg"></i>
      </button>

      <button *ngIf="postagem?.verificado == false && postagem?.usuarioId != usuarioId && !postagem.reportado"
        type="button" class="btn btn-sm bg-transparent float-right" placement="top" ngbTooltip="Denunciar"
        (click)="denunciar(postagem)">
        <i class="fas fa-bullhorn text-danger fa-lg"></i>
      </button>
    </div>

    <div class="card-footer text-muted py-1" *ngIf="mostrarDetalhes">
      <span class="card-title h5">Comentários</span>
      <br />
      <small *ngIf="showMessage">Não possui comentários</small>
      <div class="row" *ngFor="let comentario of comentarios">
        <div class="col py-1">
          <div class="player-holder float-left pt-2">
            <div class="player-thumb" *ngIf="comentario?.usuario?.imagem != null"
              [ngStyle]="{ 'background-image': 'url(' + comentario?.usuario.imagem + ')'}"></div>
            <div class="player-thumb fal fa-user-circle fa-2x" *ngIf="comentario?.usuario?.imagem == null"></div>
          </div>
          <div class="pl-5">

            <small class="text-primary mr-1" *ngIf="comentario?.verificado">
              <i class="fas fa-check-circle"></i></small>

            <span class="card-text">{{comentario?.texto}}</span>
            <br />
            <small class="text-dark">Por: {{comentario?.usuario.nome}} -
              {{comentario?.dataHora | date:'dd/MM/yyyy'}}</small>
            <br *ngIf="comentario.reportado" />
            <small class="text-muted" *ngIf="comentario.reportado" style="font-size: 12px">Denunciado, sob
              investigação</small>
            <br />
            <a [routerLink]="[]" (click)="verMais(comentario)"><small>+ Ver mais</small></a>
            <hr class="my-1" />
          </div>
        </div>
      </div>
      <form [formGroup]="comentarioForm" novalidate autocomplete="off">
        <div class="row p-3">
          <textarea #firstElement type="text" formControlName="texto" class="form-control form-control-sm"
            placeholder="Digite aqui..." rows="3" (keyup.control.enter)="comentar()"></textarea>
        </div>
        <button type="submit" class="btn btn-sm btn-primary btn-on-full mb-2 float-right" (click)="comentar()"
          [disabled]="comentarioForm.value.texto == null || comentarioForm.value.texto == ''">
          <i class="fal fa-check"></i>&nbsp;Comentar</button>
      </form>
    </div>
  </div>