import { Lancamento } from "./lancamento.model";
import { Beneficiario } from "./beneficiario.model";

export class Organizacao {
  id: string;
  imagem: string;
  nome: string;
  administradores: string[];
  uf: string;
  cidade: string;
  beneficiarios: Beneficiario[];
  lancamentos: Lancamento[];
}
