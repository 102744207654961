<nav aria-label="breadcrumb ">
  <ol class="breadcrumb b-radius breadcrumb-appckground mt-3">
    <a class="breadcrumb-item mt-1" aria-current="page" [routerLink]="['/beneficiarios']"><i
        class="far fa-address-card"></i>&nbsp;Benefíciarios</a>
    <li class="breadcrumb-item active breadcrumb-link-actual mt-1" aria-current="page">Adicionar</li>
    <li class="ml-auto">
      <button type="button" class="btn btn-sm btn-outline-primary btn-on" [routerLink]="['/beneficiarios']">
        <i class="fal fa-list"></i>
        <span class="d-none d-lg-inline-flex">&nbsp;Listar</span>
      </button>
    </li>
  </ol>
</nav>

<form [formGroup]="beneficiarioForm" novalidate autocomplete="off">
  <input type="hidden" formControlName="id" />
  <div class="row">
    <div class="col-lg-9 order-2 order-lg-1">
      <div class="row">
        <div class="col-6">
          <app-input label="Nome" errorMessage="Campo obrigatório." [isRequired]="true">
            <input #firstElement type="text" formControlName="nome" class="form-control form-control-sm"
              placeholder="Nome do beneficiário" />
          </app-input>
        </div>

        <div class="col-6">
          <app-input label="Endereço" errorMessage="Campo obrigatório.">
            <input type="text" formControlName="endereco" class="form-control form-control-sm" placeholder="Endereço" />
          </app-input>
        </div>

        <div class="col-6 col-md-3">
          <app-input label="Estado" [isRequired]="true">
            <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true" formControlName="uf"
              (change)="filtrar(filtroEnum.Estado)">
              <ng-option value="">Selecione o estado</ng-option>
              <ng-option *ngFor="let uf of ufs" [value]="uf.uf">{{uf.nome}}</ng-option>
            </ng-select>
          </app-input>
        </div>

        <div class="col-6 col-md-3">
          <app-input label="Cidade">
            <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true" formControlName="cidade"
              (change)="filtrar(filtroEnum.Cidade)">
              <ng-option value="">Selecione a cidade</ng-option>
              <ng-option *ngFor="let cidade of cidades" [value]="cidade.nome">{{cidade.nome}}</ng-option>
            </ng-select>
          </app-input>
        </div>

        <div class="col-6 col-md-3" >
          <app-input label="Telefone">
            <input type="text" formControlName="telefone" class="form-control form-control-sm" placeholder="Telefone"
              [textMask]="{mask: telefoneMovelMask, guide: false}" />
          </app-input>
        </div>

        <div class="col-6 col-md-3" >
          <app-input label="Ramo Atividade" errorMessage="Campo obrigatório.">
            <input type="text" formControlName="ramoAtividade" class="form-control form-control-sm"
              placeholder="Ramo Atividade" />
          </app-input>
        </div>

        <div class="col-md-6" *ngIf="areAdmin">
          <app-input label="Organização" errorMessage="Este campo é obrigatório." [isRequired]="true">
            <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true" formControlName="organizacaoId">
              <ng-option value="">Selecione a Organização</ng-option>
              <ng-option *ngFor="let organizacao of organizacoes" [value]="organizacao.id">{{organizacao.nome}}
              </ng-option>
            </ng-select>
          </app-input>
        </div>
      </div>
    </div>

    <div class="col-lg-3 order-1 order-lg-2">
      <div class="row">
        <div class="col">
          <app-input label="Ícone do beneficiário" labelAlign="text-center" errorMessage="Campo obrigatório.">
            <app-img formControlName="imagem" icone="fas fa-address-card"></app-img>
          </app-input>
        </div>
      </div>
    </div>
  </div>

  <button type="submit" class="btn btn-sm btn-primary btn-on-full mr-2" (click)="salvar(beneficiarioForm.value)"
    [disabled]="areSubmitting || this.beneficiarioForm.invalid">
    <i class="fal fa-check"></i>&nbsp;Salvar
  </button>

  <button type="button" class="btn btn-sm btn-outline-danger btn-on float-right" (click)="remover()"
    *ngIf="beneficiarioForm.controls.id.value != null && areAdmin">
    <i class="fal fa-trash"></i>
    <span class="d-none d-lg-inline-flex">&nbsp;Remover</span>
  </button>
</form>
