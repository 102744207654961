import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Comentario, Usuario } from '../models';
import { LoginService } from './login.service';

@Directive()
@Injectable()
export class ComentarioService {
  
    constructor(private http: HttpClient, private loginService: LoginService) { }

    comentario: Comentario;
    @Output() tiraBola = new EventEmitter();

    listar(pesquisa?: string): Observable<Comentario[]> {
        let params: HttpParams;
        if (pesquisa) {
            params = new HttpParams().set('query', pesquisa);
        }
        return this.http.get<Comentario[]>(`${environment.api}/comentarios`, { params: params });
    }

    encontrar(id: string): Observable<Comentario> {
        return this.http.get<Comentario>(`${environment.api}/comentarios/${id}`);
    }

    denunciar(id: string): Observable<Comentario> {
        return this.http.get<Comentario>(`${environment.api}/comentarios/${id}/denunciar`);
    }

    reportados(): Observable<Comentario[]> {
        return this.http.get<Comentario[]>(`${environment.api}/comentarios/reportados`);
    }

    listarPorProposta(propostaId: string): Observable<Comentario[]> {
        return this.http.get<Comentario[]>(`${environment.api}/comentarios/proposta/${propostaId}/usuario/${this.loginService.usuario ? this.loginService.usuario.id : null}`);
    }

    listarPorDenuncia(denunciaId: string): Observable<Comentario[]> {
        return this.http.get<Comentario[]>(`${environment.api}/comentarios/denuncia/${denunciaId}/usuario/${this.loginService.usuario ? this.loginService.usuario.id : null}`);
    }

    listarPorPostagem(postagemId: string): Observable<Comentario[]> {
        return this.http.get<Comentario[]>(`${environment.api}/comentarios/postagem/${postagemId}/usuario/${this.loginService.usuario ? this.loginService.usuario.id : null}`);
    }

    salvar(comentario: Comentario): Observable<Comentario> {
        return this.http.post<Comentario>(`${environment.api}/comentarios`, comentario);
    }

    remover(id: string): Observable<Comentario> {
        return this.http.delete<Comentario>(`${environment.api}/comentarios/${id}`);
    }

    tiraBolinha() {
        this.tiraBola.emit();
    }
}