import { Component, forwardRef, ViewChild, ContentChild, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControlName } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UploadService } from 'src/app/services/upload.service';
import { NotificationService } from '../messages/notification.service';

@Component({
  selector: 'app-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImgComponent),
      multi: true
    }
  ]
})

export class ImgComponent implements ControlValueAccessor {
  @ViewChild('fileInput') fileInput;
  @ViewChild('dropdown') dropdown;
  @ContentChild(FormControlName) control: FormControlName;
  @Input() icone = 'fal fa-user';
  @Input() url: string;
  @Input() square: boolean = false;
  //Declara junto com as declarações das variaveis
  @Input() imageSize: number = 5;
  @Input() videoSize: number = 1000;
  midiaSize: number;
  isVideo: boolean = false;

  isLoading: boolean = false;

  //Declarar o array abaixo
  acceptedFileTypes: string[] = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/bmp',
    'image/gif',
    'image/webp',
    'video/mp4'
  ]

  // Criar esse Construtor
  constructor(
    private notificationService: NotificationService,
    private _uploadService: UploadService,
    private _sanitizer: DomSanitizer
  ) { }

  // Métodos do componente
  changeFile(): void {
    if (!this.isValid()) {
      this.fileInput.nativeElement.click();
    } else {
      setTimeout(() => {
        this.dropdown.nativeElement.click();
      }, 100);
    }
  }

  openFile(): void {
    this.fileInput.nativeElement.click();
  }

  removeFile(): void {
    this.url = null;
    this.fileInput.nativeElement.value = null;
    // Propagar a alteração
    this.propagateChange(this.url);
  }

  addFile(): void {
    const fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0]) {
      const fileToUpload = fi.files[0];

      if (fileToUpload.type == "video/mp4")
        this.isVideo = true;

      this.midiaSize = fileToUpload.type == "video/mp4" ? this.videoSize : this.imageSize;

      if (fileToUpload.size > this.midiaSize * 1000000) {
        this.notificationService.notifty(`O tamanho da mídia excede o limite de ${this.midiaSize} Mb.`);
      }
      else if (this.acceptedFileTypes.indexOf(fileToUpload.type) == -1) {
        this.notificationService.notifty("Formato não suportado.");
      }
      else {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          this.url = <string>reader.result;
          this.isLoading = true;


          // Propagar a alteração
          if (fileToUpload.type == "video/mp4") {
            this.isVideo = true;

            this._uploadService.uploadVideo(fileToUpload).subscribe((value: string) => {
              this._uploadCallback(value)
            });
          } else {
            this._uploadService.uploadImage(fileToUpload).subscribe((value: string) => this._uploadCallback(value));
          }

          // this.propagateChange(this.url);
        };

        reader.readAsDataURL(fileToUpload);

      }
    }
  }

  _uploadCallback(value: string): void {
    this.url = value.replace("\"", "").replace("\"", "");
    this.isLoading = false;
    this.propagateChange(this.url);
  }

  // Métodos do ValueAccessor
  propagateChange = (_: any) => {
  };

  writeValue(value: any): void {
    if (value && value.includes('.mp4'))
      this.isVideo = true;

    this.url = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
  }
  // Validação do componente
  isValid(): boolean {
    return this.url != null && this.url !== '';
  }
  isRequired(): boolean {
    if (!this.control?.errors) {
      return false;
    }
    return this.control.errors.required;
  }
}