export class Politico {
    id: string;
    nome: string;
    cargo: string;
    siglaPartido: string;
    imagem: string;
    biografia: string;
    email: string;
    receberEmail: boolean;
    acessores: string[];
}