<nav aria-label="breadcrumb ">
  <ol class="breadcrumb b-radius breadcrumb-appckground mt-3">
    <li class="breadcrumb-item active breadcrumb-link-actual mt-1" aria-current="page"><i class="far fa-images"></i>&nbsp;Validação de imagens</li>
  </ol>
</nav>
<form [formGroup]="filtroForm" novalidate autocomplete="off">
  <div class="row">
    <div class="col-3">
      <select formControlName="politicoId" class="form-control form-control-sm">
        <option [ngValue]="null">Todos os políticos</option>
        <option *ngFor="let politico of politicos" [ngValue]="politico.id">{{politico.nome}}</option>
      </select>
    </div>
    <div class="col-3">
      <select formControlName="uf" class="form-control form-control-sm">
        <option [ngValue]="null">Todos as UFs</option>
        <optgroup label="Federação">
          <option value="Brasil">Brasil</option>
        </optgroup>
        <optgroup label="Estados">
          <option *ngFor="let uf of ufs" [ngValue]="uf.value">{{uf.value}}</option>
        </optgroup>
      </select>
    </div>
  </div>
</form>
<div class="table-responsive" *ngIf="!mensagemListaVazia">
  <table class="table table-sm table-hover table-striped">
    <thead>
      <tr>
        <th class="text-left">Denunciante</th>
        <th class="text-left">Título</th>
        <th class="text-left">Localidade</th>
      </tr>
    </thead>
    <tbody class="text-left">
      <tr class="text-nowrap tr-click" *ngFor="let denuncia of denuncias" [routerLink]="['/denuncias', denuncia.id]">
        <td>{{denuncia.usuario.nome}}</td>
        <td>{{denuncia.titulo}}</td>
        <td>{{denuncia.localidade}}</td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="mensagemListaVazia">
  <h6 class="text-muted">Não possui imagens para validação.</h6>
</div>
