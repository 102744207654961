import { formatNumber } from '@angular/common';
import { Component, ElementRef, HostListener, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LiveIceCandidateDto } from 'src/app/dtos/live/live-ice-candidate-dto';
import { LivePeerConnectionDescriptionDto } from 'src/app/dtos/live/live-peer-connection-descritpion-dto';
import { PeerConnectionDescriptionDto } from 'src/app/dtos/peer-connection/peer-connection-description-dto';
import { Usuario } from 'src/app/models';
import { RoomLiveItem } from 'src/app/models/room-live-item.model';
import { RoomLive } from 'src/app/models/room-live.model';
import { LoginService } from 'src/app/services';
import { RoomLiveService } from 'src/app/services/room-live.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { NotificationService } from 'src/app/shared/messages/notification.service';

@Component({
  selector: 'app-room-live',
  templateUrl: './room-live.component.html',
  styleUrls: ['./room-live.component.scss']
})
export class RoomLiveComponent implements OnInit, OnDestroy {

  // #region PeerConnection
  connection?: RTCPeerConnection;
  currentPeer: RoomLiveItem;
  remotePeers: RoomLiveItem[] = [];
  // #endregion

  // #region Streams/Tracks/Devices
  stream?: MediaStream;
  remoteStream?: MediaStream;
  cameras: MediaDeviceInfo[] = [];
  // #endregion

  isWatching: boolean = false;
  isLoading: boolean = false;
  isStarted: boolean = false;
  canSwitchCamera: boolean = false;
  switchedCamera: boolean = false;
  isMuted: boolean = false;

  liveId?: string = null;
  usuario: Usuario;

  usersWatching: number = 0;

  @ViewChild('video') video!: ElementRef;
  @ViewChild('remoteVideo') remoteVideo!: ElementRef;

  @HostListener('window:beforeunload') goToPage() {
    this.end();

    this._dispose();
  }

  constructor(
    private _loginService: LoginService,
    private _service: RoomLiveService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _notificationService: NotificationService,
    private _zone: NgZone,
    private _usuarioService: UsuarioService
  ) { }

  ngOnInit(): void {
    this.liveId = this._route.snapshot.params['liveId'];
    this.isWatching = !!this.liveId;

    this.isLoading = true;

    this._initMediaConstraints();

    if (!this.isWatching) {
      this._loginService.alterou.subscribe(usuario => this.usuario = usuario);

      this.usuario = this._loginService.usuario;

      this._getUserMedia().then((stream: MediaStream) => {
        this.stream = stream;

        setTimeout(() => {
          this.video.nativeElement.srcObject = this.stream;
          this.video.nativeElement.play();

          this.isLoading = false;
        });
      });
    }
    else {
      this._usuarioService.encontrar(this.liveId).subscribe({
        next: (usuario: Usuario) => {
          this.usuario = usuario

          this._setUsersWatching(() => {
            this.initLive();
          })
        },
        error: _ => {
          this._notificationService.notifty(`Não foi possível assistir a transmissão!`);
          this._router.navigate(['/feed']);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.end();

    this._dispose();
  }

  initLive(): void {
    this.isStarted = true;

    this._service.validateConnection(async () => {
      this.isLoading = true;

      this._configureSignaling();

      if (!this.isWatching)
        this._start();
      else
        this._watch();
    });
  }

  end(): void {
    if (!this.isWatching)
      this._service.encerrouLive(this.liveId).subscribe(() => {
        this._notificationService.notifty(`Transmissão finalizada!`);
        this._dispose();
        this._router.navigate(['/feed']);
      });
    else {
      this._service.stopWatching(this.liveId, this.currentPeer.id).subscribe(() => {
        this._dispose();
        this._router.navigate(['/feed']);
      });
    }
  }

  switchCamera() {
    if (this.stream != null) {
      this.switchedCamera = !this.switchedCamera;

      for (const track of this.stream.getTracks())
        track.stop();

      this._getUserMedia().then((stream: MediaStream) => {
        this.video.nativeElement.srcObject = null;
        this.stream = stream;
        this.video.nativeElement.srcObject = this.stream;

        if (this.remotePeers.length)
          for (const peer of this.remotePeers)
            if (peer.connection)
              for (const track of this.stream.getTracks())
                peer.connection.addTrack(track, this.stream);
      });
    }
  }

  toggleMute() {
    if (this.stream != null) {
      this.isMuted = !this.isMuted;
      this.stream!.getAudioTracks()[0].enabled = !this.isMuted;
    }
  }

  private _initMediaConstraints(): void {
    navigator.mediaDevices.enumerateDevices().then((infos: MediaDeviceInfo[]) => {
      this.cameras = infos.filter(x => x.kind == 'videoinput');

      this.canSwitchCamera = this.cameras.length > 1;
    }).catch(_ => { });
  }

  private _start(): void {
    let live: RoomLive = new RoomLive();
    live.roomName = this.usuario.id;
    live.sessionId = this._service.hubConnection?.connectionId;
    live.aoVivo = true;

    this._service.iniciarLive(live).subscribe({
      next: (id: string) => {
        this.liveId = id;

        this.isLoading = false;

        setTimeout(() => {
          this.video.nativeElement.srcObject = this.stream;
          this.video.nativeElement.play();
        });

      },
      error: _ => this._router.navigate(['/feed'])
    });
  }

  private _watch(): void {
    let peer: RoomLiveItem = new RoomLiveItem();
    peer.sessionId = this._service.hubConnection?.connectionId;
    peer.liveId = this.liveId;
    peer.userId = this._loginService.getUsuario().id;

    this._service.watchLive(peer).subscribe({
      next: (item: RoomLiveItem) => {
        this.isLoading = false;
        item.pendingCandidates = [];

        this.currentPeer = item;
      },
      error: _ => this._router.navigate(['/feed'])
    });
  }

  private _setUsersWatching(callback?: Function): void {
    this._service.find(this.liveId).subscribe({
      next: (live: RoomLive) => {
        this.usersWatching = live?.usersWatching ?? 0;

        if (callback)
          callback();
      }
    })
  }

  private _configureSignaling(): void {
    this._service.userStartedWatching.subscribe(async (item: RoomLiveItem) => await this._userStartedWatchingCallback(item));

    this._service.userStoppedWatching.subscribe(_ => this._userStoppedWatchingCallback());

    this._service.watchOfferReceived.subscribe(async (offer: PeerConnectionDescriptionDto) => await this._watchOfferReceivedCallback(offer));

    this._service.watchAnswerReceived.subscribe(async (result: any) => await this._watchAnswerReceivedCallback(result));

    this._service.liveEnded.subscribe((liveId: string) => this._liveEndedCallback(liveId));

    this._service.candidateAdded.subscribe(async (candidate: LiveIceCandidateDto) => await this._candidatedAddedCallback(candidate));
  }

  // #region Signaling callbacks
  private async _userStartedWatchingCallback(item: RoomLiveItem): Promise<void> {
    if (this.isWatching)
      this._setUsersWatching();
    else {
      if (!item.connection) {
        item.connection = this._createConnection();

        const offer = await item.connection.createOffer();
        await item.connection.setLocalDescription(offer);

        let dto: PeerConnectionDescriptionDto = {
          sdp: offer.sdp,
          type: offer.type
        };

        this._service.sendWatchOffer(dto, item.id).subscribe(_ => {
          item.pendingCandidates = [];
          this.remotePeers.push(item);
        });
      }
    }
  }

  private _userStoppedWatchingCallback(): void {
    this._setUsersWatching();
  }

  private async _watchOfferReceivedCallback(offer: PeerConnectionDescriptionDto): Promise<void> {
    if (!this.connection) {
      this.connection = this._createConnection();

      await this.connection.setRemoteDescription({ sdp: offer.sdp, type: <any>offer.type });

      const answer = await this.connection!.createAnswer();
      await this.connection!.setLocalDescription(answer);

      let dto: LivePeerConnectionDescriptionDto = new LivePeerConnectionDescriptionDto();
      dto.liveId = this.liveId,
        dto.description = {
          sdp: answer.sdp,
          type: answer.type
        };

      this._setUsersWatching();

      setTimeout(() => {
        this._service.sendWatchAnswer(dto, this.currentPeer?.id).subscribe(_ => {
          if (this.remoteStream && this.remoteVideo) {
            this.remoteVideo.nativeElement.srcObject = this.remoteStream;
            this.remoteVideo.nativeElement.play();
          }
        });
      });
    }
  }

  private async _watchAnswerReceivedCallback(result: any): Promise<void> {
    let item: RoomLiveItem = this.remotePeers.find(x => x.id == result.itemId)

    if (item) {
      if (item.connection?.connectionState != "connected" && !item.connection?.currentRemoteDescription) {
        await item.connection.setRemoteDescription({ sdp: result.answer.description.sdp, type: <any>result.answer.description.type });

        if (item.pendingCandidates.length) {
          for (const candidate of item.pendingCandidates)
            await item.connection.addIceCandidate({
              candidate: candidate.candidate.candidate,
              sdpMid: candidate.candidate.sdpMid,
              sdpMLineIndex: candidate.candidate.sdpMLineIndex
            });

          item.pendingCandidates = [];
        }

        this._setUsersWatching();
      }
    }
  }

  private _liveEndedCallback(liveId: string): void {
    if (this.liveId == liveId) {
      if (this.currentPeer)
        this._service.stopWatching(this.liveId, this.currentPeer.id).subscribe(() => {
          this._dispose();
          this._router.navigate(['/feed']);
        });
      else {
        this._dispose();
        this._router.navigate(['/feed']);
      }
    }
  }

  private async _candidatedAddedCallback(candidate: LiveIceCandidateDto): Promise<void> {
    let peer = this.remotePeers.find(x => x.id == candidate.liveItemId);

    if (peer) {
      if (peer.connection?.remoteDescription) {
        await peer.connection!.addIceCandidate({
          candidate: candidate.candidate.candidate,
          sdpMid: candidate.candidate.sdpMid,
          sdpMLineIndex: candidate.candidate.sdpMLineIndex
        });
      }
      else
        peer.pendingCandidates.push(candidate);
    }
  }
  // #endregion

  private _getUserMedia(): Promise<MediaStream | undefined> {
    const constraints = {
      'audio': { 'echoCancellation': true },
      'video': {
        facingMode: !this.switchedCamera ? 'user' : 'environment'
      }
    }

    return navigator.mediaDevices.getUserMedia(constraints);
  }

  private _createConnection(): RTCPeerConnection {
    const configuration = {
      'iceServers': [{
        'urls': ['stun:150.230.72.73:3478'],
        "username": "username1",
        "credential": "password1"
      }],
      "iceTransportPolicy": "all",
      "iceCandidatePoolSize": 0
    }

    let connection = new RTCPeerConnection(<any>configuration);

    if (!this.isWatching && this.stream)
      this.stream!.getTracks().forEach(track => {
        connection.addTrack(track, this.stream!);
      });


    connection.onicecandidate = (event: any) => {
      if (event.candidate && connection.remoteDescription) {
        let liveCandidate: LiveIceCandidateDto = {
          liveId: this.liveId,
          liveItemId: this.currentPeer?.id,
          candidate: {
            candidate: event.candidate.candidate,
            sdpMid: event.candidate.sdpMid,
            sdpMLineIndex: event.candidate.sdpMLineIndex
          },
        };

        this._service.addCandidate(liveCandidate).subscribe();
      }
    };

    connection.onicecandidateerror = (event: any) => {

    };

    connection.onconnectionstatechange = (event: any) => {
      if (this.isWatching)
        if (connection.connectionState == "disconnected") {
          this._zone.run(() => {
            this.end();
            this._notificationService.notifty('Transmissão finalizada!');
          });
        }
    };

    connection.ontrack = (event: any) => {
      this._zone.run(() => {
        const [remoteStream] = event.streams;

        this.remoteStream = remoteStream;

        setTimeout(() => {
          if (this.remoteVideo) {
            this.remoteVideo.nativeElement.srcObject = this.remoteStream;
          }
        });
      });
    };

    return connection;
  }

  private _dispose(): void {
    if (this.connection)
      this.connection.close();

    if (this.remotePeers.length)
      for (const peer of this.remotePeers)
        peer.connection.close();

    if (this.stream) {
      for (const track of this.stream.getTracks())
        track.stop();

      this.stream = null;
    }

    if (this.remoteStream) {
      for (const track of this.remoteStream.getTracks())
        track.stop();

      this.remoteStream = null;
    }
  }

}
