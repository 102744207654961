import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Usuario, AlterarSenha, BloqueioUsuario } from '../models';
import { FiltroUsuario } from '../shared/filter/Filtro-usuario';
import { TipoMidia } from '../enum/tipo-midia';
import { tap } from 'rxjs/operators';

@Injectable()
export class UsuarioService {
    constructor(private http: HttpClient) { }

    listar(pesquisa?: string): Observable<Usuario[]> {
        let params: HttpParams;
        if (pesquisa) {
            params = new HttpParams().set('query', pesquisa);
        }
        return this.http.get<Usuario[]>(`${environment.api}/usuarios`, { params: params });
    }

    listarTodos(pesquisa?: string): Observable<Usuario[]> {
        let params: HttpParams;
        if (pesquisa) {
            params = new HttpParams().set('query', pesquisa);
        }
        return this.http.get<Usuario[]>(`${environment.api}/usuarios/todos`, { params: params });
    }

    encontrar(id: string): Observable<Usuario> {
        return this.http.get<Usuario>(`${environment.api}/usuarios/${id}`);
    }

    encontrarUsuarioVendedor(id: string): Observable<Usuario> {
        return this.http.get<Usuario>(`${environment.api}/usuarios/${id}`);
    }

    ListarByUsuarioPerfilVendedor(): Observable<Usuario[]> {
        return this.http.get<Usuario[]>(`${environment.api}/usuarios/vendedores`,);
    }

    encontrarBloqueados(id: string): Observable<BloqueioUsuario[]> {
        return this.http.get<BloqueioUsuario[]>(`${environment.api}/usuarios/${id}/bloqueados`);
    }

    bloquear(usuarioId: string, usuarioBloqueadoId: string): Observable<Usuario> {
        return this.http.get<Usuario>(`${environment.api}/usuarios/${usuarioId}/bloquear/${usuarioBloqueadoId}`);
    }

    desbloquear(usuarioBloqueadoId: string): Observable<Usuario> {
        return this.http.get<Usuario>(`${environment.api}/usuarios/desbloquear/${usuarioBloqueadoId}`);
    }

    salvar(usuario: Usuario): Observable<Usuario> {
        return this.http.post<Usuario>(`${environment.api}/usuarios`, usuario);
    }

    remover(id: string): Observable<Usuario> {
        return this.http.delete<Usuario>(`${environment.api}/usuarios/${id}`);
    }

    atualizarSenha(alterarSenha: AlterarSenha): Observable<AlterarSenha> {
        return this.http.post<AlterarSenha>(`${environment.api}/usuarios/alterar-senha`, alterarSenha);
    }

    filtrar(filtro: FiltroUsuario): Observable<Usuario[]> {
        return this.http.post<Usuario[]>(`${environment.api}/usuarios/filtrar`, filtro);
    }

    listarTodosAmigos(usuarioLogado1Id: string): Observable<Usuario[]> {
        return this.http.get<Usuario[]>(`${environment.api}/usuarios/listar/amigos/${usuarioLogado1Id}`);
    }

    salvarMidia(blob: any, tipo: TipoMidia): Observable<string> {
        const formData = new FormData();
        formData.append('file', blob);

        return this.http.post<string>(`${environment.api}/usuarios/salvarMidia/${tipo}`, formData, { responseType: 'text' as 'json' });
    }

    reportados(): Observable<Usuario[]> {
        return this.http.get<Usuario[]>(`${environment.api}/usuarios/reportados`);
    }

    alterarStatusOnline(usuarioId: string, online: boolean): void {
        this.http.patch(`${environment.api}/usuarios/${usuarioId}/AlterarStatusOnline/${online}`, {}).subscribe({
            next: _ => { },
            error: _ => { }
        });
    }
}