import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-postagem-video',
  templateUrl: './postagem-video.component.html',
  styleUrls: ['./postagem-video.component.scss']
})
export class PostagemVideoComponent {

  @Input() title: string;
  @Input() url: string;

  constructor(private activeModal: NgbActiveModal) { }

  dismiss() {
    this.activeModal.dismiss();
  }
}
