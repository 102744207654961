<nav aria-label="breadcrumb ">
  <ol class="breadcrumb b-radius breadcrumb-appckground mt-3">
    <li class="breadcrumb-item active breadcrumb-link-actual mt-1" aria-current="page"><i class="fas fa-box-open"></i>&nbsp;Produtos</li>
    <li class="ml-auto">
      <button type="button" class="btn btn-sm btn-outline-primary btn-on" [routerLink]="['adicionar']" *ngIf="areVendedor">
        <i class="fal fa-plus"></i>
        <span class="d-none d-lg-inline-flex">&nbsp;Adicionar</span>
      </button>
    </li>
  </ol>
</nav>
<app-searchbox (search)="pesquisar($event)"></app-searchbox>
<div class="table-responsive" *ngIf="!mensagemListaVazia">
  <table class="table table-sm table-hover table-striped">
    <thead>
      <tr>
        <th></th>
        <th class="text-left">Titulo</th>
      </tr>
    </thead>
    <tbody class="text-left">
      <tr class="text-nowrap tr-click" *ngFor="let produto of produtos" [routerLink]="['/produtos', produto.id]">
        <td class="td-adjustment"><img src="{{produto.imagem}}" class="img-adjustment" /></td>
        <td>{{produto.titulo}}</td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="mensagemListaVazia">
  <h6 class="text-muted">Não possui produtos.</h6>
</div>