import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Usuario, Denuncia, Estado, Cidade, Bairro, Endereco } from 'src/app/models';
import { LoginService, DenunciaService, OnMapsService } from 'src/app/services';
import * as $ from 'jquery';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DenunciaCadastroComponent } from '../denuncia-cadastro/denuncia-cadastro.component';
import { UsuarioService } from 'src/app/services/usuario.service';
import { FiltroEnum, TipoMidia } from 'src/app/enum';
import { AddressResult } from 'src/app/models/address-result.model';

@Component({
  selector: 'app-denuncia-menu',
  templateUrl: './denuncia-menu.component.html',
  styleUrls: ['./denuncia-menu.component.scss']
})
export class DenunciaMenuComponent implements OnInit {

  mensagemListaVazia: boolean = false;
  denunciasBkp: Denuncia[];
  denuncias: Denuncia[];
  usuario: Usuario;
  filtroForm: UntypedFormGroup;
  currentRate: number;
  showImagens: boolean;
  ufs: Estado[];
  cidades: Cidade[];
  bairros: Bairro[];
  filtroEnum = FiltroEnum;

  constructor(
    private denunciaService: DenunciaService,
    private loginService: LoginService,
    private formBuilder: UntypedFormBuilder,
    private mapsService: OnMapsService,
    private modalService: NgbModal,
    private usuarioService: UsuarioService,
  ) { }

  ngOnInit() {
    this.isLoggedIn();

    // Preenchendo usuário
    this.loginService.alterou.subscribe(usuario => {
      this.usuario = usuario;
      this.listar();
    })
    this.usuario = this.loginService.usuario;

    this.denunciaService.atualiza.subscribe(() => this.listar());

    this.filtroForm = this.formBuilder.group({
      uf: [''],
      cidade: [''],
      bairro: ['']
    });

    this.listar();

    this.loginService.logou.subscribe(resp => this.listar());
    this.loginService.deslogou.subscribe(resp => this.listar());

    // Listar Adicionais
    this.mapsService.estados().subscribe(estados => this.ufs = estados);
  }

  scrollTop() {
    setTimeout(function () {
      $('.content-page').animate({ scrollTop: 0 }, 200);
    }, 1)
  }

  filtrar(filtroEnum: FiltroEnum) {
    this.denuncias = this.denunciasBkp;

    if (filtroEnum == FiltroEnum.Estado) {
      if (this.filtroForm.controls.uf.value == '') {
        this.denuncias = this.denunciasBkp;
      } else {
        this.denuncias = this.denuncias.filter(x => x.uf == this.filtroForm.controls.uf.value);
        this.mapsService.cidades(this.filtroForm.controls.uf.value).subscribe(cidades => this.cidades = cidades);
      }
      this.cidades = [];
      this.bairros = [];
      this.filtroForm.controls.cidade.setValue('');
      this.filtroForm.controls.bairro.setValue('');
    }

    if (filtroEnum == FiltroEnum.Cidade) {
      if (this.filtroForm.controls.cidade.value == '') {
        this.denuncias = this.denunciasBkp;
        this.denuncias = this.denuncias.filter(x => x.uf == this.filtroForm.controls.uf.value);
      } else {
        this.denuncias = this.denuncias.filter(x => x.cidade == this.filtroForm.controls.cidade.value);
        this.mapsService.bairros(this.filtroForm.controls.uf.value, this.filtroForm.controls.cidade.value).subscribe(bairros => this.bairros = bairros)
      }
      this.bairros = [];
      this.filtroForm.controls.bairro.setValue('');
    }

    if (filtroEnum == FiltroEnum.Bairro) {
      if (this.filtroForm.controls.bairro.value == '') {
        this.denuncias = this.denunciasBkp;
        this.denuncias = this.denuncias.filter(x => x.cidade == this.filtroForm.controls.cidade.value);
      } else {
        this.denuncias = this.denuncias.filter(x => x.bairro == this.filtroForm.controls.bairro.value);
      }
    }

    if (this.denuncias.length < 1) {
      this.mensagemListaVazia = true;
    }
    else {
      this.mensagemListaVazia = false;
    }
  }

  listar() {
    this.denunciaService.listar().subscribe(denuncias => {

      denuncias.forEach(denuncia => {
        denuncia.midias.forEach(midia => {
          if (midia.tipoMidia == TipoMidia.Video)
            midia.url = `${midia.url}/thumb`;
        });
      });

      if (this.loginService.isLoggedIn()) {
        this.usuarioService.encontrarBloqueados(this.loginService.usuario.id).subscribe(bloqueados => {
          if (bloqueados.length > 0) {
            bloqueados.forEach(bloqueado => {
              denuncias = denuncias.filter(x => x.usuarioId != bloqueado.usuarioBloqueadoId);
            });
            this.denuncias = denuncias;
            this.denunciasBkp = denuncias;
          }
          else {
            this.denuncias = denuncias
            this.denunciasBkp = denuncias;
          }
        });
      }
      else {
        this.denuncias = denuncias
        this.denunciasBkp = denuncias;
      }

      if (denuncias.length < 1) {
        this.mensagemListaVazia = true;
      }
      else {
        this.mensagemListaVazia = false;
      }
    });
  }

  isLoggedIn() {
    return this.loginService.isLoggedIn();
  }

  usarLocalizacao() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;

        this.mapsService.getLocalizacao(latitude, longitude).subscribe((result: AddressResult) => {
          if (result) {
            const state = this.ufs.find(x => x.nome == result.address.state);

            this.filtroForm.controls.uf.setValue(state.uf);
            this.filtroForm.controls.cidade.setValue(result.address.city);
            this.filtroForm.controls.bairro.setValue(result.address.suburb);

            this.mapsService.cidades(state.uf).subscribe(cidades => this.cidades = cidades);
            this.mapsService.bairros(state.uf, this.filtroForm.controls.city.value).subscribe(bairros => this.bairros = bairros);

            this.denuncias = this.denuncias.filter(x => x.uf == state.uf && x.cidade == result.address.city && x.bairro == result.address.suburb);

            this.mensagemListaVazia = this.denuncias.length == 0;
          }
        });
      }, _ => { }, { enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 });
    }
  }

  abrirAdicionar() {
    if (this.loginService.isLoggedIn()) {
      const modalRef = this.modalService.open(DenunciaCadastroComponent, { size: 'lg', centered: true });
      modalRef.result.then(confirma => {
        this.listar();
      }, dismiss => { });
    }
    else {
      this.loginService.handleLogin();
    }
  }
}
