import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Gostei, GosteiDto } from '../models';

@Injectable()
export class GosteiService {
    constructor(private http: HttpClient) { }

    avaliacao: Gostei;

    listar(pesquisa?: string): Observable<Gostei[]> {
        let params: HttpParams;
        if (pesquisa) {
            params = new HttpParams().set('query', pesquisa);
        }
        return this.http.get<Gostei[]>(`${environment.api}/gostei`, { params: params });
    }

    encontrar(id: string): Observable<Gostei> {
        return this.http.get<Gostei>(`${environment.api}/gostei/${id}`);
    }

    ratePostagem(dto: GosteiDto): Observable<Gostei> {
        return this.http.post<Gostei>(`${environment.api}/gostei/avaliacao-postagem`, dto);
    }

    rateDenuncia(dto: GosteiDto): Observable<Gostei> {
        return this.http.post<Gostei>(`${environment.api}/gostei/avaliacao-denuncia`, dto);
    }

    salvar(avaliacao: Gostei): Observable<Gostei> {
        return this.http.post<Gostei>(`${environment.api}/gostei`, avaliacao);
    }

    remover(id: string): Observable<Gostei> {
        return this.http.delete<Gostei>(`${environment.api}/gostei/${id}`);
    }
}