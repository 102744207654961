import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LivePeerConnectionDescriptionDto } from 'src/app/dtos/live/live-peer-connection-descritpion-dto';
import { Usuario } from 'src/app/models';
import { RoomLive } from 'src/app/models/room-live.model';
import { LoginService } from 'src/app/services';
import { RoomLiveService } from 'src/app/services/room-live.service';

@Component({
  selector: 'app-room-live-list',
  templateUrl: './room-live-list.component.html',
  styleUrls: ['./room-live-list.component.scss']
})
export class RoomLiveListComponent implements OnInit {

  @Input() usuario: Usuario;
  @Input() usuarios: Usuario[] = [];
  @Input() roomLives: RoomLive[] = [];
  @Input() description: LivePeerConnectionDescriptionDto;
  constructor(
    private _loginService: LoginService,
    private _router: Router,
    private _service: RoomLiveService
  ) { }

  ngOnInit() {
    this._service.validateConnection(() => { }).then(_ => { });
    this._service.liveStarted.subscribe(_ => this.listarLives());
    this._service.liveEnded.subscribe(_ => this.listarLives());

    // Preenchendo usuário
    this._loginService.alterou.subscribe(usuario => {
      this.usuario = usuario;
    });
    this.usuario = this._loginService.usuario;
    this.listarLives();
  }

  fazerLive() {
    this._router.navigate(['/room-live']);
  }

  listarLives() {
    this._service.listarSalasAoVivo().subscribe(usuarios => {
      this.usuarios = usuarios;
    })
  }

  verLive(liveId: string) {
    this._router.navigate(['/room-live', liveId]);
  }

}
