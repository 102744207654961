import { Component, OnInit } from '@angular/core';
import { Usuario, Vendedor } from 'src/app/models';
import { VendedorService, LoginService } from 'src/app/services';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-vendedores-lista',
  templateUrl: './vendedores-lista.component.html',
  styleUrls: ['./vendedores-lista.component.scss']
})
export class VendedoresListaComponent implements OnInit {

  vendedores: Usuario[];
  mensagemListaVazia: boolean = false;
  vendedoresBkp: Usuario[];
  areAdmin: boolean;

  constructor(
    private _service: VendedorService,
    private _usuarioService: UsuarioService,
    private _loginService: LoginService
  ) { }

  ngOnInit() {
    this.areAdmin = this._loginService.areAdmin();

    if (this.areAdmin) {
      this.listar();
    } else {
      this.vendedoresBkp = this.vendedores;
    }
  }

  listar() {
    this._usuarioService.ListarByUsuarioPerfilVendedor()
      .subscribe(vendedores => {
        this.vendedores = vendedores
        this.vendedoresBkp = vendedores;
        if (vendedores.length < 1) {
          this.mensagemListaVazia = true;
        }
      });
  }

  pesquisar(pesquisa: string) {
    this.vendedores = this.vendedoresBkp;

    if (pesquisa != '') {
      this.vendedores = this.vendedores.filter(x => x.nome.toLowerCase().trim().indexOf(pesquisa.toLowerCase().trim()) > -1);
    }

    if (this.vendedores.length < 1) {
      this.mensagemListaVazia = true;
    } else {
      this.mensagemListaVazia = false;
    }
  }
}
