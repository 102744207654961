import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ComentarioService } from 'src/app/services';
import { Comentario } from 'src/app/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalRemoverComponent } from 'src/app/shared/modal/modal-remover/modal-remover.component';
import { NotificationService } from 'src/app/shared/messages/notification.service';

@Component({
  selector: 'app-comentario-lista-reportados',
  templateUrl: './comentario-lista-reportados.component.html',
  styleUrls: ['./comentario-lista-reportados.component.scss']
})
export class ComentarioListaReportadosComponent implements OnInit {

  reportados: Comentario[];
  mensagemListaVazia: boolean;

  constructor(
    private comentarioService: ComentarioService,
    private modalService: NgbModal,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.listar();
  }

  remover(reportado: Comentario) {
    const modalRef = this.modalService.open(ModalRemoverComponent, { centered: true });
    modalRef.componentInstance.mensagem = `Deseja realmente remover o comentário reportado?`;
    modalRef.componentInstance.titulo = `Remover`;

    modalRef.result.then(confirmaRemocao => {
      if (confirmaRemocao) {
        this.comentarioService.remover(reportado.id).subscribe(_ => {
          this.notificationService.notifty(`O comentário reportado foi removido.`);
          this.listar();
        });
      }
    }, dismiss => { });
  }

  restaurar(reportado: Comentario) {
    reportado.reportado = false;
    reportado.verificado = true;
    this.comentarioService.salvar(reportado).subscribe(() => {
      this.notificationService.notifty(`O comentário reportado foi restaurado com sucesso!`);
      this.listar();
    })
  }

  listar() {
    this.comentarioService.reportados().subscribe(reportados => {
      this.reportados = reportados;

      this.mensagemListaVazia = reportados.length < 1;

      if (this.mensagemListaVazia)
        this.comentarioService.tiraBolinha();

    })
  }
}
