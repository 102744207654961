<nav aria-label="breadcrumb ">
  <ol class="breadcrumb b-radius breadcrumb-appckground mt-3">
    <li class="breadcrumb-item active breadcrumb-link-actual mt-1" aria-current="page"><i class="fal fa-exclamation-circle"></i>&nbsp;Comentários
      reportados</li>
  </ol>
</nav>
<div class="table-responsive" *ngIf="!mensagemListaVazia">
  <table class="table table-sm table-hover table-striped">
    <thead>
      <tr>
        <th></th>
        <th class="text-left">Usuário</th>
        <th class="text-left">Texto</th>
        <th class="text-left">Ações</th>
      </tr>
    </thead>
    <tbody class="text-left">
      <tr class="text-nowrap tr-click" *ngFor="let reportado of reportados" [routerLink]="[]">
        <td class="td-adjustment"><img src="{{reportado.usuario.imagem}}" class="img-adjustment" *ngIf="reportado.usuario.imagem" /><i
            class="fal fa-user-circle" *ngIf="!reportado?.usuario?.imagem" style="margin-left: 1.5px;"></i></td>
        <td>{{reportado.usuario.nome}}</td>
        <td>{{reportado.texto}}</td>
        <td>
          <button type="submit" class="btn btn-sm btn-outline-primary btn-on-full mr-2" (click)="restaurar(reportado)">
            <i class="fal fa-check"></i>&nbsp;Restaurar</button>

          <button type="submit" class="btn btn-sm btn-outline-danger btn-on-full" (click)="remover(reportado)">
            <i class="fal fa-trash-alt"></i>&nbsp;Remover</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="mensagemListaVazia">
  <h6 class="text-muted">Não possui comentários reportados.</h6>
</div>
