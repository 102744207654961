import { Component, OnInit } from '@angular/core';
import { Lancamento } from 'src/app/models';

@Component({
  selector: 'app-gasto-publico-detalhe',
  templateUrl: './gasto-publico-detalhe.component.html',
  styleUrls: ['./gasto-publico-detalhe.component.scss']
})
export class GastoPublicoDetalheComponent implements OnInit {

  lancamentos: Lancamento[] = [];

  constructor() { }

  ngOnInit() {
  }

}
