import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-searchbox',
  templateUrl: './searchbox.component.html',
  styleUrls: ['./searchbox.component.scss']
})
export class SearchboxComponent implements OnInit {

  searchForm: UntypedFormGroup;
  searchControl: UntypedFormControl;
  private pesquisa: string;

  @Output() search = new EventEmitter<string>();

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.searchControl = this.fb.control('');
    this.searchForm = this.fb.group({
      searchControl: this.searchControl
    });

    this.searchControl.valueChanges
    .pipe(
      debounceTime(500),
      distinctUntilChanged()
      )
      .subscribe(pesquisa => {
        this.search.emit(pesquisa);
      });
  }
}