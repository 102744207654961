<nav aria-label="breadcrumb ">
  <ol class="breadcrumb b-radius breadcrumb-appckground mt-3">
    <a class="breadcrumb-item mt-1" aria-current="page" [routerLink]="['/politicos']"><i class="fas fa-user-tie"></i>&nbsp;Políticos</a>
    <li class="breadcrumb-item active breadcrumb-link-actual mt-1" aria-current="page">Adicionar</li>
    <li class="ml-auto">
      <button type="button" class="btn btn-sm btn-outline-primary btn-on" [routerLink]="['/politicos']">
        <i class="fal fa-list"></i>
        <span class="d-none d-lg-inline-flex">&nbsp;Listar</span>
      </button>
    </li>
  </ol>
</nav>
<div *ngIf="politicoForm.controls.id.value != null">
  <button type="button" class="btn btn-sm btn-outline-secondary btn-on" (click)="verPropostas()">
    <i class="far fa-ballot-check"></i>
    <span class="d-none d-lg-inline-flex">&nbsp;Propostas</span></button>
  <hr />
</div>
<form [formGroup]="politicoForm" novalidate autocomplete="off">
  <input type="hidden" formControlName="id" />
  <div class="row">
    <div class="col-lg-9 order-2 order-lg-1">
      <div class="row">
        <div class="col-lg-6">
          <app-input label="Nome" errorMessage="Campo obrigatório." [isRequired]="true">
            <input #firstElement type="text" formControlName="nome" class="form-control form-control-sm" placeholder="Nome do político" />
          </app-input>
        </div>

        <div class="col-6 col-lg-3">
          <app-input label="Cargo" errorMessage="Campo obrigatório." [isRequired]="true">
            <input type="text" formControlName="cargo" class="form-control form-control-sm" placeholder="Cargo do político" />
          </app-input>
        </div>

        <div class="col-6 col-lg-3">
          <app-input label="Partido">
            <input type="text" formControlName="siglaPartido" class="form-control form-control-sm" placeholder="Sigla" />
          </app-input>
        </div>

        <div class="col-6 col-lg-9">
          <app-input label="E-mail">
            <input type="text" formControlName="email" class="form-control form-control-sm" placeholder="E-mail do político" />
          </app-input>
        </div>

        <div class="col-6 col-lg-3">
          <app-input label="Receber E-mails?">
            <input type="checkbox" formControlName="receberEmail" />
          </app-input>
        </div>

        <div class="col-12">
          <app-input label="Biografia" errorMessage="Campo obrigatório." [isRequired]="true">
            <textarea type="text" formControlName="biografia" class="form-control form-control-sm" placeholder="Biografia do político"
              rows="5"></textarea>
          </app-input>
        </div>

        <div class="col-12">
          <button type="button" class="btn btn-sm btn-outline-primary mb-3" (click)="addEmails(null)">
            <i class="fal fa-plus-circle"></i>
            &nbsp;Adicionar Acessores</button>
        </div>

        <div class="col-12">
          <div class="row" *ngFor="let email of getEmailsFormArray().controls; let i = index;">
            <div class="col-10">
              <div formArrayName="emails">
                <div [formGroupName]="i">
                  <app-input label="E-mail">
                    <input type="text" formControlName="endereco" class="form-control form-control-sm" placeholder="email@email.com" />
                  </app-input>
                </div>
              </div>
            </div>

            <div class="col-2">
              <button type="button" class="btn btn-sm btn-outline-danger mt-4" (click)="removeEmail(i)">
                <i class="fal fa-minus-circle"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3 order-1 order-lg-2">
      <div class="row">
        <div class="col">
          <app-input label="Ícone do político" labelAlign="text-center" errorMessage="Campo obrigatório." [isRequired]="true" (change)="onFileChanged($event)">
            <app-img formControlName="imagem" icone="fas fa-user-tie"></app-img>
          </app-input>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <button type="submit" class="btn btn-sm btn-primary btn-on-full mr-2" (click)="salvar(politicoForm.value)" [disabled]="areSubmitting || this.politicoForm.invalid">
    <i class="fal fa-check"></i>&nbsp;Salvar
  </button>

  <button type="button" class="btn btn-sm btn-outline-danger btn-on float-right" (click)="remover()" *ngIf="politicoForm.controls.id.value != null && areAdmin">
    <i class="fal fa-trash"></i>
    <span class="d-none d-lg-inline-flex">&nbsp;Remover</span>
  </button>
</form>
