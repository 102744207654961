import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Produto } from '../models';

@Injectable()
export class ProdutoService {
    constructor(private http: HttpClient) { }

    produto: Produto;

    listar(pesquisa?: string): Observable<Produto[]> {
        let params: HttpParams;
        if (pesquisa) {
            params = new HttpParams().set('query', pesquisa);
        }
        return this.http.get<Produto[]>(`${environment.api}/produtos`, { params: params });
    }

    ListarByVendedor(id: string): Observable<Produto[]> {
        return this.http.get<Produto[]>(`${environment.api}/produtos/listarPorAnunciantes/${id}`,);
    }

    encontrar(id: string): Observable<Produto> {
        return this.http.get<Produto>(`${environment.api}/produtos/${id}`);
    }

    salvar(produto: Produto): Observable<Produto> {
        return this.http.post<Produto>(`${environment.api}/produtos`, produto);
    }

    remover(id: string): Observable<Produto> {
        return this.http.delete<Produto>(`${environment.api}/produtos/${id}`);
    }
}