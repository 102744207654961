<nav aria-label="breadcrumb ">
  <ol class="breadcrumb b-radius breadcrumb-appckground mt-3">
    <a class="breadcrumb-item mt-1" aria-current="page" [routerLink]="['/vendedores']"><i
        class="fas fa-user-shield"></i>&nbsp;Vendedor</a>
    <li class="breadcrumb-item active breadcrumb-link-actual mt-1" aria-current="page">Adicionar</li>
    <li class="ml-auto">
      <button type="button" class="btn btn-sm btn-outline-primary btn-on" [routerLink]="['/vendedores']">
        <i class="fal fa-list"></i>
        <span class="d-none d-lg-inline-flex">&nbsp;Listar</span>
      </button>
    </li>
  </ol>
</nav>

<form [formGroup]="form" novalidate autocomplete="off">
  <input type="hidden" formControlName="id" />
  <div class="row">
    <div class="col-lg-9 order-2 order-lg-1">
      <div class="row">
        <div class="col-lg-9">
          <app-input label="Nome" errorMessage="Campo obrigatório." [isRequired]="true">
            <input #firstElement type="text" formControlName="nome" class="form-control form-control-sm" placeholder="Nome do vendedor" />
          </app-input>
        </div>

        <div class="col-6 col-lg-3">
          <app-input label="Telefone">
            <input [textMask]="{mask: telefoneMovelMask, guide: false}" type="text" formControlName="telefone" class="form-control form-control-sm" placeholder="telefone do vendedor" />
          </app-input>
        </div>

        <div class="col-6 col-lg-6">
          <app-input label="E-mail" errorMessage="Campo obrigatório." [isRequired]="true">
            <input type="text" formControlName="email" class="form-control form-control-sm" placeholder="E-mail do vendedor" />
          </app-input>
        </div>

        <div class="col-6" *ngIf="!hiddingPassword">
          <app-input label="Senha" errorMessage="Campo obrigatório." [isRequired]="true">
            <input type="password" formControlName="senha" class="form-control form-control-sm" placeholder="Senha" />
          </app-input>
        </div>
     
      </div>
    </div>

    <div class="col-lg-3 order-1 order-lg-2">
      <div class="row">
        <div class="col">
          <app-input label="Ícone do vendedor" labelAlign="text-center" errorMessage="Campo obrigatório." [isRequired]="true" (change)="onFileChanged($event)">
            <app-img formControlName="imagem" icone="fas fa-user-shield"></app-img>
          </app-input>
        </div>
      </div>
    </div>
  </div>
  <hr />

  <button type="submit" class="btn btn-sm btn-primary btn-on-full mr-2" (click)="salvar(form.value)" [disabled]="areSubmitting || this.form.invalid">
    <i class="fal fa-check"></i>&nbsp;Salvar
  </button>

  <button type="button" class="btn btn-sm btn-outline-danger btn-on float-right" (click)="remover()" *ngIf="form.controls.id.value != null && areAdmin">
    <i class="fal fa-trash"></i>
    <span class="d-none d-lg-inline-flex">&nbsp;Remover</span>
  </button>
</form>