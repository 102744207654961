import { Component, OnInit } from '@angular/core';
import { Beneficiario, Estado, Cidade, Organizacao } from 'src/app/models';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';
import { BeneficiarioService, LoginService, OnMapsService, OrganizacaoService } from 'src/app/services';
import { NotificationService } from 'src/app/shared/messages/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedMasks } from 'src/app/shared/shared.mask';
import { FiltroEnum } from 'src/app/enum';

@Component({
  selector: 'app-beneficiario',
  templateUrl: './beneficiario.component.html',
  styleUrls: ['./beneficiario.component.scss']
})
export class BeneficiarioComponent implements OnInit {

  beneficiario: Beneficiario;
  beneficiarioForm: UntypedFormGroup;
  areSubmitting: boolean;
  id: string;
  emailFormArray: UntypedFormArray;
  areAdmin: boolean;
  ufs: Estado[];
  cidades: Cidade[];
  telefoneMovelMask: any;
  filtroEnum= FiltroEnum;
  organizacoes: Organizacao[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private beneficiarioService: BeneficiarioService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private mapsService: OnMapsService,
    private organizacaoService: OrganizacaoService,
  ) { }

  ngOnInit() {
    this.areAdmin = this.loginService.areAdmin();

    this.beneficiarioForm = this.formBuilder.group({
      id: [null],
      imagem: [null],
      nome: ['', Validators.required],
      uf: ['', Validators.required],
      cidade: [''],
      endereco: [null],
      telefone: [null],
      organizacaoId: [''],
      ramoAtividade: [null],
    });

    // Verificar se esta editando o beneficiario
    this.id = this.route.snapshot.params['id'];
    if (this.id != null) {
      this.beneficiarioService.encontrar(this.id).subscribe(beneficiario => {
        this.beneficiarioForm.patchValue(beneficiario);
      })
    }

    // Listar Adicionais
    this.organizacaoService.listar().subscribe(organizacoes => this.organizacoes = organizacoes);
    this.mapsService.estados().subscribe(estados => {
      var estadoNovo = new Estado();
      estadoNovo.nome = 'Brasil';
      estadoNovo.uf = 'Brasil';
      estados.push(estadoNovo);
      this.ufs = estados;
    });

    // Atribuir Mascaras
    this.telefoneMovelMask = SharedMasks.telefoneMovelMask;
  }

  salvar(beneficiario: Beneficiario) {
    this.areSubmitting = true;
    this.beneficiarioService.salvar(beneficiario)
      .subscribe(resp => {
        this.notificationService.notifty(`O beneficiário ${beneficiario.nome} foi salvo com sucesso.`);
        this.resetForm();
        this.areSubmitting = false;
      }, error => this.areSubmitting = false);
  }

  remover() {
    this.beneficiarioService.remover(this.id).subscribe(_ => {
      this.notificationService.notifty(`O beneficiário ${this.beneficiarioForm.value.nome} foi removido.`);
      this.resetForm();
    });
  }

  resetForm() {
    this.router.navigate(['/beneficiarios']);
  }

  filtrar(tipo: FiltroEnum) {
    if (tipo == FiltroEnum.Estado && this.beneficiarioForm.controls.uf.value != '') {
      this.beneficiarioForm.controls.cidade.setValue('');
      this.mapsService.cidades(this.beneficiarioForm.controls.uf.value).subscribe(cidades => this.cidades = cidades);
    }

    if (this.beneficiarioForm.controls.uf.value == '') {
      this.beneficiarioForm.controls.cidade.setValue('');
    }
  }
}
