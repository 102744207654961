import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Login, Usuario } from 'src/app/models';
import { LoginService } from 'src/app/services';
import { NotificationService } from 'src/app/shared/messages/notification.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ResetarSenhaDto } from 'src/app/models/resetar-senha-dto.model';
import { SharedMasks } from 'src/app/shared/shared.mask';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FacebookLoginProvider, SocialAuthService } from '@alenosmanovic91x/angularx-social-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  usuario: Usuario;
  loginForm: UntypedFormGroup;
  cadastroForm: UntypedFormGroup;
  recuperacaoForm: UntypedFormGroup;
  telefoneMovelMask: any;
  areSubmitting: boolean;
  estaLogando: boolean = true;
  estaRecuperando: boolean;
  emailVerificado: boolean;
  usuarioEncontrado: Usuario;

  @ViewChild('firstElement') firstElementRef: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private notificationService: NotificationService,
    private authService: SocialAuthService,
    private activeModal: NgbActiveModal,
    private usuarioService: UsuarioService,
    private router: Router,
    private modalService: NgbModal,
  ) { }


  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      id: [0],
      email: ['', Validators.required],
      senha: ['', Validators.required]
    });

    this.recuperacaoForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      codigoAlteracao: ['', [Validators.required]],
      novaSenha: ['', [Validators.required, Validators.minLength(6)]],
      novaSenhaConfirmar: ['', [Validators.required, Validators.minLength(6)]]
    }, { validator: this.senhasCoincidem });

    this.cadastroForm = this.formBuilder.group({
      id: [null],
      email: [null, Validators.required],
      telefone: [null],
      documento: [null],
      facebookId: [null],
      googleId: [null],
      senha: [null],
      token: [null],
      temSenha: [false],
      perfil: [null],
      nome: [null, Validators.required],
      imagem: [null],
    });

    // Atribuir Mascaras
    this.telefoneMovelMask = SharedMasks.telefoneMovelMask;
  }

  senhasCoincidem(group: AbstractControl): { [key: string]: boolean } {
    const novaSenha = group.get('novaSenha');
    const novaSenhaConfirmar = group.get('novaSenhaConfirmar');

    if (!novaSenha || !novaSenhaConfirmar) {
      return null;
    }

    if (novaSenha.value !== novaSenhaConfirmar.value) {
      return { senhasNaoCoincidem: true };
    }

    return null;
  }

  logon(login: Login) {
    this.areSubmitting = true;

    const loginCallback = (coordenadas) => this.loginService.login(login, coordenadas)
      .subscribe(usuario => {
        if (usuario.reportado && usuario.verificado) {
          this.notificationService.notifty(`Opa! Esta conta foi desabilitada, não é possível fazer login.`);
          this.areSubmitting = false;
        }
        else {
          this.notificationService.notifty(`Bem vindo ao Olhar Nacional.`);
          this.areSubmitting = false;
          this.fecharModal(usuario);

          this.usuarioService.alterarStatusOnline(usuario.id, true);
        }
      }, _ => this.areSubmitting = false);

    if (navigator.geolocation)
      navigator.geolocation.getCurrentPosition((position) => {

        let coordenadas = null;
        if (position.coords.latitude && position.coords.longitude)
          coordenadas = position.coords.latitude.toString() + ',' + position.coords.longitude.toString();

        loginCallback(coordenadas);
      }, _ => loginCallback(null));
  }

  abrirTermos() {
    this.activeModal.dismiss();
    this.router.navigate(['/politica-privacidade']);
  }

  logonFacebook() {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    this.areSubmitting = true;
    var coordenadas = null;

    if (navigator.geolocation)
      navigator.geolocation.getCurrentPosition((position) => {
        if (position.coords.latitude && position.coords.longitude)
          coordenadas = position.coords.latitude.toString() + ',' + position.coords.longitude.toString();
      });

    this.authService.authState.subscribe(user => {
      if (user != null) {
        this.loginService.loginFacebook(user, coordenadas).toPromise().then(usuario => {


          this.notificationService.notifty(`Bem vindo ao Olhar Nacional.`);
          this.fecharModal(usuario);
          this.areSubmitting = false;
        }).catch(error => this.areSubmitting = false);
      }
    }, error => this.areSubmitting = false);
  }

  fecharModal(usuario: Usuario = null) {
    if (usuario == null) {
      this.activeModal.dismiss();
    }
    else {
      this.activeModal.close(usuario);
    }
  }

  enviar() {
    if (!this.recuperacaoForm.controls.email.value) {
      this.notificationService.notifty(`Por favor preencha o e-mail corretamente.`);
    }
    else {
      this.loginService.encontrarLoginPorEmail(this.recuperacaoForm.controls.email.value)
        .subscribe(usuario => {
          this.usuarioEncontrado = usuario;
          this.emailVerificado = true;
        }, error => {
          this.notificationService.notifty(`Erro, e-mail incorreto.`);
          this.abrirModal();
        });
    }
  }

  recuperar() {
    let dto = new ResetarSenhaDto();
    dto.codigoAlteracao = this.recuperacaoForm.controls.codigoAlteracao.value;
    dto.email = this.recuperacaoForm.controls.email.value;
    dto.novaSenha = this.recuperacaoForm.controls.novaSenhaConfirmar.value;

    this.loginService.cadastrarNovaSenha(dto)
      .subscribe(() => {
        this.notificationService.notifty(`Senha alterada com sucesso!`)
        this.voltar();
      }, error => this.notificationService.notifty(`Erro ao recuperar senha.`));
  }

  cadastrar() {
    this.estaLogando = false;
    this.cadastroForm.reset();
  }

  voltar() {
    this.estaLogando = true;
    this.estaRecuperando = false;
    this.loginForm.reset();
  }

  salvar(usuario: Usuario) {
    this.areSubmitting = true;

    if (this.cadastroForm.controls.temSenha.value != true && this.areSubmitting != true) {
      this.notificationService.notifty(`Por favor preencha o campo de senha.`);
      this.areSubmitting = false;
    }
    else {
      if (usuario.perfil == null) {
        usuario.perfil = 'user';
      }

      this.usuarioService.salvar(usuario)
        .subscribe(resp => {
          this.notificationService.notifty(`O usuário ${usuario.nome} foi salvo com sucesso.`);
          if (this.loginService.usuario != null && usuario.id == this.loginService.usuario.id) {
            this.loginService.alterarUsuario(usuario);
          }
          if (this.loginService.areAdmin()) {
            this.router.navigate(['/usuarios']);
          }
          else {
            this.router.navigate(['/feed']);
          }
          this.fecharModal();

          let logon = new Login;
          logon.email = usuario.email;
          logon.senha = usuario.senha;
          this.logon(logon);

          this.areSubmitting = false;
        }, error => this.areSubmitting = false);
    }
  }

  allowSubmit(): boolean {
    return this.loginForm.valid && !this.areSubmitting;
  }

  abrirModal() {
    this.estaRecuperando = true;
    this.emailVerificado = false;
    this.recuperacaoForm.controls.email.enable();
    this.recuperacaoForm.reset();
  }

  resetForm() {
    this.loginForm.reset({
      email: '',
      senha: ''
    });
  }
}