<nav aria-label="breadcrumb ">
  <ol class="breadcrumb b-radius breadcrumb-appckground mt-3">
    <a class="breadcrumb-item mt-1" aria-current="page" [routerLink]="['/propostas']"><i class="far fa-ballot-check"></i>&nbsp;Propostas</a>
    <li class="breadcrumb-item active breadcrumb-link-actual mt-1" aria-current="page">Adicionar</li>
    <li class="ml-auto">
      <button type="button" class="btn btn-sm btn-outline-primary btn-on" [routerLink]="['/propostas']">
        <i class="fal fa-list"></i>
        <span class="d-none d-lg-inline-flex">&nbsp;Listar</span>
      </button>
    </li>
  </ol>
</nav>
<form [formGroup]="propostaForm" novalidate autocomplete="off">
  <input type="hidden" formControlName="id" />
  <div class="row">
    <div class="col-lg-9 order-2 order-lg-1">
      <div class="row">
        <div class="col-lg-6">
          <app-input label="Político" errorMessage="Este campo é obrigatório." [isRequired]="true">
            <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true" formControlName="politicoId">
              <ng-option value="">Selecione o político</ng-option>
              <ng-option *ngFor="let politico of politicos" [value]="politico.id">{{politico.nome}}</ng-option>
            </ng-select>
          </app-input>
        </div>

        <div class="col">
          <app-input label="Estado">
            <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true" formControlName="uf" (change)="filtrar(filtroEnum.Estado)">
              <ng-option value="">Selecione o estado</ng-option>
              <ng-option *ngFor="let uf of ufs" [value]="uf.uf">{{uf.nome}}</ng-option>
            </ng-select>
          </app-input>
        </div>

        <div class="col-6 col-md-6 col-lg-3" *ngIf="showCidade">
          <app-input label="Cidade">
            <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true" formControlName="cidade" (change)="filtrar()">
              <ng-option value="">Selecione a cidade</ng-option>
              <ng-option *ngFor="let cidade of cidades" [value]="cidade.nome">{{cidade.nome}}</ng-option>
            </ng-select>
          </app-input>
        </div>

        <div class="col-lg-6">
          <app-input label="Título" errorMessage="Campo obrigatório." [isRequired]="true">
            <input #firstElement type="text" formControlName="titulo" class="form-control form-control-sm" placeholder="Título da proposta" />
          </app-input>
        </div>

        <div class="col-lg-3">
          <app-input label="Data" errorMessage="Este campo é obrigatório." [isRequired]="true">
            <div class="input-group">
              <input class="form-control form-control-sm" type="text" ngbDatepicker #data="ngbDatepicker"
                formControlName="data" maxlength="10" />
              <div class="input-group-append">
                <button class="btn btn-sm btn-outline-secondary" (click)="data.toggle()" type="button">
                  <i class="fal fa-calendar"></i>
                </button>
              </div>
            </div>
          </app-input>
        </div>

        <div class="col-lg-3">
          <app-input label="Status" errorMessage="Campo obrigatório." [isRequired]="true">
            <select formControlName="status" class="form-control form-control-sm">
              <option *ngFor="let statusProp of statusProps" [ngValue]="statusProp.value">{{statusProp.key}}</option>
            </select>
          </app-input>
        </div>

        <div class="col">
          <app-input label="Descrição" errorMessage="Campo obrigatório." [isRequired]="true">
            <textarea type="text" formControlName="descricao" class="form-control form-control-sm" placeholder="Descrição da proposta"
              rows="5"></textarea>
          </app-input>
        </div>
      </div>
    </div>
    <div class="col-lg-3 order-1 order-lg-2">
      <div class="row">
        <div class="col">
          <app-input label="Imagem da proposta" labelAlign="text-center" errorMessage="Campo obrigatório." [isRequired]="true" (change)="onFileChanged($event)">
            <app-img formControlName="imagem" icone="far fa-ballot-check"></app-img>
          </app-input>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <button type="submit" class="btn btn-sm btn-primary btn-on-full mr-2" (click)="salvar()" [disabled]="areSubmitting || this.propostaForm.invalid">
    <i class="fal fa-check"></i>&nbsp;Salvar</button>
  <button type="button" class="btn btn-sm btn-outline-danger btn-on float-right" (click)="remover()" *ngIf="propostaForm.controls.id.value != null">
    <i class="fal fa-trash"></i>
    <span class="d-none d-lg-inline-flex">&nbsp;Remover</span></button>
</form>
