import { Component, OnInit } from '@angular/core';
import { Pedido } from 'src/app/models';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { StatusPedido } from 'src/app/enum';
import { PedidoService } from 'src/app/services';
import { NotificationService } from 'src/app/shared/messages/notification.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pedidos-detalhe-modal',
  templateUrl: './pedidos-detalhe-modal.component.html',
  styleUrls: ['./pedidos-detalhe-modal.component.scss']
})
export class PedidosDetalheModalComponent implements OnInit {
  pedido: Pedido;
  pedidos: Pedido[] = [];
  form: UntypedFormGroup;
  status = StatusPedido;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private _service: PedidoService,
    private _notificationService: NotificationService,
    private _modalRef: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.gerarForm();

    this.form.patchValue(this.pedido);
  }

  gerarForm() {
    this.form = this.formBuilder.group({
      id: [null],
      clienteId: [null],
      vendedorId: [null],
      dataHoraFinalizacao: [null],
      dataHoraCriacao: [null],
      dataHoraPagamento: [null],
      estaFinalizado: [null],
      estaPago: [null],
      subtotal: [null],
      valorProdutos: [null],
      valorDesconto: [null],
      valorFrete: [null],
      valorTotal: [null],
      valorPagamento: [null],
      valorPendente: [null],
      status: [null],
      itens: [this.pedido.itens]
    });
  }

  retornaEnum(status: StatusPedido): string {
    switch (status) {
      case StatusPedido.Novo:
        return "Novo";
      case StatusPedido.Entregue:
        return "Entregue";
      case StatusPedido.EmTransporte:
        return "Em Transporte";
      case StatusPedido.EmSeparacao:
        return "Em Separação";
      case StatusPedido.AguardandoTransporte:
        return "Aguardando Transporte";
      case StatusPedido.AguardandoRetirada:
        return "Aguardando Retirada";
        break;
    }
  }

  salvar(pedido: Pedido) {
    if (pedido.status == StatusPedido.Entregue) {
      pedido.estaFinalizado = true;
      pedido.dataHoraFinalizacao = new Date();
    }
    else
      pedido.estaFinalizado = false;

    for (const item of this.pedido.itens) {
      item.produto = null;
    }

    
    this._service.salvar(pedido)
    .subscribe(resp => { this._notificationService.notifty(`O pedido foi salvo com sucesso.`) })

    this._modalRef.close(pedido);
  }
}
