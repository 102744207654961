<nav aria-label="breadcrumb">
  <ol class="breadcrumb b-radius breadcrumb-appckground mt-3">
    <li class="breadcrumb-item active breadcrumb-link-actual mt-1" aria-current="page"><i
        class="far fa-user-chart"></i>&nbsp;Lançamento</li>
    <li class="ml-auto">
      <button type="button" class="btn btn-sm btn-outline-primary btn-on" [routerLink]="['adicionar']">
        <i class="fal fa-plus"></i>
        <span class="d-none d-lg-inline-flex">&nbsp;Adicionar</span>
      </button>
    </li>
  </ol>
</nav>
<form [formGroup]="filtroForm" novalidate autocomplete="off">
<div class="row">
  <div class="col-12">
    <app-searchbox (search)="filtrar(filtroForm.controls.pesquisa.setValue($event))"></app-searchbox>
  </div>
</div>
</form>
<div class="table-responsive" *ngIf="!mensagemListaVazia">
  <table class="table table-sm table-hover table-striped">
    <thead>
      <tr>
        <th class="text-left">Beneficiário</th>
        <th class="text-left">Organização</th>
        <th class="text-left">Data</th>
        <th class="text-left">Valor</th>
        <th class="text-left">Tipo</th>
      </tr>
    </thead>
    <tbody class="text-left">
      <tr class="text-nowrap tr-click" *ngFor="let lancamento of lancamentos"
        [routerLink]="['/lancamentos', lancamento.id]">
        <td>{{lancamento.beneficiario.nome}}</td>
        <td>{{lancamento.organizacao.nome}}</td>
        <td>{{lancamento.data | date:'dd/MM/yyyy'}}</td>
        <td>{{lancamento.valor | currency:'BRL'}}</td>
        <td>{{lancamento.creditaValor? 'Crédito':'Débito'}}</td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="mensagemListaVazia">
  <h6 class="text-muted">Não possui Lançamentos.</h6>
</div>
