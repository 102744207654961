import { Component, OnInit } from '@angular/core';
import { SharedConversions, DateIO } from 'src/app/shared/shared.conversions';
import { UfService } from 'src/app/shared/uf.service';
import { DenunciaService, LoginService, OnMapsService } from 'src/app/services';
import { NotificationService } from 'src/app/shared/messages/notification.service';
import { Denuncia, Midia, Estado, Cidade, Bairro, Endereco } from 'src/app/models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { TipoMidia } from 'src/app/enum';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AddressResult } from 'src/app/models/address-result.model';

@Component({
  selector: 'app-denuncia-cadastro',
  templateUrl: './denuncia-cadastro.component.html',
  styleUrls: ['./denuncia-cadastro.component.scss']
})
export class DenunciaCadastroComponent implements OnInit {

  denunciaForm: UntypedFormGroup;
  midiaFormArray: UntypedFormArray;
  estaValidando: boolean;
  denuncia = new Denuncia;
  id: string;
  ufs: Estado[];
  cidades: Cidade[];
  bairros: Bairro[];
  midias: Midia[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private ufService: UfService,
    private denunciaService: DenunciaService,
    private notificationService: NotificationService,
    private loginService: LoginService,
    private activeModal: NgbActiveModal,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private mapsService: OnMapsService,
  ) { }

  ngOnInit() {
    this.denunciaForm = this.formBuilder.group({
      id: [null],
      titulo: [null, Validators.required],
      data: [SharedConversions.convertDate(DateIO.js, DateIO.ngb, new Date()), Validators.required],
      uf: ['', Validators.required],
      cidade: ['', Validators.required],
      bairro: ['', Validators.required],
      descricao: [null, Validators.required],
      validada: [false],
      usuarioId: [this.loginService.usuario.id],
      midias: this.formBuilder.array([]),
    });

    // Listar Adicionais
    this.mapsService.estados().subscribe(estados => this.ufs = estados);

    // Verificar se esta editando a denuncia
    this.id = this.route.snapshot.params.id;

    if (this.id != null) {
      this.denunciaService.encontrar(this.id).subscribe(denuncia => {
        this.denuncia = denuncia
        this.midias = this.denuncia.midias;

        this.estaValidando = true;
        this.location.replaceState('/denuncias');
      });
    };
  }

  renderMidia(): UntypedFormGroup {
    return this.formBuilder.group({
      url: [''],
    });
  }

  addMidias() {
    this.midiaFormArray = <UntypedFormArray>this.denunciaForm.get('midias');
    this.midiaFormArray.push(this.renderMidia());
  }

  getMidiasFormArray(): UntypedFormArray {
    return <UntypedFormArray>this.denunciaForm.get('midias');
  }

  removeMidia(index: number) {
    this.midiaFormArray = <UntypedFormArray>this.denunciaForm.get('midias');
    this.midiaFormArray.removeAt(index);
  }

  enviar(denuncia: Denuncia) {
    if (this.id) {
      denuncia = this.denuncia;
    }
    else {
      denuncia.midias = this.denunciaForm.controls.midias.value;
      denuncia.data = <Date>SharedConversions.convertDate(DateIO.ngb, DateIO.cs, denuncia.data);
    }

    denuncia.midias.forEach(midia => {
      if (midia.url.match("video/mp4"))
        midia.tipoMidia = TipoMidia.Video;
      else
        midia.tipoMidia = TipoMidia.Imagem
    });

    this.denunciaService.salvar(denuncia)
      .subscribe(resp => {
        if (this.estaValidando) {
          this.notificationService.notifty(`A denúncia foi validada com sucesso.`);
          this.router.navigateByUrl("/img-validacao");
        } else {
          this.notificationService.notifty(`A denúncia ${denuncia.titulo} foi feita com sucesso.`);
          this.activeModal.close();
        }
        this.denunciaService.tiraBolinhaImagens();
      }, error => {
        this.notificationService.notifty(`Erro ao enviar denúncia.`);
      });
  }

  aceitar(midia: Midia) {
    let midiaCerta = this.midias.find(x => x.id == midia.id);
    midiaCerta.aprovada = true;
  }

  recusar(midia: Midia) {
    let midiaCerta = this.midias.find(x => x.id == midia.id);
    midiaCerta.aprovada = false;
  }

  aceitarTodos() {
    this.midias.forEach(midia => {
      midia.aprovada = true;
    });
  }

  recusarTodos() {
    this.midias.forEach(midia => {
      midia.aprovada = false;
    });
  }

  usarLocalizacao() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;

        this.mapsService.getLocalizacao(latitude, longitude).subscribe((result: AddressResult) => {
          if (result) {
            const state = this.ufs.find(x => x.nome == result.address.state);

            this.denunciaForm.controls.uf.setValue(state.uf);
            this.denunciaForm.controls.cidade.setValue(result.address.city);
            this.denunciaForm.controls.bairro.setValue(result.address.suburb);

            this.mapsService.cidades(state.uf).subscribe(cidades => this.cidades = cidades);
            this.mapsService.bairros(state.uf, this.denunciaForm.controls.cidade.value).subscribe(bairros => this.bairros = bairros);
          }
        });
      }, _ => { }, { enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 });
    }
  }

  filtrar(tipo: string) {
    if (tipo == 'estado') {
      this.denunciaForm.controls.cidade.setValue('');
      this.denunciaForm.controls.bairro.setValue('');
      this.bairros = [];
      this.mapsService.cidades(this.denunciaForm.controls.uf.value).subscribe(cidades => this.cidades = cidades);
    }

    if (tipo == 'cidade') {
      this.denunciaForm.controls.bairro.setValue('');
      this.mapsService.bairros(this.denunciaForm.controls.uf.value, this.denunciaForm.controls.cidade.value).subscribe(bairros => {
        this.bairros = bairros;
        if (this.bairros.length == 0) {
          var bairro = new Bairro();
          bairro.nome = 'Único';
          this.bairros.push(bairro);
        }
      }
      )
    }
  }
}
