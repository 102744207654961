<app-modal title="Adicionar Denúncia" *ngIf="!estaValidando">
  <form [formGroup]="denunciaForm" novalidate autocomplete="off">
    <input type="hidden" formControlName="id" />
    <div class="row">

      <div class="col-8">
        <app-input label="Título" errorMessage="Campo obrigatório." [isRequired]="true">
          <input #firstElement type="text" formControlName="titulo" class="form-control form-control-sm" placeholder="Título da denuncia" />
        </app-input>
      </div>

      <div class="col-4">
          <app-input label="Data" errorMessage="Este campo é obrigatório." [isRequired]="true">
            <div class="input-group">
              <input class="form-control form-control-sm" type="text" ngbDatepicker #data="ngbDatepicker" formControlName="data"
                maxlength="10" />
              <div class="input-group-append">
                <button class="btn btn-sm btn-outline-secondary" (click)="data.toggle()" type="button">
                  <i class="fal fa-calendar"></i>
                </button>
              </div>
            </div>
          </app-input>
        </div>

      <div class="col-12 d-flex justify-content-center">
          <button type="submit" class="btn btn-sm btn-primary" (click)="usarLocalizacao()" style="height: 30px;">
          <i class="fal fa-map-marker-alt"></i>&nbsp;Usar localização Atual</button>
      </div>

      <div class="col-4 mt-4">
        <app-input>
          <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true" formControlName="uf" (change)="filtrar('estado')">
            <ng-option value="" disabled>Todos os Estados</ng-option>
            <ng-option *ngFor="let uf of ufs" [value]="uf.uf">{{uf.nome}}</ng-option>
          </ng-select>
        </app-input>
      </div>
      <div class="col-4 mt-4">
        <app-input>
          <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true" formControlName="cidade" (change)="filtrar('cidade')">
            <ng-option value="" disabled>Todos as Cidades</ng-option>
            <ng-option *ngFor="let cidade of cidades" [value]="cidade.nome">{{cidade.nome}}</ng-option>
          </ng-select>
        </app-input>
      </div>
      <div class="col-4 mt-4">
        <app-input>
          <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true" formControlName="bairro" (change)="filtrar('bairro')">
            <ng-option value="" disabled>Todos os Bairros</ng-option>
            <ng-option *ngFor="let bairro of bairros" [value]="bairro.nome">{{bairro.nome}}</ng-option>
          </ng-select>
        </app-input>
      </div>

      <div class="col-12">
        <app-input label="Descrição" errorMessage="Campo obrigatório." [isRequired]="true">
          <textarea type="text" formControlName="descricao" class="form-control form-control-sm" placeholder="Descrição da denuncia"
            rows="5"></textarea>
        </app-input>
      </div>
      <hr />
    </div>
    <strong class="text-muted">Obs: Os vídeos são processados assim que a denúncia é efetuada.</strong>
    <hr />
    <div class="row">
      <div class="col-lg-3 col-12 col-sm-6 col-md-4" *ngFor="let imagem of getMidiasFormArray().controls; let i = index;">
        <div formArrayName="midias" class="p-0 m-0 mx-auto" style="position: relative; width: 150px;">

          <button type="button" class="btn btn-sm btn-image-remove" (click)="removeMidia(i)">
            <i class="fas fa-minus-circle text-danger"></i>
          </button>

          <div [formGroupName]="i">
            <app-input>
              <app-img formControlName="url" icone="far fa-camera" [square]="true"></app-img>
            </app-input>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-12 col-sm-6 col-md-4 text-center">
        <button type="button" class="btn btn-sm btn-outline-primary btn-image" (click)="addMidias()">
          <i class="fal fa-plus-circle fa-3x"></i>
          <br />
          Adicionar Mídias
        </button>
      </div>
    </div>

    <hr />
    <button type="submit" class="btn btn-sm btn-primary btn-on-full mr-2" (click)="enviar(denunciaForm.value)"
      [disabled]="this.denunciaForm.invalid">
      <i class="fal fa-check"></i>&nbsp;Enviar</button>
  </form>
</app-modal>

<div *ngIf="estaValidando">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb b-radius breadcrumb-appckground mt-3">
      <a class="breadcrumb-item mt-1" aria-current="page" [routerLink]="['/denuncias']"><i class="far fa-bullhorn"></i>&nbsp;Denúncias</a>
      <li class="breadcrumb-item active breadcrumb-link-actual mt-1" aria-current="page">Validando</li>
      <li class="ml-auto">
        <button type="button" class="btn btn-sm btn-outline-primary btn-on" [routerLink]="['/denuncias']">
          <i class="fal fa-list"></i>
          <span class="d-none d-lg-inline-flex">&nbsp;Listar</span>
        </button>
      </li>
    </ol>
  </nav>

  <div class="row">
    <div class="col-12 mb-2">
      <h4>{{denuncia.titulo}}</h4>
      <span>Por: {{denuncia.usuario.nome}}</span>
      <br />
      <span>Localidade: {{denuncia.cidade}} - {{denuncia.bairro}}/{{denuncia.uf}}</span>
    </div>
  </div>
  <hr />

  <div class="row">
    <div class="btn-group btn-group-sm mt-1 mb-2 mx-auto" role="group">
      <button type="button" class="btn btn-primary" (click)="aceitarTodos()"><i class="fal fa-plus-circle"></i>
        Aceitar Todos</button>
      <button type="button" class="btn btn-danger" (click)="recusarTodos()"><i class="fal fa-minus-circle"></i>
        Recusar Todos</button>
    </div>
  </div>

  <form [formGroup]="denunciaForm" novalidate autocomplete="off">
    <div class="row">
      <div class="col-md-6 col-lg-4 col-xl-3 text-center position-relative mb-2 btn-group-opacity-trigger" *ngFor="let imagem of midias">
        <div class="border rounded" [style.background-image]="'url(' + imagem.url + ')'" style="background-repeat: no-repeat; background-size: cover; background-position: center; width: 100%; height: 150px; transition: all 250ms ease-in-out; border-width: 2px !important;"
          [class.border-warning]="imagem.aprovada == null" [class.border-danger]="imagem.aprovada == false"
          [class.border-success]="imagem.aprovada == true">
        </div>
        <div class="btn-group btn-group-sm position-absolute btn-group-opacity" role="group" style="right: 20px; top: 114px;">
          <button type="button" class="btn btn-primary" (click)="aceitar(imagem)"><i class="fal fa-check-circle"></i></button>
          <button type="button" class="btn btn-danger" (click)="recusar(imagem)"><i class="fal fa-ban"></i></button>
        </div>
      </div>
    </div>

    <hr />
    <button type="submit" class="btn btn-sm btn-primary btn-on-full mr-2" (click)="enviar(denunciaForm.value)"
      [disabled]="this.denunciaForm.invalid" *ngIf="!estaValidando">
      <i class="fal fa-check"></i>&nbsp;Enviar</button>

    <button type="submit" class="btn btn-sm btn-primary btn-on-full mr-2" (click)="enviar(null)">
      <i class="fal fa-check"></i>&nbsp;Salvar</button>
  </form>
</div>
