<div class="card mt-3">
  <a [routerLink]="['/detalhes', proposta.id]" *ngIf="!mostrarDetalhes">
    <div class="card-header py-2">
      <div class="row">
        <div class="col-4 col-md-3 col-lg-2">
          <div *ngIf="proposta?.politico?.imagem" class="rounded-circle img-fluid img-usuario p-0 m-0"
            [style.background-image]="'url(' + proposta?.politico?.imagem + ')'" style="background-repeat: no-repeat; background-size: cover; background-position: center; transition: all 250ms ease-in-out; margin-left: -10px !important;">
          </div>

        </div>
        <div class="col-8 col-md-9 col-lg-10 py-1">
          <span class="card-title h5">{{proposta?.politico?.nome}}</span>
          <br />
          <span class="text-muted">{{proposta?.politico?.cargo}}</span>
        </div>
      </div>
    </div>

    <div [style.background-image]="'url(' + proposta.imagem + ')'" style="background-repeat: no-repeat; background-size: cover; background-position: center; width: 100%; height: 300px; transition: all 250ms ease-in-out;">
    </div>

    <div class="card-body py-2">
      <p class="mb-2">
        <small class="text-muted">{{proposta?.uf}} - <span *ngIf="proposta?.cidade">{{proposta?.cidade}} - </span>{{proposta?.data | date:'dd/MM/yyyy'}}</small>
        <span class="badge badge-secondary float-right">{{proposta?.statusText}}</span>
      </p>
      <span class="card-title h5">{{proposta?.titulo}}</span>
    </div>
  </a>
  <div *ngIf="mostrarDetalhes">
    <div class="card-header py-2">
      <div class="row">
        <div class="col-4 col-md-3 col-lg-2">
          <div *ngIf="proposta?.politico?.imagem" class="rounded-circle img-fluid img-usuario p-0 m-0"
            [style.background-image]="'url(' + proposta?.politico?.imagem + ')'" style="background-repeat: no-repeat; background-size: cover; background-position: center; transition: all 250ms ease-in-out; margin-left: -10px !important;">
          </div>

        </div>
        <div class="col-8 col-md-9 col-lg-10 py-1">
          <span class="card-title h5">{{proposta?.politico?.nome}}</span>
          <br />
          <span class="text-muted">{{proposta?.politico?.cargo}}</span>
        </div>
      </div>
    </div>
    <img class="card-img-top" src="{{proposta?.imagem}}" alt="Card image cap">

    <div class="card-body py-2">
      <p class="mb-2">
        <small class="text-muted">{{proposta?.uf}} - <span *ngIf="proposta?.cidade">{{proposta?.cidade}} - </span>{{proposta?.data | date:'dd/MM/yyyy'}}</small>
        <span class="badge badge-secondary float-right">{{proposta?.statusText}}</span>
      </p>
      <span class="card-title h5">{{proposta?.titulo}}</span>
    </div>
  </div>
  <div class="card-body py-2" *ngIf="mostrarDetalhes">
    <span class="card-text">{{proposta?.descricao}}</span>
  </div>
  <div class="card-footer text-muted py-1">
    <ngb-rating [(rate)]="currentRate" max="5" (rateChange)="rate()">
      <ng-template let-fill="fill" let-index="index">
        <span class="fas fa-star" [class.filled]="fill === 100"></span>
      </ng-template>
    </ngb-rating>
    <small class="text-muted" *ngIf="areLoggedIn">&nbsp;Média de {{proposta?.avaliacao | number : '1.2-2'}}</small>
  </div>
  <div class="card-footer text-muted py-1" *ngIf="mostrarDetalhes">
    <span class="card-title h5">Comentários</span>
    <br />
    <small *ngIf="showMessage">Não possui comentários</small>
    <div class="row" *ngFor="let comentario of comentarios">
      <div class="col py-1">
        <div class="player-holder float-left pt-2">
          <div class="player-thumb" *ngIf="comentario?.usuario?.imagem != null" [ngStyle]="{ 'background-image': 'url(' + comentario?.usuario.imagem + ')'}"></div>
          <div class="player-thumb fal fa-user-circle fa-2x" *ngIf="comentario?.usuario?.imagem == null"></div>
        </div>
        <div class="pl-5">

          <small class="text-primary mr-1" *ngIf="comentario?.verificado">
            <i class="fas fa-check-circle"></i></small>

          <span class="card-text">{{comentario?.texto}}</span>
          <br />
          <small class="text-dark">Por: {{comentario?.usuario.nome}} - {{comentario?.dataHora | date:'dd/MM/yyyy'}}</small>
          <br *ngIf="comentario.reportado" />
          <small class="text-muted" *ngIf="comentario.reportado" style="font-size: 12px">Denunciado, sob investigação</small>
          <br />
          <a [routerLink]="[]" (click)="verMais(comentario)"><small>+ Ver mais</small></a>
          <hr class="my-1" />
        </div>
      </div>
    </div>
    <form [formGroup]="comentarioForm" novalidate autocomplete="off">
      <div class="row p-3">
        <textarea #firstElement type="text" formControlName="texto" class="form-control form-control-sm" placeholder="Digite aqui..."
          rows="3" (keyup.control.enter)="comentar()"></textarea>
      </div>
      <button type="submit" class="btn btn-sm btn-primary btn-on-full mb-2 float-right" (click)="comentar()" [disabled]="comentarioForm.value.texto == null || comentarioForm.value.texto == ''">
        <i class="fal fa-check"></i>&nbsp;Comentar</button>
    </form>
  </div>
</div>
