import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Contato } from '../models/contato.model';

@Injectable()
export class ContatoService {
    constructor(private http: HttpClient) { }

    avaliacao: Contato;

    listar(pesquisa?: string): Observable<Contato[]> {
        let params: HttpParams;
        if (pesquisa) {
            params = new HttpParams().set('query', pesquisa);
        }
        return this.http.get<Contato[]>(`${environment.api}/contatos`, { params: params });
    }

    encontrar(id: string): Observable<Contato> {
        return this.http.get<Contato>(`${environment.api}/contatos/${id}`);
    }

    salvar(avaliacao: Contato): Observable<Contato> {
        return this.http.post<Contato>(`${environment.api}/contatos`, avaliacao);
    }

    remover(id: string): Observable<Contato> {
        return this.http.delete<Contato>(`${environment.api}/contatos/${id}`);
    }

    encontrarPorUsuarioSalvar(id: string): Observable<Contato> {
        return this.http.get<Contato>(`${environment.api}/contatos/encontrar/salvar/${id}`);
    }

    encontrarPorUsuarioRemover(id: string): Observable<Contato> {
        return this.http.get<Contato>(`${environment.api}/contatos/encontrar/remover/${id}`);
    }
}