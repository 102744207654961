import { Component, OnInit, Input } from '@angular/core';
import { Comentario } from 'src/app/models';
import { LoginService, ComentarioService } from 'src/app/services';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioService } from 'src/app/services/usuario.service';
import { NotificationService } from 'src/app/shared/messages/notification.service';

@Component({
  selector: 'app-comentario',
  templateUrl: './comentario.component.html',
  styleUrls: ['./comentario.component.scss']
})
export class ComentarioComponent implements OnInit {

  meuComentario: boolean;
  showIcones: boolean;
  showCancelar: boolean;
  comentarioVerificado: boolean;
  toggle: boolean;
  comentarioForm: UntypedFormGroup;

  @Input() comentario: Comentario;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private comentarioService: ComentarioService,
    private usuarioService: UsuarioService,
    private modalRef: NgbActiveModal,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    if (this.loginService.isLoggedIn()) {
      this.showIcones = true;

      if (this.loginService.usuario.id == this.comentario.usuarioId) {
        this.meuComentario = true;
      }
    }

    this.comentarioVerificado = this.comentario.verificado;

    this.comentarioForm = this.formBuilder.group({
      texto: [this.comentario.texto],
    });
  }

  editar() {
    this.toggle = true;
    this.meuComentario = false;
    this.showIcones = false;

    this.showCancelar = true;
  }

  bloquear() {
    this.usuarioService.bloquear(this.loginService.usuario.id, this.comentario.usuarioId).subscribe(() => {
      this.cancelar();
      this.notificationService.notifty(`O Usuário foi bloqueado com sucesso!`);
    });
  }

  denunciar() {
    this.comentarioService.denunciar(this.comentario.id).subscribe(() => {
      this.cancelar();
      this.notificationService.notifty(`O comentário foi denunciado com sucesso!`);
      this.comentarioService.tiraBolinha();
    });
  }

  remover() {
    this.comentarioService.remover(this.comentario.id).subscribe(() => {
      this.cancelar();
      this.notificationService.notifty(`O comentário foi removido.`);
    });
  }

  salvar() {
    this.comentario.texto = this.comentarioForm.value.texto;
    this.comentarioService.salvar(this.comentario).subscribe(() => {
      this.cancelar();
      this.notificationService.notifty(`O comentário editado com sucesso!`);
    });
  }

  cancelar() {
    this.modalRef.close();
  }

}
