import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Lancamento, Beneficiario, Organizacao, CentroCusto } from 'src/app/models';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormArray } from '@angular/forms';
import { LancamentoService, LoginService, BeneficiarioService, OrganizacaoService, CentroCustoService, } from 'src/app/services';
import { NotificationService } from 'src/app/shared/messages/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedConversions, DateIO } from 'src/app/shared/shared.conversions';
import { optionsCurrencyMask } from './../../shared/optionsCurrencyMask';

@Component({
  selector: 'app-lancamento',
  templateUrl: './lancamento.component.html',
  styleUrls: ['./lancamento.component.scss']
})
export class LancamentoComponent implements OnInit {

  lancamento: Lancamento;
  lancamentoForm: UntypedFormGroup;
  areSubmitting: boolean;
  id: string;
  areAdmin: boolean;
  beneficiarios: Beneficiario[] = [];
  organizacoes: Organizacao[] = [];
  centroCustos: CentroCusto[] = [];
  optionsCurrencyMask = optionsCurrencyMask;

  @ViewChild('firstElement') firstElementRef: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private lancamentoService: LancamentoService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private beneficiarioService: BeneficiarioService,
    private organizacaoService: OrganizacaoService,
    private centroCustoService: CentroCustoService
  ) { }

  ngOnInit() {
    this.lancamentoForm = this.formBuilder.group({
      id: [null],
      data: [SharedConversions.convertDate(DateIO.js, DateIO.ngb, new Date())],
      historico: ['', Validators.required],
      valor: ['', Validators.required],
      creditaValor: ['0'],
      centroCustoId: ['', Validators.required],
      beneficiarioId: ['', Validators.required],
      organizacaoId: ['', Validators.required],
    });

    // Verificar se esta editando o lancamento
    this.id = this.route.snapshot.params['id'];
    if (this.id != null) {
      this.lancamentoService.encontrar(this.id).subscribe(lancamento => {
        const data = SharedConversions.convertDate(DateIO.cs, DateIO.ngb, lancamento.data);
        this.lancamentoForm.controls.id.setValue(this.id);
        this.lancamentoForm.controls.beneficiarioId.setValue(lancamento.beneficiarioId);
        this.lancamentoForm.controls.organizacaoId.setValue(lancamento.organizacaoId);
        this.lancamentoForm.controls.valor.setValue(lancamento.valor);
        if (lancamento.creditaValor)
          this.lancamentoForm.controls.creditaValor.setValue('1');
        else
          this.lancamentoForm.controls.creditaValor.setValue('0');
        this.lancamentoForm.controls.centroCustoId.setValue(lancamento.centroCustoId);
        this.lancamentoForm.controls.data.setValue(data);
        this.lancamentoForm.controls.historico.setValue(lancamento.historico);
      })
    }
    this.beneficiarioService.listar().subscribe((beneficiarios: Beneficiario[]) => {
      this.beneficiarios = beneficiarios;
    });
    this.organizacaoService.listar().subscribe((organizacoes: Organizacao[]) => {
      this.organizacoes = organizacoes;
    });
  }

  salvar() {
    var lancamento = this.lancamentoForm.value;
    lancamento.data = SharedConversions.convertDate(DateIO.ngb, DateIO.cs, this.lancamentoForm.controls.data.value);

    if (lancamento.creditaValor == 1)
      lancamento.creditaValor = true;
    else
      lancamento.creditaValor = false;

    this.areSubmitting = true;

    this.lancamentoService.salvar(lancamento)
      .subscribe(resp => {
        this.notificationService.notifty(`O lançamento foi salvo com sucesso.`);
        this.limparForm();
        this.areSubmitting = false;
      }, error => this.areSubmitting = false);
  }

  remover() {
    this.lancamentoService.remover(this.id).subscribe(_ => {
      this.notificationService.notifty(`O lançamento foi removido.`);
      this.resetForm();
    });
  }

  resetForm() {
    this.router.navigate(['/lancamentos']);
  }

  listarCentroCustos() {
    this.centroCustoService.listarPorOrganizacao(this.lancamentoForm.controls.organizacaoId.value).subscribe(centroCustos => this.centroCustos = centroCustos);
  }

  limparForm() {
    this.lancamentoForm.reset({
      creditaValor: '0',
      id: null,
      beneficiarioId: '',
      organizacaoId: '',
      centroCustoId: '',
      data: SharedConversions.convertDate(DateIO.js, DateIO.ngb, new Date())
    });
  }
}

