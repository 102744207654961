import { Component, OnInit, forwardRef, ViewChild, ContentChild, Input, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControlName, ControlValueAccessor } from '@angular/forms';
import { NotificationService } from '../messages/notification.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AudioRecordService } from './audio-record.service';

@Component({
  selector: 'app-aud',
  templateUrl: './aud.component.html',
  styleUrls: ['./aud.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AudComponent),
      multi: true
    }
  ]
})
export class AudComponent implements OnDestroy, ControlValueAccessor {

  @ViewChild('fileInput') fileInput;
  @ViewChild('dropdown') dropdown;

  @ContentChild(FormControlName) control: FormControlName;

  @Input() icone = 'fal fa-user';
  @Input() url: string;
  @Input() fileSize: number = 10;
  @Input() fullWidth: boolean = false;

  gravandoAudio: boolean = false;
  acceptedFileTypes: string[] = [
    'audio/mp3',
    'audio/mp4',
    'audio/mpeg',
    'audio/ogg',
    'audio/wav',
    'audio/vnd.wav'
  ];

  constructor(
    private _notificationService: NotificationService,
    private _sanitizer: DomSanitizer,
    private _audioService: AudioRecordService
  ) {
    this.obterGravacaoAudio();
  }

  changeFile(): void {
    setTimeout(() => {
      this.dropdown.nativeElement.click();
    }, 100);
  }

  openFile(): void {
    this.fileInput.nativeElement.click();
  }

  removeFile(): void {
    this.url = null;
    this.fileInput.nativeElement.value = null;
    //Propagar a alteração
    this.propagateChange(this.url);
  }

  addFile(): void {
    const fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0]) {
      const fileToUpload = fi.files[0];
      const reader = new FileReader();

      if ((fileToUpload.size > this.fileSize * 1000000) && this.fullWidth == false)
        this._notificationService.notifty(`O tamanho do áudio excede o limite de ${this.fileSize} Mb.`)
      else if (this.acceptedFileTypes.indexOf(fileToUpload.type) == -1) {
        this._notificationService.notifty("Formato não suportado.");
      }
      else {
        reader.onload = () => {
          this.url = <string>reader.result;
          // Propagar a alteração
          this.propagateChange(this.url);
        };
        reader.readAsDataURL(fileToUpload);
      }
    }
  }

  iniciarGravacaoAudio() {
    this.removeFile();
    this._audioService.startRecording();
    this.toggleGravacaoAudio();
  }

  pararGravacaoAudio() {
    this._audioService.stopRecording();
    this.toggleGravacaoAudio();
  }

  abortarGravacaoAudio() {
    this._audioService.abortRecording();
    this.toggleGravacaoAudio();
  }

  fecharGravacaoAudio() {
    this.abortarGravacaoAudio();
  }

  toggleGravacaoAudio() {
    this.gravandoAudio = !this.gravandoAudio;
  }
  obterGravacaoAudio() {
    this._audioService.getRecordedBlob().subscribe((file: any) => {
      // this._service
      var reader = new FileReader();
      reader.onload = () => {
        this.url = <string>reader.result;
        // Propagar a alteração
        this.propagateChange(this.url);
      }
      reader.readAsDataURL(file.blob);
    });
  }

  // Métodos do ValueAccessor
  propagateChange = (_: any) => { };
  writeValue(value: any): void {
    this.url = value;
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void { }
  // Validação do componente
  isValid(): boolean {
    return this.url != null && this.url !== '';
  }
  isRequired(): boolean {
    if (!this.control.errors) {
      return false;
    }
    return this.control.errors.required;
  }

  sanitize(url: string) {
    return this._sanitizer.bypassSecurityTrustUrl(url);
  }

  ngOnDestroy() {
    this.abortarGravacaoAudio();
  }
}
