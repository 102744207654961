<div class="container py-2">
  <h5 class="lead mb-3">Transmissões ao vivo</h5>

  <div class="d-flex flex-row align-items-center" style="overflow-x: auto;">
    <div class="mr-3" style="cursor: pointer; width: 100px; max-width: 100px;" (click)="fazerLive()"
      ngbTooltip="Iniciar transmissão ao vivo">
      <div class="d-flex justify-content-center" style="position: relative;">
        <img class="rounded-circle img-fluid img-usuario p-0 m-0" style="object-fit: cover;" [src]="usuario?.imagem" />

        <div style="position: absolute; bottom: 0; right: 0;">
          <span class="fa-stack">
            <i class="fas fa-circle fa-stack-2x text-primary"></i>
            <i class="fas fa-camera fa-stack-1x fa-inverse"></i>
          </span>
        </div>
      </div>
      <p class="h6 text-truncate w-100 mb-0 text-center" style="font-size: 12px;">{{usuario?.nome}}</p>
    </div>

    <div class="mr-3" style="cursor: pointer; width: 100px; max-width: 100px;" *ngFor="let item of usuarios"
      (click)="verLive(item.id)" ngbTooltip="Assistir transmissão ao vivo">
      <div class="d-flex justify-content-center w-100">
        <img class="rounded-circle img-fluid img-usuario p-0 m-0" style="object-fit: cover;" [src]="item?.imagem" />
      </div>
      <p class="h6 text-truncate w-100 mb-0 text-center" style="font-size: 12px;">{{item?.nome}}</p>
    </div>
  </div>
</div>