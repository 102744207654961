import { Component, OnInit } from '@angular/core';
import { Produto } from 'src/app/models';
import { ProdutoService, LoginService } from 'src/app/services';
import { StatusPedido } from 'src/app/enum';

@Component({
  selector: 'app-produtos-lista',
  templateUrl: './produtos-lista.component.html',
  styleUrls: ['./produtos-lista.component.scss']
})
export class ProdutosListaComponent implements OnInit {

  produtos: Produto[];
  mensagemListaVazia: boolean = false;
  produtosBkp: Produto[];
  areVendedor: boolean;

  constructor(
    private _service: ProdutoService,
    private _loginService: LoginService
  ) { }

  ngOnInit() {
    this.areVendedor = this._loginService.areVendedor();

    if (this.areVendedor) {
      this.listar();
    }
  }

  listar() {
    this._service.ListarByVendedor(this._loginService.usuario.id)
      .subscribe(produtos => {
        this.produtos = produtos
        this.produtosBkp = produtos;
        
        if (produtos.length < 1) {
          this.mensagemListaVazia = true;
        }
      });
  }

  pesquisar(pesquisa: string) {
    this.produtos = this.produtosBkp;

    if (pesquisa != '') {
      this.produtos = this.produtos.filter(x => x.titulo.toLowerCase().trim().indexOf(pesquisa.toLowerCase().trim()) > -1);
    }

    if (this.produtos.length < 1) {
      this.mensagemListaVazia = true;
    } else {
      this.mensagemListaVazia = false;
    }
  }
}
