import { Injectable } from '@angular/core';
import * as RecordRTC from 'recordrtc';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class AudioRecordService {
    constructor() { }

    private stream: any;
    private recorder: any;
    private interval: any;
    private startTime: any;
    private _recorded = new Subject<any>();
    private _recordingTime = new Subject<string>();
    private _recordingFailed = new Subject<string>();


    getRecordedBlob(): Observable<any> {
        return this._recorded.asObservable();
    }

    getRecordedTime(): Observable<string> {
        return this._recordingTime.asObservable();
    }

    recordingFailed(): Observable<string> {
        return this._recordingFailed.asObservable();
    }

    startRecording() {

        if (this.recorder) {
            // It means recording is already started or it is already recording something
            return;
        }

        this._recordingTime.next('00:00');
        navigator.mediaDevices.getUserMedia({ audio: true }).then(s => {
            this.stream = s;
            this.record();
        }).catch(error => {
            this._recordingFailed.next();
        });

    }

    private record() {

        this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
          type: 'audio',
          mimeType: 'audio/webm'
        });
      
        this.recorder.record();
        this.startTime = Date();
        this.interval = setInterval(
          () => {
            // const currentTime = Date();
            // const diffTime = currentTime - this.startTime;
            // const time = this.toString(diffTime.minutes()) + ':' + this.toString(diffTime.seconds());
            this._recordingTime.next('99:99');
          },
          1000
        );
      }

      private toString(value) {
        let val = value;
        if (!value) {
          val = '00';
        }
        if (value < 10) {
          val = '0' + value;
        }
        return val;
      }

      stopRecording() {

        if (this.recorder) {
          this.recorder.stop((blob) => {
            if (this.startTime) {
              const mp3Name = encodeURIComponent('audio_' + new Date().getTime() + '.wav');
              this.stopMedia();
              this._recorded.next({ blob: blob, title: mp3Name });
            }
          }, () => {
            this.stopMedia();
            this._recordingFailed.next();
          });
        }
      
      }

      private stopMedia() {
        if (this.recorder) {
          this.recorder = null;
          clearInterval(this.interval);
          this.startTime = null;
          if (this.stream) {
            this.stream.getAudioTracks().forEach(track => track.stop());
            this.stream = null;
          }
        }
      }

      abortRecording() {
        this.stopMedia();
      }
}