<div class="mt-3" *ngIf="isLoggedIn">
  <app-room-live-list></app-room-live-list>
  <hr />
</div>

<div class="row">
  <div class="btn-group btn-group-toggle btn-group-sm mt-3 mx-auto" data-toggle="buttons">
    <label class="btn btn-outline-primary" [routerLink]="['postagens']" routerLinkActive="active">
      <input type="radio" name="options" autocomplete="off"> Postagens
    </label>
    <label class="btn btn-outline-primary" [routerLink]="['propostas']" routerLinkActive="active">
      <input type="radio" name="options" autocomplete="off"> Propostas
    </label>
    <!-- <label class="btn btn-outline-primary" [routerLink]="['gastos-publicos']" routerLinkActive="active">
      <input type="radio" name="options" autocomplete="off"> Gastos
    </label>
    <label class="btn btn-outline-primary" [routerLink]="['denuncias']" routerLinkActive="active">
      <input type="radio" name="options" autocomplete="off"> Denúncias
    </label> -->
  </div>
</div>

<router-outlet></router-outlet>