import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { LoginService, CentroCustoService, BeneficiarioService } from 'src/app/services';
import { CentroCusto } from 'src/app/models';
import { TipoCentroCusto } from 'src/app/enum';

@Component({
  selector: 'app-centro-custo-lista',
  templateUrl: './centro-custo-lista.component.html',
  styleUrls: ['./centro-custo-lista.component.scss']
})
export class CentroCustoListaComponent implements OnInit {

  centroCustos: CentroCusto[] = [];
  centroCustosBkp: CentroCusto[] = [];
  mensagemListaVazia: boolean = false;
  id: string;
  areAdmin: boolean;
  filtroForm: UntypedFormGroup;
  Administradores: string;
  ufs: any[];
  disableTodasOrganizacoes = false;

  constructor(
    private centroCustoService: CentroCustoService,
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private beneficiarioService: BeneficiarioService
  ) { }

  ngOnInit() {
    this.areAdmin = this.loginService.areAdmin();

    if (this.areAdmin) {
      this.listar();
    }
    else if (this.loginService.usuario.organizacoes.length > 0) {
      this.centroCustoService.listar()
        .subscribe(centroCustos => {
          this.loginService.usuario.organizacoes.forEach(organizacaoResp => {
            this.centroCustos = centroCustos.filter( x=> x.organizacaoId == organizacaoResp.id)
          });
          this.centroCustosBkp = this.centroCustos

          this.mensagemListaVazia = centroCustos.length == 0;
        });
    }
    else {
      this.disableTodasOrganizacoes = true;
    }
    this.filtroForm = this.formBuilder.group({
      pesquisa: [null],
    });
  }

  listar() {
    this.centroCustoService.listar()
      .subscribe(centroCustos => {
        this.centroCustos = centroCustos
        this.centroCustosBkp = centroCustos

        this.mensagemListaVazia = centroCustos.length == 0;
      });
  }

  getTipoCentroCusto(tipoCentroCusto: TipoCentroCusto): string {
    switch (tipoCentroCusto) {
      case TipoCentroCusto.Analitico:
        return "Análitico";
      case TipoCentroCusto.Sintetico:
        return "Sintético";
    }
  }

  pesquisar(pesquisa: string) {
    this.centroCustoService.listar(pesquisa)
      .subscribe(centroCustos => this.centroCustos = centroCustos);
  }

  filtrar(any) {
    var filtro = this.filtroForm.value;
    this.centroCustos = this.centroCustosBkp;

    this.centroCustos = this.centroCustos.filter(x => x.nome.toLocaleLowerCase().indexOf
      (filtro.pesquisa.toLocaleLowerCase()) > -1 || x.codigo.toLocaleLowerCase().indexOf);

    this.mensagemListaVazia = this.centroCustos.length == 0;
  }
}
