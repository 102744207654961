import { Component, OnInit } from '@angular/core';
import { Organizacao, Estado, Cidade } from 'src/app/models';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { OrganizacaoService, LoginService, OnMapsService } from 'src/app/services';
import { NotificationService } from 'src/app/shared/messages/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FiltroEnum } from 'src/app/enum';

@Component({
  selector: 'app-organizacao',
  templateUrl: './organizacao.component.html',
  styleUrls: ['./organizacao.component.scss']
})
export class OrganizacaoComponent implements OnInit {

  organizacao: Organizacao;
  organizacaoForm: UntypedFormGroup;
  areSubmitting: boolean;
  id: string;
  emailFormArray: UntypedFormArray;
  areAdmin: boolean;
  ufs: Estado[];
  cidades: Cidade[];
  filtroEnum=FiltroEnum;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private organizacaoService: OrganizacaoService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private mapsService: OnMapsService,
  ) { }

  ngOnInit() {

    this.areAdmin = this.loginService.areAdmin();

    this.organizacaoForm = this.formBuilder.group({
      id: [null],
      imagem: [null],
      nome: ['', Validators.required],
      administradores: [null],
      uf: ['', Validators.required],
      cidade: [''],
      emails: this.formBuilder.array([]),
    });

    // Verificar se esta editando a organizacao
    this.id = this.route.snapshot.params['id'];
    if (this.id != null) {
      this.organizacaoService.encontrar(this.id).subscribe(organizacao => {
        this.organizacaoForm.patchValue(organizacao);
        if (organizacao.administradores) {
          organizacao.administradores.forEach(email => {
            this.addEmails(email);
          });
        }
      })
    }

    // Listar Adicionais
    this.mapsService.estados().subscribe(estados => {
      var estadoNovo = new Estado();
      estadoNovo.nome = 'Brasil';
      estadoNovo.uf = 'Brasil';
      estados.push(estadoNovo);
      this.ufs = estados;
    });
  }

  salvar(organizacao: Organizacao) {
    this.areSubmitting = true;

    organizacao.administradores = [];
    this.organizacaoForm.controls.emails.value.forEach(administrador => {
      if (administrador.endereco != null) {
        organizacao.administradores.push(administrador.endereco);
      }
    });

    this.organizacaoService.salvar(organizacao)
      .subscribe(resp => {
        this.notificationService.notifty(`A Organização ${organizacao.nome} foi salvo com sucesso.`);
        this.resetForm();
        this.areSubmitting = false;
      }, error => this.areSubmitting = false);
  }

  remover() {
    this.organizacaoService.remover(this.id).subscribe(_ => {
      this.notificationService.notifty(`A Organização ${this.organizacaoForm.value.nome} foi removido.`);
      this.resetForm();
    });
  }

  resetForm() {
    this.router.navigate(['/organizacoes']);
  }

  renderEmail(email: string): UntypedFormGroup {
    return this.formBuilder.group({
      endereco: [email || ''],
    });
  }

  addEmails(email: string) {
    this.emailFormArray = <UntypedFormArray>this.organizacaoForm.get('emails');
    this.emailFormArray.push(this.renderEmail(email));
  }

  getEmailsFormArray(): UntypedFormArray {
    return <UntypedFormArray>this.organizacaoForm.get('emails');
  }

  removeEmail(index: number) {
    this.emailFormArray = <UntypedFormArray>this.organizacaoForm.get('emails');
    this.emailFormArray.removeAt(index);
  }

  filtrar(tipo: FiltroEnum) {
    if (tipo == FiltroEnum.Estado && this.organizacaoForm.controls.uf.value != '') {
      this.organizacaoForm.controls.cidade.setValue('');
      this.mapsService.cidades(this.organizacaoForm.controls.uf.value).subscribe(cidades => this.cidades = cidades);
    }

    if (this.organizacaoForm.controls.uf.value == '') {
      this.organizacaoForm.controls.cidade.setValue('');
    }
  }
}

