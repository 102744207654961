<app-modal title="Pedido">
  <form [formGroup]="form" novalidate autocomplete="off">

  <div style="height: 70vh; overflow-y: auto; overflow-x: hidden;">
    <div class="row">
      <div class="col-sm-4 mt-1" *ngIf="pedido.estaFinalizado == true">
        <small>Cliente</small><br />
        <strong>{{pedido.cliente?.nome}}</strong>
      </div>
      
      <div class="col-sm-6 mt-1" *ngIf="pedido.estaFinalizado == false">
        <small>Cliente</small><br />
        <strong>{{pedido.cliente?.nome}}</strong>
      </div>

      <div class="col-sm-2 mt-1">
        <small>Telefone</small><br />
        <strong>{{pedido.cliente?.telefone}}</strong>
      </div>

      <div class="col-sm-2 mt-1">
        <small>Data Agendamento</small><br />
        <strong>{{pedido.dataHoraCriacao | date:'dd/MM/yyyy HH:mm'}}h</strong>
      </div>

      <div class="col-sm-2 mt-1" *ngIf="pedido.estaFinalizado == true">
        <small>Data Finalização</small><br />
        <strong>{{pedido.dataHoraFinalizacao | date:'dd/MM/yyyy HH:mm'}}h</strong>
      </div>

      <div class="col-sm-2 mt-1">
        <small>Status</small><br />
        <app-input>
          <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true" formControlName="status">
            <ng-option [value]="status.Novo">Novo</ng-option>
            <ng-option [value]="status.EmSeparacao">Em Separação</ng-option>
            <ng-option [value]="status.AguardandoTransporte">Aguardando Transporte</ng-option>
            <ng-option [value]="status.EmTransporte">Em Transporte</ng-option>
            <ng-option [value]="status.AguardandoRetirada">Aguardando Retirada</ng-option>
            <ng-option [value]="status.Entregue">Entregue</ng-option>
          </ng-select>
        </app-input>
      </div>

      <div class="col-12 mt-3" style="page-break-after: always;">
        <h4 class="mt-3 text-center">Produtos</h4>
        <div class="mt-2 table-responsive w-100">
          <table class="table table-sm" style="background-color: transparent;">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Quantidade</th>
                <th class="text-right">Valor Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let pedidoItem of pedido.itens; let i = index">
                <td>{{pedidoItem.produto?.titulo}}</td>
                <td>{{pedidoItem.quantidade}}</td>
                <td class="text-right">{{pedidoItem.valorTotalItem | currency:'BRL'}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Valor total do pedido</th>
                <th></th>
                <th class="text-right">{{pedido.valorTotal | currency:'BRL'}}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>

  <button type="submit" class="btn btn-sm btn-primary btn-on-full float-right" (click)="salvar(form.value)">
    <i class="fal fa-check"></i>&nbsp;Confirmar
  </button>
  </form>
</app-modal>