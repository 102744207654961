<div class="container-fluid">
  <div class="row">
    <div class="col-2 col-lg-3 col-xl-2 background-sidenav-color background-side-nav"
      *ngIf="areAdmin() || areAcessor() || areOrgAdmin() || areVendedor()">
      <div class="row full-height justify-content-center">
        <div class="nav flex-column nav-pills w-100 h-100 mt-4 ml-3">
          <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
            <img class="img-fluid mb-4 mx-auto" src="assets/images/logo.png">
          </div>
          <div class="nav flex-column nav-pills w-100 padding-div">
            <a *ngFor="let menu of menus" class="nav-link zoom" [class.active]="isPageActive(menu.url)"
              [routerLink]="[menu.url]">
              <span class="d-flex d-lg-inline-flex justify-content-center">
                <i [class]="menu.icon"></i>
              </span>
              <span class="d-none d-lg-inline-flex">
                <span class="nav-gradient">&nbsp;{{menu.title}}</span>
              </span>
            </a>

            <a class="nav-link zoom" [routerLink]="['/reportados']" [class.active]="isPageActive('/reportados')"
              *ngIf="areAdmin()">
              <div class="position-relative">
                <span class="d-flex d-lg-inline-flex justify-content-center">
                  <i class="fal fa-exclamation-circle"></i>
                </span>
                <span class="d-none d-lg-inline-flex">
                  <span class="nav-gradient">&nbsp;Coment. Report.</span>
                </span>
                <span *ngIf="bolinhaReportados" class="text-danger position-absolute"
                  style="top: 0; right:0;">&nbsp;●</span>
              </div>
            </a>

            <!-- <a class="nav-link zoom" [routerLink]="['/denuncia/reportada']"
              [class.active]="isPageActive('/denuncia/reportada')" *ngIf="areAdmin()">
              <div class="position-relative">
                <span class="d-flex d-lg-inline-flex justify-content-center">
                  <i class="far fa-bullhorn"></i>
                </span>
                <span class="d-none d-lg-inline-flex">
                  <span class="nav-gradient">&nbsp;Denúncias Report.</span>
                </span>
                <span *ngIf="bolinhaDenunciasReportadas" class="text-danger position-absolute"
                  style="top: 0; right:0;">&nbsp;●</span>
              </div>
            </a> -->

            <a class="nav-link zoom" [routerLink]="['/postagem/reportada']"
              [class.active]="isPageActive('/postagem/reportada')" *ngIf="areAdmin()">
              <div class="position-relative">
                <span class="d-flex d-lg-inline-flex justify-content-center">
                  <i class="far fa-list-alt"></i>
                </span>
                <span class="d-none d-lg-inline-flex">
                  <span class="nav-gradient">&nbsp;Postagens Report.</span>
                </span>
                <span *ngIf="bolinhaDenunciasReportadas" class="text-danger position-absolute"
                  style="top: 0; right:0;">&nbsp;●</span>
              </div>
            </a>

            <a class="nav-link zoom" [routerLink]="['/usuario/reportada']"
            [class.active]="isPageActive('/usuario/reportada')" *ngIf="areAdmin()">
            <div class="position-relative">
              <span class="d-flex d-lg-inline-flex justify-content-center">
                <i class="far fa-user-times"></i>
              </span>
              <span class="d-none d-lg-inline-flex">
                <span class="nav-gradient">&nbsp;Usuarios Report.</span>
              </span>
              <span *ngIf="bolinhaDenunciasReportadas" class="text-danger position-absolute"
                style="top: 0; right:0;">&nbsp;●</span>
            </div>
          </a>

            <a class="nav-link zoom" [routerLink]="['/img-validacao']" [class.active]="isPageActive('/img-validacao')"
              *ngIf="areAdmin()">
              <div class="position-relative">
                <span class="d-flex d-lg-inline-flex justify-content-center">
                  <i class="far fa-images"></i>
                </span>
                <span class="d-none d-lg-inline-flex">
                  <span class="nav-gradient">&nbsp;Validação Imagens</span>
                </span>
                <span *ngIf="bolinhaDenunciados" class="text-danger position-absolute"
                  style="top: 0; right:0;">&nbsp;●</span>
              </div>
            </a>

            <!-- <div id="accordion">
              <a class="nav-link zoom text-white" data-toggle="collapse" data-target="#collapseOne" (click)="toggle()">
                <span class="d-flex d-lg-inline-flex justify-content-center">
                  <i class="fas fa-money-check-edit-alt"></i>
                  
                  <i class="fas fa-caret-right d-lg-none ml-3" *ngIf="!colapsado" data-toggle="collapse" data-target="#collapseOne"></i>
                  <i class="fas fa-sort-down d-lg-none ml-3" *ngIf="colapsado" data-toggle="collapse" data-target="#collapseOne"></i>
                </span>
                <span class="d-none d-lg-inline-flex">
                  <span class="nav-gradient">&nbsp;Gastos Públicos</span>
                </span>
                <i class="fas fa-caret-right float-right d-none d-lg-inline-flex mt-1" *ngIf="!colapsado"></i>
                <i class="fas fa-sort-down float-right d-none d-lg-inline-flex mt-1" *ngIf="colapsado"></i>
              </a>
            </div> -->

            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
              <a *ngFor="let gasto of gastos" class="nav-link zoom bg-secondary pl-4"
                [class.active]="isPageActive(gasto.url)" [routerLink]="[gasto.url]">
                <span class="d-flex d-lg-inline-flex justify-content-center">
                  <i [class]="gasto.icon"></i>
                </span>
                <span class="d-none d-lg-inline-flex">
                  <span class="nav-gradient">&nbsp;{{gasto.title}}</span>
                </span>
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="col mb-0 p-0 content" style="overflow-y: auto; overflow-x: hidden;">
      <nav class="navbar navbar-expand navbar-dark bg-navbar" style="z-index: 1;">
        <ul class="navbar-nav" *ngIf="!areAdmin() && !areVendedor()">
          <li class="nav-item ml-3">
            <a [routerLink]="['/']">
              <img class="img-fluid" src="assets/images/icone.png">
            </a>
          </li>
        </ul>
        <ul class="navbar-nav ml-auto" *ngIf="!isLoggedIn()">
        
          <li class="nav-item">
            <a class="nav-link" href="javascript:;" (click)="login()">
              <span><i class="fas fa-user"></i>&nbsp;Entrar</span>
            </a>
          </li>
        </ul>



        <ul class="navbar-nav ml-auto" *ngIf="isLoggedIn()">
          <!-- <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" href="javascript:;" (click)="abrirTermos()">
                <span><i class="fas fa-shield-check"></i>&nbsp;Política de Privacidade</span>
              </a>
            </li>
          </ul> -->

          <li class="nav-item">
            <a class="nav-link" style="padding: 11px;" ngbTooltip="Chats" placement="bottom-left" (click)="abrirChat()">
              <i class="fas fa-comment-alt-lines"></i>
              <i *ngIf="possuiChats" class="text-danger"
                style="position: relative; right: 7px; bottom: 7px;">&nbsp;●</i>
            </a>
          </li>

          <!-- Teste de service worker-->
          <!-- <button (click)="toggleSubscription()" class="btn btn-primary"
            [disabled]="notificationMiddleware.pushNotificationStatus.isInProgress || !notificationMiddleware.pushNotificationStatus.isSupported">
            <span *ngIf="!notificationMiddleware.pushNotificationStatus.isSupported">Push is NOT supported by this
              device</span>
            <span
              *ngIf="notificationMiddleware.pushNotificationStatus.isSupported && notificationMiddleware.pushNotificationStatus.isSubscribed">Unsubscribe</span>
            <span
              *ngIf="notificationMiddleware.pushNotificationStatus.isSupported && !notificationMiddleware.pushNotificationStatus.isSubscribed">Subscribe</span>
          </button>

          <ul class="list-group">
            <li class="list-group-item" *ngFor="let notif of notificationMiddleware.notifications">
              <a [routerLink]="cleanUrl(notif.Url)" (click)="removeNotif(notif)">{{notif.Title}}
                <small>{{notif.Message}}</small>
              </a>
            </li>
          </ul> -->
          <!-- fim -->

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" [routerLink]="[]" id="navbarDropdown" role="button"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img src="{{usuario?.imagem}}" class="img-adjustment" *ngIf="usuario?.imagem" /><i
                class="fal fa-user-circle" *ngIf="!usuario?.imagem"></i> &nbsp;<span>{{usuario?.nome}}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
              <a class="dropdown-item px-0" [routerLink]="['/perfil', usuario?.id]">
                <div class="col-12">
                  <div class="row">
                    <div class="col-3 text-center p-0">
                      <i class="fal fa-user-cog"></i>
                    </div>
                    <div class="col-9 text-left p-0">
                      Editar
                    </div>
                  </div>
                </div>
              </a>
              <a class="dropdown-item px-0" [routerLink]="['/alterar-senha']">
                <div class="col-12">
                  <div class="row">
                    <div class="col-3 text-center p-0">
                      <i class="fal fa-unlock-alt"></i>
                    </div>
                    <div class="col-9 text-left p-0">
                      Alterar senha
                    </div>
                  </div>
                </div>
              </a>
              <a class="dropdown-item px-0" [routerLink]="['/usuarios/bloqueados/', usuario?.id]">
                <div class="col-12">
                  <div class="row">
                    <div class="col-3 text-center p-0">
                      <i class="fal fa-user-lock"></i>
                    </div>
                    <div class="col-9 text-left p-0">
                      Bloqueados
                    </div>
                  </div>
                </div>
              </a>
              <a class="dropdown-item px-0" [routerLink]="['/politica-privacidade']">
                <div class="col-12">
                  <div class="row">
                    <div class="col-3 text-center p-0">
                      <i class="fal fa-shield-check"></i>
                    </div>
                    <div class="col-9 text-left p-0">
                      P. Privacidade
                    </div>
                  </div>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item px-0" [routerLink]="[]" (click)="logout()">
                <div class="col-12">
                  <div class="row">
                    <div class="col-3 text-center p-0">
                      <i class="fal fa-sign-out"></i>
                    </div>
                    <div class="col-9 text-left p-0">
                      Sair
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </nav>
      <div class="col-12 content content-page">
        <div class="row">
          <div class="container">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<audio #som controls [hidden]="true">
  <source src="./assets/sounds/notificacao.mp3" type="audio/mp3">
</audio>