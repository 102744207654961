import { Injectable } from '@angular/core';

@Injectable()
export class UfService {
    listar() {
        return [
            { value: 'Acre' },
            { value: 'Alagoas' },
            { value: 'Amapá' },
            { value: 'Amazonas' },
            { value: 'Bahia' },
            { value: 'Ceará' },
            { value: 'Distrito Federal' },
            { value: 'Espírito Santo' },
            { value: 'Goiás' },
            { value: 'Maranhão' },
            { value: 'Mato Grosso' },
            { value: 'Mato Grosso do Sul' },
            { value: 'Minas Gerais' },
            { value: 'Pará' },
            { value: 'Paraíba' },
            { value: 'Paraná' },
            { value: 'Pernambuco' },
            { value: 'Piauí' },
            { value: 'Rio de Janeiro' },
            { value: 'Rio Grande do Norte' },
            { value: 'Rio Grande do Sul' },
            { value: 'Rondonia' },
            { value: 'Roraima' },
            { value: 'Santa Catarina' },
            { value: 'São Paulo' },
            { value: 'Sergipe' },
            { value: 'Tocantins' }
        ];
    }
}