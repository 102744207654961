<div style="width: 100vw; height: 100vh;" style="position: fixed; top: 0; left: 0; z-index: 0; overflow: hidden;">
  <video #video *ngIf="stream" [muted]="true" class="video-fullscreen"></video>
  <video #remoteVideo *ngIf="remoteStream" autoplay class="video-fullscreen"></video>
</div>

<div class="d-flex flex-column justify-content-between"
  style="width: 100vw; height: 85vh; margin-top: 10vh; position: fixed; top: 0; left: 0; z-index: 2;">
  <div class="row">
    <div class="col-4 pl-4 text-left">
      <span class="h5 text-white w-75" style="text-shadow: 2px 2px 4px rgba(0,0,0,0.3); text-overflow: clip;">
        {{usuario?.nome}}
      </span>
    </div>

    <div class="col-4 text-center">
      <div>
        <span class="h4 text-white" [class.live-text]="isStarted && !isLoading" [class.h5]="isStarted && !isLoading">
          <span *ngIf=" isLoading">Carregando...</span>
          <span *ngIf="!isLoading">
            <span *ngIf="!isStarted">Transmissão ao vivo</span>
            <span *ngIf="isStarted">Ao vivo</span>
          </span>
        </span>
      </div>
    </div>

    <div class="col-4 pr-4 text-right">
      <span class="h5 text-white text-truncate" style="text-shadow: 2px 2px 4px rgba(0,0,0,0.3);" *ngIf="isStarted"
        ngbTooltip="Usuários assistindo">
        {{usersWatching}}<i class="fas fa-eye ml-2"></i>
      </span>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-3 mx-3" *ngIf="!isLoading">
    <!--Iniciar live-->
    <button type="button" class="btn btn-primary rounded-circle" ngbTooltip="Iniciar transmissão"
      style="min-width: 48px; min-height: 48px; width: 48px; height: 48px;" *ngIf="!isStarted" (click)="initLive()">
      <i class="fas fa-signal-stream text-white"></i>
    </button>

    <!--Câmera-->
    <button type="button" class="btn btn-primary rounded-circle mr-4"
      style="min-width: 48px; min-height: 48px; width: 48px; height: 48px;" ngbTooltip="Alternar câmera"
      (click)="switchCamera()" *ngIf="isStarted && canSwitchCamera && !isWatching">
      <i class="fas fa-sync text-white"></i>
    </button>

    <!--Finalizar live-->
    <button type="button" class="btn btn-danger rounded-circle mr-4"
      style="min-width: 48px; min-height: 48px; width: 48px; height: 48px;"
      [ngbTooltip]="(isWatching ? 'Sair da' : 'Finalizar') + ' transmissão'" (click)="end()" *ngIf="isStarted">
      <i class="fas  text-white" [class]="isWatching ? 'fa-times' : 'fa-stop'"></i>
    </button>

    <!--Microfone-->
    <button type="button" class="btn btn-primary rounded-circle"
      style="min-width: 48px; min-height: 48px; width: 48px; height: 48px;"
      [ngbTooltip]="(isMuted ? 'Habilitar' : 'Desabilitar') + ' microfone'" (click)="toggleMute()"
      *ngIf="isStarted && !isWatching">
      <i class="fas fa-microphone-slash text-white" *ngIf="isMuted"></i>
      <i class="fas fa-microphone text-white" *ngIf="!isMuted"></i>
    </button>
  </div>
</div>