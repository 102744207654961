import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Denuncia } from '../models';

@Directive()
@Injectable()
export class DenunciaService {
    constructor(private http: HttpClient) { }

    denuncia: Denuncia;
    @Output() tiraBola = new EventEmitter();
    @Output() tiraBolaDenuncia = new EventEmitter();
    @Output() atualiza = new EventEmitter();

    listar(pesquisa?: string): Observable<Denuncia[]> {
        let params: HttpParams;
        if (pesquisa) {
            params = new HttpParams().set('query', pesquisa);
        }
        return this.http.get<Denuncia[]>(`${environment.api}/denuncias`, { params: params });
    }

    
    aprovando(): Observable<Denuncia[]> {
        return this.http.get<Denuncia[]>(`${environment.api}/denuncias/aprovando`);
    }

    encontrar(id: string): Observable<Denuncia> {
        return this.http.get<Denuncia>(`${environment.api}/denuncias/${id}`);
    }

    salvar(denuncia: Denuncia): Observable<Denuncia> {
        return this.http.post<Denuncia>(`${environment.api}/denuncias`, denuncia);
    }

    remover(id: string): Observable<Denuncia> {
        return this.http.delete<Denuncia>(`${environment.api}/denuncias/${id}`);
    }

    reportados(): Observable<Denuncia[]> {
        return this.http.get<Denuncia[]>(`${environment.api}/denuncias/reportadas`);
    }

    tiraBolinhaImagens() {
        this.tiraBola.emit();
    }

    tiraBolinhaDenuncia() {
        this.tiraBolaDenuncia.emit();
    }

    atualizaDenuncias() {
        this.atualiza.emit();
    }
}