import { Component, OnInit } from '@angular/core';
import { Denuncia, Politico } from 'src/app/models';
import { DenunciaService, PoliticoService } from 'src/app/services';
import { UfService } from 'src/app/shared/uf.service';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-denuncia-lista',
  templateUrl: './denuncia-lista.component.html',
  styleUrls: ['./denuncia-lista.component.scss']
})
export class DenunciaListaComponent implements OnInit {

  denuncias: Denuncia[];
  politicos: Politico[];
  ufs: any[];
  mensagemListaVazia: boolean = false;
  statusProp: string;
  filtroForm: UntypedFormGroup;
  id: string;

  constructor(
    private denunciaService: DenunciaService,
    private politicoService: PoliticoService,
    private ufService: UfService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.listar();

    this.filtroForm = this.formBuilder.group({
      politicoId: [null],
      uf: [null],
      pesquisa: [null],
    });

    // Listar Adicionais
    this.ufs = this.ufService.listar();
    this.politicoService.listar()
      .subscribe(politicos => this.politicos = politicos);

    // Verificar se esta editando o politico
    this.id = this.route.snapshot.fragment;
    if (this.id != null) {
      this.filtroForm.controls.politicoId.setValue(this.id);
      this.location.replaceState('/denuncias');
    };
  }

  listar() {
    this.denunciaService.aprovando()
      .subscribe(denuncias => {
        this.denuncias = denuncias.filter(x => x.midias.length > 0);
        if (denuncias.length < 1) {
          this.mensagemListaVazia = true;
        }
      });
  }

  pesquisar(pesquisa: string) {
    this.denunciaService.listar(pesquisa)
      .subscribe(denuncias => this.denuncias = denuncias);
  }
}
