import { Vendedor } from "./vendedor.model";
import { Comentario } from "./comentario.model";
import { Midia } from "./midia.model";
import { StatusPedido } from "../enum/status-pedido";

export class Produto {
    id: string;
    titulo: string;
    imagem: string;
    descricao: string;
    quantidade: number;
    valor: number;
    valorPromocao: number;
    data: Date;
    reportado: boolean;
    verificado: boolean;

    vendedorId: string;
    vendedor: Vendedor;

    midias: Midia[] = [];
    comentarios: Comentario[] = [];
}