import { Component, OnInit, Input } from '@angular/core';
import { Denuncia } from 'src/app/models';

@Component({
  selector: 'app-denuncia',
  templateUrl: './denuncia.component.html',
  styleUrls: ['./denuncia.component.scss']
})
export class DenunciaComponent implements OnInit {

  @Input() denuncias: Denuncia[];
  @Input() gostei?: boolean;
  
  constructor() { }

  ngOnInit() {
  }

}
