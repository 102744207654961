import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Estado, Cidade, Bairro, Endereco } from '../models';
import { environment } from 'src/environments/environment.prod';
import { AddressResult } from '../models/address-result.model';

@Injectable()
export class OnMapsService {
    constructor(private http: HttpClient) { }

    estados(): Observable<Estado[]> {
        return this.http.get<Estado[]>(`${environment.mapsApi}/estados`);
    }

    cidades(uf: string): Observable<Cidade[]> {
        return this.http.get<Cidade[]>(`${environment.mapsApi}/cidades/${uf}`);
    }

    bairros(uf: string, cidade: string): Observable<Bairro[]> {
        return this.http.get<Bairro[]>(`${environment.mapsApi}/bairros/${uf}/${cidade}`);
    }

    getLocalizacao(latitude: number, longitude: number): Observable<AddressResult> {
        return this.http.get<AddressResult>(`${environment.geocodeApi}/reverse?lat=${latitude}&lon=${longitude}`);
    }
}