import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Usuario } from 'src/app/models';
import { UsuarioService } from 'src/app/services/usuario.service';
import { NotificationService } from 'src/app/shared/messages/notification.service';
import { ModalRemoverComponent } from 'src/app/shared/modal/modal-remover/modal-remover.component';

@Component({
  selector: 'app-usuarios-denunciados',
  templateUrl: './usuarios-denunciados.component.html',
  styleUrls: ['./usuarios-denunciados.component.scss']
})
export class UsuariosDenunciadosComponent implements OnInit {
  reportados: Usuario[];
  mensagemListaVazia: boolean;

  constructor(
    private denunciaService: UsuarioService,
    private modalService: NgbModal,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.listar();
  }

  desabilidar(reportado: Usuario) {
    const modalRef = this.modalService.open(ModalRemoverComponent, { centered: true });
    modalRef.componentInstance.mensagem = `Deseja realmente desabilitar este usuário reportado?`;
    modalRef.componentInstance.titulo = `Desabilitar`;

    modalRef.result.then(confirmaRemocao => {
      if (confirmaRemocao) {
        reportado.verificado = true;

        this.denunciaService.salvar(reportado).subscribe(_ => {
          this.notificationService.notifty(`O usuário foi desabilitado com sucesso.`);
          this.listar();
        });
      }
    }, dismiss => { });
  }

  restaurar(reportado: Usuario) {
    reportado.reportado = false;
    reportado.verificado = true;
    this.denunciaService.salvar(reportado).subscribe(() => {
      this.notificationService.notifty(`O usuário reportado foi restaurado com sucesso!`);
      this.listar();
    })
  }

  listar() {
    this.denunciaService.reportados().subscribe(reportados => {
      this.reportados = reportados;

      this.mensagemListaVazia = reportados.length < 1;
    })
  }
}
