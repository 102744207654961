<nav aria-label="breadcrumb ">
  <ol class="breadcrumb b-radius breadcrumb-appckground mt-3">
    <a class="breadcrumb-item mt-1" aria-current="page" [routerLink]="['/organizacoes']"><i
        class="far fa-building"></i>&nbsp;Organização</a>
    <li class="breadcrumb-item active breadcrumb-link-actual mt-1" aria-current="page">Adicionar</li>
    <li class="ml-auto">
      <button type="button" class="btn btn-sm btn-outline-primary btn-on" [routerLink]="['/organizacoes']">
        <i class="fal fa-list"></i>
        <span class="d-none d-lg-inline-flex">&nbsp;Listar</span>
      </button>
    </li>
  </ol>
</nav>

<form [formGroup]="organizacaoForm" novalidate autocomplete="off">
  <input type="hidden" formControlName="id" />
  <div class="row">
    <div class="col-lg-9 order-2 order-lg-1">
      <div class="row">
        <div class="col-12 col-md-6">
          <app-input label="Nome" errorMessage="Campo obrigatório." [isRequired]="true">
            <input #firstElement type="text" formControlName="nome" class="form-control form-control-sm"
              placeholder="Nome da organização" />
          </app-input>
        </div>

        <div class="col-6 col-md-3">
          <app-input label="Estado" [isRequired]="true">
            <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true" formControlName="uf"
              (change)="filtrar(filtroEnum.Estado)">
              <ng-option value="">Selecione o estado</ng-option>
              <ng-option *ngFor="let uf of ufs" [value]="uf.uf">{{uf.nome}}</ng-option>
            </ng-select>
          </app-input>
        </div>

        <div class="col-6 col-md-3">
          <app-input label="Cidade">
            <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true" formControlName="cidade"
              (change)="filtrar(filtroEnum.Cidade)">
              <ng-option value="">Selecione a cidade</ng-option>
              <ng-option *ngFor="let cidade of cidades" [value]="cidade.nome">{{cidade.nome}}</ng-option>
            </ng-select>
          </app-input>
        </div>

        <div class="col-12" *ngIf="areAdmin">
          <button type="button" class="btn btn-sm btn-outline-primary mb-3" (click)="addEmails(null)">
            <i class="fal fa-plus-circle"></i>
            &nbsp;Adicionar Administradores</button>
        </div>

        <div class="col-12" *ngIf="areAdmin">
          <div class="row" *ngFor="let email of getEmailsFormArray().controls; let i = index;">
            <div class="col-10">
              <div formArrayName="emails">
                <div [formGroupName]="i">
                  <app-input label="E-mail">
                    <input type="text" formControlName="endereco" class="form-control form-control-sm"
                      placeholder="email@email.com" />
                  </app-input>
                </div>
              </div>
            </div>

            <div class="col-2">
              <button type="button" class="btn btn-sm btn-outline-danger mt-4" (click)="removeEmail(i)">
                <i class="fal fa-minus-circle"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3 order-1 order-lg-2">
      <div class="row">
        <div class="col">
          <app-input label="Ícone da organização" labelAlign="text-center" errorMessage="Campo obrigatório.">
            <app-img formControlName="imagem" icone="fas fa-align-left"></app-img>
          </app-input>
        </div>
      </div>
    </div>
  </div>

  <button type="submit" class="btn btn-sm btn-primary btn-on-full mr-2" (click)="salvar(organizacaoForm.value)"
    [disabled]="areSubmitting || this.organizacaoForm.invalid">
    <i class="fal fa-check"></i>&nbsp;Salvar
  </button>

  <button type="button" class="btn btn-sm btn-outline-danger btn-on float-right" (click)="remover()"
    *ngIf="organizacaoForm.controls.id.value != null && areAdmin">
    <i class="fal fa-trash"></i>
    <span class="d-none d-lg-inline-flex">&nbsp;Remover</span>
  </button>
</form>
