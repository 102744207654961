<div class="row mb-3 p-3">
  <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
    <app-denuncia-item [denuncia]="denuncia" [mostrarDetalhes]=true></app-denuncia-item>
  </div>
  <div class="col-sm-1">
    <div class="btn-fixed">
      <button type="button" class="btn btn-secondary btn-radius" placement="top" ngbTooltip="Voltar" [routerLink]="['/feed/denuncias']">
        <i class="fal fa-arrow-to-left"></i>
      </button>
    </div>
  </div>
</div>
