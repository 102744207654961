import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChatService } from 'src/app/services/chat.service';
import { NotificationService } from 'src/app/shared/messages/notification.service';
import { Chat } from 'src/app/models/chat.model';
import { LoginService } from 'src/app/services';

@Component({
  selector: 'app-modal-remover-mensagem',
  templateUrl: './modal-remover-mensagem.component.html',
  styleUrls: ['./modal-remover-mensagem.component.scss']
})
export class ModalRemoverMensagemComponent implements OnInit {

@Input()chat: Chat;

  public mensagem: string;
  public podeRemover: boolean = true;

  constructor(
    private _modalRef: NgbActiveModal,
    private _service: ChatService,
    private _notificationService: NotificationService,
    private _loginService: LoginService,
    ) { }

  ngOnInit() {
    this.desabilitarRemoverParaTodos();
  }

  removerParaMim() {
    this.chat.usuarioDeletouId = this._loginService.usuario.id;
    this._service.salvar(this.chat).subscribe(_ => {
      this._notificationService.notifty(`A mensagem foi deletada.`);
      this._modalRef.close();
    });
  }
  
  removerParaTodos() {
    this._service.remover(this.chat.id).subscribe(_ => {
      this._notificationService.notifty(`A mensagem foi deletada.`);
      this._modalRef.close();
    }) 
  }

  cancelar() {
    this._modalRef.dismiss();
  }

  desabilitarRemoverParaTodos(){
    if(this.chat.destinatarioId == this._loginService.usuario.id)
      this.podeRemover = false;
  }
}
