<div class="p-3">
  <div class="text-center">
    <form [formGroup]="loginForm" novalidate autocomplete="off" *ngIf="estaLogando && !estaRecuperando">
      <img src="assets/images/logo.png" style="width: 50%;" class="img-fluid mx-auto p-0" />
      <hr />
      <input type="hidden" formControlName="id" />
      <app-input label="Login" errorMessage="Campo obrigatório." [isRequired]="true">
        <input #firstElement type="text" formControlName="email" class="form-control form-control-sm"
          placeholder="Usuário" />
      </app-input>
      <app-input label="Senha" errorMessage="Campo obrigatório." [isRequired]="true">
        <input type="password" formControlName="senha" class="form-control form-control-sm" placeholder="Senha" />
      </app-input>

      <div style="margin-top: -10px;">
        <small><a [routerLink]="[]" class="pl-3 text-left" (click)="abrirModal()">Esqueceu sua senha?</a></small>
        <br />  
       
      </div>

      <hr />
      <div class="clearfix">
        <div class="row">
          <div class="col">
            <button type="submit" class="btn btn-sm btn-primary btn-block" (click)="logon(loginForm.value)"
              [disabled]="areSubmitting || loginForm.invalid">
              <i class="fal fa-check"></i>&nbsp;Entrar</button>
          </div>

          <div class="col">
            <button type="button" class="btn btn-sm btn-outline-danger btn-block" (click)="fecharModal()">
              <i class="fal fa-ban"></i>&nbsp;Cancelar</button>
          </div>
        </div>
        <p class="text-muted mt-2">ou</p>
        <button type="button" class="btn btn-sm btn-fb btn-block" (click)="logonFacebook()"> <i
            class="fab fa-facebook-f"></i>&nbsp;&nbsp;Entrar
          com Facebook</button>
        <button type="button" class="btn btn-sm btn-secondary btn-block" (click)="cadastrar()"><i
            class="fal fa-clipboard-list"></i>&nbsp;&nbsp;
          Quero me Cadastrar</button>
          <small><a [routerLink]="['/politica-privacidade']" class="pl-3 mt-3 d-block text-center" (click)="abrirTermos()">Política de
            Privacidade</a></small>
      </div>
    </form>
    <form [formGroup]="cadastroForm" novalidate autocomplete="off" *ngIf="!estaLogando && !estaRecuperando">
      <h4>Registrar</h4>
      <hr />
      <input type="hidden" formControlName="id" />
      <div class="row">

        <div class="col-12">
          <app-input label="Imagem do usuário" labelAlign="text-center">
            <app-img formControlName="imagem" icone="fas fa-user"></app-img>
          </app-input>

          <app-input label="Nome" errorMessage="Campo obrigatório." [isRequired]="true">
            <input #firstElement type="text" formControlName="nome" class="form-control form-control-sm"
              placeholder="Nome do usuário" />
          </app-input>

          <app-input label="E-mail" errorMessage="Campo obrigatório." [isRequired]="true">
            <input type="email" formControlName="email" class="form-control form-control-sm"
              placeholder="email@email.com.br" />
          </app-input>

          <app-input label="Telefone" errorMessage="Campo obrigatório." [isRequired]="true">
            <input type="tel" formControlName="telefone" [textMask]="{mask: telefoneMovelMask, guide: false}"
              class="form-control form-control-sm" placeholder="Telefone" />
          </app-input>

          <app-input label="Senha" errorMessage="Campo obrigatório." [isRequired]="true">
            <input type="password" formControlName="senha" class="form-control form-control-sm" placeholder="Senha" />
          </app-input>
        </div>

        <div class="col-6">
          <button type="submit" class="btn btn-sm btn-primary btn-block" (click)="salvar(cadastroForm.value)"
            [disabled]="areSubmitting || cadastroForm.invalid">
            <i class="fal fa-check"></i>&nbsp;Salvar</button>
        </div>

        <div class="col-6">
          <button type="button" class="btn btn-sm btn-outline-danger btn-block" (click)="voltar()">
            <i class="fal fa-arrow-left"></i>&nbsp;Voltar</button>
        </div>

      </div>
    </form>
    <form [formGroup]="recuperacaoForm" novalidate autocomplete="off" *ngIf="estaRecuperando">
      <h4>Recuperar senha</h4>
      <hr />

      <div class="row">

        <div class="col-12">
          <app-input label="E-mail" errorMessage="Campo obrigatório." [isRequired]="true" *ngIf="!emailVerificado">
            <input type="email" formControlName="email" class="form-control form-control-sm"
              placeholder="E-mail do usuário" />
          </app-input>


          <div *ngIf="emailVerificado">
            <label class="float-left" style="margin-bottom: -10px;">E-mail <span
                style="color:#dc3545 !important; font-weight: bold;">*</span></label>
            <br />
            <label
              class="form-control form-control-sm float-left text-left mb-4">{{this.recuperacaoForm.controls.email.value}}</label>

            <app-input label="Código de Alteração" errorMessage="Campo obrigatório." [isRequired]="true">
              <input type="text" formControlName="codigoAlteracao" class="form-control form-control-sm"
                placeholder="Código" />
            </app-input>

            <app-input label="Nova senha" errorMessage="Preencha com 6 ou mais caracteres." [isRequired]="true">
              <input type="password" formControlName="novaSenha" class="form-control form-control-sm"
                placeholder="******" />
            </app-input>

            <app-input label="Confirmar nova senha" errorMessage="Preencha com 6 ou mais caracteres."
              [isRequired]="true">
              <input type="password" formControlName="novaSenhaConfirmar" class="form-control form-control-sm"
                placeholder="******" />
            </app-input>
            <small class="text-danger" *ngIf="recuperacaoForm.errors && recuperacaoForm.errors['senhasNaoCoincidem']">
              <i class="fal fa-exclamation-triangle"></i> &nbsp;As senhas não coincidem.
            </small>
          </div>

        </div>

        <div class="col-6">
          <button type="submit" class="btn btn-sm btn-primary btn-block" (click)="enviar()"
            [disabled]="recuperacaoForm.controls.email.invalid" *ngIf="!emailVerificado">
            <i class="fal fa-check"></i>&nbsp;Enviar código</button>

          <button type="submit" class="btn btn-sm btn-primary btn-block" (click)="recuperar()"
            [disabled]="recuperacaoForm.invalid" *ngIf="emailVerificado">
            <i class="fal fa-check"></i>&nbsp;Recuperar</button>
        </div>

        <div class="col-6">
          <button type="button" class="btn btn-sm btn-outline-danger btn-block" (click)="voltar()">
            <i class="fal fa-arrow-left"></i>&nbsp;Voltar</button>
        </div>

      </div>
    </form>
  </div>
</div>