import { Component, OnInit } from '@angular/core';
import { Beneficiario } from 'src/app/models';
import { BeneficiarioService, LoginService } from 'src/app/services';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-beneficiario-lista',
  templateUrl: './beneficiario-lista.component.html',
  styleUrls: ['./beneficiario-lista.component.scss']
})
export class BeneficiarioListaComponent implements OnInit {

  beneficiarios: Beneficiario[] = [];
  beneficiariosBkp: Beneficiario[] = [];
  mensagemListaVazia: boolean = false;
  id: string;
  areAdmin: boolean;
  filtroForm: UntypedFormGroup;
  RamoAtividade: string;
  ufs: any[];
  disableTodosBeneficiarios = false;

  constructor(
    private beneficiarioService: BeneficiarioService,
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    this.areAdmin = this.loginService.areAdmin();

    if (this.areAdmin) {
      this.listar();
    }
    else if (this.loginService.usuario.organizacoes.length > 0) {
      this.beneficiarioService.listar()
        .subscribe(beneficiarios => {
          this.loginService.usuario.organizacoes.forEach(organizacaoResp => {
            this.beneficiarios = beneficiarios.filter(x => x.organizacaoId == organizacaoResp.id)
          });

          this.beneficiariosBkp = this.beneficiarios

          this.mensagemListaVazia = beneficiarios.length == 0;
        });
    }
    else {
      this.disableTodosBeneficiarios = true;
    }
    this.filtroForm = this.formBuilder.group({
      pesquisa: [null],
    });
  }

  listar() {
    this.beneficiarioService.listar()
      .subscribe(beneficiarios => {
        this.beneficiarios = beneficiarios
        this.beneficiariosBkp = beneficiarios

        this.mensagemListaVazia = beneficiarios.length == 0;
      });
  }

  pesquisar(pesquisa: string) {
    this.beneficiarioService.listar(pesquisa)
      .subscribe(beneficiarios => this.beneficiarios = beneficiarios);
  }

  filtrar(any) {
    var filtro = this.filtroForm.value;
    this.beneficiarios = this.beneficiariosBkp;

    this.beneficiarios = this.beneficiarios.filter(x => x.nome.toLocaleLowerCase().indexOf(filtro.pesquisa.toLocaleLowerCase()) > -1 || x.ramoAtividade.toLocaleLowerCase().indexOf(filtro.pesquisa.toLocaleLowerCase()) > -1 || x.uf.toLocaleLowerCase().indexOf(filtro.pesquisa.toLocaleLowerCase()) > -1 || x.cidade.toLocaleLowerCase().indexOf(filtro.pesquisa.toLocaleLowerCase()) > -1 || x.telefone.toLocaleLowerCase().indexOf(filtro.pesquisa.toLocaleLowerCase()) > -1);

    this.mensagemListaVazia = this.beneficiarios.length == 0;
  }
}
