import { Component, OnInit } from '@angular/core';
import { Organizacao } from 'src/app/models';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { OrganizacaoService, LoginService, BeneficiarioService } from 'src/app/services';

@Component({
  selector: 'app-organizacao-lista',
  templateUrl: './organizacao-lista.component.html',
  styleUrls: ['./organizacao-lista.component.scss']
})
export class OrganizacaoListaComponent implements OnInit {

  organizacoes: Organizacao[] = [];
  organizacoesBkp: Organizacao[] = [];
  mensagemListaVazia: boolean = false;
  id: string;
  areAdmin: boolean;
  filtroForm: UntypedFormGroup;
  Administradores: string;
  ufs: any[];
  disableTodasOrganizacoes = false;

  constructor(
    private organizacaoService: OrganizacaoService,
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private beneficiarioService: BeneficiarioService
  ) { }

  ngOnInit() {
    this.areAdmin = this.loginService.areAdmin();

    if (this.areAdmin) {
      this.listar();
    }
    else if (this.loginService.usuario.organizacoes.length > 0) {
      this.organizacaoService.listar()
        .subscribe(organizacoes => {
          let bene;

          this.beneficiarioService.listar()
            .subscribe(beneficiarios => {
              this.loginService.usuario.organizacoes.forEach(organizacaoResp => {
                bene = beneficiarios.filter(x => x.organizacaoId == organizacaoResp.id)
              });
              organizacoes = organizacoes.filter(x => x.beneficiarios == bene);
            });

          this.organizacoes = organizacoes
          this.organizacoesBkp = organizacoes

          this.mensagemListaVazia = organizacoes.length == 0;
        });
    }
    else {
      this.disableTodasOrganizacoes = true;
    }
    this.filtroForm = this.formBuilder.group({
      pesquisa: [null],
    });
  }

  listar() {
    this.organizacaoService.listar()
      .subscribe(organizacoes => {
        this.organizacoes = organizacoes
        this.organizacoesBkp = organizacoes

        this.mensagemListaVazia = organizacoes.length == 0;
      });
  }

  pesquisar(pesquisa: string) {
    this.organizacaoService.listar(pesquisa)
      .subscribe(organizacoes => this.organizacoes = organizacoes);
  }

  filtrar(any) {
    var filtro = this.filtroForm.value;
    this.organizacoes = this.organizacoesBkp;

    this.organizacoes = this.organizacoes.filter(x => x.nome.toLocaleLowerCase().indexOf
      (filtro.pesquisa.toLocaleLowerCase()) > -1 || x.uf.toLocaleLowerCase().indexOf
        (filtro.pesquisa.toLocaleLowerCase()) > -1 || x.cidade.toLocaleLowerCase().indexOf
          (filtro.pesquisa.toLocaleLowerCase()) > -1);

    this.mensagemListaVazia = this.organizacoes.length == 0;
  }
}
