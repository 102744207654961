import { Midia } from "./midia.model";

export class Denuncia {
    id: string;
    usuarioId: string;
    titulo: string;
    data: Date;
    uf: string;
    cidade: string;
    midias: Midia[];
    bairro: string;
    localizacao: string;
    descricao: string;
    gostei?: boolean;
    verificado: boolean;
    reportado: boolean;
}