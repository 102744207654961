import { Usuario, Denuncia } from ".";
import { TipoMidia } from "../enum";

export class Midia {
    id: string;
    denunciaId: string;
    usuarioId: string;
    url: string;
    aprovada: boolean;
    dataAprovacao: Date;
    denuncia: Denuncia;
    usuario: Usuario
    tipoMidia: TipoMidia
}