import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CentroCusto, Organizacao } from 'src/app/models';
import { TipoCentroCusto } from 'src/app/enum';
import { CentroCustoService, LoginService, OnMapsService, OrganizacaoService } from 'src/app/services';
import { NotificationService } from 'src/app/shared/messages/notification.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-centro-custo',
  templateUrl: './centro-custo.component.html',
  styleUrls: ['./centro-custo.component.scss']
})
export class CentroCustoComponent implements OnInit {

  centroCusto: CentroCusto;
  centroCustoForm: UntypedFormGroup;
  areSubmitting: boolean;
  id: string;
  areAdmin: boolean;
  organizacoes: Organizacao[];
  tipoCentroCusto = TipoCentroCusto;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private centroCustoService: CentroCustoService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private organizacaoService: OrganizacaoService,
  ) { }

  ngOnInit() {

    this.areAdmin = this.loginService.areAdmin();

    this.centroCustoForm = this.formBuilder.group({
      id: [null],
      nome: ['', Validators.required],
      codigo: ['', Validators.required],
      organizacaoId: ['', Validators.required],
      tipoCentroCusto: [TipoCentroCusto.Analitico],
    });

    // Verificar se esta editando a centroCusto
    this.id = this.route.snapshot.params['id'];
    if (this.id != null) {
      this.centroCustoService.encontrar(this.id).subscribe(centroCusto => {
        this.centroCustoForm.patchValue(centroCusto);
      });
    }

    // Listar Adicionais
    if (this.areAdmin)
      this.organizacaoService.listar().subscribe(organizacoes => this.organizacoes = organizacoes);
    else {
      this.organizacoes = this.loginService.usuario.organizacoes;
    }
  }

  salvar(centroCusto: CentroCusto) {
    this.areSubmitting = true;
    this.centroCustoService.salvar(centroCusto)
      .subscribe(resp => {
        this.notificationService.notifty(`O Centro de Custo ${centroCusto.nome} foi salvo com sucesso.`);
        this.resetForm();
        this.areSubmitting = false;
      }, error => this.areSubmitting = false);
  }

  remover() {
    this.centroCustoService.remover(this.id).subscribe(_ => {
      this.notificationService.notifty(`O Centro de Custo ${this.centroCustoForm.value.nome} foi removido.`);
      this.resetForm();
    });
  }

  resetForm() {
    this.router.navigate(['/centro-custos']);
  }
}

