<button type="button" #hackButton class="d-none">
  NÃO REMOVER ESTE BOTÃO!!!
  Este botão deve existir para fazer com que a caixa de seleção de imagens não seja aberta ao clicar em qualquer parte
  do componente.
</button>
<input #fileInput id="fileInput" type="file" accept="image/*,video/*" (change)="addFile()"
  style="display: none;" />
<div class="text-center avatar-container" style="position: relative;">
  <div class="img-circle avatar-wrapper" [class.avatar-radius]="!square"
    [class.avatar-wrapper-error]="!isValid() && isRequired()">
    <div class="avatar-flex" *ngIf="!isValid()">
      <i class="{{icone}} text-center avatar-icon-size"></i>
    </div>
    <div *ngIf="!isLoading">
      <img [src]="url" class="avatar" *ngIf="isValid() && !isVideo" />
      <img src="./assets/images/video_fundo.png" class="avatar" *ngIf="isValid() && isVideo" />
    </div>
    <div class="fa-3x text-success" *ngIf="isLoading">
      <i class="fas fa-circle-notch fa-spin"></i>
    </div>
  </div>
  <button type="button" (click)="changeFile()" class="btn color-icon avatar-btn" tabindex="-1">
    <i class="fal fa-camera text-primary"></i>
  </button>
  <div class="dropdown" ngbDropdown style="position: absolute; right: 0;">
    <a [routerLink]="[]" class="btn-invisible" #dropdown ngbDropdownToggle></a>
    <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
      <li class="dropdown-item"> <a [routerLink]="[]" (click)="openFile()">
          <i class="fal fa-sync"></i> Alterar</a></li>
      <li class="dropdown-item"> <a [routerLink]="[]" (click)="removeFile()">
          <i class="fal fa-trash-alt"></i> Remover</a></li>
    </ul>
  </div>
</div>