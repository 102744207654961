<app-modal title="Comentário">
  <div class="col py-1">
    <div class="player-holder float-left pt-2">
      <div class="player-thumb" *ngIf="comentario.usuario.imagem != null" [ngStyle]="{ 'background-image': 'url(' + comentario.usuario.imagem + ')'}"></div>
      <div class="player-thumb fal fa-user-circle fa-2x" *ngIf="comentario.usuario.imagem == null"></div>
    </div>

    <div class="pl-5">

      <small class="text-primary mr-1" *ngIf="comentarioVerificado">
        <i class="fas fa-check-circle"></i></small>

      <span class="card-text" *ngIf="!toggle">{{comentario.texto}}</span>

      <span class="card-text" *ngIf="toggle">Comentário</span>
      <form [formGroup]="comentarioForm" novalidate autocomplete="off" *ngIf="toggle">
        <textarea type="text" formControlName="texto" class="form-control form-control-sm" rows="3"></textarea>
      </form>

      <br />
      <small class="text-dark">Por: {{comentario.usuario.nome}} - {{comentario.dataHora | date:'dd/MM/yyyy'}}</small>
      <br />
      <small class="text-muted" *ngIf="comentario.reportado" style="font-size: 12px">Denunciado, sob investigação</small>
      <hr class="my-1" />
    </div>
  </div>
  <br />
  <button type="submit" class="btn btn-sm btn-outline-primary btn-on-full mb-2 float-left" *ngIf="showIcones && meuComentario"
    (click)="editar()">
    <i class="fal fa-edit"></i>&nbsp;Editar</button>

  <button type="submit" class="btn btn-sm btn-outline-danger btn-on-full mb-2 float-right" *ngIf="showIcones && meuComentario"
    (click)="remover()">
    <i class="fal fa-trash-alt"></i>&nbsp;Remover</button>

  <button type="submit" class="btn btn-sm btn-outline-primary btn-on-full mb-2 float-left" *ngIf="showCancelar" (click)="salvar()">
    <i class="fal fa-check"></i>&nbsp;Salvar</button>

  <button type="submit" class="btn btn-sm btn-outline-danger btn-on-full mb-2 float-right" *ngIf="showCancelar" (click)="cancelar()">
    <i class="fal fa-ban"></i>&nbsp;Cancelar</button>

  <button type="submit" class="btn btn-sm btn-outline-primary btn-on-full mb-2 float-left" *ngIf="showIcones && !meuComentario"
    (click)="bloquear()">
    <i class="fal fa-ban"></i>&nbsp;Bloquear</button>

  <button type="submit" class="btn btn-sm btn-outline-primary btn-on-full mb-2 float-right" *ngIf="showIcones && !meuComentario && !comentarioVerificado"
    (click)="denunciar()">
    <i class="fal fa-exclamation-circle"></i>&nbsp;Denunciar</button>
</app-modal>
