import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { LoginService } from 'src/app/services';
import { UsuarioService } from 'src/app/services/usuario.service';
import { NotificationService } from 'src/app/shared/messages/notification.service';
import { AlterarSenha } from 'src/app/models';

@Component({
  selector: 'app-usuario-alterar-senha',
  templateUrl: './usuario-alterar-senha.component.html',
  styleUrls: ['./usuario-alterar-senha.component.scss']
})
export class UsuarioAlterarSenhaComponent implements OnInit {

  passwordForm: UntypedFormGroup;
  areSubmitting: boolean;
  mostrarSenhaAtual: boolean = true;

  @ViewChild('firstElement') firstElementRef: ElementRef;
  @ViewChild('secondElement') secondElementRef: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private usuarioService: UsuarioService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.mostrarSenhaAtual = this.loginService.usuario.temSenha || this.loginService.usuario.temSenha && this.loginService.usuario.googleId != null || this.loginService.usuario.temSenha && this.loginService.usuario.facebookId != null;

    if (this.mostrarSenhaAtual) {
      this.firstElementRef.nativeElement.focus();
    } else {
      this.secondElementRef.nativeElement.focus();
    }
    this.passwordForm = this.formBuilder.group({
      senhaAtual: [null],
      novaSenha: ['', [Validators.required, Validators.minLength(6)]],
      novaSenhaConfirmar: ['', [Validators.required, Validators.minLength(6)]]
    }, { validator: this.senhasCoincidem });
  }

  senhasCoincidem(group: AbstractControl): { [key: string]: boolean } {
    const novaSenha = group.get('novaSenha');
    const novaSenhaConfirmar = group.get('novaSenhaConfirmar');

    if (!novaSenha || !novaSenhaConfirmar) {
      return null;
    }

    if (novaSenha.value !== novaSenhaConfirmar.value) {
      return { senhasNaoCoincidem: true };
    }

    return null;
  }

  salvar(alterarSenha: AlterarSenha) {
    this.areSubmitting = true;

    alterarSenha.login = this.loginService.usuario.email;

    this.usuarioService.atualizarSenha(alterarSenha)
      .subscribe(resp => {
        this.notificationService.notifty(`A nova senha foi definida com sucesso.`);
        this.areSubmitting = false;
        this.loginService.logout();
      }, error => this.areSubmitting = false);
  }

  resetForm() {
    this.firstElementRef.nativeElement.focus();

    this.passwordForm.reset();
  }
}
