import { Usuario, Vendedor } from ".";
import { PedidoItem } from "./pedido-item.model";
import { StatusPedido } from "../enum";

export class Pedido {
    id: string;
    status: StatusPedido;
    dataHoraCriacao: Date;
    dataHoraFinalizacao: Date;
    dataHoraPagamento: Date;
    estaFinalizado: boolean;
    estaPago: Boolean;
    clienteId: string;
    cliente: Usuario;
    vendedorId: string;
    vendedor: Vendedor;
    valorProdutos: number;
    subtotal: number;
    valorDesconto: number;
    valorFrete: number;
    valorTotal: number;
    valorPagamento: number;
    valorPendente: number;
    
    itens: PedidoItem [];
}