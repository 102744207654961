import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(
    private _http: HttpClient
  ) { }

  uploadImage(image: File): Observable<string> {
    let formData: FormData = new FormData();
    formData.append('file', image, image.name);

    return this._http.post<string>(`${environment.onStockApi}/images`, formData, { responseType: <any>'text' });
  }

  uploadVideo(video: File): Observable<string> {
    let formData: FormData = new FormData();
    formData.append('file', video, video.name);

    return this._http.post<string>(`${environment.onStockApi}/videos`, formData, { responseType: <any>'text' });
  }
}
