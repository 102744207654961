import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { FiltroDto, Postagem } from '../models';
import { FiltroDenunciaDto } from '../dtos/filtro-denuncia-dto';
import { TipoMidia } from '../enum';

@Directive()
@Injectable()
export class PostagemService {
    constructor(private http: HttpClient) { }

    static ITENS_POR_PAGINA: number = 10;

    denuncia: Postagem;

    @Output() tiraBola = new EventEmitter();
    @Output() tiraBolaPostagem = new EventEmitter();
    @Output() atualiza = new EventEmitter();

    listar(pesquisa?: string): Observable<Postagem[]> {
        let params: HttpParams;
        if (pesquisa) {
            params = new HttpParams().set('query', pesquisa);
        }
        return this.http.get<Postagem[]>(`${environment.api}/postagens`, { params: params });
    }

    listarPartial(count: number): Observable<Postagem[]> {
        return this.http.get<Postagem[]>(`${environment.api}/postagens/${count}/${PostagemService.ITENS_POR_PAGINA}`);
    }

    filtrar(filtro: FiltroDenunciaDto, count: number): Observable<Postagem[]> {
        return this.http.post<Postagem[]>(`${environment.api}/postagens/filtrar/${count}/${PostagemService.ITENS_POR_PAGINA}`, filtro);
    }

    aprovando(): Observable<Postagem[]> {
        return this.http.get<Postagem[]>(`${environment.api}/postagens/aprovando`);
    }

    encontrar(id: string): Observable<Postagem> {
        return this.http.get<Postagem>(`${environment.api}/postagens/${id}`);
    }

    salvar(denuncia: Postagem): Observable<Postagem> {
        return this.http.post<Postagem>(`${environment.api}/postagens`, denuncia);
    }

    remover(id: string): Observable<Postagem> {
        return this.http.delete<Postagem>(`${environment.api}/postagens/${id}`);
    }

    reportados(): Observable<Postagem[]> {
        return this.http.get<Postagem[]>(`${environment.api}/postagens/reportadas`);
    }

    tiraBolinhaImagens() {
        this.tiraBola.emit();
    }

    tiraBolinhaPostagem() {
        this.tiraBolaPostagem.emit();
    }

    atualizaPostagems() {
        this.atualiza.emit();
    }

    salvarMidia(blob: any, tipo: TipoMidia): Observable<string> {
        const formData = new FormData();
        formData.append('file', blob);

        return this.http.post<string>(`${environment.api}/postagens/salvarMidia/${tipo}`, formData, { responseType: 'text' as 'json' });
    }
}