<nav aria-label="breadcrumb">
  <ol class="breadcrumb b-radius breadcrumb-appckground mt-3">
    <li class="breadcrumb-item active breadcrumb-link-actual mt-1" aria-current="page"><i class="far fa-ballot-check"></i>&nbsp;Propostas</li>
    <li class="ml-auto">
      <button type="button" class="btn btn-sm btn-outline-primary btn-on" [routerLink]="['adicionar']">
        <i class="fal fa-plus"></i>
        <span class="d-none d-lg-inline-flex">&nbsp;Adicionar</span>
      </button>
    </li>
  </ol>
</nav>
<form [formGroup]="filtroForm" novalidate autocomplete="off">
  <div class="row">
    <div class="col-3">
      <select formControlName="politicoId" class="form-control form-control-sm" (change)="filtrar(null)">
        <option [ngValue]="null">Todos os políticos</option>
        <option *ngFor="let politico of politicos" [ngValue]="politico.id">{{politico.nome}}</option>
      </select>
    </div>
    <div class="col-3">
      <select formControlName="uf" class="form-control form-control-sm" (change)="filtrar(null)">
        <option [ngValue]="null">Todos as UFs</option>
        <optgroup label="Federação">
          <option value="Brasil">Brasil</option>
        </optgroup>
        <optgroup label="Estados">
          <option *ngFor="let uf of ufs" [ngValue]="uf.value">{{uf.value}}</option>
        </optgroup>
      </select>
    </div>
    <div class="col-6">
      <app-searchbox (search)="filtrar(filtroForm.controls.pesquisa.setValue($event))"></app-searchbox>
    </div>
  </div>
</form>
<div class="table-responsive" *ngIf="!mensagemListaVazia">
  <table class="table table-sm table-hover table-striped">
    <thead>
      <tr>
        <th></th>
        <th class="text-left">Político</th>
        <th class="text-left">Status</th>
        <th class="text-left">Estado</th>
        <th class="text-left">Cidade</th>
        <th class="text-left">Título</th>
      </tr>
    </thead>
    <tbody class="text-left">
      <tr class="text-nowrap tr-click" *ngFor="let proposta of propostas" [routerLink]="['/menu-propostas', proposta.id]">
        <td class="td-adjustment"><img src="{{proposta.imagem}}" class="img-adjustment" /></td>
        <td>{{proposta.politico.nome}}</td>
        <td>{{statusProp}}</td>
        <td>{{proposta.uf}}</td>
        <td *ngIf="proposta.cidade">{{proposta.cidade}}</td>
        <td *ngIf="!proposta.cidade">-</td>
        <td>{{proposta.titulo}}</td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="mensagemListaVazia">
  <h6 class="text-muted">Não possui propostas.</h6>
</div>
