import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalRemoverComponent } from 'src/app/shared/modal/modal-remover/modal-remover.component';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Comentario, Postagem, GosteiDto, Midia, Gostei } from 'src/app/models';
import { TipoMidia } from 'src/app/enum';
import { LoginService, ComentarioService, GosteiService } from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { PostagemService } from 'src/app/services/postagem.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/shared/messages/notification.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { PostagemVideoComponent } from '../postagem-video/postagem-video.component';
import { ComentarioComponent } from '../../feed/feed-item/comentario/comentario.component';

@Component({
  selector: 'app-postagem-item',
  templateUrl: './postagem-item.component.html',
  styleUrls: ['./postagem-item.component.scss']
})
export class PostagemItemComponent implements OnInit {

  id: string;
  comentarioForm: UntypedFormGroup;
  areSubmitting: boolean = false;
  areAdmin: boolean = false;
  comentarios: Comentario[];
  areLoggedIn: boolean = false;
  showMessage = false;
  usuarioId: string;
  @Input() postagem: Postagem;
  @Input() mostrarDetalhes = false;
  @Input() gostei?: boolean = null;
  @Output() login = new EventEmitter();
  tipo = TipoMidia;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private comentarioService: ComentarioService,
    private gosteiService: GosteiService,
    private postagemService: PostagemService,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private usuarioService: UsuarioService,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.comentarioForm = this.formBuilder.group({
      texto: [null]
    });

    this.areLoggedIn = this.loginService.isLoggedIn();

    if (this.areLoggedIn && this.loginService.usuario.perfil === "admin") {
      this.areAdmin = true;
    }

    // Verificar se tem id na rota
    this.id = this.route.snapshot.params['id'];

    if (this.id) {
      this.postagemService.encontrar(this.id).subscribe(postagem => {
        this.postagem = postagem;

        this.postagem.midias = this.postagem.midias.filter(x => x.aprovada || x.aprovada == null);
      });
    }
    else {
      this.postagem.midias = this.postagem.midias.filter(x => x.aprovada || x.aprovada == null);
    }

    if (this.areLoggedIn) {
      this.usuarioId = this.loginService.usuario.id;

      var dto = new GosteiDto;

      if (this.id != null) {
        dto.postagemId = this.id;
      }
      else {
        dto.postagemId = this.postagem.id;
      }
      dto.usuarioId = this.loginService.usuario.id;

      this.gosteiService.ratePostagem(dto).subscribe(gostei => {
        if (gostei != null) {
          this.gostei = gostei.like;
        }
      });
    }

    this.getComentarios();
  }

  getComentarios() {
    if (this.id) {
      this.comentarioService.listarPorPostagem(this.id).subscribe(comentarios => {
        this.comentarios = comentarios;
        this.showMessage = comentarios.length < 1;
      });
    };
  }

  comentar() {
    if (this.areLoggedIn) {
      var comentario = new Comentario;
      comentario.texto = this.comentarioForm.value.texto;
      comentario.usuarioId = this.loginService.usuario.id;
      comentario.postagemId = this.postagem.id;
      comentario.dataHora = new Date();

      this.comentarioService.salvar(comentario)
        .subscribe(resp => {
          this.areSubmitting = false;
          this.getComentarios();
          this.comentarioForm.reset();
          this.notificationService.notifty(`Comentado com sucesso!`);
        }, error => this.areSubmitting = false);
    }
    else {
      this.loginService.handleLogin();
    }
  }

  abrirVideo(midia: Midia, titulo: string) {
    if (midia.tipoMidia == TipoMidia.Video) {
      const modalRef = this.modalService.open(PostagemVideoComponent, { centered: true, size: 'lg', backdrop: false });
      modalRef.componentInstance.title = titulo;
      modalRef.componentInstance.url = this.sanitizer.bypassSecurityTrustResourceUrl(midia.url.replace("/thumb", "/preview"));
    }
  }

  verMais(comentario: Comentario) {
    if (this.areLoggedIn) {
      const modalRef = this.modalService.open(ComentarioComponent, { centered: true });
      modalRef.result.then(confirma => {
        this.getComentarios();
      }, dismiss => { });

      modalRef.componentInstance.comentario = comentario;
    }
    else {
      this.loginService.handleLogin();
    }
  }

  like(gostei: boolean) {
    if (this.areLoggedIn) {
      this.gostei = gostei;
      var avaliacao = new Gostei;
      avaliacao.like = this.gostei;
      avaliacao.postagemId = this.postagem.id;
      avaliacao.usuarioId = this.loginService.usuario.id;

      this.gosteiService.salvar(avaliacao)
        .subscribe(resp => {
          this.areSubmitting = false;
          this.postagemService.encontrar(avaliacao.postagemId).subscribe(postagem => {
            postagem.midias.forEach(midia => {
              if (midia.tipoMidia == TipoMidia.Video)
                midia.url = `${midia.url}/thumb`;
            });
            this.postagem = postagem;
          });
        }, error => this.areSubmitting = false);
    }
    else {
      this.loginService.handleLogin();
    }
  }

  denunciar(postagem: Postagem) {
    if (this.areLoggedIn) {
      postagem.reportado = true;
      this.postagemService.salvar(postagem).subscribe(() => {
        this.notificationService.notifty(`Denúnciado com sucesso!`)
        this.postagemService.tiraBolinhaPostagem();
      }
        , error => this.notificationService.notifty(`Erro ao tentar denúnciar`));
    }
    else {
      this.loginService.handleLogin();
    }
  }

  remover(postagem: Postagem) {
    if (this.areLoggedIn) {
      const modalRef = this.modalService.open(ModalRemoverComponent, { centered: true });
      modalRef.componentInstance.mensagem = `Deseja realmente remover esta postagem?`;
      modalRef.componentInstance.titulo = `Remover`;

      modalRef.result.then(confirmaRemocao => {
        if (confirmaRemocao) {
          this.postagemService.remover(postagem.id).subscribe(_ => {
            this.notificationService.notifty(`Postagem removida!`);
            this.postagemService.atualizaPostagems();
            this.router.navigate(['/feed/postagens']);
          });
        }
      }, dismiss => { });
    }
    else {
      this.loginService.handleLogin();
    }
  }

  bloquear(postagem: Postagem) {

    if (this.id) {
      this.router.navigate(['feed/postagens']);
    }

    this.areSubmitting = true;
    if (this.areLoggedIn) {

      this.usuarioService.bloquear(this.loginService.usuario.id, postagem.usuarioId).subscribe(() => {
        this.postagemService.atualizaPostagems();
        this.notificationService.notifty(`Bloqueado com sucesso!`)
        this.areSubmitting = false;

      }, error => {
        this.areSubmitting = false;
        this.notificationService.notifty(`Erro ao tentar bloquear`)
      });
    }
    else {
      this.loginService.handleLogin();
    }
  }
}
