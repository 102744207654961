<nav aria-label="breadcrumb">
  <ol class="breadcrumb b-radius breadcrumb-appckground mt-3">
    <li class="breadcrumb-item active breadcrumb-link-actual mt-1" aria-current="page"><i
        class="far fa-building"></i>&nbsp;Organização</li>
    <li class="ml-auto">
      <button *ngIf="areAdmin" type="button" class="btn btn-sm btn-outline-primary btn-on" [routerLink]="['adicionar']">
        <i class="fal fa-plus"></i>
        <span class="d-none d-lg-inline-flex">&nbsp;Adicionar</span>
      </button>
    </li>
  </ol>
</nav>
<form [formGroup]="filtroForm" novalidate autocomplete="off">
    <div class="row">
      <div class="col-12">
        <app-searchbox (search)="filtrar(filtroForm.controls.pesquisa.setValue($event))"></app-searchbox>
      </div>
    </div>
  </form>
  <div class="table-responsive" *ngIf="!mensagemListaVazia">
    <table class="table table-sm table-hover table-striped">
      <thead>
        <tr>
          <th></th>
          <th class="text-left">Organização</th>
          <th class="text-left">Estado</th>
          <th class="text-left">Cidade</th>
        </tr>
      </thead>
      <tbody class="text-left">
        <tr class="text-nowrap tr-click" *ngFor="let organizacao of organizacoes"
          [routerLink]="['/organizacoes', organizacao.id]">
          <td class="td-adjustment"><img src="{{organizacao.imagem}}" class="img-adjustment" /></td>
          <td>{{organizacao.nome}}</td>
          <td>{{organizacao.uf}}</td>
          <td *ngIf="organizacao.cidade">{{organizacao.cidade}}</td>
          <td *ngIf="!organizacao.cidade">-</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="mensagemListaVazia">
    <h6 class="text-muted">Não possui organizações.</h6>
  </div>

