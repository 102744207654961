import { TipoCentroCusto } from "../enum";
import { Lancamento } from "./lancamento.model";

export class CentroCusto {
  id: string;
  nome: string;
  codigo: string;
  organizacaoId: string;
  tipoCentroCusto: TipoCentroCusto;
  lancamentos: Lancamento[];

  constructor(id?: string, nome?: string) {
    this.id = id;
    this.nome = nome;
  }
}
