import { Endereco } from "./endereco.model";
import { Produto } from "./produto.model";

export class Vendedor {
    id: string;
    imagem: string;
    nome: string;
    email: string;
    senha: string;
    telefone: string;
    vendedores: string[];

    produtos: Produto[] = [];
}