import { Component, OnInit, Input } from '@angular/core';
import { Proposta } from 'src/app/models';

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss']
})

export class FeedComponent implements OnInit {

  @Input() propostas: Proposta[];
  @Input() currentRate: number;

  constructor(
  ) { }

  ngOnInit() {
  }
}
