import { Component, HostListener, OnInit } from '@angular/core';
import { LoginService } from './services';
import { NgSelectConfig } from '@ng-select/ng-select';
import { environment } from 'src/environments/environment';
import { NotificationMiddlewareService } from './core/notification-middleware.service';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { UsuarioService } from './services/usuario.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, CanDeactivate<AppComponent>  {
  title = 'olhar-nacional';

  // NAO REMOVER ESTE CÓDIGO
  // Ele é responsável por setar o usuário como offline ao sair da página
  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (this.loginService.isLoggedIn())
      this._usuarioService.alterarStatusOnline(this.loginService.getUsuario().id, false);
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away

    return true;
  }

  constructor(
    private loginService: LoginService,
    private config: NgSelectConfig,
    private _notificationMiddleware: NotificationMiddlewareService,
    private _usuarioService: UsuarioService
  ) {
    // Redirecionar de http para https
    if (environment.production && location.protocol === 'http:') {
      window.location.href = location.href.replace('http', 'https');
    }
    this.config.notFoundText = 'Nada foi encontrado';
    this.config.loadingText = 'Buscando...';
  }

  isLoggedIn() {
    return this.loginService.isLoggedIn();
  }

  ngOnInit() {
    this._notificationMiddleware.init();
  }
}
