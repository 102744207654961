<nav aria-label="breadcrumb ">
  <ol class="breadcrumb b-radius breadcrumb-appckground mt-3">
    <li class="breadcrumb-item active breadcrumb-link-actual mt-1" aria-current="page"><i
        class="fas fa-clipboard-list"></i>&nbsp;Pedidos</li>
  </ol>
</nav>
<app-searchbox (search)="pesquisar($event)"></app-searchbox>
<div class="table-responsive" *ngIf="!mensagemListaVazia">
  <table class="table table-sm table-hover table-striped">
    <thead>
      <tr>
        <th class="text-left">Cliente</th>
        <th class="text-left">Data</th>
        <th class="text-left">Valor Total</th>
        <th class="text-left">Status  </th>
      </tr>
    </thead>
    <tbody class="text-left">
      <tr class="text-nowrap tr-click" *ngFor="let pedido of pedidos" (click)="selecionar(pedido)">
        <td>{{pedido.cliente.nome}}</td>
        <td>{{pedido.dataHoraCriacao | date:'dd/MM/yyyy HH:mm'}}h</td>
        <td>{{pedido.valorTotal | currency:'BRL'}}</td>
        <td>{{retornaEnum(pedido.status)}}</td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="mensagemListaVazia">
  <h6 class="text-muted">Não possui pedidos.</h6>
</div>