import { Component, forwardRef, ViewChild, ContentChild, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControlName } from '@angular/forms';
import { NotificationService } from '../messages/notification.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-vid',
  templateUrl: './vid.component.html',
  styleUrls: ['./vid.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VidComponent),
      multi: true
    }
  ]
})
export class VidComponent implements ControlValueAccessor {

  @ViewChild('fileInput') fileInput;
  @ViewChild('dropdown') dropdrown;
  @ContentChild(FormControlName) control: FormControlName;
  @Input() icone = 'fal fa-user';
  @Input() url: string;
  @Input() fileSize: number = 40;
  @Input() fullWidth: boolean = false;

  acceptedFileTypes: string[] = [
    'video/x-flv',
    'video/mp4',
    'video/MP2T',
    'video/3gpp',
    'video/quickime',
    'video/x-msvideo',
    'video/x-ms-wmv'
  ];

  constructor(
    private notificationService: NotificationService,
    private sanitizer: DomSanitizer
  ) { }


  changeFile(): void {
    if (!this.isValid())
      this.fileInput.nativeElement.click();
    else {
      setTimeout(() => {
        this.dropdrown.nativeElement.click();
      }, 100)
    }
  }

  openFile(): void {
    this.fileInput.nativeElement.click();
  }

  removeFile(): void {
    this.url = null;
    this.fileInput.nativeElement.value = null;
    // Propagar a alteração
    this.propagateChange(this.url);
  }

  addFile(): void {
    const fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0]) {
      const fileToUpload = fi.files[0];
      const reader = new FileReader();

      if ((fileToUpload.size > this.fileSize * 1000000) && this.fullWidth == false)
        this.notificationService.notifty(`O tamanho do vídeo excede o limite de ${this.fileSize} Mb.`);
      else if (this.acceptedFileTypes.indexOf(fileToUpload.type) == -1)
        this.notificationService.notifty("Formato não suportado.");
      else {
        reader.onload = () => {
          this.url = <string>reader.result;
          //Propagar a alteração
          this.propagateChange(this.url)
        };
        reader.readAsDataURL(fileToUpload);
      }
    }
  }

  //Métodos do ValueAcessor
  propagateChange = (_: any) => { };
  writeValue(value: any): void {
    this.url = value;
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void { }

  //Validação do componente
  isValid(): boolean {
    return this.url != null && this.url !== '';
  }

  isRequired(): boolean {
    if(!this.control.errors)
      return false;
    return this.control.errors.required;
  }

  sanitize(url: string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error("Method not implemented.");
  }
}
