import { DatePipe } from '@angular/common';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TipoMidia } from 'src/app/enum';
import { Usuario } from 'src/app/models';
import { Chat } from 'src/app/models/chat.model';
import { LoginService } from 'src/app/services';
import { ChatService } from 'src/app/services/chat.service';
import { MenuService } from 'src/app/services/menu.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { AudioRecordService } from 'src/app/shared/aud/audio-record.service';
import { NotificationService } from 'src/app/shared/messages/notification.service';
import { ModalAdicionarUsuariosComponent } from 'src/app/shared/modal/modal-adicionar-usuarios/modal-adicionar-usuarios.component';
import { ModalRemoverMensagemComponent } from 'src/app/shared/modal/modal-remover-mensagem/modal-remover-mensagem/modal-remover-mensagem.component';

@Component({
  selector: 'app-chat-lista',
  templateUrl: './chat-lista.component.html',
  styleUrls: ['./chat-lista.component.scss']
})
export class ChatListaComponent implements OnInit, OnDestroy {

  usuarios: Usuario[] = [];
  usuariosFiltrados: Usuario[] = [];
  usuarioSelecionado: Usuario;
  usuarioLogadoId: string;
  chats: Chat[] = [];
  tipoMidia = TipoMidia;
  tipoMidiaSelecionada: TipoMidia = TipoMidia.Texto;
  fadeChat: boolean = false;
  fadeFoto: boolean = false;
  fadeAudio: boolean = false;
  excluidaParaMim: boolean = false;
  imagem: string;
  pesquisa: string;
  naoAtualizar: boolean = false;
  estaAdicionando: boolean = false;

  @ViewChild('texto') textoRef: ElementRef;
  @ViewChild('chat') chatRef: ElementRef;
  @ViewChild('som') som: ElementRef;
  @ViewChild('lottie') lottie: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('fileInputArquivo') fileInputArquivo: ElementRef;

  @HostListener('document:keydown.escape', ['$event'])
  onEscape(event) {
    if (this.tipoMidiaSelecionada == TipoMidia.Texto) {
      this._modalRef.dismiss();
    } else {
      this.toggle(TipoMidia.Texto);
    }

    this.abortarGravacaoAudio();
  }

  constructor(
    private _usuarioService: UsuarioService,
    private _loginService: LoginService,
    private _service: ChatService,
    private _ns: NotificationService,
    private _audioService: AudioRecordService,
    private _modalRef: NgbActiveModal,
    private _sanitizer: DomSanitizer,
    private _menuService: MenuService,
    private _datePipe: DatePipe,
    private _modalService: NgbModal,
  ) { }

  ngOnInit() {
    //Fade do chat
    this.fadeChat = true;

    this.usuarioLogadoId = this._loginService.usuario.id;

    //Ficar escutando as alterações de áudio
    this.obterGravacaoAudio();

    if (!this.naoAtualizar) {
      //Listar os usuários
      this.listarUsuariosAmigos();
    }

    //SignalR
    this._menuService.hubChatConnection.on('chat_mensagem_recebida', (message) => {
      let chat = <Chat>message;

      //Caso o usuário que recebeu a mensagem seja selecionado
      if (this.usuarioSelecionado && (chat.remetenteId == this.usuarioSelecionado.id))
        this.selecionar(this.usuarioSelecionado, false);
      else
        this.definirNovasMensagens(chat.remetenteId, true);
    });

    this._menuService.hubChatConnection.on('chat_mensagem_lida', (message) => {
      let chat = <Chat>message;

      //Caso o usuário que enviou a mensagem esteja selecionado
      if (this.usuarioSelecionado && (chat.destinatarioId == this.usuarioSelecionado.id)) {

        let chatExistente = this.chats.find(x => x.dataHoraEnvio.toString().substr(0, 19) == chat.dataHoraEnvio.toString().substr(0, 19));

        if (chatExistente) {
          chatExistente.id = chat.id;
          chatExistente.dataHoraLeitura = chat.dataHoraLeitura;
        }
      }
      else
        this.definirNovasMensagens(chat.remetenteId, true);
    });
  }

  ngOnDestroy() {
    this.abortarGravacaoAudio();
  }

  listarUsuariosAmigos() {

    if (this.naoAtualizar == false) {
      this._usuarioService
        .listarTodosAmigos(this._loginService.usuario.id)
        .subscribe(usuarios => {
          this.usuarios = usuarios;
          this.usuariosFiltrados = usuarios;

          this.pesquisar(this.pesquisa);

          if (this.usuarioSelecionado) {
            let usuario = this.usuarios.find(x => x.id == this.usuarioSelecionado.id);
            let usuarioFiltrado = this.usuarios.find(x => x.id == this.usuarioSelecionado.id);

            if (usuario != null) {
              usuario.estaSelecionado = true;
              usuarioFiltrado.estaSelecionado = true;
            }
          }
        });
    }
  }

  pesquisar(pesquisa: string) {
    if (this.naoAtualizar == false) {


      this.pesquisa = pesquisa;

      if (!pesquisa)
        this.usuariosFiltrados = this.usuarios;
      else
        this.usuariosFiltrados = this.usuarios.filter(x => x.nome.toLowerCase().includes(pesquisa.toLowerCase()));
    }
    else {
      if (this.usuarioSelecionado != null && this.estaAdicionando == true)
        this.usuariosFiltrados.push(this.usuarioSelecionado)
    }

  }

  selecionar(usuarioSelecionado: Usuario, estaAdicionando: boolean) {
    this.usuarioSelecionado = usuarioSelecionado;
    this.estaAdicionando = estaAdicionando;



    //Preencher usuários
    for (const usuario of this.usuarios) {
      usuario.estaSelecionado = usuario.id == usuarioSelecionado.id;
    }

    //Preencher usuários filtrados
    for (const usuario of this.usuariosFiltrados) {
      usuario.estaSelecionado = usuario.id == usuarioSelecionado.id;
    }

    this.listarMensagens(usuarioSelecionado, estaAdicionando);
  }

  listarMensagens(usuarioSelecionado: Usuario, estaAdicionando: boolean) {

    this._service
      .listar(0, this._loginService.usuario.id, usuarioSelecionado.id)
      .subscribe((chats: Chat[]) => {
        this.chats = chats;

        this.scrollToBottom();

        this.definirNovasMensagens(usuarioSelecionado.id, false);

        this._service.emitirAlterado();

      });
  }

  enviar(tipo: TipoMidia = TipoMidia.Texto, conteudo: string = null) {
    let chat = new Chat();

    chat.tipo = tipo;
    chat.conteudo = conteudo || this.textoRef.nativeElement.value;
    chat.remetenteId = this._loginService.usuario.id;
    chat.dataHoraEnvio = new Date();
    chat.destinatarioId = this.usuarioSelecionado.id;
    chat.ehPublico = false;
    chat.enviadoPorMim = true;

    if (chat.conteudo) {
      this._service
        .salvar(chat)
        .subscribe((dataHoraEnvio: Date) => {
          chat.dataHoraEnvio = dataHoraEnvio;
          this.chats.push(chat);

          this.textoRef.nativeElement.value = '';
          this.textoRef.nativeElement.focus();

          this.scrollToBottom();
        });
    }
  }

  scrollToBottom(): void {
    setTimeout(() => {
      try {
        this.chatRef.nativeElement.scrollTop = this.chatRef.nativeElement.scrollHeight;
      } catch (err) { }
    }, 500);
  }

  onFileChanged(event) {
    const file = event.target.files[0];

    if (file) {
      const tipo = file.type.includes('image') ? TipoMidia.Imagem : TipoMidia.Video;
      const mb = tipo == TipoMidia.Imagem ? 6 : 64;
      const maxSize = mb * 10000000;

      if (file.size > maxSize) {
        this._ns.notifty(`O tamanho ${tipo == TipoMidia.Imagem ? 'da imagem' : 'do vídeo'} não pode exceder ${mb}mb`);
        this.fileInput.nativeElement.value = null;
      } else {
        this._service
          .salvarMidia(file, tipo)
          .subscribe((url: string) => {
            if (url) {
              url = url.split('"').join('');
              this.enviar(tipo, url);
            }
          });
      }
    }
  }

  onfileArquivoChanged(event) {
    const file = event.target.files[0];

    if (file) {
      const mb = 128;
      const maxSize = mb * 1000000;

      if (file.size > maxSize) {
        this._ns.notifty(`O tamanho do arquivo não pode exceder ${mb}mb.`)
        this.fileInput.nativeElement.value = null;
      } else {
        this._service
          .salvarMidia(file, TipoMidia.Arquivo)
          .subscribe((url: string) => {
            if (url) {
              url = url.split('"').join('');
              this.enviar(TipoMidia.Arquivo, url);
            }
          });
      }
    }
  }

  visualizarImagem(imagem: string) {
    this.imagem = imagem;
    this.toggle(TipoMidia.Imagem);
  }

  fecharImagem() {
    this.imagem = null;
    this.toggle(TipoMidia.Texto);
  }

  toggle(tipoMensagem: TipoMidia) {
    this.tipoMidiaSelecionada = tipoMensagem;

    if (tipoMensagem == TipoMidia.Texto) {
      this.fadeChat = true;
      this.fadeFoto = false;
      this.fadeAudio = false;
    } else if (tipoMensagem == TipoMidia.Imagem) {
      this.fadeChat = false;
      this.fadeFoto = true;
      this.fadeAudio = false;
    } else {
      this.fadeChat = false;
      this.fadeFoto = false;
      this.fadeAudio = true;
    }
  }

  iniciarGravacaoAudio() {
    this._audioService.startRecording();
    this.toggle(TipoMidia.Audio);
  }

  pararGravacaoAudio() {
    this._audioService.stopRecording();
    this.toggle(TipoMidia.Texto);
  }

  abortarGravacaoAudio() {
    this._audioService.abortRecording();
  }

  fecharGravacaoAudio() {
    this._audioService.abortRecording();
    this.toggle(TipoMidia.Texto);
  }

  obterGravacaoAudio() {
    this._audioService.getRecordedBlob().subscribe((file: any) => {
      this._service
        .salvarMidia(file.blob, TipoMidia.Audio)
        .subscribe((url: string) => {
          if (url) {
            url = url.split('"').join('');
            this.enviar(TipoMidia.Audio, url);
          }
        });
    });
  }

  definirNovasMensagens(usuarioId: string, valor: boolean) {
    let usuario = this.usuarios.find(x => x.id == usuarioId);
    let usuarioFiltrado = this.usuarios.find(x => x.id == usuarioId);

    if (usuario) {
      usuario.possuiNovasMensagens = valor;
    }

    if (usuarioFiltrado) {
      usuarioFiltrado.possuiNovasMensagens = valor;
    }

    this.scrollToBottom();
  }

  sanitize(url: string) {
    return this._sanitizer.bypassSecurityTrustUrl(url);
  }

  trackByFn(index: number, usuario: Usuario): string {
    return usuario.id;
  }

  trackByChatFn(index: number, chat: Chat): number {
    return index;
  }

  abrirModalExcluir(chat: Chat) {
    const modalRef = this._modalService.open(ModalRemoverMensagemComponent, { centered: true });
    modalRef.componentInstance.chat = chat;
    modalRef.componentInstance.mensagem = `Deseja realmente remover a mensagem?`;

    modalRef.result.then(() => {
      this.listarMensagens(this.usuarioSelecionado, false);
    })
  }

  addAmigos() {
    const modalRef = this._modalService.open(ModalAdicionarUsuariosComponent, { centered: true, size: <any>'xl', backdrop: 'static', backdropClass: 'backdrop-ok', keyboard: false });


    modalRef.result.then((usuario) => {

      if (usuario != null) {

        this.naoAtualizar = true;
        this.usuarioSelecionado = usuario;
        this.selecionar(usuario, true);
      }

      // this.listarMensagens(usuario);
    })
  }
}
