import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Proposta } from 'src/app/models/proposta.model';
import { PropostaService, PoliticoService, LoginService } from 'src/app/services';
import { StatusService } from 'src/app/shared/status.service';
import { Politico } from 'src/app/models';
import { UfService } from 'src/app/shared/uf.service';
import { Location } from '@angular/common';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-proposta-lista',
  templateUrl: './proposta-lista.component.html',
  styleUrls: ['./proposta-lista.component.scss']
})
export class PropostaListaComponent implements OnInit {

  propostas: Proposta[] = [];
  politicos: Politico[] = [];
  ufs: any[];
  mensagemListaVazia: boolean = false;
  statusProp: string;
  filtroForm: UntypedFormGroup;
  id: string;
  areAdmin: boolean;
  disableTodosPoliticos = false;

  constructor(
    private propostaService: PropostaService,
    private statusService: StatusService,
    private politicoService: PoliticoService,
    private ufService: UfService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    this.areAdmin = this.loginService.areAdmin();

    if (this.areAdmin) {
      this.listar();
    } else {
      this.disableTodosPoliticos = true;
      this.listarFiltrandoAcessor();
    }

    this.filtroForm = this.formBuilder.group({
      politicoId: [null],
      uf: [null],
      pesquisa: [null],
    });

    // Listar Adicionais
    this.ufs = this.ufService.listar();
    if (this.loginService.usuario.politicos.length > 0 && !this.loginService.areAdmin()) {
      this.politicoService.listar()
        .subscribe(politicos => this.politicos = this.loginService.usuario.politicos);
    } else {
      this.politicoService.listar()
        .subscribe(politicos => this.politicos = politicos);
    }

    // Verificar se esta editando o politico
    this.id = this.route.snapshot.fragment;
    if (this.id != null) {
      this.filtroForm.controls.politicoId.setValue(this.id);
      this.filtrar(null);
      this.location.replaceState('/propostas');
    };
  }

  listar() {
    this.propostaService.listar()
      .subscribe(propostas => {
        this.propostas = propostas
        if (propostas.length < 1) {
          this.mensagemListaVazia = true;
        } else {
          propostas.forEach(proposta => {
            this.statusProp = this.statusService.obterTexto(proposta.status);
          });
          
        }
      });
  }

  listarFiltrandoAcessor() {
    this.propostaService.listar()
      .subscribe(propostas => {
        propostas.forEach(proposta => {
          if (this.loginService.usuario.politicos.find(x => x.id == proposta.politicoId)) {
            this.propostas.push(proposta);
          }
        });

        if (propostas.length < 1) {
          this.mensagemListaVazia = true;
        } else {
          propostas.forEach(proposta => {
            this.statusProp = this.statusService.obterTexto(proposta.status);
          });
        }
      });
  }

  filtrar(any) {
    


    var filtro = this.filtroForm.value;

    this.propostaService.filtrar(filtro).subscribe(filtrados => {
      this.propostas = [];

      if (this.loginService.usuario.politicos.length > 0) {
        filtrados.forEach(filtrado => {
          if (this.loginService.usuario.politicos.find(x => x.id == filtrado.politicoId)) {
            this.propostas.push(filtrado);
          }
        });
      }
      else {
        this.propostas = filtrados;
      }

      if (filtrados.length < 1) {
        this.mensagemListaVazia = true;
      } else {
        this.mensagemListaVazia = false;
      }

      
    });
  }

  pesquisar(pesquisa: string) {
    this.propostaService.listar(pesquisa)
      .subscribe(propostas => this.propostas = propostas);
  }
}
