import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CarrinhoCompra } from '../models';

@Injectable()
export class CarrinhoCompraService {
    constructor(private http: HttpClient) { }

    carrinhoCompra: CarrinhoCompra;

    listar(pesquisa?: string): Observable<CarrinhoCompra[]> {
        let params: HttpParams;
        if (pesquisa) {
            params = new HttpParams().set('query', pesquisa);
        }
        return this.http.get<CarrinhoCompra[]>(`${environment.api}/carrinhosCompras`, { params: params });
    }

    encontrar(id: string): Observable<CarrinhoCompra> {
        return this.http.get<CarrinhoCompra>(`${environment.api}/carrinhosCompras/${id}`);
    }

    salvar(carrinhoCompra: CarrinhoCompra): Observable<CarrinhoCompra> {
        return this.http.post<CarrinhoCompra>(`${environment.api}/carrinhosCompras`, carrinhoCompra);
    }

    remover(id: string): Observable<CarrinhoCompra> {
        return this.http.delete<CarrinhoCompra>(`${environment.api}/carrinhosCompras/${id}`);
    }
}