import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Beneficiario } from '../models/beneficiario.model';

@Injectable()
export class BeneficiarioService {
  constructor(private http: HttpClient) { }

  listar(pesquisa?: string): Observable<Beneficiario[]> {
    let params: HttpParams;
    if (pesquisa) {
      params = new HttpParams().set('query', pesquisa);
    }
    return this.http.get<Beneficiario[]>(`${environment.api}/beneficiarios`, { params: params });
  }

  listarPorOrganizacao(organizacaoId: string): Observable<Beneficiario[]> {
    return this.http.get<Beneficiario[]>(`${environment.api}/beneficiarios/organizacao/${organizacaoId}`);
  }

  encontrar(id: string): Observable<Beneficiario> {
    return this.http.get<Beneficiario>(`${environment.api}/beneficiarios/${id}`);
  }

  salvar(beneficiario: Beneficiario): Observable<Beneficiario> {
    return this.http.post<Beneficiario>(`${environment.api}/beneficiarios`, beneficiario);
  }

  remover(id: string): Observable<Beneficiario> {
    return this.http.delete<Beneficiario>(`${environment.api}/beneficiarios/${id}`);
  }
}
