<nav aria-label="breadcrumb">
  <ol class="breadcrumb b-radius breadcrumb-appckground mt-3">
    <li class="breadcrumb-item active breadcrumb-link-actual mt-1" aria-current="page"><i
        class="far fa-address-card"></i>&nbsp;Beneficiário</li>
    <li class="ml-auto">
      <button type="button" class="btn btn-sm btn-outline-primary btn-on" [routerLink]="['adicionar']">
        <i class="fal fa-plus"></i>
        <span class="d-none d-lg-inline-flex">&nbsp;Adicionar</span>
      </button>
    </li>
  </ol>
</nav>
<form [formGroup]="filtroForm" novalidate autocomplete="off">
  <div class="row">
    <div class="col-12">
      <app-searchbox (search)="filtrar(filtroForm.controls.pesquisa.setValue($event))"></app-searchbox>
    </div>
  </div>
</form>
<div class="table-responsive" *ngIf="!mensagemListaVazia">
  <table class="table table-sm table-hover table-striped">
    <thead>
      <tr>
        <th></th>
        <th class="text-left">Beneficiário</th>
        <th class="text-left">Ramo Atividade</th>
        <th class="text-left">Estado</th>
        <th class="text-left">Cidade</th>
        <th class="text-left">Telefone</th>
      </tr>
    </thead>
    <tbody class="text-left">
      <tr class="text-nowrap tr-click" *ngFor="let beneficiario of beneficiarios"
        [routerLink]="['/beneficiarios', beneficiario.id]">
        <td class="td-adjustment"><img src="{{beneficiario.imagem}}" class="img-adjustment" /></td>
        <td>{{beneficiario.nome}}</td>
        <td>{{beneficiario.ramoAtividade}}</td>
        <td>{{beneficiario.uf}}</td>
        <td *ngIf="beneficiario.cidade">{{beneficiario.cidade}}</td>
        <td *ngIf="!beneficiario.cidade">-</td>
        <td>{{beneficiario.telefone}}</td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="mensagemListaVazia">
  <h6 class="text-muted">Não possui beneficiários.</h6>
</div>
