import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-denuncia-video',
  templateUrl: './denuncia-video.component.html',
  styleUrls: ['./denuncia-video.component.scss']
})
export class DenunciaVideoComponent {

  @Input() title: string;
  @Input() url: string;

  constructor(private activeModal: NgbActiveModal) { }

  dismiss() {
    this.activeModal.dismiss();
  }
}
