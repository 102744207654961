import { Usuario } from "./usuario.model";

export class Comentario {
    id: string;
    texto: string;
    usuarioId: string;
    propostaId?: string;
    denunciaId?: string;
    postagemId?: string;
    dataHora: Date;
    reportado: boolean;
    verificado: boolean;
    usuario: Usuario;
}