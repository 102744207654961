import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Proposta, Comentario, Avaliacao, AvaliacaoDto } from 'src/app/models';
import { LoginService, ComentarioService, AvaliacaoService, PropostaService } from 'src/app/services';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComentarioComponent } from './comentario/comentario.component';
import { NotificationService } from 'src/app/shared/messages/notification.service';

@Component({
  selector: 'app-feed-item',
  templateUrl: './feed-item.component.html',
  styleUrls: ['./feed-item.component.scss']
})
export class FeedItemComponent implements OnInit {

  id: string;
  comentarioForm: UntypedFormGroup;
  areSubmitting: boolean = false;
  comentarios: Comentario[];
  areLoggedIn: boolean = false;
  showMessage = false;
  @Input() proposta: Proposta;
  @Input() mostrarDetalhes = false;
  @Input() currentRate: number;
  @Output() login = new EventEmitter();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private comentarioService: ComentarioService,
    private avaliacaoService: AvaliacaoService,
    private propostaService: PropostaService,
    private modalService: NgbModal,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.comentarioForm = this.formBuilder.group({
      texto: [null]
    });

    this.areLoggedIn = this.loginService.isLoggedIn();

    // Verificar se tem id na rota
    this.id = this.route.snapshot.params['id'];

    this.getComentarios();

    if (this.loginService.isLoggedIn()) {
      var dto = new AvaliacaoDto;

      if (this.id != null) {
        dto.propostaId = this.id;
      }
      else {
        dto.propostaId = this.proposta.id;
      }
      dto.usuarioId = this.loginService.usuario.id;

      this.avaliacaoService.rate(dto).subscribe(avaliacao => {
        if (avaliacao != null) {
          this.currentRate = avaliacao.nota;
        }
      });
    }
  }

  getComentarios() {
    if (this.id) {
      this.comentarioService.listarPorProposta(this.id).subscribe(comentarios => {
        this.comentarios = comentarios;
        this.showMessage = comentarios.length < 1;
      }
      )
    };
  }

  comentar() {
    if (this.loginService.isLoggedIn()) {
      var comentario = new Comentario;
      comentario.texto = this.comentarioForm.value.texto;
      comentario.usuarioId = this.loginService.usuario.id;
      comentario.propostaId = this.proposta.id;
      comentario.dataHora = new Date();

      this.comentarioService.salvar(comentario)
        .subscribe(resp => {
          this.areSubmitting = false;
          this.getComentarios();
          this.comentarioForm.reset();
          this.notificationService.notifty(`Comentado com sucesso!`);
        }, error => this.areSubmitting = false);
    }
    else {
      this.loginService.handleLogin();
    }
  }

  verMais(comentario: Comentario) {
    if (this.loginService.isLoggedIn()) {
      const modalRef = this.modalService.open(ComentarioComponent, { centered: true });
      modalRef.result.then(confirma => {
        this.getComentarios();
      }, dismiss => { });

      modalRef.componentInstance.comentario = comentario;
    }
    else {
      this.loginService.handleLogin();
    }
  }

  rate() {
    if (this.currentRate) {
      if (this.loginService.isLoggedIn()) {
        var avaliacao = new Avaliacao;
        avaliacao.nota = this.currentRate;
        avaliacao.propostaId = this.proposta.id;
        avaliacao.usuarioId = this.loginService.usuario.id;

        this.avaliacaoService.salvar(avaliacao)
          .subscribe(resp => {
            this.areSubmitting = false;
            this.propostaService.encontrar(avaliacao.propostaId).subscribe(proposta => this.proposta = proposta);
          }, error => this.areSubmitting = false);
      }
      else {
        this.currentRate = null;
        this.loginService.handleLogin();
      }
    }
  }
}
