import { Component, OnInit, Input } from '@angular/core';
import { Proposta } from 'src/app/models';
import { PropostaService } from 'src/app/services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-feed-detalhe',
  templateUrl: './feed-detalhe.component.html',
  styleUrls: ['./feed-detalhe.component.scss']
})
export class FeedDetalheComponent implements OnInit {

  id: string;
  proposta: Proposta;

  constructor(
    private propostaService: PropostaService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    if (this.id != null) {
      this.propostaService.encontrar(this.id).subscribe(proposta => {
        this.proposta = proposta;
      });
    }
  }

}
