import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { BandeiraCartao } from '../models';

@Injectable()
export class BandeiraCartaoService {
    constructor(private http: HttpClient) { }

    bandeiraCartao: BandeiraCartao;

    listar(pesquisa?: string): Observable<BandeiraCartao[]> {
        let params: HttpParams;
        if (pesquisa) {
            params = new HttpParams().set('query', pesquisa);
        }
        return this.http.get<BandeiraCartao[]>(`${environment.api}/bandeirasCartoes`, { params: params });
    }

    encontrar(id: string): Observable<BandeiraCartao> {
        return this.http.get<BandeiraCartao>(`${environment.api}/bandeirasCartoes/${id}`);
    }

    salvar(bandeiraCartao: BandeiraCartao): Observable<BandeiraCartao> {
        return this.http.post<BandeiraCartao>(`${environment.api}/bandeirasCartoes`, bandeiraCartao);
    }

    remover(id: string): Observable<BandeiraCartao> {
        return this.http.delete<BandeiraCartao>(`${environment.api}/bandeirasCartoes/${id}`);
    }
}