import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CentroCusto } from '../models';

@Injectable()
export class CentroCustoService {
  constructor(private http: HttpClient) { }

  listar(pesquisa?: string): Observable<CentroCusto[]> {
    let params: HttpParams;
    if (pesquisa) {
      params = new HttpParams().set('query', pesquisa);
    }
    return this.http.get<CentroCusto[]>(`${environment.api}/centrocustos`, { params: params });
  }

  listarPorOrganizacao(organizacaoId: string): Observable<CentroCusto[]> {
    return this.http.get<CentroCusto[]>(`${environment.api}/centrocustos/organizacao/${organizacaoId}`);
  }

  encontrar(id: string): Observable<CentroCusto> {
    return this.http.get<CentroCusto>(`${environment.api}/centrocustos/${id}`);
  }

  salvar(centroCusto: CentroCusto): Observable<CentroCusto> {
    return this.http.post<CentroCusto>(`${environment.api}/centrocustos`, centroCusto);
  }

  remover(id: string): Observable<CentroCusto> {
    return this.http.delete<CentroCusto>(`${environment.api}/centrocustos/${id}`);
  }
}
