import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { LoginService, PoliticoService, PropostaService, AvaliacaoService, OnMapsService } from 'src/app/services';
import { Usuario, Politico, Proposta, AvaliacaoDto, Cidade, Estado, Endereco } from 'src/app/models';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UfService } from 'src/app/shared/uf.service';
import * as $ from 'jquery';
import { FiltroEnum } from 'src/app/enum';
import { FiltroDenunciaDto } from 'src/app/dtos/filtro-denuncia-dto';
import { AddressResult } from 'src/app/models/address-result.model';

@Component({
  selector: 'app-feed-menu',
  templateUrl: './feed-menu.component.html',
  styleUrls: ['./feed-menu.component.scss']
})
export class FeedMenuComponent implements OnInit {

  mensagemListaVazia: boolean;
  politicos: Politico[] = [];
  propostas: Proposta[] = [];
  propostasBkp: Proposta[] = [];
  usuario: Usuario;
  filtroForm: UntypedFormGroup;
  ufs: Estado[];
  cidades: Cidade[];
  currentRate: number;
  filtroEnum = FiltroEnum;
  estaCarregando: boolean = false;
  estaFiltrando: boolean = false;
  filtroDenunciaDto: FiltroDenunciaDto;
  esconderCarregarMais: boolean = false;

  constructor(
    private politicoService: PoliticoService,
    private propostaService: PropostaService,
    private loginService: LoginService,
    private formBuilder: UntypedFormBuilder,
    private mapsService: OnMapsService,
  ) { }

  ngOnInit() {
    this.isLoggedIn();
    this.filtroDenunciaDto = new FiltroDenunciaDto;

    // Preenchendo usuário
    this.loginService.alterou.subscribe(usuario => {
      this.usuario = usuario;
      this.listar();
    })
    this.usuario = this.loginService.usuario;

    this.filtroForm = this.formBuilder.group({
      uf: [''],
      cidade: [''],
      politicoId: ['']
    });

    this.listar();

    this.loginService.logou.subscribe(resp => this.listar());
    this.loginService.deslogou.subscribe(resp => this.listar());

    // Listar Adicionais
    this.mapsService.estados().subscribe(estados => {
      var estadoNovo = new Estado();
      estadoNovo.nome = 'Brasil';
      estadoNovo.uf = 'Brasil';
      estados.push(estadoNovo);
      this.ufs = estados;
    });

    this.politicoService.listar()
      .subscribe(politicos => this.politicos = politicos);
  }

  scrollTop() {
    setTimeout(function () {
      $('.content-page').animate({ scrollTop: 0 }, 200);
    }, 1)
  }

  listar() {
    this.estaCarregando = true;

    if (!this.estaFiltrando) {
      this.propostaService.listarPartial(this.propostasBkp.length).subscribe(propostas => {

        for (const proposta of propostas) {
          this.propostas.push(proposta);
        }

        this.propostasBkp = this.propostas;

        this.mensagemListaVazia = this.propostas.length < 1;

        this.estaCarregando = false;
      }, () => this.estaCarregando = false);
    }
    else {
      this.propostaService.filtrarFeed(this.filtroDenunciaDto, this.propostas.length).subscribe(propostasFiltrada => {

        for (const propostaFiltrada of propostasFiltrada) {
          this.propostas.push(propostaFiltrada);
        }

        this.estaCarregando = false;

        if (propostasFiltrada.length < 10)
          this.esconderCarregarMais = true;
      });
    }
  }

  filtrar(filtroEnum: FiltroEnum) {
    this.estaFiltrando = true;
    this.filtroDenunciaDto.filtrandoPropostas = true;

    if (filtroEnum == FiltroEnum.Estado) {
      this.filtroDenunciaDto.cidade = null;
      this.filtroForm.controls.cidade.setValue('');

      if (this.filtroForm.controls.uf.value == '') {
        this.propostas = this.propostasBkp;
        this.esconderCarregarMais = false;
        this.filtroDenunciaDto.estado = null;
        // this.estaFiltrando = false;


        if (this.filtroForm.controls.politicoId.value != null) {
          this.propostas = this.propostasBkp.filter(x => x.politicoId == this.filtroForm.controls.politicoId.value);

          if (this.propostas == null || this.propostas.length == 0) {
            this.propostaService.filtrarFeed(this.filtroDenunciaDto, this.propostas.length).subscribe(propostasFiltrada => {
              this.propostas = propostasFiltrada;
              this.mensagemListaVazia = this.propostas.length < 1;
              if (this.estaFiltrando && this.propostas.length < 10)
                this.esconderCarregarMais = true;
            });
          }
        }
        else {
          this.filtroDenunciaDto.politico = null;
          this.filtroForm.controls.politicoId.setValue('');
        }

      } else {
        this.filtroDenunciaDto.estado = new Estado;
        this.filtroDenunciaDto.estado.uf = this.filtroForm.controls.uf.value;

        // verifica na lista atual antes de verificar no bkp
        this.propostas = this.propostas.filter(x => x.uf == this.filtroForm.controls.uf.value);



        if (this.propostas == null || this.propostas.length == 0) {

          // verifica no bkp antes de ir pro banco
          this.propostas = this.propostasBkp.filter(x => x.uf == this.filtroForm.controls.uf.value);
          this.esconderCarregarMais = false;



          if (this.propostas == null || this.propostas.length == 0) {
            this.propostaService.filtrarFeed(this.filtroDenunciaDto, this.propostas.length).subscribe(propostasFiltrada => {
              this.propostas = propostasFiltrada;
              this.mensagemListaVazia = this.propostas.length < 1;
              if (this.estaFiltrando && this.propostas.length < 10)
                this.esconderCarregarMais = true;
            });
          }
        }

        this.mapsService.cidades(this.filtroForm.controls.uf.value).subscribe(cidades => this.cidades = cidades);
      }
      this.cidades = [];
      this.filtroForm.controls.cidade.setValue('');
    }

    if (filtroEnum == FiltroEnum.Cidade) {
      if (this.filtroForm.controls.cidade.value == '') {
        this.esconderCarregarMais = false;
        this.filtroDenunciaDto.cidade = null;

        this.propostas = this.propostasBkp;
        this.propostas = this.propostas.filter(x => x.uf == this.filtroForm.controls.uf.value);

        if (this.propostas == null || this.propostas.length == 0) {
          this.propostaService.filtrarFeed(this.filtroDenunciaDto, this.propostas.length).subscribe(propostasFiltrada => {
            this.propostas = propostasFiltrada;
            this.mensagemListaVazia = this.propostas.length < 1;
            if (this.estaFiltrando && this.propostas.length < 10)
              this.esconderCarregarMais = true;
          });
        }
      } else {
        this.filtroDenunciaDto.cidade = new Cidade;
        this.filtroDenunciaDto.cidade.nome = this.filtroForm.controls.cidade.value;

        // verifica na lista atual antes de verificar no bkp
        this.propostas = this.propostas.filter(x => x.cidade == this.filtroForm.controls.cidade.value);

        if (this.propostas == null || this.propostas.length == 0) {

          // verifica no bkp antes de ir pro banco
          this.propostas = this.propostasBkp.filter(x => x.cidade == this.filtroForm.controls.cidade.value);
          this.esconderCarregarMais = false;

          if (this.propostas == null || this.propostas.length == 0) {
            this.propostaService.filtrarFeed(this.filtroDenunciaDto, this.propostas.length).subscribe(propostasFiltrada => {
              this.propostas = propostasFiltrada;
              this.mensagemListaVazia = this.propostas.length < 1;
              if (this.estaFiltrando && this.propostas.length < 10)
                this.esconderCarregarMais = true;
            });
          }
        }
      }
    }

    if (filtroEnum == FiltroEnum.Politico) {
      // if (this.filtroForm.controls.uf.value != '') {
      //   this.propostas = this.propostas.filter(x => x.uf == this.filtroForm.controls.uf.value);
      // }
      // if (this.filtroForm.controls.cidade.value != '') {
      //   this.propostas = this.propostas.filter(x => x.cidade == this.filtroForm.controls.cidade.value);
      // }

      if (this.filtroForm.controls.politicoId.value != '') {
        this.filtroDenunciaDto.politico = new Politico;
        this.filtroDenunciaDto.politico.id = this.filtroForm.controls.politicoId.value;

        // verifica na lista atual antes de verificar no bkp
        this.propostas = this.propostas.filter(x => x.politicoId == this.filtroForm.controls.politicoId.value);
        this.esconderCarregarMais = false;

        if (this.propostas == null || this.propostas.length == 0) {

          // verifica no bkp antes de ir pro banco
          this.propostas = this.propostasBkp.filter(x => x.politicoId == this.filtroForm.controls.politicoId.value);


          if (this.propostas == null || this.propostas.length == 0) {
            this.propostaService.filtrarFeed(this.filtroDenunciaDto, this.propostas.length).subscribe(propostasFiltrada => {
              this.propostas = propostasFiltrada;
              this.mensagemListaVazia = this.propostas.length < 1;
              if (this.estaFiltrando && this.propostas.length < 10)
                this.esconderCarregarMais = true;
            });
          }
        }
      }
      else {

        this.esconderCarregarMais = false;
        this.filtroDenunciaDto.politico = null;

        this.propostas = this.propostasBkp.filter(x => x.cidade == this.filtroForm.controls.cidade.value);

        if (this.propostas == null || this.propostas.length == 0) {
          // verifica no bkp antes de ir pro banco
          this.propostas = this.propostasBkp.filter(x => x.uf == this.filtroForm.controls.uf.value);

          if (this.propostas == null || this.propostas.length == 0) {
            this.propostaService.filtrarFeed(this.filtroDenunciaDto, this.propostas.length).subscribe(propostasFiltrada => {
              this.propostas = propostasFiltrada;
              this.mensagemListaVazia = this.propostas.length < 1;

              if (this.estaFiltrando && this.propostas.length < 10)
                this.esconderCarregarMais = true;
            });
          }
        }
      }
    }
  }

  usarLocalizacao() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;

        this.mapsService.getLocalizacao(latitude, longitude).subscribe((result: AddressResult) => {
          if (result) {
            const state = this.ufs.find(x => x.nome == result.address.state);

            this.filtroForm.controls.uf.setValue(state.uf);
            this.filtroForm.controls.cidade.setValue(result.address.city);

            this.mapsService.cidades(state.uf).subscribe(cidades => this.cidades = cidades);

            this.propostas = this.propostas.filter(x => x.uf == state.uf && x.cidade == result.address.city);
          }
        });
      }, _ => { }, { enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 });
    }
  }

  isLoggedIn() {
    return this.loginService.isLoggedIn();
  }
}
