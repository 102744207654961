import { Component, OnInit } from '@angular/core';
import { Produto, Vendedor } from 'src/app/models';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ProdutoService, LoginService } from 'src/app/services';
import { NotificationService } from 'src/app/shared/messages/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusPedido } from 'src/app/enum';

@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.scss']
})
export class ProdutosComponent implements OnInit {

  produto: Produto;
  form: UntypedFormGroup;
  areSubmitting: boolean;
  id: string;
  hiddingPassword: boolean = false;
  hiddingToPerfil: boolean = false;
  vendedor: Vendedor;
  areVendedor: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private _service: ProdutoService,
    private _notificationService: NotificationService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _loginService: LoginService
  ) { }

  ngOnInit() {
    this.areVendedor = this._loginService.areVendedor();

    this.form = this.formBuilder.group({
      id: [null],
      titulo: [null, Validators.required],
      imagem: [null, Validators.required],
      valor: [null, Validators.required],
      quantidade: [null, Validators.required],
      data: [new Date],
      valorPromocao: [null],
      descricao: [null],
      vendedorId: [this._loginService.getUsuario().id]
    });

    // Verificar se esta editando o produto
    this.id = this._route.snapshot.params['id'];

    if (this.id != null) {

      this._service.encontrar(this.id).subscribe(produto => {
        this.form.patchValue(produto);
        this.hiddingPassword = true;
      })
    }
  }

  salvar(produto: Produto) {
    this.areSubmitting = true;

    if (this._loginService.usuario.perfil == 'vendedor')
      produto.vendedorId = this._loginService.usuario.id;

    this._service.salvar(produto)
      .subscribe(resp => {
        this._notificationService.notifty(`O produto ${produto.titulo} foi salvo com sucesso.`);
        this.resetForm();
        this.areSubmitting = false;
      }, error => this.areSubmitting = false);
  }

  remover() {
    this._service.remover(this.id).subscribe(_ => {
      this._notificationService.notifty(`O produto ${this.form.value.titulo} foi removido com sucesso!.`);
      this.resetForm();
    });
  }

  resetForm() {
    this._router.navigate(['/produtos']);
  }
}
