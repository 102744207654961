import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Politico } from '../models';
import { TipoMidia } from '../enum/tipo-midia';

@Injectable()
export class PoliticoService {
    constructor(private http: HttpClient) { }

    politico: Politico;

    listar(pesquisa?: string): Observable<Politico[]> {
        let params: HttpParams;
        if (pesquisa) {
            params = new HttpParams().set('query', pesquisa);
        }
        return this.http.get<Politico[]>(`${environment.api}/politicos`, { params: params });
    }

    encontrar(id: string): Observable<Politico> {
        return this.http.get<Politico>(`${environment.api}/politicos/${id}`);
    }

    salvar(politico: Politico): Observable<Politico> {
        return this.http.post<Politico>(`${environment.api}/politicos`, politico);
    }

    remover(id: string): Observable<Politico> {
        return this.http.delete<Politico>(`${environment.api}/politicos/${id}`);
    }

    salvarMidia(blob: any, tipo: TipoMidia): Observable<string> {
        const formData = new FormData();
        formData.append('file', blob);

        return this.http.post<string>(`${environment.api}/politicos/salvarMidia/${tipo}`, formData, { responseType: 'text' as 'json' });
    }
}