import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { FiltroDto } from '../models';
import { Organizacao } from '../models/organizacao.model';

@Injectable()
  export class OrganizacaoService {

      constructor(private http: HttpClient) { }

      listar(pesquisa?: string): Observable<Organizacao[]> {
          let params: HttpParams;
          if (pesquisa) {
              params = new HttpParams().set('query', pesquisa);
          }
          return this.http.get<Organizacao[]>(`${environment.api}/organizacoes`, { params: params });
      }

      listarPorUf(uf: string): Observable<Organizacao[]> {
        return this.http.get<Organizacao[]>(`${environment.api}/organizacoes/uf/${uf}`);
      }

      listarPorUfECidade(uf: string, cidade: string): Observable<Organizacao[]> {
        return this.http.get<Organizacao[]>(`${environment.api}/organizacoes/uf/${uf}/cidade/${cidade}`);
      }

      encontrar(id: string): Observable<Organizacao> {
          return this.http.get<Organizacao>(`${environment.api}/organizacoes/${id}`);
      }

      salvar(organizacao: Organizacao): Observable<Organizacao> {
          return this.http.post<Organizacao>(`${environment.api}/organizacoes`, organizacao);
      }

      remover(id: string): Observable<Organizacao> {
          return this.http.delete<Organizacao>(`${environment.api}/organizacoes/${id}`);
      }
  }
