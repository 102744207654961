<nav aria-label="breadcrumb ">
  <ol class="breadcrumb b-radius breadcrumb-appckground mt-3">
    <li class="breadcrumb-item active breadcrumb-link-actual mt-1" aria-current="page"><i class="fal fa-unlock-alt"></i>&nbsp;Alterar senha</li>
  </ol>
</nav>
<form [formGroup]="passwordForm" novalidate autocomplete="off">
  <div class="flex-column">
    <div class="d-flex justify-content-center">
      <div class="col-sm-7 col-md-4 col-xl-3">
        <app-input label="Senha atual" [hidden]="!mostrarSenhaAtual">
          <input #firstElement type="password" formControlName="senhaAtual" class="form-control form-control-sm" />
        </app-input>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="col-sm-7 col-md-4 col-xl-3">
        <app-input label="Nova senha" errorMessage="Preencha com 6 ou mais caracteres." [isRequired]="true">
          <input #secondElement type="password" formControlName="novaSenha" class="form-control form-control-sm" />
        </app-input>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="col-sm-7 col-md-4 col-xl-3">
        <app-input label="Confirmar nova senha" errorMessage="Preencha com 6 ou mais caracteres." [isRequired]="true">
          <input type="password" formControlName="novaSenhaConfirmar" class="form-control form-control-sm" />
        </app-input>
        <small class="text-danger" *ngIf="passwordForm.errors && passwordForm.errors['senhasNaoCoincidem']">
          <i class="fal fa-exclamation-triangle"></i> &nbsp;As senhas não coincidem.
        </small>
      </div>
    </div>
    <hr class="mt-3" />
    <div class="d-flex justify-content-center">
      <button type="submit" class="btn btn-sm btn-secondary btn-on-full mr-2" (click)="salvar(passwordForm.value)"
        [disabled]="areSubmitting || this.passwordForm.invalid">
        <i class="fal fa-check"></i>&nbsp;Alterar</button>
      <button type="button" class="btn btn-sm btn-outline-secondary btn-on-full" (click)="resetForm()">
        <i class="fal fa-ban"></i>&nbsp;Cancelar</button>
    </div>
  </div>
</form>
