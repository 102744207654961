<div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 mb-3">
  <div class="row mt-3">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Gastos Públicos</h5>
          <form [formGroup]="gastosForm" novalidate autocomplete="off">
            <div class="row">
              <div class="col-6">
                <app-input label="Estado" [isRequired]="true">
                  <ng-select #firstElement [clearable]="false" [virtualScroll]="true" [selectOnTab]="true"
                    formControlName="uf" (change)="listarCidades()">
                    <ng-option value="" disabled>Selecione o Estado</ng-option>
                    <ng-option *ngFor="let uf of ufs" [value]="uf.uf">{{uf.nome}}</ng-option>
                  </ng-select>
                </app-input>
              </div>

              <div class="col-6">
                <app-input label="Cidade">
                  <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true" formControlName="cidade"
                    (change)="listarOrganizacoes()">
                    <ng-option value="" disabled>Selecione a Cidade</ng-option>
                    <ng-option *ngFor="let cidade of cidades" [value]="cidade.nome">{{cidade.nome}}</ng-option>
                  </ng-select>
                </app-input>
              </div>

              <div class="col-12">
                <app-input label="Organização" errorMessage="Este campo é obrigatório." [isRequired]="true">
                  <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true"
                    formControlName="organizacaoId" (change)="listarBeneficiarios()">
                    <ng-option value="" disabled>Selecione o Organização</ng-option>
                    <ng-option *ngFor="let organizacao of organizacoes" [value]="organizacao.id">
                      {{organizacao.nome}}
                    </ng-option>
                  </ng-select>
                </app-input>
              </div>

              <div class="col-6">
                <app-input label="Data Inicio">
                  <div class="input-group">
                    <input class="form-control form-control-sm" type="text" ngbDatepicker #dataInicio="ngbDatepicker"
                      formControlName="dataInicio" maxlength="10" />
                    <div class="input-group-append">
                      <button class="btn btn-sm btn-outline-secondary" (click)="dataInicio.toggle()" type="button">
                        <i class="fal fa-calendar"></i>
                      </button>
                    </div>
                  </div>
                </app-input>
              </div>

              <div class="col-6">
                <app-input label="Data Fim">
                  <div class="input-group">
                    <input class="form-control form-control-sm" type="text" ngbDatepicker #dataFim="ngbDatepicker"
                      formControlName="dataFim" maxlength="10" />
                    <div class="input-group-append">
                      <button class="btn btn-sm btn-outline-secondary" (click)="dataFim.toggle()" type="button">
                        <i class="fal fa-calendar"></i>
                      </button>
                    </div>
                  </div>
                </app-input>
              </div>

              <div class="col-12">
                <app-input label="Beneficiário">
                  <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true"
                    formControlName="beneficiarioId" (change)="listarCentroCustos()">
                    <ng-option value="" disabled>Selecione o Beneficiário</ng-option>
                    <ng-option *ngFor="let beneficiario of beneficiarios" [value]="beneficiario.id">
                      {{beneficiario.nome}}
                    </ng-option>
                  </ng-select>
                </app-input>
              </div>


              <div class="col-12">
                <app-input label="Centro de Custo">
                  <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true"
                    formControlName="centroCustoId">
                    <ng-option value="" disabled>Selecione o Centro de Custos</ng-option>
                    <ng-option *ngFor="let centroCusto of centroCustos" [value]="centroCusto.id">
                      {{centroCusto.nome}}
                    </ng-option>
                  </ng-select>
                </app-input>
              </div>

            </div>
          </form>
          <div class="row">
            <div class="col-6">
              <button type="button" [disabled]="gastosForm.invalid" class="btn btn-sm btn-primary float-left w-100"
                (click)="filtrarDto()">Filtrar</button>
            </div>
            <div class="col-6">
              <button type="button" class="btn btn-sm btn-outline-primary float-right w-100"
                (click)="limparFiltro()">Limpar
                Filtro</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="btn-fixed-top2">
    <button type="button" class="btn btn-secondary btn-radius" placement="bottom" ngbTooltip="Usar localização atual"
      (click)="usarLocalizacao()">
      <i class="fal fa-map-marker-alt"></i>
    </button>
  </div>

</div>
