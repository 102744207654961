<nav aria-label="breadcrumb ">
  <ol class="breadcrumb b-radius breadcrumb-appckground mt-3">
    <a class="breadcrumb-item mt-1" aria-current="page" [routerLink]="['/centro-custos']"><i
        class="far fa-vote-nay"></i>&nbsp;Centro de Custos</a>
    <li class="breadcrumb-item active breadcrumb-link-actual mt-1" aria-current="page">Adicionar</li>
    <li class="ml-auto">
      <button type="button" class="btn btn-sm btn-outline-primary btn-on" [routerLink]="['/centro-custos']">
        <i class="fal fa-list"></i>
        <span class="d-none d-lg-inline-flex">&nbsp;Listar</span>
      </button>
    </li>
  </ol>
</nav>

<form [formGroup]="centroCustoForm" novalidate autocomplete="off">
  <input type="hidden" formControlName="id" />
  <div class="row">
    <div class="col-6">
      <app-input label="Nome" errorMessage="Campo obrigatório." [isRequired]="true">
        <input #firstElement type="text" formControlName="nome" class="form-control form-control-sm"
          placeholder="Nome do centro de custo" />
      </app-input>
    </div>

    <div class="col-6">
      <app-input label="Código" errorMessage="Campo obrigatório." [isRequired]="true">
        <input type="text" formControlName="codigo" class="form-control form-control-sm"
          placeholder="Código do centro de custo" />
      </app-input>
    </div>

    <div class="col-md-6">
      <app-input label="Organização" errorMessage="Este campo é obrigatório." [isRequired]="true">
        <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true" formControlName="organizacaoId">
          <ng-option value="">Selecione a Organização</ng-option>
          <ng-option *ngFor="let organizacao of organizacoes" [value]="organizacao.id">{{organizacao.nome}}
          </ng-option>
        </ng-select>
      </app-input>
    </div>

    <div class="col-md-6">
      <app-input label="Tipo Centro de Custo" errorMessage="Este campo é obrigatório." [isRequired]="true">
        <ng-select [clearable]="false" [virtualScroll]="true" [selectOnTab]="true" formControlName="tipoCentroCusto">
          <ng-option [value]="tipoCentroCusto.Analitico">Analítico</ng-option>
          <ng-option [value]="tipoCentroCusto.Sintetico">Sintético</ng-option>
        </ng-select>
      </app-input>
    </div>
  </div>

  <button type="submit" class="btn btn-sm btn-primary btn-on-full mr-2" (click)="salvar(centroCustoForm.value)"
    [disabled]="areSubmitting || this.centroCustoForm.invalid">
    <i class="fal fa-check"></i>&nbsp;Salvar
  </button>

  <button type="button" class="btn btn-sm btn-outline-danger btn-on float-right" (click)="remover()"
    *ngIf="centroCustoForm.controls.id.value != null && areAdmin">
    <i class="fal fa-trash"></i>
    <span class="d-none d-lg-inline-flex">&nbsp;Remover</span>
  </button>
</form>
